import Typography from "@material-ui/core/Typography";
import IconFA from "@terminal-packages/ui/core/IconFA";
import { useTranslation } from "react-i18next";

import { BucketType } from "../..";
import useStyles from "./styles";

export type DomainStatusProps = Pick<BucketType, "domainStatus"> & {
  verifyDns: () => void;
};

const DomainStatus = ({ domainStatus, verifyDns }: DomainStatusProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (domainStatus === "PROPAGATED") {
    return null;
  }

  return (
    <div className={classes.domainStatus}>
      <div
        tabIndex={0}
        role="button"
        className={classes.warningWrapper}
        onClick={verifyDns}
        onKeyDown={verifyDns}
      >
        <div className={classes.warningIcon}>
          <IconFA
            icon={["fas", "exclamation-triangle"]}
            fontSize="inherit"
            color="inherit"
          />
        </div>
        <Typography className={classes.check}>
          {t("sites.tabs.settings.customDomains.checkDNS") as string}
        </Typography>
      </div>
    </div>
  );
};

export default DomainStatus;
