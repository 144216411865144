import get from 'lodash/get';

export const LOG_OUT_ITEM = {
  key: 'logout',
  icon: 'sign-out',
};

export const SETTINGS_ITEM = {
  key: 'settings',
  icon: 'cog',
};

const getConfig = (user = {}, t) => {
  if (user.loading || user.error) {
    return {
      user: {
        id: '',
        name: t('layout.userMenu.loading'),
      },
    };
  }
  
  const profilePicUrl = get(user, 'individual.profilePicUrl');

  return {
    user: {
      id: get(user, 'id', ''),
      email: get(user, 'email', ''),
      name: get(user, 'individual.firstname', ''),
      username: get(user, 'username', ''),
      ...(profilePicUrl && { profilePicUrl }),
    },
  };
};

export default getConfig;
