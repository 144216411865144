import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    position: 'absolute',
    zIndex: 10,
    bottom: 10,
    right: 30,
  },
  logo: {
    width: 60,
    cursor: 'pointer',
  },
}));
