import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkIfWeb3AccountLinked } from "src/views/AuthCallback/utils";

export const useWeb3LinkingFetcher = (uid) => {
  const [data, setData] = useState(undefined);
  const [isVerifying, setIsVerifying] = useState(false);
  const { accounts } = useSelector((state) => state.web3);

  const checkIsWeb3Linked = useCallback(async () => {
    try {
      setIsVerifying(true);

      const data = await checkIfWeb3AccountLinked(uid);
      setData(data);
    } catch (error) {
      console.error(error);
      setData(undefined);
    } finally {
      setIsVerifying(false);
    }
  }, [uid]);

  useEffect(() => {
    if (uid) {
      checkIsWeb3Linked();
    }
  }, [accounts, checkIsWeb3Linked, uid]);

  return {
    isWeb3Linked: data?.uid && uid && data?.uid === uid,
    linkedData: data,
    isLinkingVerifiying: isVerifying,
    checkIsWeb3Linked,
  };
};
