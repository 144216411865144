import PropTypes from "prop-types";
import config from "src/config";
import getAccountIdFromUrl from "src/utils/get-account-id-from-url";

import { OVERLAY_TYPES } from "../constants";
import manOnBrowserBackgroundSvg from "../images/man-on-browser-background.svg";

const ImageComponent = (props) => {
  return (
    <img src={manOnBrowserBackgroundSvg} alt="man on browser" {...props} />
  );
};

ImageComponent.defaultProps = {
  className: "",
};

ImageComponent.propTypes = {
  className: PropTypes.string,
};

const getOverlaysConfig = ({ history, classes }) => ({
  [OVERLAY_TYPES.MIGRATED_TEAM]: {
    ImageComponent,
    transKeys: {
      title: "migration.modal.title",
      subtitle: "migration.modal.subtitle",
      description: "migration.modal.description",
      goToDocs: "migration.modal.goToDocs",
      buttonText: "migration.modal.buttonText",
    },
    link: "xyz docs link",
    onClickButton: () =>
      window.open(config.migration.url, '_blank'),
    overrideClasses: {
      description: classes.thinnerDescription,
    },
  },
  [OVERLAY_TYPES.SITES_LIST]: {
    ImageComponent,
    transKeys: {
      title: "noDataInfo.sitesList.title",
      subtitle: "noDataInfo.sitesList.subtitle",
      description: "noDataInfo.sitesList.description",
      goToDocs: "noDataInfo.sitesList.goToDocs",
      buttonText: "noDataInfo.sitesList.buttonText",
    },
    link: "https://docs.fleek.co/hosting/overview/",
    onClickButton: () =>
      history.push(
        `/start/connect-repository?accountId=${getAccountIdFromUrl()}`
      ),
    overrideClasses: {
      description: classes.thinnerDescription,
    },
  },
});

export default getOverlaysConfig;
