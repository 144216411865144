import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { toast } from "@terminal-packages/ui";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import List from "@terminal-packages/ui/core/List";
import ListItem from "@terminal-packages/ui/core/ListItem";
import { ethers } from "ethers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openModal, WALLET_LINK_MODAL } from "src/modals/actions";
import { resetState } from "src/reducers/actions/web3-state";
import { useAuth } from "src/store/AuthContext";
import { unlinkWeb3Wallet } from "src/views/AuthCallback/utils";

import useStyles from "./styles";

const Crypto = () => {
  const [isUnlinking, setIsUnlinking] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    loading,
    isWeb3Linked,
    isLinkingVerifiying,
    isGithubProvider,
    isEmailProvider,
    linkedData,
    isWeb3Provider,
    checkIsWeb3Linked,
  } = useAuth();
  const { ens } = useSelector((state) => state.web3);

  const isEmailOrGithubProviders = isGithubProvider || isEmailProvider;

  const walletAddress =
    isWeb3Provider || isWeb3Linked ? linkedData?.address : null;

  const cryptoEntries = Object.entries({
    address: {
      value: walletAddress,
      fallback: "No wallet connected",
    },
    ens: {
      value: ens?.name,
      fallback: "No ENS detected",
    },
  });

  const openWalletLinkModal = () => dispatch(openModal(WALLET_LINK_MODAL, {}));
  const unlinkWallet = async () => {
    try {
      setIsUnlinking(true);
      await unlinkWeb3Wallet(ethers.utils.getAddress(walletAddress));
      await checkIsWeb3Linked();
      dispatch(resetState());
      toast.success(t("auth.unlinkSuccess"));
    } catch (error) {
      toast.error(t("auth.unlinkFailed"));
    } finally {
      setIsUnlinking(false);
    }
  };

  return (
    <>
      <Typography variant="body1" style={{ marginTop: "14px" }}>
        <Box component="span" fontWeight={500}>
          {t("accountSettings.crypto.title")}
        </Box>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {isEmailOrGithubProviders
          ? t("accountSettings.crypto.description")
          : t("accountSettings.crypto.description2")}
      </Typography>
      <br />
      <CardTitled
        mainContent={t("accountSettings.crypto.card.title")}
        classes={{
          content: classes.content,
        }}
      >
        {isEmailOrGithubProviders && (
          <Typography variant="body2" className={classes.linkingText}>
            {t("accountSettings.crypto.card.description")}
          </Typography>
        )}
        <List striped>
          {cryptoEntries.map(([key, object]) => (
            <ListItem key={key} className={classes.item}>
              <Typography
                variant="body2"
                color="textPrimary"
                className={classes.key}
              >
                {`${t(`accountSettings.crypto.card.${key}`)}:`}
              </Typography>
              {loading ? (
                <Skeleton
                  width={240}
                  height={20}
                  variant="rect"
                  animation="wave"
                  classes={{
                    root: classes.skeletonRoot,
                  }}
                />
              ) : (
                <Typography
                  color={object.value ? "textPrimary" : "textSecondary"}
                  noWrap
                  variant="body2"
                >
                  <Box component="span" fontWeight={500}>
                    {object.value || object.fallback}
                  </Box>
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
        {isEmailOrGithubProviders && (
          <div className={classes.buttonContainer}>
            <GenericButton
              disabled={isLinkingVerifiying || isUnlinking}
              loading={isLinkingVerifiying || isUnlinking}
              buttonVariant={isWeb3Linked ? "secondary" : "primary"}
              onClick={isWeb3Linked ? unlinkWallet : openWalletLinkModal}
            >
              {isWeb3Linked
                ? t("accountSettings.crypto.card.unlinkLabel")
                : t("accountSettings.crypto.card.linkLabel")}
            </GenericButton>
          </div>
        )}
      </CardTitled>
    </>
  );
};

export default Crypto;
