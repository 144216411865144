import { useMutation } from "@apollo/react-hooks";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import { toast } from "@terminal-packages/ui/core/Toast";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";
import { EditableList } from "src/components";
import { linkEmailToWallet } from "src/views/AuthCallback/utils";

import { UPDATE_BILLING_INFO } from "../../../../../graphql/mutations";
import { updateBillingInformationCache } from "../../utils";
import Skeleton from "./components/Skeleton";
import useStyles from "./styles";

const BillingInformation = ({ billingData, disabled, loading, refetchTeamBillingInfo, walletAddress, walletEmail }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { params } = useRouteMatch();

  const [updateBillingInfo, { loading: updateBillingLoading }] = useMutation(UPDATE_BILLING_INFO, {
    client: newApiClient,
    onCompleted: () => {
      toast.success(t("billingDetails.billingInformation.success"));
    },
  });

  const onSubmitChanges = async (values) => {
    try {
      if (walletAddress) {
        await linkEmailToWallet(walletAddress, values.email);
      }
      await updateBillingInfo({
        variables: {
          input: {
            teamId: params.teamId,
            name: values.name || null,
            email: values.email || null,
          },
        },
        update: updateBillingInformationCache(params.teamId, values),
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      if (refetchTeamBillingInfo) {
        refetchTeamBillingInfo();
      }
    }
  };

  const data = [
    {
      stateKey: "name",
      label: t("billingDetails.billingInformation.name"),
      value: get(billingData, "name", ""),
      placeholder: t("billingDetails.billingInformation.namePlaceholder"),
    },
    {
      stateKey: "email",
      label: t("billingDetails.billingInformation.email"),
      value: get(billingData, "email", "") || walletEmail,
      placeholder: t("billingDetails.billingInformation.emailPlaceholder"),
    },
  ];

  const getContent = () => {
    if (loading) {
      return (
        <>
          <Skeleton />
          <EditableList
            data={[]}
            disabled={loading}
            goToEditButtonText={t("common.edit")}
            goToEditButtonClass={classes.goToEditButton}
          />
        </>
      );
    }

    return (
      <EditableList
        data={data}
        disabled={disabled || updateBillingLoading}
        goToEditButtonText={t("common.edit")}
        goToEditButtonClass={classes.goToEditButton}
        onSubmitChanges={onSubmitChanges}
      />
    );
  };

  return (
    <CardTitled
      mainContent={t("billingDetails.billingInformation.title")}
      classes={{ content: classes.root }}
    >
      {getContent()}
    </CardTitled>
  );
};

BillingInformation.defaultProps = {
  disabled: false,
  billingData: {
    name: "",
    email: "",
  },
  refetchTeamBillingInfo: () => {},
  walletAddress: "",
  walletEmail: "",
};

BillingInformation.propTypes = {
  disabled: PropTypes.bool,
  billingData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  refetchTeamBillingInfo: PropTypes.func.isRequired,
  walletAddress: PropTypes.string,
  walletEmail: PropTypes.string,
};

export default BillingInformation;
