import { Grid, Switch, Typography } from "@material-ui/core";
import CardTitled from '@terminal-packages/ui/core/CardTitled';
import { toast } from "@terminal-packages/ui/core/Toast";
import axios from "axios";
import PropTypes from 'prop-types';
import { useState } from "react";
import { firebaseFunctionEndpoints } from "src/integrations/firebase/constants";

import useStyles from './styles';

const BlockOFAC = ({
  blockEnabled,
  i18n,
  siteId,
  loadingSite
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleChange = async (e) => {
    try {
      setLoading(true);
      const enabled = e.target.checked;
      await axios.post(firebaseFunctionEndpoints.blockOFACCountries, {
        data: {
          enabled,
          siteId,
        }
      })
      toast.success(enabled ? i18n.successEnabled : i18n.successDisabled);
    } catch (error) {
      toast.error(`${i18n.error}: ${error.message}`, {
        autoClose: 6000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardTitled
      mainContent={i18n.title}
      classes={{
        content: classes.sectionContent,
      }}
    >
      <Typography variant="body2">{i18n.subtitle}</Typography>
      <br />
      {
        loadingSite
          ? (
            <Typography variant="body2">Loading...</Typography>
          )
          : (
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="body2">Disabled</Typography>
              </Grid>
              <Grid item>
                <Switch
                  defaultChecked={blockEnabled}
                  disabled={loading || loadingSite}
                  onChange={handleChange}
                  color='primary'
                />
              </Grid>
              <Grid item>
                <Typography variant="body2">Enabled</Typography>
              </Grid>
            </Grid>
          )
      }
    </CardTitled>
  );
};

BlockOFAC.propTypes = {
  i18n: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    successEnabled: PropTypes.string,
    successDisabled: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
  blockEnabled: PropTypes.bool.isRequired,
  loadingSite: PropTypes.bool.isRequired,
};

export default BlockOFAC;
