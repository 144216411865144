// @ts-nocheck
import { useMutation } from "@apollo/react-hooks";
import { Elements } from "@stripe/react-stripe-js";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import { toast } from "@terminal-packages/ui/core/Toast";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { newApiClient } from "src/clients";
import {
  ATTACH_PAYMENT_METHOD,
  SETUP_BILLING_INTENT,
  SETUP_BILLING_INTENT_WITHOUT_TEAM_ID,
} from "src/graphql/mutations";
import { GET_TEAM_BY_ID } from "src/graphql/queries";
import { useAccountId } from "src/hooks";
import { stripePromise } from "src/integrations/stripe_";
import { useAuth } from "src/store/AuthContext";
import { checkIfWeb3AccountLinked } from "src/views/AuthCallback/utils";

import { deleteClientSecret, saveClientSecret } from "./actions";
import PaymentMethodForm from "./PaymentMethodForm";
import presenter from "./presenter";
import { SetEmailModal } from "./SetEmailModal";
import useStyles from "./styles";

export const PaymentMethodModal = (props) => {
  const { open, useTeamId, closeModal, onSuccess: onSuccessCB } = props;
  const { t } = useTranslation();
  const { i18n } = presenter(t);

  const { currentUser, isWeb3Provider, linkedData } = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [error, setError] = useState([]);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [billingEmail, setBillingEmail] = useState('');
  const [loadingWalletInfo, setLoadingWalletInfo] = useState(true);

  const teamId = useAccountId();

  const [setupBillingIntent, { loading: setupIntentLoading }] = useMutation(SETUP_BILLING_INTENT, {
    client: newApiClient,
  });

  const [setupBillingIntentWithoutTeamId, { loading: setupIntentWithoutTeamLoading }] = useMutation(
    SETUP_BILLING_INTENT_WITHOUT_TEAM_ID,
    { client: newApiClient }
  );

  const [attachPaymentMethod] = useMutation(ATTACH_PAYMENT_METHOD, {
    client: newApiClient,
  });

  const clientSecret = useSelector((state) => state.billing.clientSecretId);

  const onSuccess = async (stripeResponse) => {
    dispatch(deleteClientSecret());

    let isPaymentMethodAdded = false;

    if (!stripeResponse.error) {
      if (useTeamId) {
        try {
          const result = await attachPaymentMethod({
            variables: {
              teamId,
              paymentMethodId: get(
                stripeResponse,
                "setupIntent.payment_method"
              ),
            },
            refetchQueries: [
              {
                query: GET_TEAM_BY_ID,
                variables: { id: teamId },
              },
            ],
          });

          isPaymentMethodAdded = result.data;
        } catch (error) {
          console.error(error);
        }
      } else {
        isPaymentMethodAdded = true;
      }

      const toastTrigger = isPaymentMethodAdded ? toast.success : toast.error;
      const message = isPaymentMethodAdded
        ? "modals.paymentMethod.success"
        : "modals.paymentMethod.error";

      toastTrigger(t(message), { autoClose: 6000 });

      closeModal();
      onSuccessCB(stripeResponse);
    }
  };

  useEffect(() => {
    const getClientSecret = async () => {
      try {
        const setupBillingMutation = useTeamId
          ? setupBillingIntent
          : setupBillingIntentWithoutTeamId;

        const mutationName = useTeamId
          ? "setupBillingIntent"
          : "setupBillingIntentWithoutTeam";

        const result = await setupBillingMutation({
          variables: {
            ...(useTeamId && { teamId }),
            billingEmail
          },
        });

        const intentId = get(result, `data.${mutationName}.intentId`);

        if (intentId) {
          dispatch(saveClientSecret(intentId));
        }
      } catch (error) {
        console.error(error);
        setError([get(i18n, "error.internal", "")]);
      }
    };

    const missingEmail = isWeb3Provider && !get(currentUser, "email") && (!billingEmail || loadingWalletInfo);
    if (!clientSecret && !missingEmail) {
      getClientSecret();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingEmail]);

  useEffect(() => {
    const getWalletEmail = async () => {
      try {
        setLoadingWalletInfo(true);
        const walletData = await checkIfWeb3AccountLinked(linkedData.uid);
        if (!get(walletData, "email")) {
          setOpenEmailModal(true);
        } else {
          setBillingEmail(get(walletData, "email"));
        }
      } catch (error) {
        console.error(error);
        setError([get(i18n, "error.internal", "")]);
      } finally {
        setLoadingWalletInfo(false);
      }
    };

    if (isWeb3Provider && !get(currentUser, "email")) {
      getWalletEmail();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isWeb3Provider && !get(currentUser, "email") && (!billingEmail || loadingWalletInfo)
      ? (
        <SetEmailModal
          open={openEmailModal}
          closeModal={() => closeModal()}
          i18n={i18n}
          className={classes.form}
          walletAddress={linkedData?.address}
          setBillingEmail={setBillingEmail}
        />
      )
      : (
        <BaseModal
          open={open}
          maxWidth={460}
          onClose={() => closeModal()}
          title={i18n.title}
          disableAutoFocus
          disableEnforceFocus
        >
          <Elements stripe={stripePromise}>
            <PaymentMethodForm
              i18n={i18n}
              onSuccess={onSuccess}
              className={classes.form}
              clientSecret={clientSecret}
              error={error}
              setError={setError}
              loading={setupIntentLoading || setupIntentWithoutTeamLoading}
            />
          </Elements>
        </BaseModal>
      )
  );
};

PaymentMethodModal.defaultProps = {
  open: false,
  useTeamId: true,
  onSuccess: () => {},
  closeModal: () => {},
};

PaymentMethodModal.propTypes = {
  open: PropTypes.bool,
  onSuccess: PropTypes.func,
  closeModal: PropTypes.func,
  useTeamId: PropTypes.bool,
};
