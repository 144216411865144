import ListItem from '@terminal-packages/ui/core/ListItem';
import Skeleton from '@terminal-packages/ui/core/Skeleton';
import React from 'react';

import useStyles from './styles';


const SiteRowSkeleton = () => {
  const classes = useStyles();

  return (
    <ListItem className={classes.rowContainer}>
      <div className={classes.imageContainer}>
        <Skeleton width={66} height={48} />
      </div>
      <div className={classes.siteInfoContainer}>
        <Skeleton width={133} height={19} />
        <Skeleton width={166} height={14} />
      </div>
      <div className={classes.siteOwnerInfoContainer}>
        <Skeleton width={202} height={19} />
        <Skeleton width={264} height={14} />
      </div>
      <div className={classes.endArrowContainer}>
        <Skeleton width={16} height={22} />
      </div>
    </ListItem>
  );
};

export default SiteRowSkeleton;
