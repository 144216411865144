// @ts-nocheck
import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import { newApiClient } from "src/clients";
import getAccountIdFromUrl from "src/utils/get-account-id-from-url";

import GET_GITHUB_TOKEN_QUERY from "./get-github-token-query";

export const useGithubToken = (queryOptions = {}) => {
  const teamId = getAccountIdFromUrl();
  const {
    data: githubTokenData,
    refetch: refetchGetGithubToken,
    loading: loadingGetGithubToken,
  } = useQuery(GET_GITHUB_TOKEN_QUERY, {
    client: newApiClient,
    variables: { teamId },
    skip: !teamId,
    ...queryOptions,
  });

  const githubToken = get(githubTokenData, "getGithubToken");
  return [loadingGetGithubToken, githubToken, refetchGetGithubToken];
};
