import get from "lodash/get";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AutoPublishButton, Header } from "src/components";
import { isSourceTypeLocal } from "src/utils/get-source-type";

import { SettingsButton } from "../shared/components";
import useStyles from "./styles";

const TEXT_LOADING = "...";

const isDataReady = (data) => !data.filter((v) => v === undefined).length;

const getSubtitle = (t, autoPublishing, isDeployLocal) => {
  if (typeof autoPublishing === "undefined") return TEXT_LOADING;

  if (isDeployLocal) {
    return t("sites.tabs.deploys.sections.deployInfo.subtitleLocal");
  }
  return t("sites.tabs.deploys.sections.deployInfo.subtitle", {
    isAuto: t(
      `sites.tabs.deploys.sections.deployInfo.${autoPublishing ? "on" : "off"}`
    ),
  });
};

const getDescription = (t, autoPublishing, isDeployLocal, branch) => {
  if (typeof autoPublishing === "undefined") return TEXT_LOADING;

  if (isDeployLocal) {
    return t("sites.tabs.deploys.sections.deployInfo.descriptionLocal");
  }
  return t(
    `sites.tabs.deploys.sections.deployInfo.${
      autoPublishing ? "descriptionAuto" : "descriptionNotAuto"
    }`,
    {
      branch,
    }
  );
};

const DeployInfo = (props) => {
  const { siteBySlug } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const autoPublishing = get(
    siteBySlug,
    "data.getSiteBySlug.deploySettings.autoPublishing"
  );
  const siteId = get(siteBySlug, "data.getSiteBySlug.id");
  const branch = get(
    siteBySlug,
    "data.getSiteBySlug.deploySettings.source.branch",
    "master"
  );
  const source = get(
    siteBySlug,
    "data.getSiteBySlug.deploySettings.source",
    {}
  );
  const platform = get(siteBySlug, "data.getSiteBySlug.platform");
  const isDeployLocal = isSourceTypeLocal(source);

  return (
    <Header
      title={t("sites.tabs.deploys.sections.deployInfo.title")}
      subtitle={getSubtitle(t, autoPublishing, isDeployLocal)}
      description={getDescription(t, autoPublishing, isDeployLocal, branch)}
    >
      {!isDeployLocal && (
        <div className={classes.buttonsContainer}>
          <SettingsButton />
          {isDataReady([autoPublishing, siteId]) && platform !== "dfinity" && (
            <AutoPublishButton
              autoPublishing={autoPublishing}
              siteId={siteId}
            />
          )}
        </div>
      )}
    </Header>
  );
};

DeployInfo.defaultProps = {
  siteBySlug: {},
};

DeployInfo.propTypes = {
  siteBySlug: PropTypes.shape({}),
};

export default DeployInfo;
