import { makeStyles } from "@material-ui/core";

export default makeStyles({
  content: {
    padding: "3px 0 15px 0",
  },
  item: {
    height: 46,
    padding: "0 20px",
    alignItems: "center",
  },
  buttonContainer: {
    padding: "0 20px",
    marginTop: "20px",
  },
  key: {
    minWidth: 202,
  },
  skeletonRoot: {
    margin: "20px",
    borderRadius: 4,
  },
  editIconAndTextContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  editIcon: {
    marginLeft: 5,
    "& svg": {
      width: 14,
      height: 14,
    },
  },
});
