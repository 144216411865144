import { useEffect, useRef, useState } from "react";
import { useGithub } from "src/components";
import { useGithubInstallationLink, useGithubToken } from "src/hooks";

const useFetchGithubToken = (onTokenFetched: () => void) => {
  const permissionsWereCompleted = useRef(false);
  const { isSettingPermissionsCompleted, openGithubPermissionsPopup, popupOpen } =
    useGithubInstallationLink();
  const [permissionsPopupWasOpened, setPermissionsPopupWasOpened] = useState(false);

  if (isSettingPermissionsCompleted) {
    permissionsWereCompleted.current = true;
  }

  const [isGithubTokenLoading, githubToken, refetch] = useGithubToken();
  const {
    hasInstallations: validInstallation,
    setHasInstallations,
    refetchData
  } = useGithub({ 
    onError: () => {}, 
    onSuccess: () => {} 
  });

  useEffect(() => {
    if (!isGithubTokenLoading && githubToken && validInstallation) {
      onTokenFetched();
    }
  }, [isGithubTokenLoading, githubToken, onTokenFetched, validInstallation]);

  useEffect(() => {
    const refetchToken = async () => {
      await refetch();
      // This refetches installations
      setHasInstallations(undefined);
    }

    if (!popupOpen && permissionsPopupWasOpened) {
      refetchToken();
    }

    setPermissionsPopupWasOpened(popupOpen)
  }, [popupOpen, permissionsPopupWasOpened, refetch, refetchData, setHasInstallations])

  return [openGithubPermissionsPopup, validInstallation];
};

export default useFetchGithubToken;
