import { SOURCE_TYPES } from "src/constants";

const getSourceType = (source = {}) => {
  // Right now we recognise local sources by whether or not they have a CID field
  // in the future, as we add more sources, we will have a source field that will include
  // github, local, bitbucket, etc...
  if (source.cid) {
    return SOURCE_TYPES.LOCAL;
  }
  return SOURCE_TYPES.GITHUB;
};

export const isSourceTypeLocal = (source) =>
  getSourceType(source) === SOURCE_TYPES.LOCAL;

export default getSourceType;
