import Skeleton from "@material-ui/lab/Skeleton";

import useStyles from "./styles";

const skeletonsWidthsPercentage = [52, 80, 67, 43, 31];

export const ReposListPlaceholder = () => {
  const classes = useStyles();

  return (
    <>
      {skeletonsWidthsPercentage.map((width) => (
        <Skeleton
          key={width}
          width={`${width}%`}
          height={29}
          variant="rect"
          animation="wave"
          classes={{
            root: classes.skeletonRoot,
          }}
        />
      ))}
    </>
  );
};
