import gql from "graphql-tag";

import { BUCKET_DETAIL } from "../fragments";

export default gql`
  mutation verifyStorageDns($input: VerifyStorageDnsInput!) {
    verifyStorageDns(input: $input) {
      ...BucketDetail
    }
  }
  ${BUCKET_DETAIL}
`;
