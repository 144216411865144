import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import { useEffect } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";
import {
  GET_TEAM_BILLING_INFORMATION,
  GET_TEAM_BY_ID,
} from "src/graphql/queries";
import { useAuth } from "src/store/AuthContext";

import BillingSettingsNav from "./components/BillingSettingsNav";
import HeaderCard from "./components/HeaderCard";
import useStyles from "./styles";

const Billing = () => {
  const classes = useStyles();
  const { displayName } = useAuth();
  const match = useRouteMatch();
  const location = useLocation();

  const { data: billingInfoData, loading: billingInfoLoading } = useQuery(
    GET_TEAM_BILLING_INFORMATION,
    {
      client: newApiClient,
      fetchPolicy: "cache-and-network",
      variables: {
        teamId: match.params.teamId,
      },
    }
  );

  const { data: teamInfoData, loading: teamInfoLoading } = useQuery(
    GET_TEAM_BY_ID,
    {
      client: newApiClient,
      variables: {
        id: match.params.teamId,
      },
    }
  );
  const teamName = get(teamInfoData, "getTeamById.name");

  const loading = billingInfoLoading || teamInfoLoading;

  useEffect(() => {
    window.analytics.page("Billing", {
      path: location.pathname,
      search: location.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <HeaderCard
        recipient={teamName === "My team" ? displayName : teamName}
        startDate={get(
          billingInfoData,
          "getTeamBillingInformation.activePlan.activatedAt",
          ""
        )}
        endDate={get(
          billingInfoData,
          "getTeamBillingInformation.activePlan.expiresAt",
          ""
        )}
        loading={loading}
      />
      <div className={classes.navMenuContainer}>
        <BillingSettingsNav billingInfo={billingInfoData} loading={loading} />
      </div>
    </div>
  );
};

export default Billing;
