// @ts-ignore
import { useLocation } from "react-router";
import { url } from "src/utils";

export const useAccountId = () => {
  const location = useLocation();
  const accountId = url.getAccountIdFromUrl(location);

  return accountId;
};
