import Typography from '@material-ui/core/Typography';
import ArrowLink from '@terminal-packages/ui/core/ArrowLink';
import Box from '@terminal-packages/ui/core/Box';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const FleekCliLogin = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <img
        className={classes.logo}
        src="https://storage.googleapis.com/terminal-assets/images/fleek/fleek-logo.png"
        alt="fleek-logo"
      />
      <Box
        overrideClass={{
          wrapper: classes.box,
        }}
      >
        <Typography className={classes.title}>
          {t('fleekCliLogin.title')}
        </Typography>
        <Typography className={classes.description}>
          {t('fleekCliLogin.message')}
        </Typography>
        <a
          href="https://docs.fleek.co/fleek-cli/overview/#retrieving-and-using-an-api-key"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.resetAnchorStyles}
        >
          <ArrowLink className={classes.link}>
            {t('fleekCliLogin.learnMore')}
          </ArrowLink>
        </a>
      </Box>
    </div>
  );
};

export default FleekCliLogin;
