import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: '3px 0 15px 0',
  },
  item: {
    height: 46,
    padding: '0 20px',
    alignItems: 'center',
  },
  key: {
    minWidth: 202,
  },
  skeletonRoot: {
    borderRadius: 4,
  },
  editIconAndTextContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  editIcon: {
    marginLeft: 5,
    '& svg': {
      width: 14,
      height: 14,
    },
  },
});
