import { useMutation } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import PropTypes from "prop-types";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";
import { RETRY_DEPLOY } from "src/views/Sites/graphql/mutations";

import {
  onRetryDeployCache,
  onRetryDeployError,
  onRetryDeployOnCompleted,
} from "../../utils";

const RetryButton = ({ disabled, siteId, t }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const {
    params: { deployId },
  } = match;

  const [onClickRetry, { loading, response }] = useMutation(RETRY_DEPLOY, {
    client: newApiClient,
    variables: {
      siteId,
      deployId,
    },
    update: onRetryDeployCache(siteId, deployId),
    onCompleted: (mutationData) =>
      onRetryDeployOnCompleted(mutationData, match, history),
    onError: () => onRetryDeployError(response),
  });

  return (
    <GenericButton
      disabled={disabled || loading}
      loading={loading}
      onClick={onClickRetry}
      buttonVariant="primary"
    >
      <Typography variant="body2">
        {t("sites.tabs.deploys.sections.deployStatus.retryDeploy")}
      </Typography>
    </GenericButton>
  );
};

RetryButton.defaultProps = {
  siteId: null,
};

RetryButton.propTypes = {
  siteId: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default RetryButton;
