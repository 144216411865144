// @ts-nocheck
import PropTypes from "prop-types";
import { useLocation } from "react-router";
import { Redirect, Route } from "react-router-dom";
import config from "src/config";

import { useAuth } from "../../store/AuthContext";

export const UnauthRoute = ({ children, ...rest }) => {
  const { currentUser, isGithubProvider } = useAuth();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={() => {
        const shouldRedirectToVerifyEmail =
          location.pathname !== "/auth/verify-email" &&
          currentUser?.email &&
          !currentUser?.emailVerified &&
          !isGithubProvider &&
          config.auth.emailVerificationEnabled;

        const shouldRedirectToApp =
          currentUser &&
          (currentUser?.emailVerified ||
            !currentUser?.email ||
            isGithubProvider);

        if (shouldRedirectToVerifyEmail) {
          return <Redirect to="/auth/verify-email" />;
        }

        if (shouldRedirectToApp) {
          return <Redirect to="/" />;
        }

        return children;
      }}
    />
  );
};

UnauthRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
