import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { DOMAIN_STATUS } from "src/constants";

import useStyles from "./styles";

const DomainInfo = ({ domain, status }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.domainInfo}>
      {status === DOMAIN_STATUS.PROPAGATED ? (
        <Typography variant="body2">
          <a
            href={`http://${domain}`}
            target="_blank"
            rel="noopener noreferrer"
            className={classnames(classes.domainText, classes.activeText)}
          >
            {domain}
          </a>
        </Typography>
      ) : (
        <Typography
          variant="body2"
          className={classnames(classes.domainText, classes.inactiveText)}
        >
          {domain}
        </Typography>
      )}
      <Typography className={classes.typeText}>
        {t("sites.tabs.settings.hns.hnsDomain")}
      </Typography>
    </div>
  );
};

DomainInfo.propTypes = {
  domain: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default DomainInfo;
