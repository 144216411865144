import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import GenericButton from '@terminal-packages/ui/core/Buttons/GenericButton';
import CardTitled from '@terminal-packages/ui/core/CardTitled';
import IconFA from '@terminal-packages/ui/core/IconFA';
import List from '@terminal-packages/ui/core/List';
import ListItem from '@terminal-packages/ui/core/ListItem';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const Api = ({
  generateApiOnClick,
  handleCopy,
  apiKeys,
  loading,
  i18nKey,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getApiKeyList = () => {
    if (apiKeys.length === 0) {
      return (
        <ListItem className={classes.item}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.key}
          >
            {`${t(`accountSettings.${i18nKey}.card.apiKey`)}:`}
          </Typography>
          <Typography noWrap variant="body2" className={classes.placeholderText}>
            <Box component="span" fontWeight={500}>
              {t(`accountSettings.${i18nKey}.card.placeholder`)}
            </Box>
          </Typography>
        </ListItem>
      );
    }
    return (
      apiKeys.map(({ key }) => (
        <ListItem key={key} className={classes.item}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.key}
          >
            {`${t(`accountSettings.${i18nKey}.card.apiKey`)}:`}
          </Typography>
          <Typography noWrap variant="body2">
            <Box component="span" fontWeight={500}>
              {key}
            </Box>
          </Typography>
          <div className={classes.iconContainer}>
            <IconFA
              icon={['fal', 'copy']}
              onClick={handleCopy({ t, apiKey: key })}
            />
          </div>
        </ListItem>
      ))
    );
  };

  return (
    <>
      <Typography variant="body1">
        <Box component="span" fontWeight={500}>
          {t(`accountSettings.${i18nKey}.title`)}
        </Box>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t(`accountSettings.${i18nKey}.description`)}
      </Typography>
      <br />
      <CardTitled
        mainContent={t(`accountSettings.${i18nKey}.card.title`)}
        classes={{
          content: classes.content,
        }}
      >
        <List striped>
          {getApiKeyList()}
        </List>
        <br />
        <div className={classes.buttonContainer}>
          <GenericButton
            disabled={loading}
            buttonVariant="primary"
            onClick={generateApiOnClick}
          >
            {t(`accountSettings.${i18nKey}.card.new`)}
          </GenericButton>
        </div>
      </CardTitled>
    </>
  );
};

Api.propTypes = {
  handleCopy: PropTypes.func.isRequired,
  apiKeys: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    secret: PropTypes.string,
  })).isRequired,
  loading: PropTypes.bool.isRequired,
  i18nKey: PropTypes.string.isRequired,
  generateApiOnClick: PropTypes.func.isRequired,
};

export default Api;
