import { useMutation } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { toast } from "@terminal-packages/ui/core/Toast";
import get from "lodash/get";
import PropTypes from "prop-types";
import qs from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Element } from "react-scroll";
import { newApiClient } from "src/clients";
import { DOMAIN_TYPE, GA_EVENTS_CATEGORIES, PROXY_TYPES } from "src/constants";
import { VERIFY_ENS_IPNS } from "src/graphql/mutations";
import { HnsModal, SetEnsControllerModal } from "src/modals";
import {
  CONFIRM_DNS_UPGRADE_MODAL,
  DNS_A_RECORD_MODAL,
  DNS_CNAME_MODAL,
  openModal,
} from "src/modals/actions";
import { url } from "src/utils";

import { SECTION_IDS } from "../../../get-navigation-items";
import {
  VERIFY_DNS_CONFIGURATION,
  VERIFY_HNS_CONFIGURATION,
} from "../../../graphql/mutations";
import CustomDomains from "../../Subsections/CustomDomains";
import Ens from "../../Subsections/Ens";
import Handshake from "../../Subsections/Handshake";
import getI18n from "./i18n";
import useStyles from "./styles";
import handleSetContentHash from "./utils/ens/handle-set-content-hash";
import { getPurchasedDomainPendings } from "./utils/purchased-domain-pending";
import { onVerifyDnsOnCompleted } from "./utils/verify-dns";

const DomainManagement = ({ siteBySlug }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [dnsError, setDnsError] = useState(false);
  const [hnsError, setHnsError] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState({});
  const [selectedHnsDomain, setSelectedHnsDomain] = useState({});
  const [hnsModalState, setHnsModalState] = useState(false);
  const [ensControllerModalState, setEnsControllerModalState] = useState({
    error: null,
    loading: false,
    open: false,
  });

  const siteId = get(siteBySlug, "data.getSiteBySlug.id");
  const ipns = get(siteBySlug, "data.getSiteBySlug.ipns", null);
  const domains = get(siteBySlug, "data.getSiteBySlug.domains", []);
  const ensDomain = get(siteBySlug, "data.getSiteBySlug.ensDomain");
  const platform = get(siteBySlug, "data.getSiteBySlug.platform");
  const dnsCNAMERecord =
    platform === "dfinity" ? "ic0-lb.fleek.co" : "ipfs-ha.fleek.co";
  const pullZoneUrl = get(siteBySlug, "data.getSiteBySlug.pullZoneUrl");
  const siteSlug = get(siteBySlug, "data.getSiteBySlug.slug");
  const { loading } = siteBySlug;
  const classes = useStyles();

  const { t } = useTranslation();

  const i18n = getI18n(t);

  const [verifyEnsIpns] = useMutation(VERIFY_ENS_IPNS, {
    client: newApiClient,
  });

  const setEnsControllerOnClick = () => {
    setEnsControllerModalState({
      ...ensControllerModalState,
      open: true,
    });
  };

  const setContentHashOnSubmit = () => {
    handleSetContentHash({
      state: ensControllerModalState,
      setState: setEnsControllerModalState,
      ensDomain,
      siteId,
      t,
      verifyEnsIpns,
      verifyOwnership: false,
      ipns,
      classes,
    });
  };

  useEffect(() => {
    /* eslint-disable camelcase */
    const queryParams = qs.parse(location.search);
    const { errorPayment, successPayment } = queryParams;

    const redirectUrl = url.buildUrl(null, null, [
      "successPayment",
      "errorPayment",
    ]);

    if (successPayment) {
      toast.success(t("sites.tabs.settings.customDomains.successPayment"), {
        autoClose: 6000,
      });
    } else if (errorPayment) {
      toast.error(t("sites.tabs.settings.customDomains.errorPayment"), {
        autoClose: 6000,
      });
    }

    history.push(redirectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [verifyDns] = useMutation(VERIFY_DNS_CONFIGURATION, {
    client: newApiClient,
    onCompleted: () =>
      onVerifyDnsOnCompleted(
        setDnsError
        // setDnsCnameModalState,
        // setDnsARecordModalState,
      ),
  });

  const [verifyHns, { loading: verifyHnsLoading }] = useMutation(
    VERIFY_HNS_CONFIGURATION,
    {
      client: newApiClient,
      onCompleted: () => {
        setHnsError(false);
        setHnsModalState(false);
      },
    }
  );

  const checkHNSOnClick = (domain) => {
    setSelectedHnsDomain(domain);
    setHnsModalState(true);
  };

  const getDomains = () => (
    <>
      <Typography variant="body1">{i18n.domains.title}</Typography>
      <Typography className={classes.paragraph}>
        {i18n.domains.paragraph}
      </Typography>
    </>
  );

  const getEns = () => (
    <>
      <Typography variant="body1">{i18n.ens.title}</Typography>
      <Typography className={classes.paragraph}>
        {i18n.ens.paragraph}
      </Typography>
    </>
  );

  const getHns = () => (
    <>
      <Typography variant="body1">{i18n.hns.title}</Typography>
      <Typography className={classes.paragraph}>
        {i18n.hns.paragraph}
      </Typography>
    </>
  );

  const verifyHnsConfiguration = async () => {
    setHnsError(false);
    try {
      await verifyHns({
        variables: {
          input: {
            siteId,
            domain: selectedHnsDomain.domain,
            domainId: selectedHnsDomain.domainId,
          },
        },
      });
    } catch (e) {
      setHnsError(true);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const verifyDnsConfiguration = async (domain) => {
    window.ga(
      "send",
      "event",
      GA_EVENTS_CATEGORIES.SITES,
      "Verify DNS configuration"
    );

    const eventProperties = {
      siteId,
      domain: selectedDomain.domain,
      domainId: selectedDomain.domainId,
      teamId: url.getAccountIdFromUrl(),
    };

    try {
      await verifyDns({
        variables: {
          input: {
            siteId,
            domainId: domain.domainId,
          },
        },
      });

      window.analytics.track("Verify DNS configuration", eventProperties);
    } catch (e) {
      eventProperties.error = e.message;
      window.analytics.track(
        "Failed Verify DNS configuration",
        eventProperties
      );
      // eslint-disable-next-line no-console
      console.error(e);

      return false;
    }

    return true;
  };

  const checkDNSOnClick = (domain) => {
    const isSubDomain = /^[\w,-]+\.([\w,-]+\.)+[\w,-]+$/.test(domain.domain);

    window.ga(
      "send",
      "event",
      GA_EVENTS_CATEGORIES.SITES,
      "Check DNS Configuration"
    );
    window.analytics.track("Check DNS Configuration", {
      siteId,
      domain: domain.domain,
      domainId: selectedDomain.domainId,
      teamId: url.getAccountIdFromUrl(),
    });

    setSelectedDomain(domain);

    // Check confirmation
    if (
      domain.proxyType !== PROXY_TYPES.BUNNY &&
      domain.type !== DOMAIN_TYPE.DEFAULT_SUBDOMAIN
    ) {
      dispatch(
        openModal(CONFIRM_DNS_UPGRADE_MODAL, {
          siteId,
          teamId: url.getAccountIdFromUrl(),
          domainId: domain.domainId,
          customDomain: domain.domain,
          siteSlug,
        })
      );
      return;
    }

    if (isSubDomain) {
      // CNAME for subdomain

      dispatch(
        openModal(DNS_CNAME_MODAL, {
          onSubmit: () => verifyDnsConfiguration(domain),
          domain: domain.domain,
          proxyType: domain.proxyType,
          terminalRecord: dnsCNAMERecord,
          pullZoneUrl,
        })
      );

      return;
    }
    // A record for domain

    dispatch(
      openModal(DNS_A_RECORD_MODAL, {
        onSubmit: () => verifyDnsConfiguration(domain),
        server: "http://rihana.ns.cloudflare.com/",
        ip: "44.233.93.166",
        domain: domain.domain,
        error: dnsError,
        proxyType: domain.proxyType,
        pullZoneUrl,
      })
    );
  };

  const isPurchasedDomainPending =
    getPurchasedDomainPendings(domains).length > 0;

  return (
    <div id="test-sites-domain-managment">
      <div id={SECTION_IDS.CUSTOM_DOMAINS}>
        <Element name={SECTION_IDS.CUSTOM_DOMAINS} />
        <div className={classes.titleWrapper}>{getDomains()}</div>
        <div className={classes.sectionWrapper}>
          {isPurchasedDomainPending && (
            <Alert className={classes.pollingMessage} severity="info">
              {t("sites.tabs.settings.customDomains.pollingMessage")}
            </Alert>
          )}
          <CustomDomains
            siteId={siteId}
            domains={domains}
            checkDNSOnClick={checkDNSOnClick}
            loading={loading}
            platform={platform}
            siteBySlug={siteBySlug}
          />
        </div>
      </div>
      {platform === "ipfs" && (
        <div id={SECTION_IDS.ENS}>
          <Element name={SECTION_IDS.ENS} />
          <div className={classes.titleWrapper}>{getEns()}</div>
          <div className={classes.sectionWrapper}>
            <Ens
              ensInfo={ensDomain}
              setEnsControllerOnClick={setEnsControllerOnClick}
              loading={loading}
              siteId={siteId}
              ipns={ipns}
            />
          </div>
        </div>
      )}
      <div id={SECTION_IDS.HNS}>
        <Element name={SECTION_IDS.HNS} />
        <div className={classes.titleWrapper}>{getHns()}</div>
        <div className={classes.sectionWrapper}>
          <Handshake
            siteId={siteId}
            domains={domains}
            checkHNSOnClick={checkHNSOnClick}
            loading={false}
          />
        </div>
      </div>
      {/* CNAME modal
        <DnsCnameModal
            open={dnsCnameModalState}
            onClose={() => {
              setDnsCnameModalState(false);
              setDnsError(false);
            }}
            loading={verifyDnsLoading}
            onSubmit={() => verifyDnsConfiguration()}
            domain={selectedDomain.domain}
            terminalRecord={dnsCNAMERecord}
            error={dnsError}
            shouldConfirm={dnsCnameModalConfirm}
        />
        */}
      {/* A record modal
          <DnsARecordModal
          open={dnsARecordModalState}
          onClose={() => {
            setDnsARecordModalState(false);
            setDnsError(false);
          }}
          loading={verifyDnsLoading}
          onSubmit={() => verifyDnsConfiguration()}
          server="http://rihana.ns.cloudflare.com/"
          ip="44.233.93.166"
          domain={selectedDomain.domain}
          error={dnsError}
        />
        */}
      <SetEnsControllerModal
        open={ensControllerModalState.open}
        onClose={() => {
          setEnsControllerModalState({
            ...ensControllerModalState,
            open: false,
          });
        }}
        state={ensControllerModalState}
        setState={setEnsControllerModalState}
        onSubmit={() => setContentHashOnSubmit()}
        ensDomain={ensDomain}
        siteId={siteId}
      />
      <HnsModal
        open={hnsModalState}
        onClose={() => {
          setHnsModalState(false);
          setHnsError(false);
        }}
        onSubmit={() => verifyHnsConfiguration()}
        domain={selectedHnsDomain.domain}
        error={hnsError}
        loading={verifyHnsLoading}
        pullZoneUrl={pullZoneUrl}
      />
    </div>
  );
};

DomainManagement.defaultProps = {
  siteBySlug: {
    loading: true,
    data: null,
    error: null,
  },
};

DomainManagement.propTypes = {
  siteBySlug: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.object,
    error: PropTypes.object,
  }),
};

export default DomainManagement;
