import { useQuery } from "@apollo/react-hooks";
import TeamSelect from "@terminal-packages/ui/core/TeamSelect";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { newApiClient } from "src/clients";
import { projectAuth } from "src/firebase";
import { GET_MEMBERSHIPS } from "src/graphql/queries";
import { useAuth } from "src/store/AuthContext";
import { url } from "src/utils";

import getOptions, { ADD_TEAM } from "./get-options";
import useStyles from "./styles";

const TeamSelector = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { displayName } = useAuth();
  const accountId = url.getAccountIdFromUrl();

  const memberships = useQuery(GET_MEMBERSHIPS, {
    client: newApiClient,
    skip: !projectAuth.currentUser,
    onCompleted: () => {
      const options = getOptions(memberships, accountId, t);
      const selectedTeam = options.find((team) => team.selected);
      if (selectedTeam) {
        window.analytics.group(selectedTeam.id, {
          name: selectedTeam.name,
        });
      }
    },
  });

  const options = getOptions(memberships, accountId, t, displayName);

  const onChangeTeam = (option) => {
    if (option.id === ADD_TEAM) {
      history.push(url.buildUrl(null, "/teams/create/team-name"));
      return;
    }

    const newUrl = url.buildUrl(
      { accountId: option.id },
      `/teams/${option.id}/sites`
    );

    // set selected team as current userId tracking
    window.ga("set", "userId", option.id);
    window.analytics.group(option.id, {
      name: option.name,
    });

    history.push(newUrl);
  };

  const selectedOption = options.find((option) => option.selected);

  return (
    <TeamSelect
      value={selectedOption || {}}
      options={options}
      onChange={(option) => onChangeTeam(option, history)}
      className={classes.select}
    />
  );
};

export default TeamSelector;
