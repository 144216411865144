import { useMutation,useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";

import { ADD_HNS_DOMAIN } from "../../../../views/Sites/graphql/mutations";
import { GET_HNS_TLD_INFO } from "../../../../views/Sites/graphql/queries";
import { GET_SITE_BY_SLUG } from "../../../../views/Sites/graphql/queries";
import { SECTION_IDS } from "../../../../views/Teams/Billing/General/components/BillingSettingsNav/get-navigation-items";
import {
  BuyDomain,
  DomainName,
  OwningConfirmation,
  PurchasingDomain,
  VerifyAction,
} from "../../components";

const STEPS = {
  VERIFYING_DOMAIN: "VERIFYING_DOMAIN",
  OWNING_CONFIRMATION: "OWNING_CONFIRMATION",
  PURCHASING_DOMAIN: "PURCHASING_DOMAIN",
  BUY_DOMAIN: "BUY_DOMAIN",
};

const AddHNSDomainForm = ({ closeModal }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();

  const { data } = useQuery(GET_SITE_BY_SLUG, {
    client: newApiClient,
    variables: {
      slug: match.params.siteSlug,
    },
    fetchPolicy: "cache-and-network",
  });

  const siteId = get(data, "getSiteBySlug.id", "");

  const [addHnsMutation] = useMutation(ADD_HNS_DOMAIN, {
    client: newApiClient,
  });

  const [state, setState] = useState({
    error: null,
    loading: false,
    domainName: "",
    rootDomain: "",
    step: STEPS.VERIFYING_DOMAIN,
    enableEdit: true,
    price: 0,
    period: 0,
    currency: null,
  });

  useEffect(() => {
    if (state.step === STEPS.BUY_DOMAIN) {
      setState({
        ...state,
        enableEdit: false,
      });
    } else {
      setState({
        ...state,
        enableEdit: true,
      });
    }

    return () => setState({ ...state, enableEdit: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.step]);

  const handleVerify = async () => {
    setState({
      ...state,
      loading: true,
    });

    const domain = state.domainName.replace("/", "");

    try {
      const tld = domain.split(".").slice(-1)[0];

      if (tld === domain) {
        setState({
          ...state,
          loading: false,
          error: t("addHNSDomain.errors.tldOnly"),
        });
        return;
      }

      const { data } = await newApiClient.query({
        query: GET_HNS_TLD_INFO,
        variables: {
          tld,
        },
      });

      const hasOwner = get(data, "getHnsTldInfo.hasOwner");

      const available = !hasOwner;

      const nextStep = available ? STEPS.BUY_DOMAIN : STEPS.OWNING_CONFIRMATION;

      setState({
        ...state,
        domainName: domain,
        rootDomain: tld,
        loading: false,
        step: nextStep,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error verifying custom domain: ", error.message);

      setState({
        ...state,
        loading: false,
        error: t("addHNSDomain.errors.verifying"),
      });
    }
  };

  const handleOwningConfirm = async () => {
    const domains = get(data, "getSiteBySlug.domains", []);
    if (domains.some(domain => domain.domain === state.domainName)) {
      setState({
        ...state,
        loading: false,
        error: t("addHNSDomain.errors.domainExists"),
      });
      return;
    }

    setState({
      ...state,
      loading: true,
    });

    try {
      const { data } = await addHnsMutation({
        variables: {
          input: {
            siteId,
            domain: state.domainName,
          },
        },
      });

      const success = get(data, "addHnsDomain");
      if (success) {
        closeModal();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error verifying custom domain: ", error.message);
      setState({
        ...state,
        loading: false,
        error: error.message,
      });
    }
  };

  const onChangeDomainName = (value) => {
    const newState = {
      ...state,
      domainName: value.replace(/\s/, "").toLowerCase(),
      step: STEPS.VERIFYING_DOMAIN,
    };

    // reset form input error
    if (state.error) {
      newState.error = null;
    }

    setState(newState);
  };

  const onBuyDomain = async () => {
    window.open(`https://namebase.io/domains/${state.rootDomain}`);
  };

  const getCurrentStepComponent = () => {
    switch (state.step) {
      case STEPS.VERIFYING_DOMAIN:
        return (
          <VerifyAction
            loading={state.loading}
            onVerify={handleVerify}
            onCancel={closeModal}
            /* Verify that the form input has a length to be valid
             * and has no error.
             */
            isFormValid={state.domainName.length > 0 && !state.error}
          />
        );
      case STEPS.OWNING_CONFIRMATION:
        return (
          <OwningConfirmation
            loading={state.loading}
            domainName={state.domainName}
            onConfirm={handleOwningConfirm}
            onCancel={() =>
              setState({ ...state, step: STEPS.VERIFYING_DOMAIN })
            }
          />
        );
      case STEPS.PURCHASING_DOMAIN:
        return (
          <PurchasingDomain
            domainName={state.domainName}
            onConfirm={() =>
              history.push(
                `/sites/${match.params.siteSlug}/settings/${SECTION_IDS.DOMAIN_MANAGEMENT}`
              )
            }
          />
        );
      case STEPS.BUY_DOMAIN:
        /* eslint-disable no-console */
        return (
          <BuyDomain
            loading={state.loading}
            domain={state.domainName}
            onBuy={onBuyDomain}
            onCancel={() =>
              setState({ ...state, step: STEPS.VERIFYING_DOMAIN })
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <div id="test-add-custom-domain">
      <DomainName
        error={state.error}
        domainName={state.domainName}
        enableEdit={state.enableEdit}
        onChangeDomainName={onChangeDomainName}
      />
      {getCurrentStepComponent()}
    </div>
  );
};

AddHNSDomainForm.propTypes = {
  closeModal: PropTypes.func.isRequired
}

export default AddHNSDomainForm;
