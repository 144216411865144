import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import firebase from "firebase/app";

import config from "./config";

const firebaseConfig = config.firebaseAuth;

const app = firebase.initializeApp(firebaseConfig);
export const githubProvider = new firebase.auth.GithubAuthProvider();
export const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
export const projectAuth = app.auth();
export const firestoreDB = app.firestore();
export const firebaseFunctions = app.functions();

export const onPasswordUpdate =
  firebaseFunctions.httpsCallable("passwordUpdate");

projectAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export default app;
