import {
  Alert,
  AuthLayout,
  Box,
  dripStitches,
  Grid,
  Typography,
} from "@fleekhq/react-drip";
import { Link } from "@material-ui/core";
import { AuthHeader } from "src/components";

import VerifyCode from "./components/VerifyCode";
import useAuthentication from "./hooks/useAuthentication";
import useReglRenderer from "./hooks/useReglRenderer";
import { ContentWrapper } from "./styled";

const { styled } = dripStitches;

const Canvas = styled("canvas", {
  position: "absolute",
  width: "100%",
  height: "100%",
  left: "0",
  right: "0",
  top: "0",
  bottom: "0",
});

const reglGradientID = "regl-gradient";

const Authentication = () => {
  const { state, alertData, onVerifyUserView, authLayoutProps } =
    useAuthentication({ shouldManipulateURL: true });

  useReglRenderer(reglGradientID);

  return (
    <Box css={{ backgroundColor: "$slate1", fontFamily: "Manrope" }}>
      {state.verifyUserView && state.resolver ? (
        <VerifyCode
          handleVerifyUserView={onVerifyUserView}
          resolver={state.resolver}
        />
      ) : (
        <>
          <Canvas id={reglGradientID} />
          <AuthHeader />
          <ContentWrapper>
            <Grid
              css={{
                gap: "$6",
                maxWidth: "$lg",
                pointerEvents: "all",
                position: "relative",
              }}
            >
              <AuthLayout {...authLayoutProps} />
              {alertData.children && (
                <Alert
                  {...alertData}
                  css={{
                    bottom: "0",
                    transform: "translateY(120%)",
                    position: "absolute",
                  }}
                />
              )}
            </Grid>
          </ContentWrapper>
        </>
      )}

      <Typography
        fontSize="sm"
        css={{
          textAlign: "center",
          color: "$slate11",
          position: "absolute",
          bottom: "$2",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        By using the Fleek platform you agree to our
        <br />
        <Link href="https://fleek.co/terms-of-service">Terms of Service</Link>
        &nbsp;and&nbsp;
        <Link href="https://fleek.co/privacy-policy">Privacy Policy</Link>
      </Typography>
    </Box>
  );
};

export default Authentication;
