import { useQuery } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import { toast } from "@terminal-packages/ui/core/Toast";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { newApiClient } from "src/clients";
import { GET_TEAM_BILLING_INFORMATION } from "src/graphql/queries";
import { useAccountId } from "src/hooks";
import { useAuth } from "src/store/AuthContext";
import { checkIfWeb3AccountLinked } from "src/views/AuthCallback/utils";

import BillingInformation from "./components/BillingInformation";
import DeleteBillingDetails from "./components/DeleteBillingDetails";
import PaymentInformation from "./components/PaymentInformation";
import useStyles from "./styles";

const BillingDetails = (props) => {
  const { loading } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const accountId = useAccountId();
  const { linkedData, isWeb3Provider, currentUser } = useAuth();

  const [walletEmail, setWalletEmail] = useState('');
  const [loadingWalletInfo, setLoadingWalletInfo] = useState(false);

  const {
    refetch,
    data: teamBillingData,
    loading: teamBillingDataLoading,
  } = useQuery(GET_TEAM_BILLING_INFORMATION, {
    variables: { teamId: accountId },
    client: newApiClient,
  });

  const billingInformation = get(
    teamBillingData,
    "getTeamBillingInformation.billingInformation"
  );

  const paymentMethod = get(
    teamBillingData,
    "getTeamBillingInformation.paymentMethod"
  );

  useEffect(() => {
    const getWalletEmail = async () => {
      try {
        setLoadingWalletInfo(true);
        if (linkedData) {
          const walletData = await checkIfWeb3AccountLinked(linkedData?.uid);
          const email = get(walletData, "email", "");
          setWalletEmail(email);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoadingWalletInfo(false);
      }
    };
    
    if (isWeb3Provider && !get(currentUser, "email")) {
      getWalletEmail();
    }

  }, [isWeb3Provider, currentUser, linkedData, billingInformation]);

  return (
    <>
      <Typography className={classes.title} variant="body1">
        {t("billingDetails.title")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("billingDetails.subtitle")}
      </Typography>
      <div className={classes.sectionWrapper}>
        <PaymentInformation
          paymentData={paymentMethod}
          refetchTeamBillingInfo={refetch}
          loading={loading || teamBillingDataLoading}
        />
      </div>
      <div className={classes.sectionWrapper}>
        <BillingInformation
          billingData={billingInformation}
          disabled={!paymentMethod}
          loading={loading || teamBillingDataLoading || loadingWalletInfo}
          refetchTeamBillingInfo={refetch}
          walletAddress={linkedData?.address}
          walletEmail={walletEmail}
        />
      </div>
      <DeleteBillingDetails
        billingInformation={billingInformation}
        paymentMethod={paymentMethod}
        refetchTeamBillingInfo={refetch}
        teamId={accountId}
      />
    </>
  );
};

BillingDetails.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default BillingDetails;
