// @ts-nocheck
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";

import useStyles from "./styles";

const MAX_SKELETON_WIDTH = 450;
const MIN_SKELETON_WIDTH = 130;

export const ObjectDetailRow = ({ field, value, loading }) => {
  const classes = useStyles();

  const getValueContent = () => {
    if (loading) {
      const randomeWidth = Math.floor(
        Math.random() * (MAX_SKELETON_WIDTH - MIN_SKELETON_WIDTH + 1) +
          MIN_SKELETON_WIDTH
      );

      return (
        <Skeleton
          height={22}
          width={randomeWidth}
          variant="rect"
          animation="wave"
          classes={{
            root: classes.skeletonRoot,
          }}
        />
      );
    }

    return (
      <Typography noWrap variant="body2" component="div">
        {value}
      </Typography>
    );
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.field}
      >
        {field}
      </Typography>
      {getValueContent()}
    </div>
  );
};

ObjectDetailRow.propTypes = {
  value: PropTypes.node.isRequired,
  field: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
