// @ts-nocheck
import { useMutation } from "@apollo/react-hooks";
import InputAdornment from "@material-ui/core/InputAdornment";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import IconFA from "@terminal-packages/ui/core/IconFA";
import InputWithError from "@terminal-packages/ui/core/InputWithError";
import { toast } from "@terminal-packages/ui/core/Toast";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { newApiClient } from "src/clients";
import { GENERATE_APP_API_KEY } from "src/graphql/mutations";
import { GET_TEAM_API_KEYS } from "src/graphql/queries";
import { url } from "src/utils";

import useStyles from "./styles";

const handleCopy =
  ({ t, type, text }) =>
  (e) => {
    e.preventDefault();

    navigator.clipboard.writeText(text);
    toast.success(t("modals.apiDetails.copy", { type }));
  };

export const AppApiDetailsModal = ({ open, closeModal }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, setState] = React.useState({
    error: null,
    loading: true,
    apiKey: "",
  });

  const teamId = url.getAccountIdFromUrl();

  const [generateAppApiKeyMutation] = useMutation(GENERATE_APP_API_KEY, {
    client: newApiClient,
    update: (cache, { data: { generateAppApiKey } }) => {
      try {
        const data = cache.readQuery({
          query: GET_TEAM_API_KEYS,
          variables: {
            teamId,
          },
        });

        const newApiKeys = [
          ...data.getTeamApiKeys.apiKeys,
          { ...generateAppApiKey },
        ];

        cache.writeQuery({
          query: GET_TEAM_API_KEYS,
          variables: {
            teamId,
          },
          data: {
            ...data,
            getTeamApiKeys: {
              ...data.getTeamApiKeys,
              apiKeys: newApiKeys,
            },
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          "Error when trying to update GET_API_KEYS query: ",
          error.message
        );
      }
    },
  });

  React.useEffect(() => {
    const generateKey = async () => {
      try {
        const { data } = await generateAppApiKeyMutation({
          variables: {
            input: {
              label: "Hosting Api Key",
              teamId,
              active: true,
              scopes: [
                {
                  target: "sites",
                  action: "*",
                },
              ],
            },
          },
        });

        const apiKey = get(data, "generateAppApiKey.key", "");

        setState({
          ...state,
          apiKey,
          loading: false,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Generate api error: ", error.message);

        toast.error(t("modals.apiDetails.error"));
        setState({
          ...state,
          loading: false,
        });
      }
    };

    generateKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseModal
      open={open}
      maxWidth={472}
      onClose={closeModal}
      className={classes.root}
      title={t("modals.apiDetails.title")}
    >
      <div className={classes.content}>
        <InputWithError
          value={state.apiKey}
          label={t("modals.apiDetails.inputs.apiKey")}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <div className={classes.iconContainer}>
                  <IconFA
                    icon={["fal", "copy"]}
                    onClick={handleCopy({ t, type: "key", text: state.apiKey })}
                  />
                </div>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.buttons}>
        <GenericButton
          onClick={closeModal}
          disabled={state.loading}
          buttonVariant="secondary"
        >
          {t("common.cancel")}
        </GenericButton>
        <GenericButton
          onClick={closeModal}
          loading={state.loading}
          disabled={state.loading}
          buttonVariant="primary"
        >
          {t("common.done")}
        </GenericButton>
      </div>
    </BaseModal>
  );
};

AppApiDetailsModal.defaultProps = {
  open: false,
};

AppApiDetailsModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};
