export const SECTION_IDS = {
  CRYPTO: "crypto",
  HOSTING_API: "hostingApi",
  STORAGE_API: "storageApi",
  PROFILE: "profile",
  WALLET_LINK: "walletLink",
  TWO_FA_AUTH: "2FA",
  GENERAL: "general",
};

export const SECTION_TYPES = {
  MAIN: "main",
  SUBSECTION: "subsection",
};
