export const DEPLOY_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  FAILED: "FAILED",
  CANCELLED: "CANCELLED",
  DEPLOYED: "DEPLOYED",
  TIMEOUT: "TIMEOUT",
};

export const DOMAIN_TYPE = {
  PRIMARY: "PRIMARY",
  DEFAULT_SUBDOMAIN: "DEFAULT_SUBDOMAIN",
  REDIRECT_TO_PRIMARY: "REDIRECT_TO_PRIMARY",
  ALIAS_DOMAIN: "ALIAS_DOMAIN",
  HNS: "HNS",
};

export const DOMAIN_STATUS = {
  PENDING_PROPAGATION: "PENDING_PROPAGATION",
  PROPAGATED: "PROPAGATED",
  ERROR: "ERROR",
};

export const GIT_EVENT = {
  PULL_REQUEST: "pull_request",
  PUSH: "push",
};

export const GA_EVENTS_CATEGORIES = {
  SITES: "Sites",
  BILLING: "Billing",
  STORAGE: "Storage",
};

export const TEAM_STATUS = {
  ACTIVE: "ACTIVE",
  OVERDUE: "OVERDUE",
  RESTRICTED: "RESTRICTED",
  SUSPENDED: "SUSPENDED",
};

export const PROXY_TYPES = {
  HA: "HA",
  BUNNY: "BUNNY",
  CLOUDFLARE: "CLOUDFLARE",
};

export const FREE_PLAN_ID = "PRO_PLAN";
export const ID_OF_SCROLLABLE_ELEMENT = "scrollable-element";
export const LIMIT_SITES_PAGINATION = 20;
export const LIMIT_DEPLOYS_PAGINATION = 20;
export const LIMIT_CANISTERS_PAGINATION = 20;
export const PLANS_CUSTOM_LIMIT = -2;

/* STORAGE CONSTANTS */

// actions
export const SEARCH_TERM_CHANGE = "SEARCH_TERM_CHANGE";

/* END STORAGE CONSTANTS */

/* Sources from site code */
export const GITHUB = "GITHUB";
export const LOCAL = "LOCAL";

export const SOURCE_TYPES = {
  [GITHUB]: GITHUB,
  [LOCAL]: LOCAL,
};

export const LOGIN_PROVIDERS = {
  GITHUB: "github.com",
  PASSWORD: "password",
};

export const LOGIN_PROVIDER_NAMES = {
  [LOGIN_PROVIDERS.GITHUB]: "GitHub",
  [LOGIN_PROVIDERS.PASSWORD]: "Email",
};

export const END_STORAGE_DATE = 1671668306304;

export const LOCAL_STORAGE_KEYS = {
  seenNewStorageFeature: "seen_new_storage_feature"   
}