// @ts-nocheck
import { useMutation } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import AlertBox from "@terminal-packages/ui/core/AlertBox";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import { toast } from "@terminal-packages/ui/core/Toast";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { newApiClient } from "src/clients";
import { REMOVE_BILLING_DETAILS } from "src/graphql/mutations";
import { useAuth } from "src/store/AuthContext";
import { unlinkEmailToWallet } from "src/views/AuthCallback/utils";

import useStyles from "./styles";

export const DeleteBillingInformationModal = ({
  open,
  onClose,
  onSuccess,
  teamId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isWeb3Provider, linkedData } = useAuth();
  const [state, setState] = useState({
    error: null,
    loading: false,
  });

  const [removeBillingDetails] = useMutation(REMOVE_BILLING_DETAILS, {
    client: newApiClient,
  });

  const onClick = async () => {
    try {
      setState({
        ...state,
        error: null,
        loading: true,
      });
      if (isWeb3Provider) {
        await unlinkEmailToWallet(linkedData?.address);
      }
      await removeBillingDetails({
        variables: {
          input: {
            teamId,
          },
        },
      });
      toast.success(t("modals.deleteBillingInfo.success"));
      setState({
        ...state,
        loading: false,
        error: null,
      });
      onClose();
      onSuccess();
    } catch (e) {
      setState({
        ...state,
        loading: false,
        error: t("modals.deleteBillingInfo.error"),
      });
    }
  };

  return (
    <BaseModal
      open={open}
      maxWidth={438}
      onClose={onClose}
      className={classes.modal}
      title={t("modals.deleteBillingInfo.title")}
    >
      {state.error && (
        <AlertBox
          type="error"
          message={state.error}
          className={classes.alert}
          icon={["fal", "times-circle"]}
        />
      )}
      <Typography
        className={classes.message}
        variant="body2"
        color="textSecondary"
      >
        {t("modals.deleteBillingInfo.message")}
      </Typography>
      <div className={classes.buttons}>
        <GenericButton
          onClick={onClose}
          buttonVariant="secondary"
          disabled={state.loading}
        >
          {t("common.cancel")}
        </GenericButton>
        <GenericButton
          type="submit"
          buttonVariant="important"
          loading={state.loading}
          disabled={state.loading}
          onClick={onClick}
        >
          {t("common.delete")}
        </GenericButton>
      </div>
    </BaseModal>
  );
};

DeleteBillingInformationModal.defaultProps = {
  open: false,
};

DeleteBillingInformationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
};
