import { useQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Box from "@terminal-packages/ui/core/Box";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import classnames from "classnames";
import get from "lodash/get";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";
import { TEAM_STATUS } from "src/constants";
import {
  GET_MEMBERS_BY_TEAM,
  GET_MEMBERSHIPS,
  GET_TEAM_BY_ID,
} from "src/graphql/queries";

import { projectAuth } from "../../../firebase";
import MembersList from "./components/MemberList";
import useStyles from "./styles";

const Members = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslation();

  const { teamId } = match.params;

  const { data: membershipsData, loading: membershipsLoading } = useQuery(
    GET_MEMBERSHIPS,
    {
      client: newApiClient,
      skip: !projectAuth.currentUser,
    }
  );

  const getTeamById = useQuery(GET_TEAM_BY_ID, {
    client: newApiClient,
    variables: {
      id: teamId,
    },
  });

  const { error, data, loading } = useQuery(GET_MEMBERS_BY_TEAM, {
    client: newApiClient,
    variables: {
      teamId,
    },
  });

  const memberships = get(membershipsData, "getMemberships.memberships", []);
  const members = get(data, "getMembersByTeam", []);
  const ownerId = get(getTeamById, "data.getTeamById.creatorId", "");
  const teamStatus = get(getTeamById, "data.getTeamById.status");
  const disabledAddMember = teamStatus === TEAM_STATUS.SUSPENDED;

  React.useEffect(() => {
    window.analytics.page("Team Members", {
      path: location.pathname,
      search: location.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAdmin = () => {
    const foundMembership = memberships.find(
      (membership) => membership.teamId === teamId
    );

    if (!foundMembership) {
      return false;
    }
    if (foundMembership.accessLevel === "admin") {
      return true;
    }
    return false;
  };

  const isUserAdmin = isAdmin();

  return (
    <Box padding="15px 0 35px 0">
      {isUserAdmin && (
        <div className={classes.buttonContainer}>
          <Link
            className={classnames({
              [classes.linkButton]: true,
              [classes.linkButtonDisabled]: disabledAddMember,
            })}
            to={`/teams/${teamId}/members/add?accountId=${teamId}`}
          >
            <GenericButton
              buttonVariant="primary"
              className={classes.button}
              disabled={disabledAddMember}
            >
              {t("members.add")}
            </GenericButton>
          </Link>
        </div>
      )}
      {error && (
        <Alert
          severity="error"
          classes={{
            root: classes.alertRoot,
          }}
          action={
            <Button
              size="small"
              color="inherit"
              onClick={() => window.location.reload()}
            >
              {t("members.tryAgain")}
            </Button>
          }
        >
          {t("members.error")}
        </Alert>
      )}
      <MembersList
        loading={loading || membershipsLoading}
        members={members}
        ownerId={ownerId}
        isCurrentUserTeamOwner={isUserAdmin}
      />
    </Box>
  );
};

export default Members;
