// @ts-nocheck
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import config from "src/config";

import { useAuth } from "../../store/AuthContext";

export const AuthRoute = ({ children, ...rest }) => {
  const { currentUser, isGithubProvider } = useAuth();

  return (
    <Route
      {...rest}
      render={() => {
        const shouldRedirectToVerifyEmail =
          currentUser?.email &&
          !currentUser?.emailVerified &&
          !isGithubProvider &&
          config.auth.emailVerificationEnabled;
        const shouldRedirectToSignIn = !currentUser;

        if (shouldRedirectToVerifyEmail) {
          return <Redirect to="/auth/verify-email" />;
        }

        if (shouldRedirectToSignIn) {
          return <Redirect to="/auth/sign-in" />;
        }

        return children;
      }}
    />
  );
};

AuthRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
