// @ts-nocheck
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import InputSlim from "@terminal-packages/ui/core/InputSlim";
import { toast } from "@terminal-packages/ui/core/Toast";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { projectAuth } from "src/firebase";
import { useAuth } from "src/store/AuthContext";

import useStyles from "./styles";

export const EditUserInfo = ({
  open,
  closeModal,
  currentFullName,
  currentEmail,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { updateUserDetails } = useAuth();
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState(currentFullName);
  const [email, setEmail] = useState(currentEmail);

  const validName = fullName !== "";

  const hasEmailProvider = projectAuth.currentUser.providerData.some(
    (provider) => provider.providerId === "password"
  );

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (fullName !== currentFullName) {
        await projectAuth.currentUser.updateProfile({
          displayName: fullName.trim(),
        });
      }

      if (hasEmailProvider && email !== currentEmail) {
        await projectAuth.currentUser.updateEmail(email.trim());
        await projectAuth.currentUser.sendEmailVerification();

        window.location.reload();
      }
      updateUserDetails({ fullName: fullName?.trim(), email: email?.trim() });
      setLoading(false);
      closeModal();
    } catch (error) {
      toast.error(`${t("modals.editProfile.updateFailed")}: ${error.message}`, {
        autoClose: 6000,
      });
      setLoading(false);
    }
  };

  return (
    <BaseModal
      open={open}
      title={t("modals.editProfile.title")}
      maxWidth={500}
      onClose={closeModal}
      className={classes.modal}
    >
      <br />

      <div className={classes.inputContainer}>
        <InputSlim
          label={t("modals.editProfile.fullname")}
          value={fullName}
          onChange={setFullName}
        />
      </div>
      {hasEmailProvider && (
        <div className={classes.inputContainer}>
          <InputSlim
            label={t("modals.editProfile.email")}
            value={email}
            onChange={setEmail}
          />
        </div>
      )}

      <div className={classes.buttonContainer}>
        <GenericButton
          buttonVariant="secondary"
          onClick={closeModal}
          disabled={loading}
        >
          {t("modals.editProfile.cancel")}
        </GenericButton>
        <GenericButton
          buttonVariant="primary"
          onClick={handleSubmit}
          disabled={!validName || loading}
        >
          {t("modals.editProfile.save")}
        </GenericButton>
      </div>
    </BaseModal>
  );
};

EditUserInfo.defaultProps = {
  open: false,
};

EditUserInfo.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  currentFullName: PropTypes.string.isRequired,
  currentEmail: PropTypes.string.isRequired,
};
