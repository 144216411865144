import { ButtonBase, Typography } from "@material-ui/core";
import { IconFA, InputSlim } from "@terminal-packages/ui";
import Selector from "@terminal-packages/ui/core/Selector";
import StripedList from "@terminal-packages/ui/core/StripedList";
import { useTranslation } from "react-i18next";

import { EditGithubPermissions, ReposListPlaceholder } from "./components";
import useStyles from "./styles";
import { useGithub, UseGithubOptions } from "./use-github";

export const PickRepository = (options: UseGithubOptions) => {
  const {
    selectorOptions,
    selectorValue,
    searchValue,
    setSearchValue,
    isLoading,
    repositories,
    onSelectRepository,
    onChangeAccount,
    refetchData,
  } = useGithub(options);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.filtersWrapper}>
        <Selector
          options={!isLoading ? selectorOptions : []}
          onChange={onChangeAccount}
          value={!isLoading ? selectorValue : undefined}
        />
        <div className={classes.searchWrapper}>
          <InputSlim
            value={searchValue}
            icon={["far", "search"]}
            placeholder={t("sites.start.pickRepository.searchPlaceholder")}
            onChange={setSearchValue}
          />
        </div>
      </div>
      <div className={classes.listWrapper}>
        <StripedList>
          {isLoading
            ? [<ReposListPlaceholder key="placeholder" />]
            : repositories.length
            ? repositories.map((item) => (
                <ButtonBase
                  onClick={() => onSelectRepository(item)}
                  className={classes.row}
                  key={item.fullName}
                >
                  <IconFA
                    icon={["fab", "github"]}
                    className={classes.githubIcon}
                  />
                  <Typography className={classes.rowText}>
                    {item.fullName}
                  </Typography>
                  <IconFA
                    icon={["far", "chevron-right"]}
                    className={classes.arrowIcon}
                  />
                </ButtonBase>
              ))
            : [<></>]}
        </StripedList>
      </div>
      <EditGithubPermissions onUpdatePermissions={refetchData} />
    </>
  );
};
