import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import IconFA from "@terminal-packages/ui/core/IconFA";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StepBase } from "src/components";
import { GA_EVENTS_CATEGORIES } from "src/constants";
import { useGithubToken, useOctokit } from "src/hooks";
import { url } from "src/utils";
import { useAddNewSiteContext } from "src/views/Start/container/add-new-site";

import useFetchGithubToken from "./hooks/useFetchGithubToken";
import useStyles from "./styles";

export const ANALYTICS_LABEL = "Connect with GitHub";

const ConnectWithGithub = () => {
  const { goToNextStep } = useAddNewSiteContext();

  const [hasValidToken, setHasValidToken] = useState(false);
  const [openGithubPermissionAccount, validInstallation] = useFetchGithubToken(goToNextStep);
  const { users } = useOctokit();
  const [isGithubTokenLoading, githubToken, refetchToken] = useGithubToken();
  const classes = useStyles();
  const { t } = useTranslation();

  const validateToken = useCallback(async () => {
    try {
      await users.getAuthenticated();
      setHasValidToken(true);
    } catch (error) {
      setHasValidToken(false);
    }
  }, [users]);

  const submitAnalytics = () => {
    window.ga("send", "event", GA_EVENTS_CATEGORIES.SITES, ANALYTICS_LABEL);
    window.analytics.track(ANALYTICS_LABEL, {
      teamId: url.getAccountIdFromUrl(),
    });
  };

  const handleButtonClick = async (...args: any[]) => {
    submitAnalytics();

    if (!hasValidToken) {
      await refetchToken();
      await validateToken();
    }

    if (hasValidToken && validInstallation) {
      // @ts-ignore
      goToNextStep.apply(null, ...args);
      return;
    }

    // @ts-ignore
    openGithubPermissionAccount.apply(null, ...args);
  };

  useEffect(() => {
    if (!isGithubTokenLoading) {
      if (githubToken) {
        validateToken();
      } else {
        setHasValidToken(false);
      }
    }
  }, [githubToken, isGithubTokenLoading, validateToken]);

  return (
    <StepBase
      title={t("sites.start.connectWithGithub.title")}
      subtitle={t("sites.start.connectWithGithub.subtitle")}
    >
      <div className={classes.buttonContainer}>
        <GenericButton
          buttonVariant="branded"
          onClick={handleButtonClick}
          loading={hasValidToken === undefined || isGithubTokenLoading}
          overrideClass={{ button: classes.button }}
          css={undefined}
        >
          <IconFA
            className={classes.icon}
            icon={["fab", "github"]}
            size="small"
          />
          <Typography className={classes.buttonText} variant="button">
            {t("sites.start.connectWithGithub.buttonMsg") as string}
          </Typography>
        </GenericButton>
      </div>
    </StepBase>
  );
};

export default ConnectWithGithub;
