import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingScreen, MainContent } from "src/components";

import { projectAuth } from "../../firebase";
import { checkIsValidRoute, checkTeamsLoaded, getQSFromHash } from "./utils";

const defaultRedirect = async (history, uid) => {
  if (uid) {
    const teamSetupDone = await checkTeamsLoaded(uid);
    if (teamSetupDone?.ready) {
      await projectAuth.currentUser.getIdToken(true);
    }
  }
  history.push("/");
  window.location.reload();
};

const AuthCallback = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const hash = location.hash.substr(1);
    const hashQS = getQSFromHash(hash);

    const processRedirect = async () => {
      if (hashQS && hashQS.id_token) {
        const { uid } = hashQS;

        if (hashQS.state) {
          const redirectTo = `/${decodeURIComponent(hashQS.state)}`;
          const isValidRoute = checkIsValidRoute(redirectTo);

          if (isValidRoute) {
            history.push(redirectTo);
          } else {
            await defaultRedirect(history, uid);
          }

          return;
        }

        await defaultRedirect(history, uid);
      }
    };

    processRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContent>
      <Helmet>
        <title>{t("auth.page_title")}</title>
      </Helmet>
      <LoadingScreen />
    </MainContent>
  );
};

export default AuthCallback;
