import { createContext } from "@fleekhq/react-drip";
import Box from "@terminal-packages/ui/core/Box";
import Stepper from "@terminal-packages/ui/core/Stepper/Stepper";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { GoBackLink } from "src/components/layout/components/GoBackLink";
import { useAddSiteMutation } from "src/graphql/hooks/use-add-site-mutation";
import { url } from "src/utils";

import {
  BuildOptions,
  ConnectWithGithub,
  DeployLocation,
  PickRepository,
} from "../../components/Steps";
import useStyles from "./styles";

const CONNECT_REPO_URL = "/connect-repository";
const CHOOSE_REPO_URL = "/choose-repository";
const DEPLOY_LOCATION_URL =
  "/deploy-location/:installationId/:accountName/:repositoryName";
const DEPLOY_SETTINGS_URL =
  "/deployment-settings/:installationId/:accountName/:repositoryName/:hostingServiceId";

type AddNewSiteContextOptions = {
  goToPreviousStep: () => void;
  goToNextStep: (options?: any) => void;
};

export const [AddNewSiteContext, useAddNewSiteContext] =
  createContext<AddNewSiteContextOptions>({
    name: "AddNewSiteContext",
    strict: false,
  });

export const AddNewSite = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const teamId = url.getAccountIdFromUrl();
  const [addSite, { loading }] = useAddSiteMutation();
  const match = useRouteMatch();
  const location = useLocation();

  const step = [
    {
      title: t("sites.start.connectWithGithub.step"),
      path: `${match.url}${CONNECT_REPO_URL}`,
    },
    {
      title: t("sites.start.pickRepository.step"),
      path: `${match.url}${CHOOSE_REPO_URL}`,
    },
    {
      title: t("sites.start.deployLocation.step"),
      path: `${match.url}${DEPLOY_LOCATION_URL}`,
    },
    {
      title: t("sites.start.buildOptions.step"),
      path: `${match.url}${DEPLOY_SETTINGS_URL}`,
    },
  ];

  const indexOfCurrentStep = step.findIndex(({ path }) =>
    matchPath(location.pathname, { path, exact: true })
  );

  const mappedStepPaths = [
    step[0].path,
    step[1].path,
    location.pathname
      .split("/")
      .slice(0, -1)
      .join("/")
      .replace("deployment-settings", "deploy-location"),
  ];

  React.useEffect(() => {
    window.analytics.page("Add Site", {
      path: location.pathname,
      search: location.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToNextStep = (options: any) => {
    const { installationId, accountName, repositoryName, hostingServiceId } =
      options || {};


    console.log(
      `${match.url}/deploy-location/${installationId}/${accountName}/${repositoryName}`
    );
    
    switch (indexOfCurrentStep) {
      case 0:
        history.push(url.buildUrl(null, `${match.url}${CHOOSE_REPO_URL}`));
        break;
      case 1:
        history.push(
          url.buildUrl(
            null,
            `${match.url}/deploy-location/${installationId}/${accountName}/${repositoryName}`
          )
        );
        break;
      case 2:
        history.push(
          url.buildUrl(
            null,
            `${match.url}/deployment-settings/${installationId}/${accountName}/${repositoryName}/${hostingServiceId}`
          )
        );
        break;
      default:
        break;
    }
  };

  const goToPreviousStep = () => {
    const newUrl =
      indexOfCurrentStep === 1
        ? url.buildUrl(null, `/teams/${teamId}/sites`)
        : url.buildUrl(null, mappedStepPaths[indexOfCurrentStep - 1]);

    history.push(newUrl);
  };

  return (
    <div className={classes.container}>
      <GoBackLink onClick={goToPreviousStep} />
      <Box padding="37px 57px 48px">
        <AddNewSiteContext value={{ goToPreviousStep, goToNextStep }}>
          <Stepper
            title={t("sites.start.stepper.title")}
            description={t("sites.start.stepper.description")}
            stepTitles={step.map(({ title }) => title)}
            indexOfActiveStep={indexOfCurrentStep}
          >
            <Switch>
              <Route path={step[0].path} exact>
                <ConnectWithGithub />
              </Route>
              <Route path={step[1].path} exact>
                <PickRepository />
              </Route>
              <Route path={step[2].path} exact>
                <DeployLocation />
              </Route>
              <Route path={step[3].path} exact>
                <BuildOptions
                  onClickDeploy={addSite}
                  isDeployLoading={loading}
                />
              </Route>
              <Redirect to={url.buildUrl(null, step[0].path)} />
            </Switch>
          </Stepper>
        </AddNewSiteContext>
      </Box>
    </div>
  );
};
