import { useQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";
import ErrorComponent from "@terminal-packages/ui/core/Error";
import get from "lodash/get";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { newApiClient } from "src/clients";
import { MainContent } from "src/components";
import { GET_MEMBERSHIPS } from "src/graphql/queries";
import { useAccountId } from "src/hooks";
import { url } from "src/utils";

import { projectAuth } from "../../firebase";
import useStyles from "./styles";

const Error = () => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const accountId = useAccountId();

  const { data } = useQuery(GET_MEMBERSHIPS, {
    client: newApiClient,
    skip: !projectAuth.currentUser,
  });

  useEffect(() => {
    const memberships = get(data, "getMemberships.memberships", []) || [];
    const membership = memberships.find(
      (_membership) => _membership.teamId === accountId
    );

    if (!membership && memberships.length) {
      const newUrl = url.buildUrl({
        accountId: memberships[0].teamId,
      });

      history.replace(newUrl);
    }
  }, [data]);

  const errorCode = parseInt(get(params, "errorCode", 500), 10);

  return (
    <MainContent>
      <div className={classes.root}>
        <ErrorComponent
          code={errorCode}
          title={t(`errors.${errorCode}.title`)}
          subtitle={t(`errors.${errorCode}.description`)}
          hexImage="https://storage.googleapis.com/terminal-assets/images/404%20hex.png"
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/teams/${accountId}/sites?accountId=${accountId}`}
          >
            {t(`errors.${errorCode}.cta`)}
          </Button>
        </ErrorComponent>
      </div>
    </MainContent>
  );
};

export default Error;
