import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AddStorageCustomDomainModal } from "src/modals";

import useStyles from "./styles";

const DomainsFooter = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isModalOpen, setIsModelOpen] = useState(false);


  const openCustomDomainModal = () => setIsModelOpen(true);
  const closeCustomDomainModal = () => setIsModelOpen(false);

  return (
    <div className={classes.button}>
      {/* @ts-ignore */}
      <GenericButton
        buttonVariant="primary"
        className={classes.button}
        onClick={() => openCustomDomainModal()}
      >
        {t("sites.tabs.settings.customDomains.buttonText") as string}
      </GenericButton>
      <AddStorageCustomDomainModal
        isOpen={isModalOpen}
        closeModal={closeCustomDomainModal}
      />
    </div>
  );
};

export default DomainsFooter;
