// @ts-nocheck
import ButtonBase from "@material-ui/core/ButtonBase";
import IconFA from "@terminal-packages/ui/core/IconFA";
import { GenericTooltip } from "@terminal-packages/ui/core/Tooltips";
import classnames from "classnames";
import PropTypes from "prop-types";

import useStyles from "./styles";

export const Info = (props) => {
  const { text, className } = props;
  const classes = useStyles();

  const icon = (
    <ButtonBase className={classnames(classes.buttonTooltip, className)}>
      <IconFA
        icon={["fal", "info-circle"]}
        size="inherit"
        className={classes.icon}
      />
    </ButtonBase>
  );

  return (
    <>
      <GenericTooltip
        text={text}
        overrideClass={{
          button: classes.iconButton,
          tooltip: classes.tooltip,
        }}
        placement="right"
      >
        {icon}
      </GenericTooltip>
    </>
  );
};

Info.defaultProps = {
  className: null,
};

Info.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};
