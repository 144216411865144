import { makeStyles } from "@material-ui/core";

export default makeStyles({
  content: {
    padding: "3px 0 15px 0",
  },
  item: {
    height: 46,
    padding: "0 20px",
    alignItems: "center",
  },
  key: {
    minWidth: 202,
  },
  skeletonRoot: {
    margin: "20px",
    borderRadius: 4,
  },
  buttonContainer: {
    padding: "26px 0 6px 20px",
  },
  linkingText: {
    fontSize: "14px",
    padding: "16px 20px 6px 20px",
  },
});
