import Typography from "@material-ui/core/Typography";
import ArrowLink from "@terminal-packages/ui/core/ArrowLink";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGithubInstallationLink } from "src/hooks";

import useStyles from "./styles";

export type EditGithubPermissionsProps = {
  onUpdatePermissions: () => void;
};

export const EditGithubPermissions = ({
  onUpdatePermissions,
}: EditGithubPermissionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isSettingPermissionsCompleted, openGithubPermissionsPopup } =
    useGithubInstallationLink();

  useEffect(() => {
    if (isSettingPermissionsCompleted) {
      onUpdatePermissions();
    }
  }, [isSettingPermissionsCompleted, onUpdatePermissions]);

  return (
    <div className={classes.root}>
      <Typography variant="body2">
        {t("sites.start.pickRepository.editPermissionsLink.question") as string}
      </Typography>
      &nbsp;
      <GenericButton
        disableRipple
        disableElevation
        upperCase={false}
        onClick={openGithubPermissionsPopup}
        overrideClass={{ button: classes.linkButton }}
        css={undefined}
      >
        <ArrowLink>
          {t("sites.start.pickRepository.editPermissionsLink.link")}
        </ArrowLink>
      </GenericButton>
    </div>
  );
};

EditGithubPermissions.propTypes = {
  onUpdatePermissions: PropTypes.func.isRequired,
};
