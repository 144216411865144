// @ts-nocheck
import Typography from "@material-ui/core/Typography";
import Checkbox from "@terminal-packages/ui/core/Checkbox";
import {
  FileCell,
  LoadingCell,
  TableCell,
} from "@terminal-packages/ui/core/Table/components";
import VirtualizedTable from "@terminal-packages/ui/core/VirtualizedTable";
import classnames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";

import useStyles from "./styles";

const LOADING_CELL_WIDTH = {
  1: 192,
  2: 215,
  3: 80,
};

export const VirtualizedFilesTable = (props) => {
  const {
    head,
    rows,
    loading,
    loadingRows,
    onSingleClickRow,
    onDoubleClickRow,
    onSelectAllClick,
    allItemsSelected,
  } = props;

  const classes = useStyles();

  /* eslint-disable-next-line react/prop-types */
  const headerRenderer = ({ label, dataKey, columnIndex }) => (
    <TableCell
      component="div"
      className={classnames(
        classes.flexContainer,
        classes.tableCell,
        columnIndex === 0 && classes.checkboxCell
      )}
    >
      {dataKey !== "select" ? (
        label
      ) : (
        <Checkbox checked={allItemsSelected} onChange={onSelectAllClick} />
      )}
    </TableCell>
  );

  /* eslint-disable-next-line react/prop-types */
  const cellRenderer = ({ cellData, dataKey, rowData }) => {
    switch (dataKey) {
      case "select":
        return (
          <TableCell
            component="div"
            onClick={() => onDoubleClickRow(rowData)}
            className={classnames(
              classes.flexContainer,
              classes.tableCell,
              classes.checkboxCell
            )}
          >
            <Checkbox
              /* eslint-disable-next-line react/prop-types */
              checked={rowData.selected}
              onClick={(e) => {
                e.stopPropagation();
                onSingleClickRow(rowData);
              }}
            />
          </TableCell>
        );

      case "name":
        return (
          <FileCell
            component="div"
            /* eslint-disable-next-line react/prop-types */
            type={rowData.type}
            onClick={() => onDoubleClickRow(rowData)}
            className={classnames(classes.flexContainer, classes.tableCell)}
          >
            <Typography variant="inherit" noWrap className={classes.fileName}>
              {cellData}
            </Typography>
          </FileCell>
        );

      case "lastModified":
        return (
          <TableCell
            component="div"
            onClick={() => onDoubleClickRow(rowData)}
            className={classnames(classes.flexContainer, classes.tableCell)}
          >
            {moment(cellData)
              .tz(moment.tz.guess())
              .format("MMM D, YYYY hh:mm:ss A z")}
          </TableCell>
        );

      default:
        return (
          <TableCell
            component="div"
            onClick={() => onDoubleClickRow(rowData)}
            className={classnames(classes.flexContainer, classes.tableCell)}
          >
            {cellData}
          </TableCell>
        );
    }
  };

  /* eslint-disable-next-line react/prop-types */
  const cellLoadingRenderer = ({ columnIndex }) => {
    if (columnIndex === 0) {
      return (
        <TableCell
          className={classnames(
            classes.flexContainer,
            classes.tableCell,
            classes.checkboxCell
          )}
        >
          <Checkbox checked={false} />
        </TableCell>
      );
    }

    return (
      <LoadingCell
        component="div"
        width={LOADING_CELL_WIDTH[columnIndex]}
        className={classnames(classes.flexContainer, classes.tableCell)}
      />
    );
  };

  const rowGetter = ({ index }) => rows[index];
  const loadingRowGetter = () => ({});

  return (
    <VirtualizedTable
      head={head}
      rowHeight={35}
      headerHeight={35}
      rowCount={loading ? loadingRows : rows.length}
      cellRenderer={loading ? cellLoadingRenderer : cellRenderer}
      headerRenderer={headerRenderer}
      rowGetter={loading ? loadingRowGetter : rowGetter}
    />
  );
};

VirtualizedFilesTable.defaultProps = {
  head: [],
  rows: [],
  loading: false,
  loadingRows: 5,
  allItemsSelected: false,
  onSingleClickRow: () => {},
  onDoubleClickRow: () => {},
  onSelectAllClick: () => {},
};

VirtualizedFilesTable.propTypes = {
  loading: PropTypes.bool,
  onSingleClickRow: PropTypes.func,
  onDoubleClickRow: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  allItemsSelected: PropTypes.bool,
  head: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      dataKey: PropTypes.string,
    })
  ),
  loadingRows: PropTypes.number,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
      bytesSize: PropTypes.string,
      selected: PropTypes.bool,
      lastModified: PropTypes.date,
    })
  ),
};
