/* eslint-disable */
import moment from 'moment';

const dateToLocalFormat = (date) => (moment(date).format('LTS L'));

const formatLogs = (logs) => {
  if (!logs) return '';

  const serverDateFormat = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/g;
  const timestampRegex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)/;

  const { headerLogs, timestampLogs} = logs.split('\n').reduce((acc, log) => {
    if (timestampRegex.test(log)) {
      acc.timestampLogs = [...acc.timestampLogs, log];
    } else {
      acc.headerLogs = [...acc.headerLogs, log.replace(serverDateFormat, (date) => dateToLocalFormat(date))];
    }

    return acc;
  }, { headerLogs: [], timestampLogs: [] });


  const objectLogs = timestampLogs.reduce((acc, log) => {
    const [dateLog] = log.match(serverDateFormat);

    if (acc[dateLog]) {
      acc[dateLog] = [...acc[dateLog], log.replace(serverDateFormat, (date) => dateToLocalFormat(date))];
    } else {
      acc[dateLog] = [log.replace(serverDateFormat, (date) => dateToLocalFormat(date))];
    }

    return acc;
  }, {});

  const sortedLogs = Object
    .keys(objectLogs)
    .sort()
    .reduce((acc, timestamp) => {
      return [...acc, ...objectLogs[timestamp]];
    }, []);


  return [...headerLogs, ...sortedLogs].join('\n');
};

export default formatLogs;
