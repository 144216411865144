import { useMutation } from "@apollo/react-hooks";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVert from "@material-ui/icons/MoreVert";
import ListItem from "@terminal-packages/ui/core/ListItem";
import Spinner from "@terminal-packages/ui/core/Spinner";
import { toast } from "@terminal-packages/ui/core/Toast";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";
import { MenuDropdown } from "src/components";
import { GET_BUCKET_BY_SLUG } from "src/views/Teams/Storage/graphql";
import { REMOVE_STORAGE_CUSTOM_DOMAIN } from "src/views/Teams/Storage/graphql/mutations";

import useStyles from "./styles";

export type DomainOptionsProps = {
  domain?: string;
};

const DomainOptions = (props: DomainOptionsProps) => {
  const classes = useStyles();
  const match = useRouteMatch<{ bucketName?: string }>();
  const bucketSlug = match.params.bucketName;
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    isLoading: false,
    isOptionsOpen: false,
  });

  const [removeStorageCustomDomain, { loading: removeCustomDomainLoading }] =
    useMutation(REMOVE_STORAGE_CUSTOM_DOMAIN, {
      client: newApiClient as any,
      update: (cache, { data: { removeStorageCustomDomain } }) => {
        try {
          const data: any = cache.readQuery({
            query: GET_BUCKET_BY_SLUG,
            variables: {
              slug: bucketSlug,
            },
          });

          const newData = {
            ...data,
            getBucketBySlug: {
              ...data.getBucketBySlug,
              ...removeStorageCustomDomain,
            },
          };

          cache.writeQuery({
            query: GET_BUCKET_BY_SLUG,
            variables: {
              slug: bucketSlug,
            },
            data: newData,
          });
        } catch (error: any) {
          // eslint-disable-next-line no-console
          console.error(
            "Error when trying to update GET_SITE_BY_SLUG query: ",
            error.message
          );
        }
      },
    });

  const handleRemoveDomain = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    setState({
      ...state,
      isLoading: false,
    });

    try {
      await removeStorageCustomDomain({
        variables: {
          input: {
            bucketSlug,
          },
        },
      });

      toast.success(t("sites.tabs.settings.customDomains.success") as string, {
        autoClose: 6000,
      });
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(
        "Error when trying to remove a storage custom domain: ",
        error.message
      );

      toast.error(t("sites.tabs.settings.customDomains.error") as string, {
        autoClose: 6000,
      });
    }
  };

  if (!props.domain) {
    return null;
  }

  return (
    <div className={classes.optionsContainer}>
      <MenuDropdown
        menuId="menu-dropdown-domain-options"
        trigger={
          <IconButton
            disableRipple
            disableFocusRipple
            classes={{
              root: classes.iconButtonRoot,
            }}
          >
            <MoreVert />
          </IconButton>
        }
      >
        <ListItem className={classes.domainOption}>
          <ButtonBase
            type="button"
            className={classes.buttonBase}
            onClick={handleRemoveDomain}
            disabled={removeCustomDomainLoading}
          >
            <Typography variant="subtitle1" className={classes.remove}>
              {t("sites.tabs.settings.customDomains.remove") as string}
              {removeCustomDomainLoading && (
                <span className={classes.spinner}>
                  <Spinner positioning="inline" />
                </span>
              )}
            </Typography>
          </ButtonBase>
        </ListItem>
      </MenuDropdown>
    </div>
  );
};

export default DomainOptions;
