import "firebase/auth";

import Typography from "@material-ui/core/Typography";
import Box from "@terminal-packages/ui/core/Box";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import InputSlim from "@terminal-packages/ui/core/InputSlim";
import { toast } from "@terminal-packages/ui/core/Toast";
import firebase from "firebase/app";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { phoneAuthProvider, projectAuth } from "src/firebase";

import useStyles from "./styles";

const VerifyCode = ({ handleVerifyUserView, resolver }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [state, setState] = useState({
    code: "",
    loading: false,
  });
  const [verificationDetails, setVerificationDetails] = useState(null);
  const [captchaVerifier, setCaptchaVerifier] = useState(null);

  const sendCode = async (recaptchaVerifier) => {
    try {
      const phoneOpts = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneOpts,
        recaptchaVerifier
      );
      setVerificationDetails(verificationId);
    } catch (error) {
      toast.error(`${t("auth.loginFailed")}: ${error.message}`, {
        autoClose: 6000,
      });
    }
  };

  useEffect(() => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "2fa-captcha",
      {
        size: "invisible",
        callback: () => {
          // reCAPTCHA solved
        },
      }
    );
    setCaptchaVerifier(recaptchaVerifier);
    sendCode(recaptchaVerifier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCodeChange = (value) => {
    setState({
      ...state,
      code: value,
    });
  };

  const handleResendCode = async (event) => {
    event.preventDefault();
    sendCode(captchaVerifier);
  };

  const handleVerifyCode = async (event) => {
    event.preventDefault();
    setState({
      ...state,
      loading: true,
    });
    try {
      const cred = await firebase.auth.PhoneAuthProvider.credential(
        verificationDetails,
        state.code
      );
      const multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      const result = await resolver.resolveSignIn(multiFactorAssertion);
      if (result && result.user) {
        const idToken = await projectAuth.currentUser.getIdToken();
        window.location.replace(
          `/#/auth/cb#id_token=${idToken}&uid=${result.user.uid}`
        );
      }
    } catch (error) {
      toast.error(`${t("modals.getTwoFa.failed")}: ${error.message}`, {
        autoClose: 6000,
      });
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const handleVerificationCancel = async (event) => {
    event.preventDefault();
    handleVerifyUserView(false, null);
  };

  return (
    <div className={classes.contentWrapper}>
      <Box overrideClass={{ wrapper: classes.box }}>
        <form id="verifyCodeForm" onSubmit={handleVerifyCode}>
          <Typography variant="body2" className={classes.description}>
            {t("modals.getTwoFa.code.description")}{" "}
            {resolver.hints[0].phoneNumber}
          </Typography>
          <div className={classes.inputContainer}>
            <InputSlim
              label={t("modals.getTwoFa.code.enterCode")}
              value={state.code}
              onChange={handleCodeChange}
            />
          </div>
          <Typography
            variant="body2"
            className={classes.codeNotSent}
            onClick={handleResendCode}
          >
            {t("modals.getTwoFa.code.codeNotSent")}
          </Typography>
          <div className={classes.buttonContainer}>
            <GenericButton
              buttonVariant="secondary"
              onClick={handleVerificationCancel}
            >
              {t("modals.getTwoFa.cancel")}
            </GenericButton>
            <GenericButton
              buttonVariant="primary"
              disabled={!state.code || state.loading}
              loading={state.loading}
              type="submit"
              form="verifyCodeForm"
            >
              {t("modals.getTwoFa.confirm")}
            </GenericButton>
          </div>
        </form>
      </Box>
      <div id="2fa-captcha" className={classes.twoFaCaptcha} />
    </div>
  );
};

VerifyCode.propTypes = {
  resolver: PropTypes.objectOf(PropTypes.any).isRequired,
  handleVerifyUserView: PropTypes.func.isRequired,
};

export default VerifyCode;
