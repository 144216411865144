import getNavItems from "src/utils/get-navigation-items";

import { SECTION_IDS, SECTION_TYPES } from "./constants";

const getItems = ({ t, section }) =>
  getNavItems(section, [
    {
      title: t("accountSettings.navs.general"),
      id: SECTION_IDS.GENERAL,
      type: SECTION_TYPES.MAIN,
      list: [
        {
          title: t("accountSettings.navs.profile"),
          id: SECTION_IDS.PROFILE,
          type: SECTION_TYPES.SUBSECTION,
        },
        {
          title: t("accountSettings.navs.crypto"),
          id: SECTION_IDS.CRYPTO,
          type: SECTION_TYPES.SUBSECTION,
        },
        {
          title: t("accountSettings.navs.hostingApi"),
          id: SECTION_IDS.HOSTING_API,
          type: SECTION_TYPES.SUBSECTION,
        },
        {
          title: t("accountSettings.navs.storageApi"),
          id: SECTION_IDS.STORAGE_API,
          type: SECTION_TYPES.SUBSECTION,
        },
        {
          title: t("accountSettings.navs.2FA"),
          id: SECTION_IDS.TWO_FA_AUTH,
          type: SECTION_TYPES.SUBSECTION,
        },
      ],
    },
  ]);

const util = {
  getItems,
};

export default util;
