import get from "lodash/get";
import { getOlderItem } from "src/utils";

export const ADD_TEAM = "ADD_TEAM";

const teamsPresenter = (memberships, selectedId, t, replacer) => {
  if (memberships.loading || memberships.error) {
    return [];
  }

  const teams = get(memberships, "data.getMemberships.memberships", []) || [];
  const sortedTeams = [...teams]
    .sort(getOlderItem)
    .map((team) =>
      team?.teamName === "My team" ? { ...team, teamName: replacer } : team
    );

  const teamOptions = sortedTeams.map(({ teamName, teamId }) => ({
    id: teamId,
    name: teamName,
    selected: selectedId === teamId,
  }));

  return [...teamOptions];
};

export default teamsPresenter;
