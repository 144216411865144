// @ts-nocheck

// import { Button } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PropTypes from "prop-types";
import React, { useState } from "react";

import useStyles from "./styles";

export const HideValue = ({ value, eyeButton }) => {
  const classes = useStyles();
  const [visibleValue, setVisibleValue] = useState(false);

  return (
    <div>
      {visibleValue ? (
        <div className={classes.hiddenValueBox}>
          <Typography variant="body1">{value}</Typography>
          {eyeButton && (
            <IconButton onClick={() => setVisibleValue(!visibleValue)}>
              <VisibilityIcon fontSize="small" color="primary" />
            </IconButton>
          )}
        </div>
      ) : (
        <div className={classes.hiddenValueBox}>
          <Typography variant="body1" className={classes.hiddenText}>
            *******
          </Typography>
          {eyeButton && (
            <IconButton onClick={() => setVisibleValue(!visibleValue)}>
              <VisibilityOffIcon fontSize="small" color="primary" />
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
};

HideValue.defaultProps = {
  value: "",
  eyeButton: false,
};

HideValue.propTypes = {
  value: PropTypes.string,
  eyeButton: PropTypes.bool,
};
