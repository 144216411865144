import { Button, Typography } from "@fleekhq/react-drip";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AuthContainer } from "src/views/Authentication/styled";

const VerificationFailed = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <AuthContainer>
      <Typography
        as="h2"
        fontSize="lg"
        fontWeight="bold"
        css={{ paddingLeft: "$0h" }}
      >
        {t("verification.verificationFailedTitle")}
      </Typography>
      <Typography css={{ color: "$slate11" }}>
        {t("verification.verificationFailedDescription")}
      </Typography>
      <Button
        colorScheme="blue"
        onClick={() => {
          history.push("/");
        }}
      >
        {t("verification.returnToFleek")}
      </Button>
    </AuthContainer>
  );
};

export default VerificationFailed;
