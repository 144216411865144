// @ts-nocheck
// auth events for listening
const events = {
  onUserAuth: "onUserAuth",
};

const auth = {
  events,
  cbs: [],
  emit(type, message) {
    // emit message to cbs if type is already registered
    if (auth.cbs[type]) {
      auth.cbs[type].forEach((cb) => {
        cb.call(null, message);
      });
    }
  },
  onListen(event, cb) {
    if (typeof auth.cbs[event] === "undefined") {
      auth.cbs[event] = [];
    }

    // save cb
    auth.cbs[event].push(cb);

    // unsubscribe return function
    return () => {
      auth.cbs[event] = auth.cbs[event].filter((_cb) => _cb !== cb);
    };
  },
};

export default auth;
