import { Avatar, Grid } from "@fleekhq/react-drip";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import FleekUIBox from "@terminal-packages/ui/core/Box";
import { toast } from "@terminal-packages/ui/core/Toast";
import classNames from "classnames";
import get from "lodash/get";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/store/AuthContext";

import useStyles from "./styles";

const SKELETON_VARIANT = "rect";
const SKELETON_ANIMATION = "wave";

const SettingsView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { currentUser, loading, error, displayName } = useAuth();

  const getContent = () => {
    if (loading) {
      return (
        <>
          <Skeleton
            width={190}
            height={25}
            variant={SKELETON_VARIANT}
            animation={SKELETON_ANIMATION}
            classes={{
              root: classes.skeletonRoot,
            }}
          />
          <Skeleton
            width={240}
            height={20}
            variant={SKELETON_VARIANT}
            animation={SKELETON_ANIMATION}
            classes={{
              root: classNames(classes.skeletonRoot, classes.accountEmail),
            }}
          />
          <Skeleton
            width={210}
            height={20}
            variant={SKELETON_VARIANT}
            animation={SKELETON_ANIMATION}
            classes={{
              root: classes.skeletonRoot,
            }}
          />
        </>
      );
    }

    const createdAt = get(currentUser, "createdAt");
    const email = get(currentUser, "email", "") || "";
    const profilePicUrl =
      get(currentUser, "individual.profilePicUrl", "") || "";

    return (
      <Grid
        css={{
          gap: "$4",
          gridAutoFlow: "column",
          alignItems: "center",
          gridTemplateColumns: profilePicUrl ? "96px 1fr" : "1fr",
        }}
      >
        {profilePicUrl && (
          <Avatar
            isRound
            fallback={displayName}
            size="2xl"
            src={profilePicUrl}
          />
        )}
        <Box>
          {displayName && (
            <Typography variant="h6" color="textPrimary">
              <Box component="span" fontWeight={500}>
                {displayName}
              </Box>
            </Typography>
          )}
          {email && (
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.accountEmail}
            >
              {email}
            </Typography>
          )}
          {createdAt && (
            <Typography variant="body2" color="textSecondary">
              {t("accountSettings.join", {
                date: moment(createdAt).format("MMM DD, YYYY"),
              })}
            </Typography>
          )}
        </Box>
      </Grid>
    );
  };

  React.useEffect(() => {
    if (error) {
      toast.error(t("accountSettings.error"));
    }
  }, [error, t]);

  return (
    <FleekUIBox
      overrideClass={{
        wrapper: classes.accountHeaderCard,
      }}
      padding="22px 27px 27px 27px"
    >
      {getContent()}
    </FleekUIBox>
  );
};

export default SettingsView;
