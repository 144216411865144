import { useMutation } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import IconFA from "@terminal-packages/ui/core/IconFA";
import { toast } from "@terminal-packages/ui/core/Toast";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { newApiClient } from "src/clients";

import { PURGE_CACHE } from "../../../../../../graphql/mutations";
import useStyles from "./styles";

const PurgeCache = ({ siteId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [purgeCache, { loading }] = useMutation(PURGE_CACHE, {
    client: newApiClient,
  });

  const onPurgeCache = async () => {
    try {
      await purgeCache({
        variables: {
          input: { siteId },
        },
      });

      toast.success(t("siteSettings.siteManagement.purgeCache.success"), {
        autoClose: 6000,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toast.error(t("siteSettings.siteManagement.purgeCache.error"), {
        autoClose: 6000,
      });
    }
  };

  return (
    <CardTitled
      mainContent={t("siteSettings.siteManagement.purgeCache.title")}
      classes={{
        content: classes.cardContent,
      }}
    >
      <Typography className={classes.paragraph}>
        {t("siteSettings.siteManagement.purgeCache.subtitle")}
      </Typography>
      <div className={classes.footer}>
        <div>
          <a
            href="https://docs.fleek.co/hosting/troubleshooting/#my-site-is-not-reflecting-the-latest-changes-cache-issue"
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="body2" className={classes.docs}>
              {t("siteSettings.siteManagement.purgeCache.learnMore")}
            </Typography>
            <IconFA
              className={classes.arrowIcon}
              icon={["fal", "long-arrow-right"]}
              size="inherit"
            />
          </a>
        </div>
        <div className={classes.buttonContainer}>
          <GenericButton
            buttonVariant="primary"
            className={classes.button}
            onClick={onPurgeCache}
            disabled={loading}
            loading={loading}
          >
            {t("siteSettings.siteManagement.purgeCache.cta")}
          </GenericButton>
        </div>
      </div>
    </CardTitled>
  );
};

PurgeCache.defaultProps = {
  siteId: "",
};

PurgeCache.propTypes = {
  siteId: PropTypes.string,
};

export default PurgeCache;
