// @ts-nocheck
/* eslint-disable no-unused-vars */
import ComplexOverlayBox from "@terminal-packages/ui/core/ComplexOverlayBox";
import PropTypes from "prop-types";

import { OVERLAY_TYPES } from "./constants";
import useOverlayConfig from "./hooks/useOverlayConfig";
import useStyles from "./styles";

export const Overlay = ({ isContentActive, type }) => {
  const { i18n, onClickButton, link, ImageComponent, overrideClasses } =
    useOverlayConfig(type);

  const classes = useStyles();

  return (
    <ComplexOverlayBox
      i18n={i18n}
      onClickButton={onClickButton}
      link={link}
      ImageComponent={ImageComponent}
      overrideClasses={{
        ...overrideClasses,
        backdrop: classes.backdrop,
      }}
      isShownOverlay={!isContentActive}
    >
      {null}
    </ComplexOverlayBox>
  );
};

Overlay.propTypes = {
  type: PropTypes.oneOf(Object.values(OVERLAY_TYPES)).isRequired,
  isContentActive: PropTypes.bool.isRequired,
};
