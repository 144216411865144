import { Button, Grid, LoadingCircle, Typography } from "@fleekhq/react-drip";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Link } from "@material-ui/core";
import { toast } from "@terminal-packages/ui";
import { ethers } from "ethers";
import isMobile from "ismobilejs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FaEthereum } from "react-icons/fa";
import { IoCheckmark, IoGitBranch, IoSend } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { QRCode } from "src/components";
import config from "src/config";
import { githubProvider, onPasswordUpdate, projectAuth } from "src/firebase";
import { useQuery } from "src/hooks/use-query";
import {
  linkWalletToUser,
  signInWithEthereum,
  signMessage,
} from "src/integrations/ethereum/utils";
import { validateCaptcha } from "src/integrations/hCaptcha";
import { resetState } from "src/reducers/actions/web3-state";
import { useAuth } from "src/store/AuthContext";

import {
  ALERT_VARIANTS,
  CONNECTION_LABELS,
  GIT_PROVIDER_NAMES,
  INJECTED_PROVIDER_NAMES,
  WEB3_PROVIDER_NAMES,
} from "../constants";
import {
  getFirebaseCreateUserWithEmailAndPasswordErrorMessage,
  getFirebaseSendEmailVerificationErrorMessage,
  getFirebaseSendPasswordResetEmailErrorMessage,
  getFirebaseSignInWithCustomTokenErrorMessage,
  getFirebaseSignInWithEmailAndPasswordErrorMessage,
  getFirebaseSignInWithPopupErrorMessage,
  getHCaptchaErrorMessage,
  getWeb3ErrorMessage,
  getWeb3ProviderName,
  getWeb3ProviderType,
} from "../utils";

const INITIAL_ALERT_DATA = {
  icon: undefined,
  children: "",
  variant: ALERT_VARIANTS.Success,
};

const TESTING_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@fleek.xyz$/;

const useAuthentication = (options) => {
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const { web3AuthType = "connect", shouldManipulateURL } = options || {};
  const web3State = useSelector((state) => state.web3);

  const { currentUser, walletConnect, walletExtension } = useAuth();
  const dispatch = useDispatch();

  const captchaRef = useRef(null);
  const { t } = useTranslation();

  const isAnyMobile = isMobile(window.navigator).any;

  const [alertData, setAlertData] = useState(INITIAL_ALERT_DATA);

  const copyToClipboard = useCallback(
    (url) => {
      navigator.clipboard.writeText(url);
      setAlertData({
        icon: <IoCheckmark size={20} />,
        children: t("common.copiedToClipboard"),
        variant: ALERT_VARIANTS.Success,
      });
    },
    [t]
  );

  const [activeTab, setActiveTab] = useState('sign-in');
  const [captchaResponse, setCaptchaResponse] = useState();
  const [isResetPasswordSent, setIsResetPasswordSent] = useState(false);
  const [state, setState] = useState({
    isLoading: false,
    isConnectionFailed: false,
    resolver: null,
    verifyUserView: false,
  });
  const [providersExtraState, setProvidersExtraState] = useState({
    [WEB3_PROVIDER_NAMES.Extension]: {
      shouldShowRetry: false,
      connectionLabel:
        CONNECTION_LABELS[WEB3_PROVIDER_NAMES.Extension].connectionAwaiting,
    },
    [WEB3_PROVIDER_NAMES.WalletConnect]: {
      shouldShowRetry: false,
      connectionLabel:
        CONNECTION_LABELS[WEB3_PROVIDER_NAMES.WalletConnect].connectionAwaiting,
    },
    [WEB3_PROVIDER_NAMES.Rainbow]: {
      shouldShowRetry: false,
      connectionLabel:
        CONNECTION_LABELS[WEB3_PROVIDER_NAMES.Rainbow].connectionAwaiting,
    },
    [WEB3_PROVIDER_NAMES.Gnosis]: {
      shouldShowRetry: false,
      connectionLabel:
        CONNECTION_LABELS[WEB3_PROVIDER_NAMES.Gnosis].connectionAwaiting,
    },
    [GIT_PROVIDER_NAMES.GitHub]: {
      shouldShowRetry: false,
      connectionLabel:
        CONNECTION_LABELS[GIT_PROVIDER_NAMES.GitHub].connectionAwaiting,
    },
  });

  const [selectedMethod, setSelectedMethod] = useState(query.get("method"));
  const [selectedProvider, setSelectedProvider] = useState(
    query.get("provider")
  );

  const handleQuerySave = useCallback(
    (queryObject) => {
      const params = new URLSearchParams(queryObject);
      if (shouldManipulateURL === true) {
        history.replace({
          pathname: location.pathname,
          search: params.toString(),
        });
      }
    },
    [history, location.pathname, shouldManipulateURL]
  );

  const handleMethodSelect = (method) => {
    const queryObject = { method };

    handleQuerySave(queryObject);
  };

  const enableMFAVerify = useCallback(
    (showUserView, resolver) => {
      setState({
        ...state,
        resolver,
        isLoading: false,
        verifyUserView: showUserView,
      });
    },
    [state]
  );

  const handleResetAlert = () => {
    setAlertData(INITIAL_ALERT_DATA);
  };

  const handleStartLoading = useCallback(() => {
    handleResetAlert();
    setState({
      ...state,
      isLoading: true,
    });
  }, [state]);

  const handleStopLoading = useCallback(() => {
    setState({
      ...state,
      isLoading: false,
    });
  }, [state]);

  const handleValidateCaptcha = async () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { response } = await captchaRef.current?.execute?.({ async: true });

    const { result } = await validateCaptcha(response);

    if (!result.success) {
      console.error(result);
      setAlertData({
        children: getHCaptchaErrorMessage(result?.error_code),
        variant: ALERT_VARIANTS.Error,
      });
      return false;
    }

    return true;
  };

  const handleSignIn = useCallback(
    async (data) => {
      if (!state.isLoading) {
        handleStartLoading();
        const { email, password } = data;
        try {
          const result = await projectAuth.signInWithEmailAndPassword(
            email,
            password
          );

          if (result && result.user) {
            await projectAuth.currentUser.getIdToken();
            // const callbackURL = `/#/auth/cb#id_token=${idToken}&uid=${result.user.uid}`;
            // window.location.replace(callbackURL);
          }
        } catch (error) {
          console.error(error);
          if (error.code === "auth/multi-factor-auth-required") {
            enableMFAVerify(true, error.resolver);
          } else {
            setAlertData({
              children:
                getFirebaseSignInWithEmailAndPasswordErrorMessage(error),
              variant: ALERT_VARIANTS.Error,
            });
            handleStopLoading();
          }
        }
      }
    },
    [enableMFAVerify, handleStartLoading, handleStopLoading, state.isLoading]
  );

  const handleSignUp = useCallback(
    async (data) => {
      if (!state.isLoading) {
        handleStartLoading();
        const isCaptchaValidated = await handleValidateCaptcha();

        if (!isCaptchaValidated) {
          handleStopLoading();
          return;
        }

        const { email, password } = data;
        try {
          const result = await projectAuth.createUserWithEmailAndPassword(
            email,
            password
          );

          if (result?.user) {
            try {
              await projectAuth.currentUser.sendEmailVerification();

              if (result.user.emailVerified) {
                await projectAuth.currentUser.getIdToken();
                // const callbackURL = `/#/auth/cb#id_token=${idToken}&uid=${result.user.uid}`;
                // window.location.replace(callbackURL);
              }
            } catch (error) {
              setAlertData({
                children: getFirebaseSendEmailVerificationErrorMessage(error),
                variant: ALERT_VARIANTS.Error,
              });
            }
          }
        } catch (error) {
          console.error(error);
          setAlertData({
            children:
              getFirebaseCreateUserWithEmailAndPasswordErrorMessage(error),
            variant: ALERT_VARIANTS.Error,
          });
        } finally {
          handleStopLoading();
        }
      }
    },
    [handleStartLoading, handleStopLoading, state.isLoading]
  );

  const handleResetPassword = useCallback(
    async ({ email }) => {
      if (!state.isLoading) {
        handleStartLoading();

        try {
          await projectAuth.sendPasswordResetEmail(email);
          await onPasswordUpdate({ hasRequestedPasswordUpdate: true });

          setIsResetPasswordSent(true);

          handleStopLoading();
          return true;
        } catch (error) {
          console.error(error);
          setAlertData({
            children: getFirebaseSendPasswordResetEmailErrorMessage(error),
            variant: ALERT_VARIANTS.Error,
          });
          handleStopLoading();
          return false;
        }
      }
    },
    [handleStartLoading, handleStopLoading, state.isLoading]
  );

  const setProviderState = useCallback(
    (providerName, providerState) => {
      setProvidersExtraState({
        ...providersExtraState,
        [providerName]: {
          ...providersExtraState[providerName],
          ...providerState,
        },
      });
    },
    [providersExtraState]
  );

  const handleGitAuth = useCallback(async () => {
    if (!state.isLoading) {
      handleStartLoading();

      setProviderState(GIT_PROVIDER_NAMES.GitHub, {
        shouldShowRetry: false,
        connectionLabel:
          CONNECTION_LABELS[GIT_PROVIDER_NAMES.GitHub].connectionAwaiting,
      });

      try {
        const result = await projectAuth.signInWithPopup(githubProvider);

        setProviderState(GIT_PROVIDER_NAMES.GitHub, {
          shouldShowRetry: false,
          connectionLabel:
            CONNECTION_LABELS[GIT_PROVIDER_NAMES.GitHub].connecting,
        });

        if (result && result.user) {
          const userDetails = result.user;
          const canUpdateProfile =
            !userDetails.displayName &&
            userDetails.providerData &&
            userDetails.providerData.length > 0 &&
            userDetails.providerData[0].displayName;

          if (canUpdateProfile) {
            await projectAuth.currentUser.updateProfile({
              displayName: userDetails.providerData[0].displayName,
            });
          }
          await projectAuth.currentUser.getIdToken();
          // const callbackURL = `/#/auth/cb#id_token=${idToken}&uid=${result.user.uid}`;
          // window.location.replace(callbackURL);
        }
      } catch (error) {
        console.error(error);

        setProviderState(GIT_PROVIDER_NAMES.GitHub, {
          connectionLabel:
            CONNECTION_LABELS[GIT_PROVIDER_NAMES.GitHub].connectionAwaiting,
          shouldShowRetry: true,
        });

        setAlertData({
          children: getFirebaseSignInWithPopupErrorMessage(error),
          variant: ALERT_VARIANTS.Error,
        });
      } finally {
        handleStopLoading();
      }
    }
  }, [
    handleStartLoading,
    handleStopLoading,
    setProviderState,
    state.isLoading,
  ]);

  const handleWeb3Auth = useCallback(
    async (options) => {
      if (!state.isLoading) {
        handleStartLoading();

        const { provider } = options || {};

        try {
          setProviderState(provider.name, {
            connectionLabel: CONNECTION_LABELS[provider.name].approvalAwaiting,
            shouldShowRetry: false,
          });

          const signer = provider.instance.getSigner();

          const response = await signMessage(signer);

          setProviderState(provider.name, {
            connectionLabel: CONNECTION_LABELS[provider.name].connecting,
            shouldShowRetry: false,
          });

          const siweFn =
            web3AuthType === "connect"
              ? signInWithEthereum
              : web3AuthType === "link"
                ? linkWalletToUser
                : undefined;

          try {
            const siweResult = await siweFn({
              uid: currentUser?.id,
              ...response,
            });
            const { result: customToken } = siweResult?.data || {};

            if (web3AuthType === "link" && customToken) {
              toast.success(t("auth.linkSuccess"));

              setProviderState(provider.name, {
                connectionLabel: CONNECTION_LABELS[provider.name].done,
                shouldShowRetry: false,
              });

              window.location.reload();
            }

            try {
              if (web3AuthType === "connect" && customToken) {
                await projectAuth.signInWithCustomToken(customToken);
                // const callbackURL = `/auth/cb?id_token=${customToken}&uid=${result.user.uid}`;
                // window.location.replace(callbackURL);
              }
            } catch (error) {
              console.error(error);
              setAlertData({
                children: getFirebaseSignInWithCustomTokenErrorMessage(error),
                variant: ALERT_VARIANTS.Error,
              });
            }

            if (!customToken) {
              setProviderState(provider.name, {
                connectionLabel:
                  CONNECTION_LABELS[provider.name].connectionAwaiting,
                shouldShowRetry: true,
              });

              // TODO: Improve error message flow
              const errorMessage =
                web3AuthType === "link"
                  ? "Account with this wallet address is already created or linked to another account, please contact Fleek team for extended support."
                  : "Error when trying to sign up, contact support for details.";

              setAlertData({
                children: errorMessage,
                variant: ALERT_VARIANTS.Error,
              });
            }
          } catch (error) {
            console.error(error);

            setProviderState(provider.name, {
              connectionLabel:
                CONNECTION_LABELS[provider.name].connectionAwaiting,
              shouldShowRetry: true,
            });

            setAlertData({
              children: error.message,
              variant: ALERT_VARIANTS.Error,
            });
          }
        } catch (error) {
          console.error(error);
          setProviderState(provider.name, {
            connectionLabel:
              CONNECTION_LABELS[provider.name].connectionAwaiting,
            shouldShowRetry: true,
          });

          setAlertData({
            children: error.message,
            variant: ALERT_VARIANTS.Error,
          });
        } finally {
          handleStopLoading();
        }
      }
    },
    [
      currentUser?.id,
      handleStartLoading,
      handleStopLoading,
      setProviderState,
      state.isLoading,
      t,
      web3AuthType,
    ]
  );

  const handleProviderSelect = useCallback(
    async (providerName) => {
      const queryObject = {
        method: query.get("method"),
        provider: providerName,
      };

      handleQuerySave(queryObject);
      if (!state.isLoading) {
        handleResetAlert();

        const extensionProviders = [
          INJECTED_PROVIDER_NAMES.MetaMask,
          INJECTED_PROVIDER_NAMES.Frame,
          WEB3_PROVIDER_NAMES.Extension,
        ];

        const isExtensionProvider = extensionProviders.some(
          (name) => providerName === name
        );

        const _providerName = isExtensionProvider
          ? WEB3_PROVIDER_NAMES.Extension
          : providerName;

        const isGitProvider = Object.values(GIT_PROVIDER_NAMES).some(
          (pn) => _providerName === pn
        );
        const isWeb3Provider = Object.values(WEB3_PROVIDER_NAMES).some(
          (pn) => _providerName === pn
        );

        if (isGitProvider) {
          handleGitAuth(_providerName);
        }

        if (isWeb3Provider) {
          setProviderState(_providerName, {
            connectionLabel:
              CONNECTION_LABELS[_providerName].connectionAwaiting,
            shouldShowRetry: false,
          });
          try {
            if (isExtensionProvider) {
              const web3Provider = await walletExtension.initialize(
                ethers.providers.Web3Provider
              );
              await handleWeb3Auth({
                provider: { name: _providerName, instance: web3Provider },
              });
            } else {
              const { isConnected, web3Provider } =
                await walletConnect.initialize(
                  ethers.providers.Web3Provider,
                  {},
                  { clearSession: true }
                );

              if (isConnected) {
                await handleWeb3Auth({
                  provider: { name: _providerName, instance: web3Provider },
                });
              } else {
                handleProviderSelect(_providerName);
              }
            }
          } catch (error) {
            console.error(error);

            setProviderState(_providerName, {
              connectionLabel:
                CONNECTION_LABELS[_providerName].connectionAwaiting,
              shouldShowRetry: true,
            });

            setAlertData({
              children: getWeb3ErrorMessage(error),
              variant: ALERT_VARIANTS.Error,
            });
          }
        }
      }
    },
    [
      handleGitAuth,
      handleQuerySave,
      handleWeb3Auth,
      query,
      setProviderState,
      state.isLoading,
      walletConnect,
      walletExtension,
    ]
  );

  const handleProviderCancel = useCallback(() => {
    setSelectedProvider();
    setSelectedMethod();
    handleResetAlert();

    const queryObject = {
      method: query.get("method"),
    };

    handleQuerySave(queryObject);
  }, [handleQuerySave, query]);

  const clearWeb3State = useCallback(() => {
    handleProviderCancel();
    dispatch(resetState());
  }, [dispatch, handleProviderCancel]);

  const handleWalletConnectDisconnect = useCallback(async () => {
    await walletConnect.disconnect();
    clearWeb3State();
  }, [clearWeb3State, walletConnect]);

  const handleResendEmailVerificaiton = useCallback(async () => {
    setState({
      ...state,
      isLoading: true,
    });

    try {
      await projectAuth.currentUser.sendEmailVerification();
      setAlertData({
        children: t("auth.email.resendSuccess"),
        variant: ALERT_VARIANTS.Primary,
        icon: <IoSend size={20} />,
      });
    } catch (e) {
      setAlertData({
        children: t("auth.email.resendFail", { error: e.message }),
        variant: ALERT_VARIANTS.Error,
      });
    }

    setState({
      ...state,
      isLoading: false,
    });
  }, [state, t]);

  const { authMethods, emailMethod } = useMemo(() => {
    const isWeb3ProviderEnabled = config.auth.web3ProviderEnabled;

    const extensionProvider = {
      logoSrc: walletExtension.metadata.src,
      subLogoSrc: walletExtension.metadata.subSrc,
      name: walletExtension.metadata.name,
      description: walletExtension.metadata.description,
      onConnectionRetry: () =>
        handleProviderSelect(WEB3_PROVIDER_NAMES.Extension),
      onCancel: clearWeb3State,
      isConnecting: true,
      ...providersExtraState[WEB3_PROVIDER_NAMES.Extension],
    };

    const walletConnectProvider = {
      logoSrc: "/images/wallet-connect.png",
      subLogoSrc: "/images/wallet-connect-sm.png",
      name: WEB3_PROVIDER_NAMES.WalletConnect,
      onConnectionRetry: () =>
        handleProviderSelect(WEB3_PROVIDER_NAMES.WalletConnect),
      onCancel: handleWalletConnectDisconnect,
      description:
        "Use WalletConnect to create an account with any Ethereum wallet.",
      children: walletConnect.uri ? (
        <QRCode
          value={walletConnect.uri}
          logo="/images/wallet-connect.png"
          style={{ marginBottom: "18px" }}
        />
      ) : (
        <LoadingCircle css={{ height: "$52", pb: "$4", mb: "$-1" }} />
      ),
      isConnecting:
        web3State.isConnected ||
        walletConnect.registry.isLoading ||
        providersExtraState[WEB3_PROVIDER_NAMES.WalletConnect].shouldShowRetry,
      ...providersExtraState[WEB3_PROVIDER_NAMES.WalletConnect],
    };

    const rainbowProviderUniversalLink = walletConnect.registry.providers.find(
      ({ name }) => name === "Rainbow"
    )?.universalLink;
    const rainbowURI = `${rainbowProviderUniversalLink}/wc?uri=${encodeURIComponent(
      walletConnect.uri
    )}`;
    const rainbowProvider = {
      logoSrc: "/images/rainbow.png",
      name: WEB3_PROVIDER_NAMES.Rainbow,
      onConnectionRetry: () =>
        handleProviderSelect(WEB3_PROVIDER_NAMES.Rainbow),
      onCancel: handleWalletConnectDisconnect,
      description:
        "Use Rainbow wallet to create an account. Great for personal accounts!",
      children: rainbowURI && (
        <QRCode
          value={rainbowURI}
          logo="/images/rainbow-transparent.png"
          style={{ marginBottom: "18px" }}
        />
      ),
      isConnecting:
        web3State.isConnected ||
        walletConnect.registry.isLoading ||
        providersExtraState[WEB3_PROVIDER_NAMES.Rainbow].shouldShowRetry,
      ...providersExtraState[WEB3_PROVIDER_NAMES.Rainbow],
    };

    const gnosisProviderUniversalLink = walletConnect.registry.providers.find(
      ({ name }) => name === "Gnosis Safe Multisig"
    )?.universalLink;
    const gnosisURI = `${gnosisProviderUniversalLink}/wc?uri=${encodeURIComponent(
      walletConnect.uri
    )}`;
    const gnosisProvider = {
      logoSrc: "/images/gnosis.png",
      name: WEB3_PROVIDER_NAMES.Gnosis,
      onConnectionRetry: () => handleProviderSelect(WEB3_PROVIDER_NAMES.Gnosis),
      onCancel: handleWalletConnectDisconnect,
      description:
        "Use Gnosis to create an account, good for DAOs and security-first projects.",
      isDisabled: true,
      children: walletConnect.uri && (
        <Grid
          css={{ gap: "4px", marginBottom: "4px", justifyContent: "center" }}
        >
          <QRCode
            size={130}
            logoSize={24}
            value={gnosisURI}
            logo="/images/gnosis.png"
          />
          <Button
            variant="ghost"
            onClick={() => copyToClipboard(walletConnect.uri)}
            colorScheme="gray"
          >
            Copy Code to Clipboard
          </Button>
        </Grid>
      ),
      isConnecting:
        web3State.isConnected ||
        walletConnect.registry.isLoading ||
        providersExtraState[WEB3_PROVIDER_NAMES.Gnosis].shouldShowRetry,
      ...providersExtraState[WEB3_PROVIDER_NAMES.Gnosis],
    };

    const web3AuthProviders = isAnyMobile
      ? [walletConnectProvider]
      : [
        extensionProvider,
        rainbowProvider,
        walletConnectProvider,
        gnosisProvider,
      ];

    const web3Auth = {
      name: "Ethereum",
      icon: <FaEthereum fontSize={20} />,
      isDisabled: false,
      onProviderSelect: (name) => {
        handleProviderSelect(getWeb3ProviderType(name));
      },
      providers: web3AuthProviders,
    };

    const gitHubProvider = {
      logoSrc: "/images/github.png",
      subLogoSrc: "/images/github-sm.png",
      name: GIT_PROVIDER_NAMES.GitHub,
      onConnectionRetry: () => handleProviderSelect(GIT_PROVIDER_NAMES.GitHub),
      onCancel: handleProviderCancel,
      isConnecting: true,
      ...providersExtraState[GIT_PROVIDER_NAMES.GitHub],
    };

    const gitLabProvider = {
      logoSrc: "/images/gitlab.png",
      subLogoSrc: "/images/gitlab-sm.png",
      name: GIT_PROVIDER_NAMES.GitLab,
      connectionLabel: "Authorize Fleek Access",
      isDisabled: true,
      children: undefined,
    };

    const bitBucketProvider = {
      logoSrc: "/images/bitbucket.png",
      subLogoSrc: "/images/bitbucket-sm.png",
      name: GIT_PROVIDER_NAMES.BitBucket,
      connectionLabel: "Authorize Fleek Access",
      isDisabled: true,
      children: undefined,
    };

    const radicleProvider = {
      logoSrc: "/images/radicle.png",
      name: GIT_PROVIDER_NAMES.Radicle,
      connectionLabel: "Authorize Fleek Access",
      isDisabled: true,
      children: undefined,
    };

    const gitAuthProviders = [
      gitHubProvider,
      gitLabProvider,
      bitBucketProvider,
      radicleProvider,
    ];

    const gitAuth = {
      name: "Git Provider",
      icon: <IoGitBranch fontSize={20} />,
      isDisabled: false,
      onProviderSelect: handleProviderSelect,
      onCancel: handleProviderCancel,
      providers: gitAuthProviders,
    };

    const handleCaptchaExpire = (error) => {
      console.error(error);
      if (captchaResponse) {
        setCaptchaResponse(undefined);
      }

      setState({
        ...state,
        isLoading: false,
      });

      setAlertData({
        children: t("auth.captcha.expired"),
        variant: ALERT_VARIANTS.Error,
      });
    };

    const handleCaptchaError = (error) => {
      console.error(error);
      if (captchaResponse) {
        setCaptchaResponse(undefined);
      }

      setState({
        ...state,
        isLoading: false,
      });

      setAlertData({
        children: t("auth.captcha.invalid"),
        variant: ALERT_VARIANTS.Error,
      });
    };

    const handleCaptchaClose = () => {
      setState({
        ...state,
        isLoading: false,
      });
    };

    const handleCaptchaVerify = (res) => {
      setCaptchaResponse(res);
    };

    // eslint-disable-next-line react/prop-types
    const CaptchaEndNode = ({ typographyProps }) => (
      <>
        <Typography
          fontSize="xs"
          css={{
            px: "$2",
            color: "$slate11",
            textAlign: "center",
            // eslint-disable-next-line react/prop-types
            ...(typographyProps?.css || {}),
          }}
        >
          This site is protected by hCaptcha and its&nbsp;
          <Link href="https://hcaptcha.com/privacy">Privacy Policy</Link>
          &nbsp;and&nbsp;
          <Link href="https://hcaptcha.com/terms">Terms of Service</Link>
          &nbsp;apply.
        </Typography>
      </>
    );

    const emailRegex =
      process.env.REACT_APP_FE_NODE_ENV !== "production" &&
        activeTab === "sign-up"
        ? TESTING_EMAIL_REGEX
        : undefined;
    const emailData = {
      onValidationFailure: ({ field, type }) => {
        let error = "";
        switch (field) {
          case "email":
            if (type === "empty") {
              error = t("auth.email.requiredError");
            }

            if (type === "invalid") {
              error = t("auth.email.invalidError");
            }
            break;
          case "password":
            if (type === "empty") {
              error = t("auth.password.requiredError");
            }

            if (type === "invalid") {
              error = t("auth.password.invalidError");
            }
            break;
          default:
            break;
        }

        return error;
      },
      signIn: {
        isLoading: state.isLoading,
        onSubmit: handleSignIn,
      },
      signUp: {
        endNode: (
          <>
            <HCaptcha
              ref={captchaRef}
              size="invisible"
              sitekey={config.hcaptcha.sitekey}
              onClose={handleCaptchaClose}
              onVerify={handleCaptchaVerify}
              onError={handleCaptchaError}
              onExpire={handleCaptchaExpire}
            />
            <CaptchaEndNode typographyProps={{ css: { pt: "$4" } }} />
          </>
        ),
        isLoading: state.isLoading,
        onSubmit: handleSignUp,
      },
      resetPassword: {
        endNode: (
          <>
            <HCaptcha
              ref={captchaRef}
              size="invisible"
              sitekey={config.hcaptcha.sitekey}
              onClose={handleCaptchaClose}
              onVerify={handleCaptchaVerify}
              onError={handleCaptchaError}
              onExpire={handleCaptchaExpire}
            />
            <CaptchaEndNode />
          </>
        ),
        onCancel: () => handleResetAlert(),
        isLoading: state.isLoading,
        onSubmit: async (data) => {
          handleStartLoading();
          const isCaptchaValidated = await handleValidateCaptcha();

          if (!isCaptchaValidated) {
            handleStopLoading();
            return;
          }

          handleResetPassword(data);
        },
      },
      resetPasswordPending: {
        isLoading: state.isLoading,
        isVisible: isResetPasswordSent,
        onCancel: () => setIsResetPasswordSent(false),
        onSubmit: async (data) => {
          const isReset = await handleResetPassword(data);

          if (isReset) {
            setAlertData({
              children: t("auth.resetPasswordSent"),
              variant: ALERT_VARIANTS.Primary,
              icon: <IoSend size={20} />,
            });
          }
        },
      },
      verifyEmailPending: {
        isLoading: state.isLoading,
        isVisible: currentUser?.emailVerified === false,
        onCancel: () => projectAuth.signOut(),
        onSubmit: handleResendEmailVerificaiton,
      },
      emailRegex,
    };

    const methods = isWeb3ProviderEnabled ? [web3Auth, gitAuth] : [gitAuth];

    return { authMethods: methods, emailMethod: emailData };
  }, [
    walletExtension.metadata.src,
    walletExtension.metadata.subSrc,
    walletExtension.metadata.name,
    walletExtension.metadata.description,
    clearWeb3State,
    providersExtraState,
    handleWalletConnectDisconnect,
    walletConnect.uri,
    walletConnect.registry.isLoading,
    walletConnect.registry.providers,
    web3State.isConnected,
    isAnyMobile,
    handleProviderCancel,
    handleProviderSelect,
    state,
    handleSignIn,
    handleSignUp,
    isResetPasswordSent,
    currentUser?.emailVerified,
    handleResendEmailVerificaiton,
    copyToClipboard,
    captchaResponse,
    t,
    handleStartLoading,
    handleResetPassword,
    handleStopLoading,
    activeTab,
  ]);

  const handleTypeSwitch = (tab) => {
    const method = query.get("method");
    const provider = query.get("provider");
    const queryMethod = method ? `method=${method}` : "";
    const queryProvider = provider ? `provider=${provider}` : "";
    const q =
      queryMethod && queryProvider
        ? `?${queryMethod}&${queryProvider}`
        : queryMethod
          ? `?${queryMethod}`
          : queryProvider
            ? `?${queryProvider}`
            : "";

    setActiveTab(tab);
    history.push(`/auth/${tab}${q}`);

    handleResetAlert();
  };

  const handleMoveToMethodsStep = () => {
    handleResetAlert();
    handleQuerySave({});
  };

  useEffect(() => {
    const method = query.get("method");
    const provider = query.get("provider");

    if (method && provider) {
      handleProviderSelect(provider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (emailMethod.verifyEmailPending?.isVisible && shouldManipulateURL) {
      const timer = setTimeout(() => window.location.reload(), 10000);

      return function timeoutCleanup() {
        clearTimeout(timer);
      };
    }
  }, [emailMethod.verifyEmailPending?.isVisible, shouldManipulateURL]);

  return {
    state,
    providersExtraState,
    onVerifyUserView: enableMFAVerify,
    alertData: {
      onClose: handleResetAlert,
      ...alertData,
    },
    authLayoutProps: {
      type: activeTab,
      methods: authMethods,
      emailMethod,
      initialSelectedProvider: selectedProvider
        ? getWeb3ProviderName({
          name: walletExtension.metadata?.name,
          type: selectedProvider,
        })
        : undefined,
      initialSelectedMethod: selectedMethod,
      onTypeChange: handleTypeSwitch,
      onMethodSelect: handleMethodSelect,
      onMoveToMethodsStep: handleMoveToMethodsStep,
      subtext: 'If you already have an account on Fleek.co you can sign in below.',
      disableSignUpBox: true,
      labels: {
        'sign-in': {
          defaultHeading: 'Sign in',
          defaultButtonText: ' ',
        },
        'sign-up': {
          defaultHeading: 'Sign up',
          defaultButtonText: 'Sign in',
        },
      }
    },
  };
};

export default useAuthentication;
