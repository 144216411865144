import "./main.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import createTerminalTheme from "@terminal-packages/fe-theme";
import { Provider } from "react-redux";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { AuthRoute, Layout, UnauthRoute } from "src/components";
import { Modal } from "src/modals";

import store from "./store";
import AuthProvider from "./store/AuthContext";
import { url } from "./utils";
import AuthCallback from "./views/AuthCallback";
import Authentication from "./views/Authentication";
import ErrorPage from "./views/Error";
import FleekCliLogin from "./views/FleekCliLogin";
import GithubAuthorized from "./views/GithubAuthorized";
import HandshakeSuccess from "./views/HandshakeSuccess";
import Invitation from "./views/Invitation";
import Root from "./views/Root";
import Settings from "./views/Settings";
import Sites from "./views/Sites";
import Start from "./views/Start";
import Teams from "./views/Teams";
import TeamSettings from "./views/TeamSettings";
import VerificationCallback from "./views/VerificationCallback";

const theme = createTerminalTheme();

function App() {
  if (window.location.href.includes("/github-authorized")) {
    return (
      <>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <GithubAuthorized />
        </ThemeProvider>
      </>
    );
  }

  if (window.location.href.includes("/handshake-success")) {
    return (
      <Router>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <HandshakeSuccess />
        </ThemeProvider>
      </Router>
    );
  }

  if (window.location.href.includes("/fleek-cli-login")) {
    return (
      <Router>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <FleekCliLogin />
        </ThemeProvider>
      </Router>
    );
  }

  return (
    <Provider store={store}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router>
            <Switch>
              <UnauthRoute path="/auth/sign-in">
                <Authentication />
              </UnauthRoute>
              <UnauthRoute path="/auth/sign-up">
                <Authentication />
              </UnauthRoute>
              <UnauthRoute path="/auth/verify-email">
                <Authentication />
              </UnauthRoute>
              <Route path="/auth/cb">
                <AuthCallback />
              </Route>
              <Route path="/verification">
                <VerificationCallback />
              </Route>
              <AuthRoute path="/">
                <Layout>
                  <>
                    <Modal />
                    <Switch>
                      <Route path="/" exact>
                        <Root />
                      </Route>
                      <AuthRoute path="/start">
                        <Start />
                      </AuthRoute>
                      <AuthRoute path="/sites">
                        <Sites />
                      </AuthRoute>
                      <AuthRoute path="/teams">
                        <Teams />
                      </AuthRoute>
                      <AuthRoute path="/invitation">
                        <Invitation />
                      </AuthRoute>
                      <AuthRoute path="/settings">
                        <Settings />
                      </AuthRoute>
                      <Route path="/error/:errorCode">
                        <ErrorPage />
                      </Route>
                      <AuthRoute path="/team-settings">
                        <TeamSettings />
                      </AuthRoute>
                      <Route path="*">
                        <Redirect to={url.buildUrl(null, "/error/404")} />
                      </Route>
                    </Switch>
                  </>
                </Layout>
              </AuthRoute>
            </Switch>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
