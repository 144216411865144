export const HOSTING_SERVICES = [
  {
    id: "ipfs",
    name: "IPFS",
    image:
      "https://storage.googleapis.com/terminal-assets/images/hosting_services/ipfs.png",
  },
  {
    id: "dfinity",
    name: "Internet Computer (Mainnet)",
    image:
      "https://storage.googleapis.com/terminal-assets/images/hosting_services/dfinity.png",
  },
];
