import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  cardContent: {
    padding: 0,
  },
  paragraph: {
    display: 'inline-block',
    width: 630,
    fontSize: 15,
    color: theme.palette.palette.black,
    lineHeight: '19px',
    margin: '20px 20px 5px 20px',
  },
  footer: {
    margin: '10px 20px 20px 20px',
  },
  link: {
    color: theme.palette.palette.linkBlue,
    display: 'inline-flex',
    textDecoration: 'none',
  },
  docs: {
    padding: 0,
    color: theme.palette.palette.linkBlue,
    marginRight: 5,
  },
  arrowIcon: {
    display: 'flex',
    fontSize: 15,
    marginLeft: 5,
    color: theme.palette.palette.primaryBlue,
  },
  buttonContainer: {
    margin: '20px 0',
  },
  button: {
    height: 36,
  },
}));
