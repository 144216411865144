import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import GenericButton from '@terminal-packages/ui/core/Buttons/GenericButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';

import useStyles from './styles';

const BuyDomain = (props) => {
  const {
    onBuy,
    domain,
    loading,
    onCancel,
  } = props;

  const classes = useStyles();

  return (
    <div>
      <div className={classes.textContainer}>
        <Typography variant="h6">
          <Trans
            i18nKey="addHNSDomain.buyDomain.title"
            values={{ domainName: domain }}
          />
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          <Box fontWeight={500}>
            <Trans
              i18nKey="addHNSDomain.buyDomain.subtitle"
            />
          </Box>
        </Typography>
        <Typography
          variant="body2"
          className={classes.detail}
        >
          <Trans
            i18nKey="addHNSDomain.buyDomain.detail"
          />
        </Typography>
      </div>
      <div className={classes.ctaSection}>
        <GenericButton
          onClick={onBuy}
          loading={loading}
          buttonVariant="primary"
          overrideClass={{ button: classes.buyButton }}
        >
          <Trans
            i18nKey="addHNSDomain.buyDomain.buyCTA"
            values={{ domain }}
          />
        </GenericButton>
        <GenericButton
          disabled
          buttonVariant="primary"
          overrideClass={{ button: classes.addDomainButton }}
        >
          <Trans i18nKey="addHNSDomain.ownerPart.confirm" />
        </GenericButton>
        <GenericButton
          onClick={onCancel}
          disabled={loading}
          buttonVariant="secondary"
        >
          <Trans i18nKey="addHNSDomain.ownerPart.cancel" />
        </GenericButton>
      </div>
    </div>
  );
};

BuyDomain.defaultProps = {
  domain: null,
  loading: false,
  onBuy: () => {},
  onCancel: () => {},
};

BuyDomain.propTypes = {
  onBuy: PropTypes.func,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  domain: PropTypes.string,
};

export default BuyDomain;
