import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, MainContent } from "src/components";

import { AddNewSite } from "./container/add-new-site";

const Sites = () => {
  const { t } = useTranslation();
  const breadcrumbs = <Breadcrumbs />;

  return (
    <MainContent topBarContent={breadcrumbs}>
      <Helmet>
        <title>{t("sites.page_title_new_site") as string}</title>
      </Helmet>
      <AddNewSite />
    </MainContent>
  );
};

export default Sites;
