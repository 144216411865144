// @ts-nocheck
import { useMutation } from "@apollo/react-hooks";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { newApiClient } from "src/clients";
import { PROXY_TYPES } from "src/constants";
import { UPGRADE_CUSTOM_DOMAIN } from "src/views/Sites/graphql/mutations";
import { GET_SITE_BY_SLUG } from "src/views/Sites/graphql/queries";

import useStyles from "./styles";

const TRANSITION_TIMEOUT = 300;

export const ConfirmDnsUpgrade = ({
  open,
  closeModal,
  siteId,
  teamId,
  domainId,
  customDomain,
  siteSlug,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [confirmDNSUpgrade] = useMutation(UPGRADE_CUSTOM_DOMAIN, {
    client: newApiClient,
    update: (cache, { data }) => {
      const success = data.upgradeCustomDomain;
      const currentSiteBySlug = cache.readQuery({
        query: GET_SITE_BY_SLUG,
        variables: {
          slug: siteSlug,
        },
      });

      if (success && currentSiteBySlug) {
        const currentDomains = currentSiteBySlug.getSiteBySlug.domains;
        const newDomains = currentDomains.reduce((domains, currentDomain) => {
          if (currentDomain.domainId === domainId) {
            const newDomain = {
              ...currentDomain,
              proxyType: PROXY_TYPES.BUNNY,
            };

            domains.push(newDomain);
          } else {
            domains.push(currentDomain);
          }

          return domains;
        }, []);

        const newSiteBySlug = {
          getSiteBySlug: {
            ...currentSiteBySlug.getSiteBySlug,
            domains: [...newDomains],
          },
        };

        cache.writeQuery({
          query: GET_SITE_BY_SLUG,
          data: newSiteBySlug,
        });
      }
    },
  });

  const displayError = () => {
    setError(true);

    setTimeout(() => {
      setError(false);
    }, 5500);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const { errors } = await confirmDNSUpgrade({
        errorPolicy: "all",
        variables: {
          input: {
            siteId,
            teamId,
            domainId,
            customDomain,
          },
        },
      });

      if (!errors) {
        closeModal();
        return;
      }

      displayError();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      displayError();
    }
  };

  const modalTitle = `🚨 ${t("modals.confirmDnsUpgrade.title")}`;

  return (
    <BaseModal
      open={open}
      title={modalTitle}
      maxWidth={500}
      onClose={closeModal}
      className={classes.modal}
    >
      <Typography variant="subtitle1" className={classes.subtitle}>
        <Trans
          i18nKey="modals.confirmDnsUpgrade.subtitle"
          components={{ bold: <strong /> }}
        />
      </Typography>
      <Typography variant="body2" className={classes.description}>
        {t("modals.confirmDnsUpgrade.description")}
      </Typography>
      {error && (
        <Grow in={error} timeout={TRANSITION_TIMEOUT}>
          <div variant="body" className={classes.errorBox}>
            {t("modals.confirmDnsUpgrade.error")}
          </div>
        </Grow>
      )}
      <div className={classes.buttonContainer}>
        <GenericButton
          buttonVariant="secondary"
          onClick={closeModal}
          disabled={loading}
        >
          {t("modals.confirmDnsUpgrade.cancel")}
        </GenericButton>
        <GenericButton
          buttonVariant="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {t("modals.confirmDnsUpgrade.submit")}
        </GenericButton>
      </div>
    </BaseModal>
  );
};

ConfirmDnsUpgrade.defaultProps = {
  open: false,
};

ConfirmDnsUpgrade.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  domainId: PropTypes.string.isRequired,
  customDomain: PropTypes.string.isRequired,
  siteSlug: PropTypes.string.isRequired,
};
