import { isEmpty } from "lodash";

import { firestoreDB, projectAuth } from "../../firebase";

export const unlinkWeb3Wallet = (address) =>
  firestoreDB.collection("wallet-address").doc(address).delete();

export const checkIfWeb3AccountLinked = async (uid) => {
  const query = await firestoreDB
    .collection("wallet-address")
    .where("uid", "==", uid)
    .get();

  if (!query.empty) {
    const snapshot = query.docs[0];

    return {
      address: snapshot.id,
      ...snapshot.data(),
    };
  } else {
    return false;
  }
};

const updateWalletEmail = async (walletAddress, email) => {
  const walletRef = await firestoreDB
    .collection('wallet-address')
    .doc(walletAddress);

  if (!walletRef) {
    throw new Error(`FirestoreDB: wallet ${walletAddress} not found.`)
  } else {
    return walletRef.update({ email: email });
  }
}

export const unlinkEmailToWallet = async (walletAddress) => {
  if (!walletAddress) {
    throw new Error('Wallet address or email fields missing link the wallet.');
  }

  updateWalletEmail(walletAddress, '');
}

export const linkEmailToWallet = async (walletAddress, email) => {
  if (!walletAddress || !email) {
    throw new Error('Wallet address or email fields missing link the wallet.');
  }

  // Check if the email is not in use on FB Auth
  const existentEmail = await projectAuth.fetchSignInMethodsForEmail(email);
  if (!isEmpty(existentEmail)) {
    throw new Error('Email is already in use for another account.');
  }

  updateWalletEmail(walletAddress, email);
};

export const checkIsValidRoute = (route) => !route.includes("undefined");

export const checkTeamsLoaded = (uid) => {
  const userId = uid;
  return new Promise((resolve) => {
    firestoreDB
      .collection("users")
      .doc(userId)
      .onSnapshot((doc) => {
        if (doc.data() === undefined) {
          resolve({ ready: true });
        }
        if (doc?.data()?.ready) {
          resolve(doc.data());
        }
      });
  });
};

export const getQSFromHash = (hash) => {
  try {
    const qs = hash.split("&").reduce((result, item) => {
      const [key, value] = item.split("=");

      return {
        ...result,
        [key]: value,
      };
    }, {});

    return qs;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`[Verify user error]: ${error.message}`);

    return null;
  }
};
