import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    background: theme.palette.common.white,
    borderRadius: theme.spacing(2),
    border: '1px solid black',
    padding: theme.spacing(1),
  },
}));
