import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    height: 36,
    display: 'flex',
    alignItems: 'center',
  },
  storageDisabledTooltip: {
    marginLeft: '10px'
  },
  disabled: {
    '& > p': {
      color: theme.palette.palette.grey1
    }
  },
  link: {
    flex: '1 1 0'
  },
  tooltip: {
    '&&&': {
      fontWeight: 'normal',
      fontFamily: theme.typography.fontFamily,
      maxWidth: 216,
      border: `1px solid ${theme.palette.palette.grey5}`,
      color: theme.palette.palette.black,
      backgroundColor: theme.palette.palette.whiteThree,
      boxShadow: `0 0 6px 0 ${theme.palette.palette.extraLightBlack}`,
      padding: '20px',
      whiteSpace: 'break-spaces',
      minWidth: 280
    },
  },
  icon: {
    display: 'flex',
    fontSize: 15,
    color: theme.palette.palette.grey2,
  },
  tooltipContent: {
    margin: 0,
    padding: 0,
    fontSize: '14px',
    lineHeight: '1.5em'
  }
}));
