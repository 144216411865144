import { CloseButton } from "@fleekhq/react-drip";
import { Modal } from "@material-ui/core"
import GenericButton from '@terminal-packages/ui/core/Buttons/GenericButton';
import { toast } from "@terminal-packages/ui/core/Toast";
import classnames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SelectFiles from 'src/modals/upload-files/components/SelectFiles';

import useStyles, { UploadEnvFileContainer } from "./styled";
import { ALLOWED_MIME_TYPES, AllowedMimeTypes, getEnvVariables } from "./utils";

interface Props {
  open: boolean;
  closeModal: () => void;
  onUpload: (envVars: { name: string; value: string; }[]) => void;
}

export const UploadEnvFileModal = ({ open, closeModal, onUpload }: Props) => {
  const [file, setFile] = useState<File>();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const { t } = useTranslation();
  const classes = useStyles();

  const onConfirm = () => {
    if (!file) {
      return;
    }

    getEnvVariables(file, (variables) => {
      if (variables.length === 0) {
        toast.error(t("modals.uploadEnvFile.noValidVariables") as string, {
          autoClose: 6000,
        });
      }

      onUpload(variables)
      closeModal();
    });
  }

  const handleSelectFiles = (files: [File]) => {
    const file = files[0];
    if (!ALLOWED_MIME_TYPES.includes(file.type as AllowedMimeTypes)) {
      setUploadError(t("modals.uploadEnvFile.fileTypeError"));
    }

    setFile(file)
  }

  const handleRemoveFile = () => {
    setFile(undefined)
    setUploadError(null);
  }

  return (
    <Modal open={open} onClose={closeModal}>
      <UploadEnvFileContainer>
        {!!file && (
          <div className={classes.uploadedFileContainer}>
            <div className={classes.fileImgContainer}>
              <CloseButton
                size="sm"
                css={{ position: "absolute", right: "-$4", top: "$4" }}
                onClick={handleRemoveFile}
              />
              <img src="images/file.png" alt={file.name} className={classes.fileImg} />
              <h3>{file.name}</h3>
              <p className={classnames(classes.error, { [classes.hidden]: !uploadError })}>{uploadError}</p>
            </div>
            <div className={classes.buttons}>
              <GenericButton
                css={{}}
                onClick={closeModal}
                buttonVariant="secondary"
                className={classes.cancelButton}
              >
                {t('common.cancel') as string}
              </GenericButton>
              <GenericButton css={{}} buttonVariant="primary" onClick={onConfirm} disabled={!!uploadError}>
                {t('common.confirm') as string}
              </GenericButton>
            </div>
          </div>
        )}
        <SelectFiles 
          onSelectFiles={handleSelectFiles} 
          maxFiles={1}
          className={classnames({ [classes.hidden]: !!file })}
          closeModal={closeModal}
        />
      </UploadEnvFileContainer>
    </Modal>
  )
}