import Typography from "@material-ui/core/Typography";
import { Trans } from "react-i18next";
import { useAccountId } from "src/hooks";
import { useTeam } from "src/store/AuthContext";

import useStyles from "./styles";

const TopBanner = () => {
  const classes = useStyles();
  const accountId = useAccountId();
  const { scheduledMigration, migrationStatus } = useTeam(accountId);

  if (scheduledMigration && scheduledMigration?.triggerAfter && migrationStatus !== 'COMPLETED') {
    return (
      <div className={classes.container}>
        <Typography className={classes.text}>
          <Trans
            i18nKey="migration.topBar.scheduled"
            values={{
              migrationDate: new Date(scheduledMigration?.triggerAfter || '').toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            }}
          />
        </Typography>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>
        <Trans
          i18nKey="topBanner.title"
          components={
            <a
              key="-"
              className={classes.link}
              href="https://fleek.xyz"
              target="_blank"
              rel="noopener noreferrer"
            >
              fleek.xyz
            </a>
          }
        />
      </Typography>
    </div>
  );
};

export default TopBanner;
