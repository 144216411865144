// @ts-nocheck
import get from "lodash/get";
import { DEPLOY_STATUS } from "src/constants";
import {
  getDeployStatus,
  getMainDomain,
  getSiteDateText,
  getSiteName,
} from "src/utils";

const getRepository = (siteBySlug) => {
  const repositoryURL =
    get(siteBySlug, "data.getSiteBySlug.deploySettings.source.url", "") || "";
  const repositoryBranch =
    get(siteBySlug, "data.getSiteBySlug.deploySettings.source.branch", "") || "";

  const splittedURL = repositoryURL.split("/");
  const owner = splittedURL[splittedURL.length - 2];
  const repoName = splittedURL[splittedURL.length - 1];

  return {
    repository: `${owner}/${repoName} (branch: ${repositoryBranch})`,
    repositoryUrl: `https://github.com/${owner}/${repoName}`,
  };
};

const getSubtitle = (t, status, siteBySlug) => {
  if (status === DEPLOY_STATUS.FAILED) {
    return t("sites.tabs.deploys.sections.deployStatus.deployFailed");
  }
  return getSiteDateText(t, {
    isPublished: !!get(siteBySlug, "data.getSiteBySlug.publishedDeploy"),
    createdAt: get(siteBySlug, "data.getSiteBySlug.createdAt"),
    lastPublishAt: get(
      siteBySlug,
      "data.getSiteBySlug.publishedDeploy.completedAt"
    ),
  });
};

const getSiteCard = ({ t, siteBySlug }) => {
  const repositoryData = getRepository(siteBySlug);
  const site = get(siteBySlug, "data.getSiteBySlug", {});
  const title = getSiteName(site);
  const siteId = get(siteBySlug, "data.getSiteBySlug.id", "");
  const startedAt =
    get(siteBySlug, "data.getSiteBySlug.publishedDeploy.startedAt", null) ||
    get(siteBySlug, "data.getSiteBySlug.latestDeploy.startedAt", "");
  const statusField =
    get(siteBySlug, "data.getSiteBySlug.publishedDeploy.status", null) ||
    get(siteBySlug, "data.getSiteBySlug.latestDeploy.status", "");
  const status = getDeployStatus({
    status: statusField,
    startedAt,
  });
  const domains = get(siteBySlug, "data.getSiteBySlug.domains", []);
  const platform = get(siteBySlug, "data.getSiteBySlug.platform", "ipfs");
  const dfinityCanisterId = get(
    siteBySlug,
    "data.getSiteBySlug.dfinityCanisterId"
  );
  const defaultDomain = getMainDomain(domains, dfinityCanisterId);
  const defaultDomaintype = get(defaultDomain, "type", "");
  const defaultDomainName = get(defaultDomain, "domain", "");
  const preview = get(
    siteBySlug,
    "data.getSiteBySlug.publishedDeploy.previewImage",
    ""
  );
  const ipfsHash = get(
    siteBySlug,
    "data.getSiteBySlug.publishedDeploy.ipfsHash",
    null
  );
  const subtitle = getSubtitle(t, status, siteBySlug);
  const localDeploy = t("sites.deployedLocally");

  return {
    siteId,
    title,
    status,
    preview,
    ipfsHash,
    platform,
    defaultDomaintype,
    canisterId: dfinityCanisterId,
    defaultDomain: defaultDomainName,
    subtitle,
    localDeploy,
    definityTag: t("sites.definityTag"),
    ...repositoryData,
  };
};

const presenter = {
  getSiteCard,
};

export default presenter;
