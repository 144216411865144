import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { MainContent } from "src/components";

import { TeamDetails } from "./components";

const TeamSettings = () => {
  const { t } = useTranslation();

  return (
    <MainContent>
      <Helmet>
        <title>{t("teamSettings.pageTitle")}</title>
      </Helmet>

      <br />

      <TeamDetails />
    </MainContent>
  );
};

export default TeamSettings;
