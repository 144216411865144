import InputSlim from '@terminal-packages/ui/core/InputSlim';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const EnvRow = ({
  classes,
  env,
  onChange,
  index
}) => {
  const { name: initialName, value: initialValue } = env;
  const [state, setState] = useState({
    name: initialName,
    value: initialValue,
  });
  const { t } = useTranslation();

  const handleChange = ({ fieldKey, fieldValue }) => {
    setState({
      ...state,
      [fieldKey]: fieldValue,
    });

    onChange({ id: env.id, fieldKey, fieldValue, index });
  };

  return (
    <>
      <InputSlim
        label={t('sites.start.buildOptions.envVars.keyLabel')}
        placeholder={t('sites.start.buildOptions.envVars.keyPlaceholder')}
        value={state.name || ''}
        onChange={(value) => handleChange({ fieldKey: 'name', fieldValue: value })}
        className={classes.textInput}
      />
      <InputSlim
        label={t('sites.start.buildOptions.envVars.variableLabel')}
        placeholder={t('sites.start.buildOptions.envVars.variablePlaceholder')}
        value={state.value || ''}
        onChange={(value) => handleChange({ fieldKey: 'value', fieldValue: value })}
        className={classes.textInput}
      />
    </>
  );
};

EnvRow.propTypes = {
  classes: PropTypes.shape({
    textInput: PropTypes.string,
  }).isRequired,
  env: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

export default EnvRow;
