import gql from 'graphql-tag';

export default gql`
  mutation editUserProfile($input: EditUserProfileInput!) {
    editUserProfile(input: $input) {
      user {
        individual {
          firstname
          lastname
        }
      }
    }
  }
`;
