// @ts-nocheck
import { Checkbox } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import { toast } from "@terminal-packages/ui/core/Toast";
import axios from "axios";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { firebaseFunctionEndpoints } from "src/integrations/firebase/constants";

import { useAuth } from "../../store/AuthContext";
import useStyles from "./styles";

export const DeleteProfileModal = ({ open, closeModal }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const { currentUser } = useAuth();
  const history = useHistory();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const currentUserId = currentUser.id;
      await axios.post(firebaseFunctionEndpoints.deleteAccount, {
        data: currentUserId
      })
      setLoading(false);
      closeModal();
      history.push('/');
      window.location.reload();
    } catch (error) {
      toast.error(`${t("modals.deleteProfile.error")}: ${error.message}`, {
        autoClose: 6000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      open={open}
      title={t("modals.deleteProfile.title")}
      maxWidth={500}
      onClose={closeModal}
      className={classes.modal}
    >
      <br />
      <Typography
        className={classes.message}
        variant="body2"
        color="textSecondary"
      >
        {t("modals.deleteProfile.description")}
      </Typography>
      <div className={classes.checkboxContainer}>
        <Checkbox color="primary" onChange={() => setCheckbox(!checkbox)} />
        <Typography
          className={classes.confirmationMessage}
          variant="body2"
          color="textSecondary"
        >
          {t("modals.deleteProfile.confirmationMessage")}
        </Typography>
      </div>

      <br />

      <div className={classes.buttonContainer}>
        <GenericButton
          buttonVariant="secondary"
          onClick={closeModal}
          disabled={loading}
        >
          {t("modals.deleteProfile.cancel")}
        </GenericButton>
        <GenericButton
          buttonVariant="important"
          onClick={handleSubmit}
          disabled={!checkbox || loading}
          loading={loading}
        >
          {t("modals.deleteProfile.delete")}
        </GenericButton>
      </div>
    </BaseModal>
  );
};

DeleteProfileModal.defaultProps = {
  open: false,
};

DeleteProfileModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};
