import { ButtonBase, Tooltip } from "@material-ui/core";
import { IconFA } from "@terminal-packages/ui";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useTeam } from "src/store/AuthContext";

import useStyles from "./styles";

const LinkTooltip = ({ tooltipContent }) => {
  const classes = useStyles();

  const TooltipIcon = (
    <ButtonBase
      className={classnames(
        classes.buttonTooltip,
        classes.storageDisabledTooltip
      )}
    >
      <IconFA
        icon={["fal", "info-circle"]}
        size="inherit"
        className={classes.icon}
      />
    </ButtonBase>
  );

  return (
    <Tooltip
      title={tooltipContent}
      placement="right"
      arrow={false}
      classes={{
        tooltip: classes.tooltip,
      }}
      interactive
    >
      {TooltipIcon}
    </Tooltip>
  );
};

const TooltipLink = ({ link, title }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    {title}
  </a>
);

const MigratedLink = ({ className, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { migrationStatus } = useTeam();

  return (
    <div
      className={classnames(classes.container, {
        [classes.disabled]: migrationStatus,
      })}
    >
      <p className={classnames(className, classes.disabled)}>{children}</p>
      <LinkTooltip
        tooltipContent={
          <p className={classes.tooltipContent}>
            {t("migration.sidebarTooltip")}
            <TooltipLink link="https://www.fleek.xyz" title="Fleek.xyz" />
            {". For more information visit our X account "}
            <TooltipLink
              link="https://twitter.com/fleek"
              title="(@fleek)"
            />
            {" "}{t("layout.sidebar.orOur")}{" "}
            <TooltipLink link="https://discord.gg/fleek" title="Discord" />
          </p>
        }
      />
    </div>
  );
};

export default MigratedLink;
