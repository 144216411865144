import gql from 'graphql-tag';

import { BILLING_INFO } from '../fragments';

export default gql`
  mutation removeBillingDetails($input: RemoveBillingDetailsInput!) {
    removeBillingDetails(input: $input) {
      ...BillingInfo
    }
  }
  ${BILLING_INFO}
`;
