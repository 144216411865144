import { useMutation } from "@apollo/react-hooks";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVert from "@material-ui/icons/MoreVert";
import ListItem from "@terminal-packages/ui/core/ListItem";
import Spinner from "@terminal-packages/ui/core/Spinner";
import { toast } from "@terminal-packages/ui/core/Toast";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";
import { MenuDropdown } from "src/components";
import { REMOVE_HNS } from "src/graphql/mutations";

import { GET_SITE_BY_SLUG } from "../../../../../../../../graphql/queries";
import useStyles from "./styles";

const DomainOptions = ({ siteId, domainId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const match = useRouteMatch();

  const [state, setState] = React.useState({
    isLoading: false,
    isOptionsOpen: false,
  });

  const [removeHns] = useMutation(REMOVE_HNS, {
    client: newApiClient,
    update: (cache) => {
      try {
        const data = cache.readQuery({
          query: GET_SITE_BY_SLUG,
          variables: {
            slug: match.params.siteSlug,
          },
        });

        const domains = data.getSiteBySlug.domains.filter(
          (_domain) => _domain.domainId !== domainId
        );

        cache.writeQuery({
          query: GET_SITE_BY_SLUG,
          variables: {
            slug: match.params.siteSlug,
          },
          data: {
            ...data,
            getSiteBySlug: {
              ...data.getSiteBySlug,
              domains,
            },
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          "Error when trying to update GET_SITE_BY_SLUG query: ",
          error.message
        );
      }
    },
  });

  const handleRemoveDomain = async (event) => {
    event.preventDefault();

    setState({
      ...state,
      isLoading: true,
    });

    try {
      await removeHns({
        variables: {
          input: {
            siteId,
            domainId,
          },
        },
      });

      toast.success(t("sites.tabs.settings.hns.success"), { autoClose: 6000 });
      setState({
        ...state,
        isLoading: false,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        "Error when trying to remove a handshake domain: ",
        error.message
      );

      toast.error(t("sites.tabs.settings.hns.error"), { autoClose: 6000 });
      setState({
        ...state,
        isLoading: false,
      });
    }
  };

  return (
    <div className={classes.optionsContainer}>
      <MenuDropdown
        menuId="menu-dropdown-domain-options"
        trigger={
          <IconButton
            disableRipple
            disableFocusRipple
            classes={{
              root: classes.iconButtonRoot,
            }}
          >
            <MoreVert />
          </IconButton>
        }
      >
        <ListItem className={classes.domainOption}>
          <ButtonBase
            type="button"
            className={classes.buttonBase}
            onClick={handleRemoveDomain}
          >
            <Typography variant="subtitle1" className={classes.remove}>
              {t("sites.tabs.settings.hns.remove")}
              {state.isLoading && (
                <span className={classes.spinner}>
                  <Spinner positioning="inline" />
                </span>
              )}
            </Typography>
          </ButtonBase>
        </ListItem>
      </MenuDropdown>
    </div>
  );
};

DomainOptions.propTypes = {
  siteId: PropTypes.string.isRequired,
  domainId: PropTypes.string.isRequired,
};

export default DomainOptions;
