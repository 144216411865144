import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import IconFA from "@terminal-packages/ui/core/IconFA";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEPLOY_STATUS, SOURCE_TYPES } from "src/constants";
import { getDeployLabel, getGithubRepositoryData } from "src/utils";
import getSourceType from "src/utils/get-source-type";

import { Header, SettingsButton } from "../shared/components";
import BranchCommitInfo from "../shared/components/BranchCommitInfo";
import PullRequestInfo from "../shared/components/PullRequestInfo";
import {
  CancelButton,
  RetryButton,
  // PublishButton,
} from "./Components";
import useStyles from "./styles";
import { mapDeploy } from "./utils";

const DeployStatus = (props) => {
  const [mutationInProgress, setMutationInProgress] = useState(false);
  const { deploy, siteBySlug } = props;
  const site = get(siteBySlug, "data.getSiteBySlug", {});
  const deployId = get(deploy, "id", null);
  const platform = get(site, "platform", "");
  const siteId = get(site, "id", null);
  const { url: repositoryUrl } = getGithubRepositoryData(siteBySlug);
  const { t } = useTranslation();
  const dfinityCanisterId = get(site, "dfinityCanisterId");
  const source = get(
    siteBySlug,
    "data.getSiteBySlug.deploySettings.source",
    {}
  );
  const sourceType = getSourceType(source);

  const {
    inProgress,
    published,
    branch,
    commit,
    startedAt,
    previewUrl,
    status,
    pullRequestUrl,
    taskId,
  } = mapDeploy(deploy, t, dfinityCanisterId);

  const classes = useStyles(props);

  const linkButton = (
    <GenericButton
      upperCase={false}
      overrideClass={{ button: classes.linkButton }}
    >
      <>
        <Typography variant="caption">
          {t("sites.tabs.deploys.sections.deployStatus.previewDeploy")}
        </Typography>
        <IconFA
          className={classes.icon}
          color="inherit"
          icon={["fal", "long-arrow-right"]}
        />
      </>
    </GenericButton>
  );

  const getSubtitle = () => {
    const subtitle = "sites.tabs.deploys.sections.deployStatus";
    let content = "";

    const successfulDeploy = status === DEPLOY_STATUS.DEPLOYED && !published;
    const publishedDeploy = status === DEPLOY_STATUS.DEPLOYED && published;
    const cancelledDeploy = status === DEPLOY_STATUS.CANCELLED;
    const failedDeploy = status === DEPLOY_STATUS.FAILED;
    const timeoutDeploy = status === DEPLOY_STATUS.TIMEOUT;

    if (inProgress) {
      content = (
        <>
          <span>{t(`${subtitle}.deployInProgress`)}</span>
          <div className={classes.gearIcon}>
            <IconFA fontSize="inherit" icon={["fal", "cog"]} />
          </div>
        </>
      );
    } else if (publishedDeploy) {
      content = (
        <>
          <span>{t(`${subtitle}.publishedDeploy`)}</span>
          <div className={classes.checkIcon}>
            <IconFA fontSize="inherit" icon={["fas", "check"]} />
          </div>
        </>
      );
    } else if (successfulDeploy) {
      content = (
        <>
          <span>{t(`${subtitle}.deploySuccessful`)}</span>
          <div className={classes.checkIcon}>
            <IconFA fontSize="inherit" icon={["fas", "check"]} />
          </div>
        </>
      );
    } else if (timeoutDeploy) {
      content = (
        <>
          <span>{t(`${subtitle}.deployTimeout`)}</span>
          <div className={classes.failedIcon}>
            <IconFA fontSize="inherit" icon={["far", "times"]} />
          </div>
        </>
      );
    } else if (cancelledDeploy) {
      content = (
        <>
          <span>{t(`${subtitle}.deployCancelled`)}</span>
          <div className={classes.failedIcon}>
            <IconFA fontSize="inherit" icon={["far", "times"]} />
          </div>
        </>
      );
    } else if (failedDeploy) {
      content = (
        <>
          <span>{t(`${subtitle}.deployFailed`)}</span>
          <div className={classes.failedIcon}>
            <IconFA fontSize="inherit" icon={["far", "times"]} />
          </div>
        </>
      );
    }
    return content;
  };

  const getDescription = () => {
    if (sourceType === SOURCE_TYPES.LOCAL) {
      return t("sites.tabs.deploys.sections.deployStatus.localDeploy");
    }
    return (
      <Typography variant="caption" color="textSecondary">
        {getDeployLabel(t, deploy)}
        &nbsp;
        {pullRequestUrl ? (
          <PullRequestInfo branch={branch} pullRequestUrl={pullRequestUrl} />
        ) : (
          <BranchCommitInfo
            branch={branch}
            commit={commit}
            repositoryUrl={repositoryUrl}
          />
        )}
        &nbsp;
        {startedAt}
      </Typography>
    );
  };

  return (
    <Header
      title={t("sites.tabs.deploys.sections.deployStatus.title")}
      subtitle={getSubtitle()}
      description={getDescription()}
    >
      <>
        {!inProgress ? (
          <a
            href={previewUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.previewLink}
          >
            {linkButton}
          </a>
        ) : (
          <Typography className={classes.description} variant="body2">
            {t("sites.tabs.deploys.sections.deployStatus.description", {
              platform: t(`common.${platform}`),
            })}
          </Typography>
        )}
        {sourceType !== SOURCE_TYPES.LOCAL && (
          <div className={classes.buttonsContainer}>
            <SettingsButton />
            {inProgress ? (
              <CancelButton
                classes={classes}
                setMutationInProgress={setMutationInProgress}
                disabled={taskId === null || mutationInProgress}
                t={t}
                siteId={siteId}
                deployId={deployId}
              />
            ) : (
              <>
                {/* {!published && (
                    <PublishButton
                      classes={classes}
                      setMutationInProgress={setMutationInProgress}
                      disabled={mutationInProgress}
                      t={t}
                    />
                  )} */}
                {<RetryButton disabled={!siteId} siteId={siteId} t={t} />}
              </>
            )}
          </div>
        )}
      </>
    </Header>
  );
};

DeployStatus.defaultProps = {
  deploy: {},
  siteBySlug: {
    data: null,
    error: null,
    loading: false,
  },
};

DeployStatus.propTypes = {
  deploy: PropTypes.shape({}),
  siteBySlug: PropTypes.shape({
    data: PropTypes.object,
    error: PropTypes.object,
    loading: PropTypes.bool,
  }),
};

export default DeployStatus;
