import { EthereumProvider } from "@walletconnect/ethereum-provider";
import config from "src/config";

export const createWalletConnectProvider = async ({
  clearSession = false,
  showQrModal = false,
} = {}) => {
  try {
    if (clearSession) {
      clearOldSession();
    }

    const provider = await EthereumProvider.init({
      projectId: config.walletConnect.projectId,
      chains: [config.ethereum.netId], // required
      showQrModal, // requires @walletconnect/modal
      qrModalOptions: {
        themeVariables: {
          "--wcm-z-index": "9999",
        },
      },
      rpcMap: {
        [config.ethereum
          .netId]: `https://${config.ethereum.network}.infura.io/v3/${config.ethereum.infuraId}`,
      },
    });

    if (!provider) {
      throw new Error("Could not create EthereumProvider provider");
    }

    return provider;
  } catch (error) {
    if (clearSession) {
      throw error;
    }

    return await createWalletConnectProvider({
      clearSession: true,
      showQrModal,
    });
  }
};

const clearOldSession = () => {
  Object.keys(localStorage)
    .filter((key) => key.startsWith("wc@"))
    .forEach((key) => {
      localStorage.removeItem(key);
    });
};
