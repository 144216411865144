/* eslint-disable no-undef */
const { REACT_APP_FE_NODE_ENV } = process.env;

const getFleekSubdomain = (platform = 'ipfs') => {
  if (platform === 'dfinity') {
    return '.ic.fleek.co';
  }

  const fleekDomain = 'fleek.co';

  if (REACT_APP_FE_NODE_ENV === 'production') {
    return `.on.${fleekDomain}`;
  }

  if (REACT_APP_FE_NODE_ENV === 'staging') {
    return `.on-stg.${fleekDomain}`;
  }

  return `.on-dev.${fleekDomain}`;
};

export default getFleekSubdomain;
