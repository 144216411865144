import {
  DELETE_CLIENT_SECRET,
  SAVE_CLIENT_SECRET,
} from "src/modals/payment-method-modal/actions";

const defaultState = {
  clientSecretId: null,
};

const billingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_CLIENT_SECRET:
      return {
        clientSecretId: action.payload,
      };

    case DELETE_CLIENT_SECRET:
      return defaultState;

    default:
      return state;
  }
};

export default billingReducer;
