import { useQuery } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { newApiClient } from "src/clients";
import config from "src/config";
import { GET_ENS_DOMAIN_INFO } from "src/graphql/queries";

import useStyles from "./styles";

const BuyDomain = ({ state, setState }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data, startPolling, stopPolling } = useQuery(GET_ENS_DOMAIN_INFO, {
    client: newApiClient,
    variables: {
      network: config.ethereum.network,
      domain: state.domain,
    },
  });

  const ownerAddress = get(data, "getEnsDomainInfo.ownerAddress");

  const splitDomain = state.domain.split(".");
  const isSubdomain = splitDomain.length >= 3;

  useEffect(() => {
    startPolling(3000);
    return () => stopPolling();
  });

  useEffect(() => {
    if (ownerAddress) {
      setState({
        ...state,
        ensDomainInfo: data.getEnsDomainInfo,
      });
      stopPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerAddress]);

  const getEnsAppLink = () => {
    if (isSubdomain) {
      const rootDomain = splitDomain.slice(1, splitDomain.length + 1).join(".");
      return `https://app.ens.domains/name/${rootDomain}/subdomains`;
    }
    return `https://app.ens.domains/name/${state.domain}`;
  };

  return (
    <>
      {isSubdomain ? (
        <Typography className={classes.description}>
          {t(
            "sites.tabs.settings.ens.addEns.addYourselfAsOwnerOfSubdomainDescription"
          )}
        </Typography>
      ) : (
        <>
          <Typography className={classes.title}>
            {t("sites.tabs.settings.ens.addEns.isAvailable", {
              domain: state.domain,
            })}
          </Typography>
          <Typography className={classes.subTitle}>
            {t("sites.tabs.settings.ens.addEns.wouldYouLike")}
          </Typography>
          <Typography className={classes.description}>
            {t("sites.tabs.settings.ens.addEns.byClicking")}
          </Typography>
        </>
      )}
      <a
        href={getEnsAppLink()}
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <GenericButton buttonVariant="primary">
          <Typography variant="body2">
            {isSubdomain
              ? t(
                  "sites.tabs.settings.ens.addEns.addYourselfAsOwnerOfSubdomain"
                )
              : t("sites.tabs.settings.ens.addEns.buyDomain", {
                  domain: state.domain,
                })}
          </Typography>
        </GenericButton>
      </a>
    </>
  );
};

BuyDomain.propTypes = {
  setState: PropTypes.func.isRequired,
  state: PropTypes.shape({
    domain: PropTypes.string.isRequired,
    ensDomainInfo: PropTypes.shape({
      ownerAddress: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default BuyDomain;
