// @ts-nocheck
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { newApiClient } from "src/clients";
import ADD_SITE_MUTATION from "src/graphql/mutations/add-site";
import { url } from "src/utils";

import addSiteUpdateCache from "./cache-update";

export const useAddSiteMutation = () => {
  const history = useHistory();

  const addSiteOnCompleted = (siteSlug, deployId) =>
    history.push(
      url.buildUrl(null, `/sites/${siteSlug}/deploys/${deployId}`, [
        "buildOptionsKey",
      ])
    );

  const mutationResult = useMutation(ADD_SITE_MUTATION, {
    client: newApiClient,
    update: addSiteUpdateCache,
    onCompleted: (responseData) =>
      addSiteOnCompleted(
        responseData.addSite.slug,
        responseData.addSite.latestDeploy.id
      ),
  });

  return mutationResult;
};
