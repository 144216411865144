import get from "lodash/get";
import { GIT_EVENT, SOURCE_TYPES } from "src/constants";

const getDeployLabel = (t, deploy, sourceType) => {
  const gitEvent = get(deploy, "gitEvent", null);
  const deployBaseRoute = 'sites.tabs.deploys.sections.deployInfo.listOfDeploys.deployItem';

  if (sourceType === SOURCE_TYPES.LOCAL) {
    return t(`${deployBaseRoute}.localDeploy`);
  }

  if (gitEvent === GIT_EVENT.PULL_REQUEST) {
    return t(`${deployBaseRoute}.deployPreview`);
  }

  return t(`${deployBaseRoute}.branchLabel`);
};

export default getDeployLabel;
