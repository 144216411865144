import Typography from "@material-ui/core/Typography";
import IconFA from "@terminal-packages/ui/core/IconFA";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { DOMAIN_STATUS } from "src/constants";

import useStyles from "./styles";

const DomainStatus = ({ status, checkHNSOnClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (
    status === DOMAIN_STATUS.ERROR ||
    status === DOMAIN_STATUS.PENDING_PROPAGATION
  ) {
    return (
      <div className={classes.domainStatus}>
        <div
          tabIndex={0}
          role="button"
          className={classes.warningWrapper}
          onClick={checkHNSOnClick}
          onKeyDown={checkHNSOnClick}
        >
          <div className={classes.warningIcon}>
            <IconFA
              icon={["fas", "exclamation-triangle"]}
              fontSize="inherit"
              color="inherit"
            />
          </div>
          <Typography className={classes.check}>
            {t("sites.tabs.settings.hns.checkHNS")}
          </Typography>
        </div>
      </div>
    );
  }

  return null;
};

DomainStatus.propTypes = {
  status: PropTypes.string.isRequired,
  checkHNSOnClick: PropTypes.func.isRequired,
};

export default DomainStatus;
