// @ts-nocheck

import Typography from "@material-ui/core/Typography";
import InputSlim from "@terminal-packages/ui/core/InputSlim";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { HideValue } from "../hide-value";
import useStyles from "./styles";

export const SettingsRow = ({
  stateKey,
  value,
  label,
  onChange,
  placeholder,
  editingInfo,
  isEditingMode,
  renderEditableComponent,
  hideLabelInEditMode,
  useBigInput,
  renderValue,
  visibility,
}) => {
  const classes = useStyles({ isEditingMode });

  const inputLabel = useMemo(() => 
    !hideLabelInEditMode && label.replace(/:$/, ''), [label, hideLabelInEditMode]);

  const getValue = () => {
    if (isEditingMode) {
      return (
        <div className={classes.editInputWrapper}>
          {renderEditableComponent ? (
            renderEditableComponent(value, onChange)
          ) : (
            <>
              <InputSlim
                placeholder={placeholder}
                className={classnames(classes.input, {
                  [classes.bigInput]: useBigInput,
                })}
                label={inputLabel}
                value={value}
                onChange={(newValue) => onChange(stateKey, newValue)}
              />
              {editingInfo && (
                <Typography color="textSecondary" className={classes.info}>
                  {editingInfo}
                </Typography>
              )}
            </>
          )}
        </div>
      );
    }

    return (
      <>
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
        <Typography
          variant="body2"
          className={classes.value}
          color={value ? undefined : "textSecondary"}
        >
          {renderValue(value) || placeholder}
        </Typography>
      </>
    );
  };

  const hideLabel = hideLabelInEditMode && isEditingMode;

  return (
    <>
      {visibility ? (
        <>
          {!hideLabel && <HideValue eyeButton={false} value={label} />}
          <HideValue eyeButton={false} value={getValue()} />
        </>
      ) : (
        getValue()
      )}
    </>
  );
};

SettingsRow.defaultProps = {
  stateKey: "",
  value: "",
  placeholder: "",
  editingInfo: "",
  onChange: undefined,
  renderEditableComponent: undefined,
  label: null,
  hideLabelInEditMode: false,
  useBigInput: false,
  renderValue: (value) => value,
  visibility: false,
};

SettingsRow.propTypes = {
  isEditingMode: PropTypes.bool.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  editingInfo: PropTypes.string,
  onChange: PropTypes.func,
  stateKey: PropTypes.string,
  renderEditableComponent: PropTypes.func,
  renderValue: PropTypes.func,
  hideLabelInEditMode: PropTypes.bool,
  useBigInput: PropTypes.bool,
  visibility: PropTypes.bool,
};
