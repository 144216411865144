import { matchPath } from "react-router";
import { Link } from "react-router-dom";
import { url } from "src/utils";

import MigratedLink from "../MigratedLink";
import NewUserStorageLink from "../NewUserStorageLink";

const getIsSelectedChecker = (path) => (routes) =>
  routes.some((route) => !!matchPath(path, route));

const useConfig = ({ t, location, teamId, migrationStatus }) => {
  const isSelected = getIsSelectedChecker(location.pathname);

  const ItemComponent = migrationStatus === 'COMPLETED' ? MigratedLink : Link;
  const StorageLink = migrationStatus === 'COMPLETED' ? MigratedLink : NewUserStorageLink;

  const baseSidebarElements = [
    {
      id: "sites",
      title: t("layout.sidebar.sites"),
      icon: "window",
      component: ItemComponent,
      to: url.buildUrl(null, `/teams/${teamId}/sites`),
      disabled: false,
      selected: isSelected(["/teams/:teamId/sites", "/sites", "/start"]),
    },
    {
      id: "storage",
      title: t("layout.sidebar.storage"),
      icon: "storage",
      component: StorageLink,
      disabled: false,
      // teamId is the default bucket
      to: url.buildUrl(null, `/teams/${teamId}/storage/${teamId}-bucket`),
      selected: isSelected(["/teams/:teamId/storage"]),
    },
    {
      id: "billing",
      title: t("layout.sidebar.billing"),
      icon: "credit-card",
      component: ItemComponent,
      to: url.buildUrl(null, `/teams/${teamId}/billing/general`),
      disabled: false,
      selected: isSelected([
        "/teams/:teamId/billing/general/:section?",
        "/teams/:teamId/billing/change-plan",
      ]),
    },
    {
      id: "members",
      title: t("layout.sidebar.members"),
      icon: "members",
      component: ItemComponent,
      disabled: false,
      to: url.buildUrl(null, `/teams/${teamId}/members`),
      selected: isSelected(["/teams/:teamId/members"]),
    },
    {
      id: "team-settings",
      title: t("layout.sidebar.teamSettings"),
      icon: "gear",
      component: ItemComponent,
      to: url.buildUrl(null, `/team-settings/${teamId}`),
      disabled: false,
      selected: isSelected(["/team-settings"]),
    },
    /* {
      id: 'builds',
      title: t('layout.sidebar.builds'),
      icon: 'share',
      component: Link,
      to: '/builds',
      disabled: true,
      selected: isSelected(['/builds']),
    },
    {
      id: 'domains',
      title: t('layout.sidebar.domains'),
      icon: 'hub',
      component: Link,
      to: '/domains',
      disabled: true,
      selected: isSelected(['/domains']),
    } */
  ];

  return baseSidebarElements
};

export default useConfig;
