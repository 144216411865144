import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import List from "@terminal-packages/ui/core/List";
import ListItem from "@terminal-packages/ui/core/ListItem";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { EDIT_USER_INFO_MODAL, openModal } from "src/modals/actions";
import { useAuth } from "src/store/AuthContext";
import { shortenEthAddress } from "src/utils";

import useStyles from "./styles";

const Profile = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser, loading, isWeb3Provider } = useAuth();

  const { username, email } = currentUser;

  const userDetailsEntries = Object.entries({
    name: shortenEthAddress(username?.trim()) || "-",
    ...(!isWeb3Provider ? { email: email || "-" } : {}),
  });

  const editInfoOnClick = () =>
    dispatch(
      openModal(EDIT_USER_INFO_MODAL, {
        currentFullName: username,
        currentEmail: email,
      })
    );

  return (
    <>
      <Typography variant="body1">
        <Box component="span" fontWeight={500}>
          {t("accountSettings.profile.title")}
        </Box>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("accountSettings.profile.description")}
      </Typography>
      <br />
      <CardTitled
        mainContent={t("accountSettings.profile.card.title")}
        classes={{
          content: classes.content,
        }}
      >
        <List striped>
          {userDetailsEntries.map(([key, value]) => (
            <ListItem key={key} className={classes.item}>
              <Typography
                variant="body2"
                color="textPrimary"
                className={classes.key}
              >
                {`${t(`accountSettings.profile.card.${key}`)}:`}
              </Typography>
              {loading ? (
                <Skeleton
                  width={240}
                  height={20}
                  variant="rect"
                  animation="wave"
                  classes={{
                    root: classes.skeletonRoot,
                  }}
                />
              ) : (
                <Typography noWrap variant="body2">
                  <Box component="span" fontWeight={500}>
                    {value}
                  </Box>
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
        <div className={classes.buttonContainer}>
          <GenericButton
            disabled={loading}
            loading={loading}
            buttonVariant="primary"
            onClick={editInfoOnClick}
          >
            {t("accountSettings.profile.edit")}
          </GenericButton>
        </div>
      </CardTitled>
    </>
  );
};

export default Profile;
