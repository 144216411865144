import Typography from "@material-ui/core/Typography";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DOMAIN_TYPE } from "src/constants";

import DomainList from "./components/DomainList";
import Footer from "./components/Footer";
import useStyles from "./styles";

const CustomDomains = ({
  siteId,
  domains,
  loading,
  checkDNSOnClick,
  platform,
  siteBySlug,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const filteredDomains = domains.filter(
    (domain) => domain.type !== DOMAIN_TYPE.HNS
  );

  return (
    <CardTitled
      mainContent={t("sites.tabs.settings.customDomains.title")}
      classes={{
        content: classes.cardContent,
      }}
    >
      <Typography className={classes.paragraph}>
        {t("sites.tabs.settings.customDomains.paragraph")}
      </Typography>
      <DomainList
        siteId={siteId}
        domains={filteredDomains}
        loading={loading}
        checkDNSOnClick={checkDNSOnClick}
        platform={platform}
      />
      <Footer
        siteId={siteId}
        domainsLength={filteredDomains.length}
        hasWaitingDomain={
          !!filteredDomains.find((domain) => domain.status === "waiting")
        }
        siteBySlug={siteBySlug}
      />
    </CardTitled>
  );
};

CustomDomains.defaultProps = {
  siteId: "",
  domains: [],
  checkDNSOnClick: () => {},
  siteBySlug: {
    loading: true,
    data: null,
    error: null,
  },
};

CustomDomains.propTypes = {
  siteId: PropTypes.string,
  checkDNSOnClick: PropTypes.func,
  domains: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  platform: PropTypes.string.isRequired,
  siteBySlug: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.object,
    error: PropTypes.object,
  }),
};

export default CustomDomains;
