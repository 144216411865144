import contentHash from '@ensdomains/content-hash';
import namehash from 'eth-ens-namehash';

import {
  handleFailedTransaction,
} from './handle-msg-errors';
import handleSuccessfulVerification from './handle-successful-verification';

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

const setContentHash = async (params) => {
  const {
    web3,
    domain,
    state,
    setState,
    ipns,
    account,
  } = params;
  try {
    // This is a workaround for a metamask bug
    // about the metamask transaction not popping up but showing as a notification
    await sleep(100);
    const encodedHash = contentHash.encode('ipns-ns', ipns);
    const nodeNamehash = namehash.hash(domain);
    const resolver = await web3.eth.ens.getResolver(domain);
    resolver.methods.setContenthash(nodeNamehash, `0x${encodedHash}`).send({
      from: account,
    }).on('transactionHash', (hash) => {
      handleSuccessfulVerification({
        ...params,
        txHash: hash,
      });
    }).on('error', () => {
      handleFailedTransaction(params);
      setState({
        ...state,
        loading: false,
      });
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    handleFailedTransaction(params);
    setState({
      ...state,
      loading: false,
    });
  }
};

export default setContentHash;
