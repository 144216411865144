import axios from "axios";
import { firebaseFunctionEndpoints } from "src/integrations/ethereum/constants";

export const validateCaptcha = async (token) => {
  const options = {
    data: { token },
  };

  const response = await axios.post(
    firebaseFunctionEndpoints.captchaVerification,
    options
  );

  return response.data;
};
