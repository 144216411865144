import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  root: {
    padding: '23px 20px',
  },
  button: {
    maxWidth: 192,
    marginTop: 15,
  },
}));
