import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { newApiClient } from "src/clients";
import { GET_GITHUB_INSTALLATION_URL } from "src/graphql/queries";
import getAccountIdFromUrl from "src/utils/get-account-id-from-url";
import { AUTHORIZATION_COMPLETED } from "src/views/GithubAuthorized/constants";

export const useGithubInstallationLink = () => {
  const [isSettingPermissionsCompleted, setIsSettingPermissionsCompleted] =
    useState(false);
  const [userWantedToOpenGithub, setUserWantedToOpenGithub] = useState(false);
  const [popupWindow, setPopupWindow] = useState<Window | null>(null); 
  // ^ this state is useful, because we cannot open window until two conditional are true:
  // 1. User clicked github authorization button
  // 2. We got a link to github

  const teamId = getAccountIdFromUrl();
  const { data: githubInstallLinkData } = useQuery(
    GET_GITHUB_INSTALLATION_URL,
    {
      variables: { teamId },
      client: newApiClient as any,
    }
  );

  const githubInstallLink = get(
    githubInstallLinkData,
    "getGithubInstallationUrl"
  );

  if (githubInstallLink && userWantedToOpenGithub) {
    const popupWidth = 1200;
    const popupHeight = 640;
    const leftPosition = (window.innerWidth - popupWidth) / 2;
    const topPosition = (window.innerHeight - popupHeight) / 2;
    const popup = window.open(
      githubInstallLink,
      "github-authorization-window",
      `width=${popupWidth},height=${popupHeight},left=${leftPosition},top=${topPosition}`
    );
    setPopupWindow(popup);
    setUserWantedToOpenGithub(false);
  }

  const updateLocalStorage = (event: any) => {
    const localStorageValue = event.storageArea.getItem(
      AUTHORIZATION_COMPLETED
    );
    event.storageArea.removeItem(AUTHORIZATION_COMPLETED);
    if (localStorageValue) {
      setIsSettingPermissionsCompleted(true);
    }
  };
  // should be in useEffect

  useEffect(() => {
    window.addEventListener("storage", updateLocalStorage);
    return () => window.removeEventListener("storage", updateLocalStorage);
  }, []);

  useEffect(() => {
    if (isSettingPermissionsCompleted) {
      // reset to initial state
      setUserWantedToOpenGithub(false);
      setIsSettingPermissionsCompleted(false);
    }
  }, [isSettingPermissionsCompleted]);

  // No good way to check if a window is closed when it's in another domain
  // For reference: https://stackoverflow.com/questions/9388380/capture-the-close-event-of-popup-window-in-javascript
  useEffect(() => {
    if (!popupWindow) return;
    const timer = setInterval(() => { 
      if(popupWindow.closed) {
        clearInterval(timer);
        setPopupWindow(null)
      }
    }, 500);
    return () => {
      clearInterval(timer);
    }
  }, [popupWindow]);

  const openGithubPermissionsPopup = () => {
    setUserWantedToOpenGithub(true);
  };

  return {
    isSettingPermissionsCompleted,
    openGithubPermissionsPopup,
    popupOpen: !!popupWindow
  };
};
