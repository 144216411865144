import config from "src/config";

export const firebaseFunctionEndpoints = {
  generateNonce: `${config.firebase.cloudFunctionsURL}/generateNonce`,
  validateWalletLogin: `${config.firebase.cloudFunctionsURL}/validateWalletLogin`,
  cryptoAuth: `${config.firebase.cloudFunctionsURL}/cryptoAuth`,
  linkWalletToUser: `${config.firebase.cloudFunctionsURL}/linkWalletToUser`,
  captchaVerification: `${config.firebase.cloudFunctionsURL}/captchaVerification`,
  createPaymentTransaction: `${config.firebase.cloudFunctionsURL}/createPaymentTransaction`,
  updatePaymentTransaction: `${config.firebase.cloudFunctionsURL}/updatePaymentTransactionId`,
};
