import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    marginLeft: 10,
  },
  spinner: {
    color: theme.palette.palette.grey2,
  },
}));
