import get from "lodash/get";
import moment from "moment";
import { EnhancedText } from "src/components";
import { SOURCE_TYPES } from "src/constants";
import { getSiteName } from "src/utils";
import getSourceType from "src/utils/get-source-type";

const getRepository = (siteBySlug) => {
  const repositoryURL =
    get(siteBySlug, "data.getSiteBySlug.deploySettings.source.url", "") || "";
  const repositoryBranch =
    get(siteBySlug, "data.getSiteBySlug.deploySettings.source.branch", "") ||"";

  const splittedURL = repositoryURL.split("/");

  return `${splittedURL.pop()} (branch: ${repositoryBranch})`;
};

const getLastRepoPublished = (siteBySlug) => {
  const publishedDeployRepo = get(siteBySlug, 'data.getSiteBySlug.publishedDeploy.repository');
  const owner = get(publishedDeployRepo, 'owner', "");
  const name = get(publishedDeployRepo, 'name', "");
  const branch = get(publishedDeployRepo, 'branch', "");
  return owner && name 
    ? `${owner}/${name} (branch: ${branch})`
    : "-";
}

const getSiteDetails = ({ t, dealData, siteBySlug }) => {
  const loading = get(siteBySlug, "loading");
  const site = get(siteBySlug, "data.getSiteBySlug", {});
  const platform = get(site, "platform", "ipfs") || "ipfs";
  const canisterId = get(site, "dfinityCanisterId");
  const repository = getRepository(siteBySlug);
  const name = getSiteName(site);
  const owner = get(siteBySlug, "data.getSiteBySlug.team.name", "") || "";
  const createdAt = get(siteBySlug, "data.getSiteBySlug.createdAt", "") || "";
  const lastPublished =
    get(siteBySlug, "data.getSiteBySlug.publishedDeploy.completedAt", "") || "";
  const lastRepoPublished = getLastRepoPublished(siteBySlug);
  // const certificateType = get(siteBySlug, 'data.getSiteBySlug.sslInfo.type', '-');
  // const numberOfDomains = get(siteBySlug, 'data.getSiteBySlug.numberOfDomains', '-');
  const ipfsHash = get(
    siteBySlug,
    "data.getSiteBySlug.publishedDeploy.ipfsHash",
    "-"
  );
  const source = get(
    siteBySlug,
    "data.getSiteBySlug.deploySettings.source",
    {}
  );
  const sourceType = getSourceType(source);

  const siteId = platform === "ipfs" ? { ipfsHash } : { canisterId };
  const dealId = get(dealData, "getDealStatus.dealId");
  const isDealIdPending = !(typeof dealId === "number" && dealId > 0);

  /* eslint-disable react/jsx-props-no-spreading */
  const dealIdNode = (
    <EnhancedText
      loading={isDealIdPending}
      {...(isDealIdPending && {
        tooltipText: t("storage.tooltips.filecoin", { item: "Deal ID" }),
      })}
    >
      {isDealIdPending ? t("common.pending") : dealId}
    </EnhancedText>
  );

  return {
    name,
    owner,
    ...(sourceType === SOURCE_TYPES.GITHUB && { repository }),
    ...(!siteBySlug.loading && siteId),
    ...(platform === "ipfs" &&
      !loading && {
        filecoinID: dealIdNode,
      }),
    // numberOfDomains,
    created: createdAt.length ? moment(createdAt).calendar() : "",
    lastPublished: lastPublished.length ? moment(lastPublished).calendar() : "-",
    lastRepoPublished,
  };
};

const presenter = {
  getSiteDetails,
};

export default presenter;
