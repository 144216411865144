import { makeStyles } from '@material-ui/core';

/* eslint-disable max-len */
export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  username: {
    marginLeft: 7,
  },
  angleIcon: {
    marginLeft: 'auto',
  },
  menuContainer: {
    width: 164,
    padding: '9px 10px',
    boxShadow:
        '0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
    '& > div > div:nth-of-type(3) > div:first-of-type > div > svg': {
      marginBottom: 2,
    },

  },
  divider: {
    height: 1,
    opacity: 0.12,
    margin: ' 7px -10px',
    backgroundColor: theme.palette.text.primary,
  },
  avatarButton: {
    padding: 0,
    width: '100%',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  [theme.breakpoints.down('xs')]: {
    avatarButton: {
      marginRight: 0,
      padding: 0,
    },
  },
}));
