// @ts-nocheck
import { Box } from "@fleekhq/react-drip";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoadingState } from "src/reducers/actions/loading-state";

import useStyles from "./styles";

export const LoadingScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoadingState(true));

    return () => dispatch(setLoadingState(false));
  });

  return (
    <Box css={{ backgroundColor: "$slate1" }} className={classes.root}>
      <img
        alt="fleek-loading"
        src="/loading-logo.svg"
        className={classes.logo}
      />
    </Box>
  );
};
