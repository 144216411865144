import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { newApiClient } from "src/clients";
import { LIMIT_DEPLOYS_PAGINATION } from "src/constants";

import { GET_DEPLOYS_BY_SITE } from "../../../graphql/queries";
import DeploysSubscription from "./components/DeploysSubscription";
import { AllDeploys, DeployDetails } from "./containers";

const Deploys = ({ siteBySlug }) => {
  const match = useRouteMatch();
  const location = useLocation();
  const siteId = get(siteBySlug, "data.getSiteBySlug.id");

  const deploys = useQuery(GET_DEPLOYS_BY_SITE, {
    client: newApiClient,
    variables: {
      siteId,
      limit: LIMIT_DEPLOYS_PAGINATION,
    },
    errorPolicy: "all",
    skip: !siteId,
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    window.analytics.page("Site - Deploy", {
      path: location.pathname,
      search: location.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DeploysSubscription config={deploys} />
      <Switch>
        <Route path={`${match.path}`} exact>
          <AllDeploys siteBySlug={siteBySlug} deploys={deploys} />
        </Route>
        <Route path={`${match.path}/:deployId`} exact>
          <DeployDetails siteBySlug={siteBySlug} deploys={deploys} />
        </Route>
        <Redirect to="*" redirect={`${match.url}`} />
      </Switch>
    </>
  );
};

Deploys.defaultProps = {
  siteBySlug: {
    data: null,
    error: null,
    loading: false,
  },
};

Deploys.propTypes = {
  siteBySlug: PropTypes.shape({
    data: PropTypes.object,
    error: PropTypes.object,
    loading: PropTypes.bool,
  }),
};

export default Deploys;
