import gql from 'graphql-tag';

export default gql`
  query getDealStatus($hash: String!) {
    getDealStatus(hash: $hash) {
      dealId
      proposalCid
    }
  }
`;
