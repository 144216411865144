/* Action Types */
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

/* Modal Ids */
export const API_DETAILS_MODAL = "API_DETAILS_MODAL";
export const APP_API_DETAILS_MODAL = "APP_API_DETAILS_MODAL";
export const CONFIRM_DNS_UPGRADE_MODAL = "CONFIRM_DNS_UPGRADE_MODAL";
export const CREATE_FOLDER_MODAL = "CREATE_FOLDER_MODAL";
export const DELETE_STORAGE_ITEMS_MODAL = "DELETE_STORAGE_ITEMS_MODAL";
export const DELETE_PROFILE_MODAL = "DELETE_PROFILE_MODAL";
export const DNS_A_RECORD_MODAL = "DNS_A_RECORD_MODAL";
export const DNS_CNAME_MODAL = "DNS_CNAME_MODAL";
export const DNS_LINK_MODAL = "DNS_LINK_MODAL";
export const EDIT_TEAM_INFO_MODAL = "EDIT_TEAM_INFO_MODAL";
export const EDIT_USER_INFO_MODAL = "EDIT_USER_INFO_MODAL";
export const HNS_MODAL = "HNS_MODAL";
export const PAYMENT_METHOD_MODAL = "PAYMENT_METHOD_MODAL";
export const PROFILE_MODAL = "PROFILE_MODAL";
export const TWO_FACTOR_AUTH_NUMBER_MODAL = "TWO_FACTOR_AUTH_NUMBER_MODAL";
export const TWO_FACTOR_AUTH_VERIFY_CODE_MODAL =
  "TWO_FACTOR_AUTH_VERIFY_CODE_MODAL";
export const TWO_FACTOR_AUTH_VERIFY_EMAIL_MODAL =
  "TWO_FACTOR_AUTH_VERIFY_EMAIL_MODAL";
export const UPLOAD_ITEMS_MODAL = "UPLOAD_ITEMS_MODAL";
export const WALLET_LINK_MODAL = "WALLET_LINK_MODAL";
export const UPLOAD_ENV_FILE = "UPLOAD_ENV_FILE";

/* Action creators */
export const openModal = (modalKey: string, props = {}) => ({
  type: OPEN_MODAL,
  payload: {
    props,
    id: modalKey,
  },
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
