import { useMutation } from "@apollo/react-hooks";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVert from "@material-ui/icons/MoreVert";
import Chip from "@terminal-packages/ui/core/Chip";
import { copyToClipboard } from "@terminal-packages/ui/core/CopyToClipboardButton/utils";
import IconFA from "@terminal-packages/ui/core/IconFA";
import ListItem from "@terminal-packages/ui/core/ListItem";
import Spinner from "@terminal-packages/ui/core/Spinner";
import { toast } from "@terminal-packages/ui/core/Toast";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { newApiClient } from "src/clients";
import { MenuDropdown } from "src/components";
import REMOVE_SITE_ENS_DOMAIN from "src/graphql/mutations/remove-site-ens-domain";
import VERIFY_ENS_CONFIGURATION from "src/graphql/mutations/verify-ens-configuration";

import { DOMAIN_STATES } from "../../constants";
import useStyles from "./styles";

const Domain = ({ ensInfo, setEnsControllerOnClick, siteId, ipns }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { domain, verificationStatus } = ensInfo;

  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);

  const [removeSiteEnsDomain] = useMutation(REMOVE_SITE_ENS_DOMAIN, {
    client: newApiClient,
  });

  const [verifyEnsConfiguration] = useMutation(VERIFY_ENS_CONFIGURATION, {
    client: newApiClient,
  });

  const domainUrl =
    verificationStatus === DOMAIN_STATES.VERIFIED ? `https://${domain}/` : null;

  const handleVerifyConfiguration = async (event) => {
    event.preventDefault();
    setIsVerifyLoading(true);
    try {
      await verifyEnsConfiguration({
        variables: {
          input: {
            siteId,
            domain: ensInfo.domain,
          },
        },
      });
    } catch (e) {
      /* eslint-disable no-console */
      console.error(e);
    }
    setIsVerifyLoading(false);
  };

  const handleRemoveDomain = async (event) => {
    event.preventDefault();

    setIsRemoveLoading(true);
    try {
      await removeSiteEnsDomain({
        variables: {
          input: {
            siteId,
          },
        },
      });
    } catch (e) {
      /* eslint-disable no-console */
      console.error(e);
    }
    setIsRemoveLoading(false);
  };

  const copyIpns = () => {
    if (!ipns) {
      return;
    }
    copyToClipboard(ipns);
    toast.success(t("common.copiedToClipboard"));
  };

  const getDomainStatus = () => {
    const addOns = {
      [DOMAIN_STATES.NOT_VERIFED]: (
        <div className={classes.notVerifiedStatus}>
          <div
            tabIndex={0}
            role="button"
            className={classes.warningWrapper}
            onClick={setEnsControllerOnClick}
            onKeyDown={setEnsControllerOnClick}
          >
            <div className={classes.warningIcon}>
              <IconFA
                icon={["fas", "exclamation-triangle"]}
                fontSize="inherit"
                color="inherit"
              />
            </div>
            <Typography className={classes.check}>
              {t("sites.tabs.settings.ens.domainRow.setFleek")}
            </Typography>
          </div>
        </div>
      ),
      [DOMAIN_STATES.PENDING]: (
        <div className={classes.verifiedStatus}>
          <div className={classes.pendingChip}>
            <Chip
              text={t("sites.tabs.settings.ens.domainRow.pending")}
              color="yellow"
            />
          </div>
        </div>
      ),
      [DOMAIN_STATES.VERIFIED]: (
        <div className={classes.verifiedStatus}>{null}</div>
      ),
    };

    return (
      <div className={classes.domainStatus}>
        {addOns[verificationStatus] || null}
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.domainInfo}>
        <Typography variant="body2">
          <a
            href={domainUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classnames(classes.domainLink, {
              [classes.activeText]:
                verificationStatus === DOMAIN_STATES.VERIFIED,
              [classes.inactiveText]:
                verificationStatus !== DOMAIN_STATES.VERIFIED,
            })}
          >
            {domain}
          </a>
        </Typography>
        <Typography className={classes.description}>
          {t("sites.tabs.settings.ens.domainRow.ensDomain")}
        </Typography>
      </div>
      {getDomainStatus()}
      <div className={classes.optionsContainer}>
        <MenuDropdown
          menuId="menu-dropdown-ens-domain-options"
          trigger={
            <IconButton
              disableRipple
              disableFocusRipple
              classes={{
                root: classes.iconButtonRoot,
              }}
            >
              <MoreVert />
            </IconButton>
          }
        >
          <ListItem className={classes.domainOption}>
            <ButtonBase
              type="button"
              className={classes.buttonBase}
              onClick={copyIpns}
            >
              <Typography variant="subtitle1">
                {t("sites.tabs.settings.ens.domainRow.copyIpns")}
              </Typography>
            </ButtonBase>
          </ListItem>
          {ensInfo.verificationStatus !== DOMAIN_STATES.VERIFIED && (
            <ListItem className={classes.domainOption}>
              <ButtonBase
                type="button"
                className={classes.buttonBase}
                onClick={handleVerifyConfiguration}
              >
                <Typography variant="subtitle1">
                  {t("sites.tabs.settings.ens.domainRow.verifyConfiguration")}
                  {isVerifyLoading && (
                    <span className={classes.spinner}>
                      <Spinner positioning="inline" />
                    </span>
                  )}
                </Typography>
              </ButtonBase>
            </ListItem>
          )}
          <ListItem className={classes.domainOption}>
            <ButtonBase
              type="button"
              className={classes.buttonBase}
              onClick={handleRemoveDomain}
            >
              <Typography variant="subtitle1" className={classes.remove}>
                {t("sites.tabs.settings.ens.domainRow.remove")}
                {isRemoveLoading && (
                  <span
                    className={classnames(classes.spinner, classes.removeColor)}
                  >
                    <Spinner positioning="inline" />
                  </span>
                )}
              </Typography>
            </ButtonBase>
          </ListItem>
        </MenuDropdown>
      </div>
    </div>
  );
};

Domain.defaultProps = {
  ipns: null,
};

Domain.propTypes = {
  ensInfo: PropTypes.shape({
    domain: PropTypes.string.isRequired,
    verificationStatus: PropTypes.string.isRequired,
  }).isRequired,
  setEnsControllerOnClick: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  ipns: PropTypes.string,
};

export default Domain;
