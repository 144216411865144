import InputWithError from '@terminal-packages/ui/core/InputWithError';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { STEPS } from '../../container/AddEnsDomainForm/constants';
import useStyles from './styles';

const EnsDomainField = ({
  state,
  setState,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleInputChange = (event) => {
    setState({
      ...state,
      domain: event.target.value,
      step: STEPS.VERIFY,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        <InputWithError
          value={state.domain}
          label={t('sites.tabs.settings.ens.addEns.ensDomain')}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};


EnsDomainField.propTypes = {
  state: PropTypes.shape({
    domain: PropTypes.string.isRequired,
  }).isRequired,
  setState: PropTypes.func.isRequired,
};

export default EnsDomainField;
