// @ts-nocheck
import { Popover } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import List from "@terminal-packages/ui/core/List";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import useStyles from "./styles";

export const MenuDropdown = ({
  menuId,
  trigger,
  children,
  className,
  offsetTop,
  popperProps,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles({ offsetTop });
  const btnRef = React.useRef(null);

  const popperId = anchorEl ? `${menuId}-popper` : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = () => {
    setAnchorEl(btnRef.current);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      {React.cloneElement(trigger, {
        "aria-haspopup": "true",
        "aria-describedby": popperId,
        "aria-controls": isOpen ? menuId : undefined,
        ref: btnRef,
        onClick: handleMenuOpen,
      })}
      <Popover
        id={popperId}
        transition
        disablePortal
        onClose={handleClose}
        open={isOpen}
        role={undefined}
        placement="bottom-end"
        className={classes.popper}
        anchorEl={anchorEl}
        {...popperProps}
      >
        <Paper classes={{ root: classes.paperRoot }}>
          <List id={menuId} className={classNames(classes.listMenu, className)}>
            {children}
          </List>
        </Paper>
      </Popover>
    </>
  );
};

MenuDropdown.defaultProps = {
  children: null,
  className: "",
  offsetTop: null,
  popperProps: {},
};

MenuDropdown.propTypes = {
  popperProps: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
  className: PropTypes.string,
  offsetTop: PropTypes.number,
  menuId: PropTypes.string.isRequired,
  trigger: PropTypes.element.isRequired,
};
