const mask = (str: string, n: number = 1) => {
  const maskingChar = "*";
  return (
    "+" +
    ("" + str).slice(1, -n).replace(/./g, maskingChar) +
    ("" + str).slice(-n)
  ); // eslint-disable-line
};

export default mask;
