import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccounts,
  setChain,
  setIsConnected,
  setWeb3Provider,
} from "src/reducers/actions/web3-state";

import { getChainName } from "../ethereum/utils";
import { createWalletConnectProvider } from "./createWalletConnectProvider";
import { useWalletConnectRegistry } from "./useWalletConnectRegistry";

export const useWalletConnect = ({ onAccountsChanged, onDisconnect }) => {
  const [uri, setUri] = useState("");
  const web3State = useSelector((state) => state.web3);
  const registry = useWalletConnectRegistry();
  const dispatch = useDispatch();

  const [provider, setProvider] = useState();

  const _onDisconnect = () => {
    if (onDisconnect) {
      onDisconnect();
    }
  };

  const onChainUpdate = (chainId) => {
    dispatch(setChain({ id: chainId, name: getChainName(chainId) }));
  };

  const _onAccountsChanged = useCallback(
    (accounts) => {
      if (!accounts?.length) dispatch(setIsConnected(false));
      dispatch(setAccounts(accounts));

      if (onAccountsChanged) {
        onAccountsChanged(accounts);
      }
    },
    [dispatch, onAccountsChanged]
  );

  const onSessionUpdate = (accounts, chainId) => {
    if (accounts.length === 0) {
      return;
    }

    dispatch(setIsConnected(true));
    dispatch(setChain({ id: chainId, name: getChainName(chainId) }));
    dispatch(setAccounts(accounts));
  };

  const subscribeToEvents = (provider) => {
    if (!provider) {
      return;
    }

    provider.on("chainChanged", onChainUpdate);
    provider.on("disconnect", _onDisconnect);
  };

  const subscribeToConnectorEvents = (provider) => {
    if (!provider) {
      return;
    }

    provider.on("display_uri", (uri) => {
      setUri(uri);
    });
  };

  const initialize = async (
    Web3Interface,
    settings = {},
    walletConnectSettings = {}
  ) => {
    if (!Web3Interface) {
      throw Error(
        "Web3 Provider is required. You can use either ethers.js or web3.js."
      );
    }

    setUri("");
    const provider = await createWalletConnectProvider(walletConnectSettings);

    subscribeToConnectorEvents(provider);
    subscribeToEvents(provider);

    await provider.enable();

    setProvider(provider);

    const web3Provider = new Web3Interface(
      ...(Object.keys(settings).length ? [provider, settings] : [provider])
    );

    dispatch(setWeb3Provider(web3Provider));

    if (provider.connected) {
      const { chainId, accounts } = provider;

      onSessionUpdate(accounts, chainId);
    }

    return { isConnected: true, web3Provider };
  };

  const disconnect = async () => {
    await web3State.web3Provider?.provider?.disconnect?.();
    await provider?.disconnect?.();
  };

  useEffect(() => {
    if (!provider) {
      return;
    }

    if (onAccountsChanged) {
      provider.on("accountsChanged", _onAccountsChanged);
    }

    return function cleanup() {
      provider.removeListener("accountsChanged", _onAccountsChanged);
    };
  }, [_onAccountsChanged, onAccountsChanged, provider]);

  return {
    initialize,
    disconnect,
    provider,
    registry,
    uri,
  };
};
