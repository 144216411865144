import { makeStyles } from "@material-ui/styles";

export default makeStyles({
  cardRoot: {
    border: "2px solid rgb(80, 135, 236, 0.5)",
  },
  mainContentBox: {
    display: "grid",
    alignItems: "center",
    gridAutoFlow: "column",
    gap: "8px",
  },
  mainContentTitle: {
    fontWeight: 500
  },
  paragraph: {
    fontSize: 15,
  },
  secondaryWrapper: {
    display: "grid",
    gridAutoFlow: "column",
    gap: "8px",
    alignItems: "center",
  }
});
