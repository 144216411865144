import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Site from "./Site";

const Sites = () => {
  const match = useRouteMatch();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("sites.page_title")}</title>
      </Helmet>
      <Switch>
        <Route path={`${match.path}/:siteSlug`}>
          <Site />
        </Route>
      </Switch>
    </>
  );
};

export default Sites;
