import { useQuery } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import * as Sentry from "@sentry/react";
import Box from "@terminal-packages/ui/core/Box";
import { IconName } from "@terminal-packages/ui/core/PreviewBox";
import CID from "cids";
import get from "lodash/get";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import { getS3Client, newApiClient } from "src/clients";
import { EnhancedText, FileCard } from "src/components";
import { DOMAIN_STATUS } from "src/constants";
import { GET_DEAL_STATUS } from "src/graphql/queries";
import { getStorageObjectPath } from "src/utils";

import { fetchObject } from "../../actions";
import { GET_BUCKET_BY_SLUG, GET_STORAGE_OBJECT } from "../../graphql";
import { CustomDomain } from "../CustomDomain";
import ButtonsGroup from "./components/ButtonsGroup";
import Details from "./components/Details";
import useStyles from "./styles";

const imageRegexp = /\.(png|jpeg|jpg|gif)$/i;

const File = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const bucketSlug = params.bucketName;
  const { pathname } = useLocation();
  const [fileUrl, setFileUrl] = useState({
    url: null,
    loading: true,
  });

  const pathnameArr = pathname.split("/");
  const objectPathInitPos = pathnameArr.indexOf("object");

  const filePath = pathnameArr.slice(objectPathInitPos + 1).join("/");
  const fileId = pathnameArr.pop();

  const { file, isLoading } = useSelector((state) => ({
    isLoading: state.storage.loading,
    file: state.storage.objects.find(
      (obj) => obj.fullKey === `${bucketSlug}/${filePath}`
    ),
  }));

  const getBucketBySlugResult = useQuery(GET_BUCKET_BY_SLUG, {
    client: newApiClient,
    variables: {
      slug: bucketSlug,
    },
  });

  React.useEffect(() => {
    dispatch(
      fetchObject({
        Key: filePath,
        Bucket: bucketSlug,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const getObjSrc = async () => {
      const s3Client = await getS3Client();

      s3Client.getSignedUrl(
        "getObject",
        { Bucket: bucketSlug, Key: filePath },
        (err, url) => {
          if (err) {
            Sentry.captureException(err, {
              tags: { scope: "s3", method: "getSignedUrl" },
            });
            /* eslint-disable no-console */
            console.error(err);
            setFileUrl({ url: null, loading: false });
            return;
          }

          setFileUrl({ url, loading: false });
        }
      );
    };

    if (!isLoading && file) {
      getObjSrc();
    }
  }, [file, filePath, isLoading, bucketSlug]);

  const { data, loading } = useQuery(GET_STORAGE_OBJECT, {
    client: newApiClient,
    fetchPolicy: "cache-and-network",
    variables: {
      bucket: bucketSlug,
      key: filePath,
    },
  });

  const fileHash = get(data, "getStorageObject.hash");

  const { data: dealData } = useQuery(GET_DEAL_STATUS, {
    client: newApiClient,
    fetchPolicy: "cache-and-network",
    skip: !fileHash,
    variables: {
      hash: fileHash ? new CID(fileHash).toV1().toString() : fileHash,
    },
  });

  const apiBucket = getBucketBySlugResult?.data?.getBucketBySlug;

  const validCustomDomain = apiBucket?.domainStatus === DOMAIN_STATUS.PROPAGATED &&
    apiBucket?.domain
  const objectUrl = getStorageObjectPath(
    bucketSlug, 
    filePath, 
    validCustomDomain
  );

  const dealId = get(dealData, "getDealStatus.dealId");
  const isDealIdPending = !(typeof dealId === "number" && dealId > 0);

  /* eslint-disable react/jsx-props-no-spreading */
  const dealIdNode = (
    <EnhancedText
      loading={isDealIdPending}
      {...(isDealIdPending && {
        tooltipText: t("storage.tooltips.filecoin", { item: "Deal ID" }),
      })}
    >
      {isDealIdPending ? t("common.pending") : dealId}
    </EnhancedText>
  );

  return (
    <>
      <FileCard
        loadingHash={loading || fileUrl.loading}
        ipfsHash={get(data, "getStorageObject.hash", "") || ""}
        status="success"
        fileName={fileId}
        loadingInfo={!file && isLoading}
        icon={file && file.subtype === "archive" ? IconName.Zip : IconName.File}
        url={objectUrl}
        imageSrc={imageRegexp.test(filePath) ? fileUrl.url : null}
        hasCustomDomain={!!validCustomDomain}
        lastModification={
          file
            ? t("storage.lastModified", {
                date: moment(file.lastModified).calendar(),
              })
            : ""
        }
      />
      <br />
      <CustomDomain
        isLoading={getBucketBySlugResult.loading}
        bucket={{
          ...(getBucketBySlugResult.data?.getBucketBySlug || {}),
          slug: bucketSlug,
        }}
      />
      <br />
      <Box padding="20px 27px 28px 27px">
        <div className={classes.header}>
          <Typography variant="h6">{t("storage.header")}</Typography>
          <ButtonsGroup
            file={file || {}}
            objectUrl={objectUrl}
            disabled={!file && isLoading}
          />
        </div>
        <div className={classes.divider} />
        <Details
          loading={!file && isLoading}
          details={{
            name: fileId,
            size: file ? file.bytesSize : "",
            hash: fileHash || "--",
            filecoinID: dealIdNode,
            objectUrl,
          }}
        />
      </Box>
    </>
  );
};

export default File;
