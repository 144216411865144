import CID from "cids";
import get from "lodash/get";
import { DEPLOY_STATUS } from "src/constants";
import { getFleekSubdomain, getGatewayUrl } from "src/utils";

import { getFormattedDate } from "../../shared/utils";

const getPreviewUrl = ({ pullRequestUrl, ipfsHash }, dfinityCanisterId) => {
  if (dfinityCanisterId) {
    return `https:${dfinityCanisterId}.ic.fleek.co`;
  }

  if (ipfsHash) {
    if (pullRequestUrl) {
      const cid = new CID(ipfsHash);
      return `https://${cid.toV1().toString()}${getFleekSubdomain()}`;
    }
    return getGatewayUrl(ipfsHash);
  }

  return null;
};

const deployMapping = (deploy, t, dfinityCanisterId) => {
  const {
    status,
    published = false,
    startedAt = null,
    pullRequestUrl,
    taskId,
  } = deploy;

  const branch = get(deploy, "repository.branch", "");
  const commit = get(deploy, "repository.commit", "");
  const newStartedAt = startedAt ? getFormattedDate(startedAt, t) : "";

  return {
    status,
    inProgress: status === DEPLOY_STATUS.IN_PROGRESS,
    published,
    branch,
    commit,
    startedAt: newStartedAt,
    previewUrl: getPreviewUrl(deploy, dfinityCanisterId),
    pullRequestUrl,
    taskId,
  };
};

export default deployMapping;
