import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { EnhancedNavigationList } from "src/components";
import { useAuth } from "src/store/AuthContext";
import { url } from "src/utils";

import Crypto from "./components/Crypto";
import DeleteProfile from "./components/DeleteProfile";
import HostingApi from "./components/HostingApi";
import Profile from "./components/Profile";
import StorageApi from "./components/StorageApi";
import TwoFactorAuthentication from "./components/TwoFactorAuthentication";
import { SECTION_IDS, SECTION_TYPES } from "./constants";
import useStyles from "./styles";
import util from "./util";

const General = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { isEmailProvider, isGithubProvider } = useAuth();
  const hostingApiRef = useRef(null);
  const storageApiRef = useRef(null);
  const cryptoRef = useRef(null);
  const profileRef = useRef(null);
  const walletLinkRef = useRef(null);
  const twoFaRef = useRef(null);
  const deleteProfileRef = useRef(null);

  const isGithubOrEmailProvider = isGithubProvider || isEmailProvider;

  const { section } = match.params;

  const renderItem = (node, { id, type }) => {
    const urlDestination = type === SECTION_TYPES.MAIN ? "" : `/${id}`;

    if (id === SECTION_IDS.TWO_FA_AUTH && !isEmailProvider) {
      return null;
    }

    if (id === SECTION_IDS.CRYPTO && !isGithubOrEmailProvider) {
      return null;
    }

    return (
      <Link
        to={url.buildUrl(null, `/settings/general${urlDestination}`)}
        className={classes.navigationLink}
        onClick={() => {
          if (id === SECTION_IDS.HOSTING_API) {
            hostingApiRef.current.scrollIntoView({ behavior: "smooth" });
            return;
          }

          if (id === SECTION_IDS.STORAGE_API) {
            storageApiRef.current.scrollIntoView({ behavior: "smooth" });
            return;
          }

          if (id === SECTION_IDS.WALLET_LINK) {
            walletLinkRef.current.scrollIntoView({ behavior: "smooth" });
            return;
          }

          if (id === SECTION_IDS.PROFILE) {
            profileRef.current.scrollIntoView({ behavior: "smooth" });
            return;
          }

          if (id === SECTION_IDS.CRYPTO) {
            cryptoRef.current.scrollIntoView({ behavior: "smooth" });
            return;
          }

          if (id === SECTION_IDS.TWO_FA_AUTH) {
            twoFaRef.current.scrollIntoView({ behavior: "smooth" });
            return;
          }
        }}
      >
        <div className={classes.nodeContainer}>{node}</div>
      </Link>
    );
  };

  const items = util.getItems({
    t,
    section,
  });

  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <EnhancedNavigationList items={items} renderItem={renderItem} />
      </div>
      <div className={classes.sections}>
        <section ref={profileRef}>
          <Profile />
        </section>
        <br />
        <section ref={cryptoRef}>
          <Crypto />
        </section>
        <br />
        <section ref={hostingApiRef}>
          <HostingApi />
        </section>
        <br />
        <section ref={storageApiRef}>
          <StorageApi />
        </section>
        <br />
        <section ref={deleteProfileRef}>
          <DeleteProfile />
        </section>
        {isEmailProvider && (
          <>
            <br />
            <section ref={twoFaRef}>
              <TwoFactorAuthentication />
            </section>
          </>
        )}
        <br />
      </div>
    </div>
  );
};

export default General;
