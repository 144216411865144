import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    marginLeft: 18,
  },
  loadingIcon: {
    display: 'flex',
    marginRight: 3,
    fontSize: 14,
    color: theme.palette.palette.linkBlue,
    '& svg': {
      animation: '$spin 4s linear infinite',
    },
    '& svg g .fa-secondary': {
      opacity: 0.5,
    },
  },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  container: {
    whiteSpace: 'break-spaces',
  },
}));
