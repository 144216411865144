// @ts-nocheck
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "@terminal-packages/ui/core/Toast";
import PropTypes from "prop-types";

import { MigrationGuard } from "../migrationGuard";
import DiscordSupportLink from "./components/DiscordSupportLink";
import Sidebar from "./components/Sidebar";
import TopBanner from "./components/TopBanner";
import useStyles from "./styles";

export const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <ToastContainer />
      <DiscordSupportLink />
      <TopBanner />
      <div className={classes.container}>
        <div className={classes.sidebar}>
          <Sidebar />
        </div>
        <div className={classes.mainContainer}>
          <div className={classes.migrationContainer}>
            <MigrationGuard />
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};
