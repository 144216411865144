// @ts-nocheck
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import PropTypes from "prop-types";
import AddEnsDomainForm from "src/components/add-ens-domain-form/container/AddEnsDomainForm/AddEnsDomainForm";

export const AddEnsDomainModal = ({ open, closeModal }) => {
  return (
    <BaseModal open={open} maxWidth={460} maxHeigth={400} onClose={closeModal}>
      <AddEnsDomainForm onModalClose={closeModal} />
    </BaseModal>
  );
};

AddEnsDomainModal.defaultProps = {
  open: false,
};

AddEnsDomainModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};
