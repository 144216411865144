import gql from 'graphql-tag';

import { SCOPED_API_KEY } from '../fragments/scoped-api-key';

export default gql`
  query getTeamApiKeys($teamId: ID!, $limit: Int, $nextToken: String) {
    getTeamApiKeys(teamId: $teamId, limit: $limit, nextToken: $nextToken) {
      apiKeys {
        ...ScopedApiKeyInfo
      }
    }
  }
  ${SCOPED_API_KEY}
`;
