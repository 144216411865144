import gql from 'graphql-tag';

import { SITE_DETAIL } from '../../../../../graphql/fragments';

export default gql`
  mutation verifyHns($input: VerifyHnsInput!) {
    verifyHns(input: $input) {
      ...SiteDetail
    }
  }
  ${SITE_DETAIL}
`;
