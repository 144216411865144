// @ts-nocheck
import TerminalBreadcrumbs from "@terminal-packages/ui/core/Breadcrumbs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";

import config from "./config";
import useResolvers from "./use-resolvers";

export const Breadcrumbs = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();

  const { items = [] } = useResolvers(config, {
    t,
    params,
    location,
    ...props,
  });

  return (
    <TerminalBreadcrumbs
      items={items}
      component={Link}
      itemsCount={{}}
      disableLastItem={false}
    />
  );
};

Breadcrumbs.defaultProps = {
  site: {},
};

Breadcrumbs.propTypes = {
  site: PropTypes.shape({
    name: PropTypes.string,
    domains: PropTypes.array,
  }),
};
