import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  sectionRoot: {
    marginBottom: 15,
  },
  sectionContent: {
    padding: '16px 20px 24px',
    '& label': {
      padding: 0,
      margin: 0,
      fontSize: '12px',
    },
  },
  buttonsContainer: {
    marginTop: 17,
  },
}));
