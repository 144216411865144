import { SOURCE_TYPES } from "src/constants";
import getNavItems from "src/utils/get-navigation-items";

export const SECTION_IDS = {
  BUILD_AND_DEPLOY: "build-and-deploy",
  DOMAIN_MANAGEMENT: "domain-management",
  CONTINUOUS_DEPLOYMENT: "continuous-deployment",
  ADVANCED_BUILD_SETTINGS: "advanced-build-settings",
  CUSTOM_DOMAINS: "custom-domains",
  ENS: "ens",
  HNS: "hns",
  TTL: "ssl",
  GENERAL: "general",
  SITE_DETAILS: "site-details",
  DANGER_ZONE: "danger-zone",
  OTHER_SETTINGS: "other-settings",
  SITE_MANAGEMENT: "site-management",
};

export const MAIN_SECTIONS = [
  SECTION_IDS.GENERAL,
  SECTION_IDS.BUILD_AND_DEPLOY,
  SECTION_IDS.DOMAIN_MANAGEMENT,
];

export const SUB_SECTIONS = [
  SECTION_IDS.CUSTOM_DOMAINS,
  SECTION_IDS.ENS,
  SECTION_IDS.HNS,
  SECTION_IDS.TTL,
  SECTION_IDS.CONTINUOUS_DEPLOYMENT,
  SECTION_IDS.ADVANCED_BUILD_SETTINGS,
  SECTION_IDS.SITE_DETAILS,
  SECTION_IDS.DANGER_ZONE,
  SECTION_IDS.OTHER_SETTINGS,
  SECTION_IDS.SITE_MANAGEMENT,
];

const getNavigationItems = (t, currActiveSubsection, platform, sourceType) => {
  const rawItems = [
    {
      title: t("siteSettings.sectionTitles.general"),
      id: SECTION_IDS.GENERAL,
      type: "main",
      list: [
        {
          title: t("siteSettings.sectionTitles.siteDetails"),
          id: SECTION_IDS.SITE_DETAILS,
          type: "subsection",
        },
        {
          title: t("siteSettings.sectionTitles.otherSettings"),
          id: SECTION_IDS.OTHER_SETTINGS,
          type: "subsection",
        },
        {
          title: t("siteSettings.sectionTitles.dangerZone"),
          id: SECTION_IDS.DANGER_ZONE,
          type: "subsection",
        },
      ],
    },
    ...(sourceType !== SOURCE_TYPES.LOCAL
      ? [
          {
            title: t("siteSettings.sectionTitles.buildAndDeploy"),
            id: SECTION_IDS.BUILD_AND_DEPLOY,
            type: "main",
            list: [
              {
                title: t("siteSettings.sectionTitles.continuousDeployment"),
                id: SECTION_IDS.CONTINUOUS_DEPLOYMENT,
                type: "subsection",
              },
              {
                title: t("siteSettings.sectionTitles.advancedBuildSettings"),
                id: SECTION_IDS.ADVANCED_BUILD_SETTINGS,
                type: "subsection",
              },
              {
                title: t("siteSettings.sectionTitles.siteManagement"),
                id: SECTION_IDS.SITE_MANAGEMENT,
                type: "subsection",
              },
            ],
          },
        ]
      : []),
    {
      title: t("siteSettings.sectionTitles.domainManagement"),
      id: SECTION_IDS.DOMAIN_MANAGEMENT,
      type: "main",
      list: [
        {
          title: t("siteSettings.sectionTitles.domains"),
          id: SECTION_IDS.CUSTOM_DOMAINS,
          type: "subsection",
        },
        ...(platform === "ipfs"
          ? [
              {
                title: t("siteSettings.sectionTitles.ens"),
                id: SECTION_IDS.ENS,
                type: "subsection",
              },
            ]
          : []),
        {
          title: t("siteSettings.sectionTitles.hns"),
          id: SECTION_IDS.HNS,
          type: "subsection",
        },
      ],
    },
  ];
  return getNavItems(currActiveSubsection, rawItems);
};

export default getNavigationItems;
