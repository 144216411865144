import axios from "axios";
import { SiweMessage } from "siwe";
import { firebaseFunctionEndpoints } from "src/integrations/ethereum/constants";

export function requestAccounts(provider) {
  if (!provider) {
    throw new Error("Install wallet to sign in");
  }

  return provider.send("eth_requestAccounts", []);
}

export async function createSiweMessage(address, statement) {
  const { data } = await axios.post(firebaseFunctionEndpoints.generateNonce, {
    data: "",
  });

  const nonce = data?.result;

  const message = new SiweMessage({
    domain: window.location.host,
    address,
    statement,
    uri: window.location.origin,
    version: "1",
    chainId: "1",
    nonce,
  });

  return message.prepareMessage();
}

export async function signMessage(signer) {
  if (!signer) {
    throw new Error("Install wallet to sign in");
  }

  const address = await signer.getAddress();
  const message = await createSiweMessage(
    address,
    "Sign up with Ethereum to the Fleek app."
  );
  const signature = await signer.signMessage(message);

  return {
    address,
    message,
    signature,
  };
}

export async function signInWithEthereum({ address, signature, message }) {
  if (!address || !signature) {
    throw new Error("Install wallet to sign in");
  }

  const data = {
    data: {
      address,
    },
  };

  const validateData = {
    data: {
      message: JSON.stringify(message),
      address,
      signature,
    },
  };

  const validateWalletLoginResponse = await axios.post(
    firebaseFunctionEndpoints.validateWalletLogin,
    validateData
  );

  if (validateWalletLoginResponse.data.result) {
    const cryptoAuthResponse = await axios.post(
      firebaseFunctionEndpoints.cryptoAuth,
      data
    );

    return cryptoAuthResponse;
  }

  if (!validateWalletLoginResponse.data.result) {
    console.error(validateWalletLoginResponse.data);
    throw new Error(
      "Failed to sign in with Ethereum. Please contact support for extra help."
    );
  }

  return undefined;
}

export async function linkWalletToUser({ uid, address, signature, message }) {
  if (!address || !signature || !message) {
    throw new Error("Install wallet to sign in");
  }

  if (!uid) {
    throw new Error("User ID is required");
  }

  const linkWalletToUserData = {
    data: {
      uid,
      address,
    },
  };

  const validateWalletData = {
    data: {
      message: JSON.stringify(message),
      address,
      signature,
    },
  };

  const validateWalletLoginResponse = await axios.post(
    firebaseFunctionEndpoints.validateWalletLogin,
    validateWalletData
  );

  if (validateWalletLoginResponse.data.result) {
    const linkWalletToUserResponse = await axios.post(
      firebaseFunctionEndpoints.linkWalletToUser,
      linkWalletToUserData
    );

    return linkWalletToUserResponse;
  }

  if (!validateWalletLoginResponse.data.result) {
    console.error(validateWalletLoginResponse.data);
    throw new Error(
      "Failed to link wallet to user. Please contact support for extra help."
    );
  }

  return undefined;
}

export const getChainName = (chainId) => {
  if (!!Number(chainId) && chainId.length > 9) {
    return "local";
  }
  switch (chainId) {
    case "1":
      return "mainnet";
    case "3":
      return "ropsten";
    case "4":
      return "rinkeby";
    case "5":
      return "goerli";
    case "10":
      return "optimism";
    case "42":
      return "kovan";
    case "137":
      return "polygon";
    case "42161":
      return "arbitrum";
    default:
      return "unknown";
  }
};

export function createPaymentTransaction({ address, currency, planDuration = 3, planType, teamId, value }) {
  // Improve this validation if necessary
  if (!address || !planType || !planDuration || !teamId || !value) {
    throw new Error("You need to specify an address, a plan type, a plan duration, a team Id and a value in order to create a payment transaction.");
  }

  const txData = {
    data: {
      address,
      currency,
      planDuration,
      planType,
      teamId,
      value,
    }
  }

  try {
    return axios.post(
      firebaseFunctionEndpoints.createPaymentTransaction,
      txData
    );
  } catch (err) {
    throw new Error(err);
  }
  
}

export function updatePaymentTransactionId({ address, teamId, txId }) {
  if (!txId || !address || !teamId) {
    throw new Error("You need to specify a transaction id in order to create a payment transaction.");
  }

  const txData = {
    data: {
      address,
      teamId,
      txId
    }
  }

  try {
    return axios.post(
      firebaseFunctionEndpoints.updatePaymentTransaction,
      txData
    );
  } catch (err) {
    throw new Error(err);
  }
  
}
