import React from "react";
import { Trans } from "react-i18next";
import config from "src/config";

export const handleGenericError = ({ state, setState, t }) => {
  setState({
    ...state,
    error: {
      type: "error",
      message: t("sites.tabs.settings.ens.setControllerModal.errors.generic"),
    },
  });
};

export const handleFailedToSign = ({ state, setState, t }) => {
  setState({
    ...state,
    loading: false,
    error: {
      type: "error",
      message: t(
        "sites.tabs.settings.ens.setControllerModal.errors.failedToSignMessage"
      ),
    },
  });
};

export const handleFailedToConnect = ({ state, setState, t }) => {
  setState({
    ...state,
    loading: false,
    error: {
      type: "error",
      message: t(
        "sites.tabs.settings.ens.setControllerModal.errors.failedToConnect"
      ),
    },
  });
};

export const handleWrongNetId = ({ state, setState, t }) => {
  const { network } = config.ethereum;
  const networkFirstLetterUpper = `${network
    .charAt(0)
    .toUpperCase()}${network.substring(1)}`;

  setState({
    ...state,
    error: {
      type: "warning",
      message: t(
        "sites.tabs.settings.ens.setControllerModal.errors.wrongNetId",
        {
          network: networkFirstLetterUpper,
        }
      ),
    },
  });
};

export const handleNotOwnerOfDomain = ({ state, setState, classes }) => {
  const link =
    "https://docs.fleek.co/hosting/troubleshooting/#setting-the-controller-of-an-ens-domain";
  setState({
    ...state,
    error: {
      type: "error",
      message: (
        <Trans
          i18nKey="sites.tabs.settings.ens.setControllerModal.errors.notOwnerOfDomain"
          values={{
            link,
          }}
          components={[
            null,
            <a
              key="link"
              className={classes.errorLink}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              LINK
            </a>,
          ]}
        />
      ),
    },
  });
};

export const handleFailedTransaction = ({ state, setState, t }) => {
  setState({
    ...state,
    error: {
      type: "error",
      message: t(
        "sites.tabs.settings.ens.setControllerModal.errors.failedTransaction"
      ),
    },
  });
};

export const handleRejectedTransaction = ({ state, setState, t }) => {
  setState({
    ...state,
    loading: false,
    error: {
      type: "error",
      message: t(
        "sites.tabs.settings.ens.setControllerModal.errors.rejectedTransaction"
      ),
    },
  });
};
