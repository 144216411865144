// @ts-nocheck
/* eslint-disable no-undef */
const { REACT_APP_FE_NODE_ENV } = process.env;
const config = {
  production: {
    migration: {
      url: 'https://app.fleek.xyz',
    },
    hcaptcha: {
      sitekey: process.env.REACT_APP_HCAPTCHA_SITEKEY,
    },
    logRocket: {
      projectId: "fleek/fleek",
      scrubHeaders: ["Authorization"],
    },
    ethereum: {
      netId: 1,
      network: "mainnet",
      ENSRegistryContractAddress: "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85",
      ENSStorageContractAddress: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
      infuraId: process.env.REACT_APP_INFURA_ID,
    },
    supportEmail: "support@fleek.co",
    stripeKey: "pk_live_5BfNUNll7l9PFbO0vNmTTSIi",
    auth: {
      clientId: "terminal",
      baseURL: "https://auth.fleek.co",
      oidcRedirectUri: "https://app.fleek.co/auth-cb/",
      web3ProviderEnabled:
        process.env.REACT_APP_WEB3_PROVIDER_ENABLED === "true",
      emailVerificationEnabled:
        process.env.REACT_APP_EMAIL_VERIFICATION_ENABLED === "true",
    },
    newApi: {
      baseURL:
        "https://b6756lokszgovfg2lkge3t4kai.appsync-api.us-west-2.amazonaws.com/graphql",
      wsBaseURL:
        "wss://b6756lokszgovfg2lkge3t4kai.appsync-realtime-api.us-west-2.amazonaws.com/graphql",
    },
    s3Client: {
      baseURL: "https://storage.fleek-internal.com/",
    },
    sentry: {
      org: "fleek-uo",
      dsn: "https://e7d85f83b6854092885b33074c5eab7d@o385630.ingest.sentry.io/5228504",
    },
    firebaseAuth: {
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
      authDomain: "ipfsgw.firebaseapp.com",
      projectId: "ipfsgw",
      storageBucket: "ipfsgw.appspot.com",
      messagingSenderId: "144194240379",
      appId: "1:144194240379:web:362e3d5e95f89d56f507de",
    },
    firebase: {
      cloudFunctionsURL: process.env.REACT_APP_CLOUD_FUNCTIONS_URL,
    },
    walletConnect: {
      projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
    },
  },
  staging: {
    migration: {
      url: 'https://staging.fleeksandbox.xyz/',
    },
    hcaptcha: {
      sitekey: process.env.REACT_APP_HCAPTCHA_SITEKEY,
    },
    logRocket: {
      projectId: "fleek/fleek",
      scrubHeaders: ["Authorization"],
    },
    ethereum: {
      netId: 3,
      network: "goerli",
      ENSRegistryContractAddress: "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85",
      ENSStorageContractAddress: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
      infuraId: process.env.REACT_APP_INFURA_ID,
    },
    supportEmail: "support@fleek.co",
    stripeKey: "pk_test_CJZoF6F9uT47IWH2gtdoxvOn",
    auth: {
      clientId: "terminal",
      baseURL: "https://stg-auth.fleek.co",
      oidcRedirectUri: "https://stg-app.fleek.co/auth-cb/",
      web3ProviderEnabled:
        process.env.REACT_APP_WEB3_PROVIDER_ENABLED === "true",
      emailVerificationEnabled:
        process.env.REACT_APP_EMAIL_VERIFICATION_ENABLED === "true",
    },
    newApi: {
      baseURL:
        "https://h6qbvxquqjg5direndhm7ugaj4.appsync-api.us-west-2.amazonaws.com/graphql",
      wsBaseURL:
        "wss://h6qbvxquqjg5direndhm7ugaj4.appsync-realtime-api.us-west-2.amazonaws.com/graphql",
    },
    s3Client: {
      baseURL: "https://storage-stg.fleek-internal.com/",
    },
    sentry: {
      org: "fleek-uo",
      dsn: "https://e7d85f83b6854092885b33074c5eab7d@o385630.ingest.sentry.io/5228504",
    },
    firebaseAuth: {
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
      authDomain: "fleek-v2-stg.firebaseapp.com",
      projectId: "fleek-v2-stg",
      storageBucket: "fleek-v2-stg.appspot.com",
      messagingSenderId: "53533819821",
      appId: "1:53533819821:web:9563be6dd79b6005cb5724",
      measurementId: "G-X8F1W34JZS",
    },
    firebase: {
      cloudFunctionsURL: process.env.REACT_APP_CLOUD_FUNCTIONS_URL,
    },
    walletConnect: {
      projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
    },
  },
  development: {
    migration: {
      url: 'https://app.fleeksandbox.xyz',
    },
    hcaptcha: {
      sitekey: process.env.REACT_APP_HCAPTCHA_SITEKEY,
    },
    logRocket: {
      projectId: "fleek/fleek",
      scrubHeaders: ["Authorization"],
    },
    ethereum: {
      netId: 1,
      network: "mainnet",
      ENSRegistryContractAddress: "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85",
      ENSStorageContractAddress: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
      infuraId: process.env.REACT_APP_INFURA_ID,
    },
    supportEmail: "support@fleek.co",
    stripeKey: "pk_test_CJZoF6F9uT47IWH2gtdoxvOn",
    auth: {
      clientId: "terminal",
      baseURL: "https://dev-auth.fleek.co",

      // If doing local development, use the helper lambda for redirection
      oidcRedirectUri:
        REACT_APP_FE_NODE_ENV === ""
          ? "https://hwpimci92a.execute-api.us-west-2.amazonaws.com/default/oidc-local-redirect-helper"
          : "https://dev-app.fleek.co/auth-cb/",
      web3ProviderEnabled:
        process.env.REACT_APP_WEB3_PROVIDER_ENABLED === "true",
      emailVerificationEnabled:
        process.env.REACT_APP_EMAIL_VERIFICATION_ENABLED === "true",
    },
    newApi: {
      baseURL:
        "https://7w65cjs2fnbwzdsltmxj427wfu.appsync-api.us-west-2.amazonaws.com/graphql",
      wsBaseURL:
        "wss://7w65cjs2fnbwzdsltmxj427wfu.appsync-realtime-api.us-west-2.amazonaws.com/graphql",
    },
    s3Client: {
      baseURL: "https://storage-dev.fleek-internal.com/",
    },
    sentry: {
      org: "fleek-uo",
      dsn: "https://e7d85f83b6854092885b33074c5eab7d@o385630.ingest.sentry.io/5228504",
    },
    firebaseAuth: {
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
      authDomain: "ipfswg-dev.firebaseapp.com",
      projectId: "ipfswg-dev",
      storageBucket: "ipfswg-dev.appspot.com",
      messagingSenderId: "700464311692",
      appId: "1:700464311692:web:c662ed775113320a2d7aa9",
      measurementId: "G-NJ599PS76E",
    },
    firebase: {
      cloudFunctionsURL: process.env.REACT_APP_CLOUD_FUNCTIONS_URL,
    },
    walletConnect: {
      projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
    },
  },
};

export default config[REACT_APP_FE_NODE_ENV] || config.development;
