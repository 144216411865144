import { useQuery } from "@apollo/react-hooks";
import Box from "@terminal-packages/ui/core/Box";
import get from "lodash/get";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";
import { FileCard } from "src/components";
import { DOMAIN_STATUS } from "src/constants";
import { useTeam } from "src/store/AuthContext";
import { getStorageObjectPath } from "src/utils";

import { fetchBucketInfo } from "../../actions";
import { GET_BUCKET_BY_SLUG } from "../../graphql";
import ButtonsGroup from "../ButtonsGroup";
import { CustomDomain } from "../CustomDomain";
import Search from "../Search";
import Table from "../Table";
import useStyles from "./styles";

const Bucket = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const bucketSlug = params.bucketName;
  const { bucket, isBucketLoading } = useSelector((state) => ({
    isBucketLoading: state.storage.buckets.isLoading,
    bucket: state.storage.buckets[params.bucketName],
  }));

  const { canUseStorage } = useTeam();

  useEffect(() => {
    dispatch(
      fetchBucketInfo({
        Delimiter: "/",
        Bucket: bucketSlug,
        isNewUser: canUseStorage,
      })
    );
  }, [canUseStorage, dispatch, bucketSlug]);

  const { data, loading } = useQuery(GET_BUCKET_BY_SLUG, {
    client: newApiClient,
    variables: {
      slug: bucketSlug,
    },
  });

  const apiBucket = data?.getBucketBySlug;
  const validCustomDomain = apiBucket?.domainStatus === DOMAIN_STATUS.PROPAGATED &&
    apiBucket?.domain

  const objectUrl = getStorageObjectPath(
    params.bucketName,
    undefined,
    validCustomDomain
  )

  return (
    <>
      <FileCard
        // TODO: this field should be set by the getBucketBySlug query loading state
        loadingHash={loading}
        ipfsHash={get(data, "getBucketBySlug.hash", "") || ""}
        status="success"
        fileName={params.bucketName}
        loadingInfo={!bucket && isBucketLoading}
        url={objectUrl}
        hasCustomDomain={!!validCustomDomain}
        lastModification={
          bucket
            ? `Created ${moment(bucket.CreationDate).format(
                "MM/DD/YYYY hh:mm A"
              )}`
            : ""
        }
      />
      <br />
      <CustomDomain
        isLoading={loading}
        bucket={{ ...(apiBucket || {}), slug: bucketSlug }}
      />
      <br />
      <Box padding="20px 0">
        <div className={classes.actionsHeader}>
          <Search />
          <ButtonsGroup
            delimiter="/"
            bucket={bucketSlug}
            disabled={!bucket && isBucketLoading}
          />
        </div>
        <Table delimiter="/" bucket={bucketSlug} />
      </Box>
    </>
  );
};

export default Bucket;
