import { useMutation } from "@apollo/react-hooks";
import ArrowLink from "@terminal-packages/ui/core/ArrowLink";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { newApiClient } from "src/clients";
import { EditableList } from "src/components";
import { GA_EVENTS_CATEGORIES } from "src/constants";
import { url } from "src/utils";

import {
  EDIT_BUILD_SETTINGS,
  EDIT_DEPLOY_SETTINGS,
} from "../../../../../../graphql/mutations";
import ConnectToGithub from "./components/ConnectToGithub";
import useStyles from "./styles";

const BuildSettings = (props) => {
  const { siteId, buildSettings, deploySettings } = props;

  const [githubInfo, setGithubInfo] = useState({});
  const buildCmd = get(buildSettings, "buildCommand", "") || "";
  const baseDir = get(buildSettings, "baseDirectoryPath", "") || "";
  const publishDir = get(buildSettings, "publishDirectoryPath", "") || "";
  const repoUrl = get(deploySettings, "source.url", "") || "";

  const { t } = useTranslation();
  const classes = useStyles(props);

  const [editBuildSettings] = useMutation(EDIT_BUILD_SETTINGS, {
    client: newApiClient,
  });

  const [editDeploySettings] = useMutation(EDIT_DEPLOY_SETTINGS, {
    client: newApiClient,
  });

  const buildSettingsUpdate = async (values) => {
    try {
      await editBuildSettings({
        variables: {
          input: {
            siteId,
            ...(values.buildCmd !== buildCmd && {
              buildCommand: values.buildCmd,
            }),
            ...(values.baseDir !== baseDir && {
              baseDirectoryPath: values.baseDir,
            }),
            ...(values.publishDir !== publishDir && {
              publishDirectoryPath: values.publishDir,
            }),
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          editBuildSettings: {
            id: siteId,
            buildSettings: {
              ...buildSettings,
              ...(values.buildCmd !== buildCmd && {
                buildCommand: values.buildCmd,
              }),
              ...(values.baseDir !== baseDir && {
                baseDirectoryPath: values.baseDir,
              }),
              ...(values.publishDir !== publishDir && {
                publishDirectoryPath: values.publishDir,
              }),
            },
            __typename: "Site",
          },
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error editing build settings:", error.message);
    }
  };

  const deploySettingsUpdate = async () => {
    const newUrl = get(githubInfo, "url", null);
    const isNewRepo = newUrl !== repoUrl;

    if (!newUrl || !isNewRepo) {
      return;
    }

    try {
      await editDeploySettings({
        variables: {
          input: {
            siteId,
            ...(isNewRepo && {
              githubSource: {
                installationId: githubInfo.installationId,
                url: newUrl,
              },
            }),
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          editDeploySettings: {
            id: siteId,
            deploySettings: {
              ...deploySettings,
              ...(isNewRepo && {
                source: {
                  ...deploySettings.source,
                  url: newUrl,
                  __typename: "Repository",
                },
              }),
              __typename: "DeploySettings",
            },
            __typename: "Site",
          },
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error editing deploy settings:", error.message);
    }
  };

  const onSubmitChanges = async (values) => {
    window.ga(
      "send",
      "event",
      GA_EVENTS_CATEGORIES.SITES,
      "Edit deploy settings",
      `${values.buildCmd}, ${values.publishDir}`
    );
    window.analytics.track("Edit deploy settings", {
      siteId,
      buildCmd: values.buildCmd,
      publishDir: values.publishDir,
      teamId: url.getAccountIdFromUrl(),
    });

    buildSettingsUpdate(values);
    deploySettingsUpdate();
  };

  const data = [
    {
      label: t("siteSettings.deployment.buildSettings.repository"),
      value: repoUrl,
      stateKey: "repoUrl",
      renderEditableComponent: (value, onChange) => (
        <ConnectToGithub
          initialValue={value}
          onChange={(urlValue, githubDetails) => {
            onChange("repoUrl", urlValue);
            setGithubInfo(githubDetails);
          }}
        />
      ),
    },
    {
      stateKey: "baseDir",
      label: t("siteSettings.deployment.buildSettings.baseDirectory"),
      value: baseDir,
      placeholder: t("siteSettings.deployment.buildSettings.notSet"),
      editingInfo: t("siteSettings.deployment.buildSettings.baseDirectoryTip"),
    },
    {
      stateKey: "buildCmd",
      label: t("siteSettings.deployment.buildSettings.buildCommand"),
      value: buildCmd,
    },
    {
      stateKey: "publishDir",
      label: t("siteSettings.deployment.buildSettings.publishDirectory"),
      value: publishDir,
    },
  ];

  return (
    <CardTitled
      mainContent={t("siteSettings.deployment.buildSettings.title")}
      classes={{
        content: classes.sectionContent,
      }}
    >
      <EditableList
        data={data}
        goToEditButtonText={t("siteSettings.editSettings")}
        onSubmitChanges={onSubmitChanges}
      >
        <a
          href="https://docs.fleek.co/hosting/site-deployment/#configuring-the-deployment"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.resetAnchorStyles}
        >
          <ArrowLink className={classes.link}>
            {t("siteSettings.deployment.buildSettings.learnMore")}
          </ArrowLink>
        </a>
      </EditableList>
    </CardTitled>
  );
};

BuildSettings.defaultProps = {
  siteId: null,
};

BuildSettings.propTypes = {
  siteId: PropTypes.string,
  buildSettings: PropTypes.shape({
    buildSettings: PropTypes.string,
    buildCmd: PropTypes.string,
    publishDir: PropTypes.string,
  }).isRequired,
  deploySettings: PropTypes.shape({
    source: {
      url: PropTypes.string,
    },
  }).isRequired,
};

export default BuildSettings;
