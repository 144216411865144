// @ts-nocheck
import PropTypes from "prop-types";
import QRCodeUtil from "qrcode";
import { useMemo } from "react";

import useStyles from "./styles";

const generateMatrix = (value, errorCorrectionLevel) => {
  const arr = Array.prototype.slice.call(
    QRCodeUtil.create(value, { errorCorrectionLevel }).modules.data,
    0
  );
  const sqrt = Math.sqrt(arr.length);
  return arr.reduce(
    (rows, key, index) =>
      (index % sqrt === 0
        ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows,
    []
  );
};

export const QRCode = ({
  ecl,
  logo,
  logoMargin,
  logoSize,
  size,
  value,
  logoBackgroundColor,
  ...other
}) => {
  const classes = useStyles();

  const dots = useMemo(() => {
    const dotsArray = [];
    const matrix = generateMatrix(value, ecl);
    const cellSize = size / matrix.length;
    const qrList = [
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 0, y: 1 },
    ];

    qrList.forEach(({ x, y }) => {
      const x1 = (matrix.length - 7) * cellSize * x;
      const y1 = (matrix.length - 7) * cellSize * y;
      for (let i = 0; i < 3; i += 1) {
        dotsArray.push(
          <rect
            key={`${x1}-${y1}-${i}`}
            fill={i % 2 !== 0 ? "white" : "black"}
            height={cellSize * (7 - i * 2)}
            rx={(i - 3) * -2 + (i === 0 ? 2 : 0)} // calculated border radius for corner squares
            ry={(i - 3) * -2 + (i === 0 ? 2 : 0)} // calculated border radius for corner squares
            width={cellSize * (7 - i * 2)}
            x={x1 + cellSize * i}
            y={y1 + cellSize * i}
          />
        );
      }
    });

    const clearArenaSize = Math.floor((logoSize + 3) / cellSize);
    const matrixMiddleStart = matrix.length / 2 - clearArenaSize / 2;
    const matrixMiddleEnd = matrix.length / 2 + clearArenaSize / 2 - 1;

    matrix.forEach((row, i) => {
      row.forEach((column, j) => {
        if (matrix[i][j]) {
          if (
            !(
              (i < 7 && j < 7) ||
              (i > matrix.length - 8 && j < 7) ||
              (i < 7 && j > matrix.length - 8)
            )
          ) {
            if (
              !(
                i > matrixMiddleStart &&
                i < matrixMiddleEnd &&
                j > matrixMiddleStart &&
                j < matrixMiddleEnd &&
                i < j + clearArenaSize / 2 &&
                j < i + clearArenaSize / 2 + 1
              )
            ) {
              dotsArray.push(
                <circle
                  key={`${i}-${j}`}
                  cx={i * cellSize + cellSize / 2}
                  cy={j * cellSize + cellSize / 2}
                  fill="black"
                  r={cellSize / 3} // calculate size of single dots
                />
              );
            }
          }
        }
      });
    });

    return dotsArray;
  }, [ecl, logoSize, size, value]);

  const logoPosition = size / 2 - logoSize / 2 - logoMargin;
  const logoWrapperSize = logoSize + logoMargin * 2;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={classes.root} {...other}>
      <svg height={size} width={size}>
        <defs>
          <clipPath id="clip-wrapper">
            <rect height={logoWrapperSize} width={logoWrapperSize} />
          </clipPath>
          <clipPath id="clip-logo">
            <rect height={logoSize} width={logoSize} />
          </clipPath>
        </defs>
        <rect fill="white" height={size} width={size} />
        {dots}
        {logo && (
          <g transform={`translate(${logoPosition},${logoPosition})`}>
            <g x={logoPosition} y={logoPosition}>
              <rect
                rx={8}
                fill={logoBackgroundColor}
                clipPath="url(#clip-wrapper)"
                height={logoWrapperSize}
                width={logoWrapperSize}
              />
              <image
                clipPath="url(#clip-logo)"
                href={logo}
                preserveAspectRatio="xMidYMid slice"
                height={logoSize}
                width={logoSize}
                style={{
                  padding: "4px",
                }}
              />
            </g>
          </g>
        )}
      </svg>
    </div>
  );
};

QRCode.defaultProps = {
  ecl: "L",
  logo: undefined,
  logoMargin: 0,
  logoSize: 28,
  logoBackgroundColor: "white",
  size: 164,
};

QRCode.propTypes = {
  ecl: PropTypes.string,
  logo: PropTypes.string,
  logoMargin: PropTypes.number,
  logoSize: PropTypes.number,
  logoBackgroundColor: PropTypes.string,
  size: PropTypes.number,
  value: PropTypes.string.isRequired,
};
