import {
  Button,
  dripStitches,
  Grid,
  IconButton,
  PasswordTextField,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  Typography,
} from "@fleekhq/react-drip";
import { toast } from "@terminal-packages/ui/core/Toast";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoInformationCircle } from "react-icons/io5";
import { AuthContainer } from "src/views/Authentication/styled";

import { projectAuth } from "../../../firebase";

const PASSWORD_REGEX = /(?=.*[0-9a-zA-Z]).{6,}/;

const { styled } = dripStitches;

const ErrorText = styled("p", {
  color: "$red9",
  fontSize: "$sm",
});

const NewPassword = ({ handleStatusUpdate, actionCode }) => {
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validatePassword = (_password) => {
    let isValid = true;

    if (!_password.match(PASSWORD_REGEX)) {
      setErrorMessage("Password should contain at least 6 characters.");
      isValid = false;
    }

    if (!_password) {
      setErrorMessage("Password should not be empty.");
      isValid = false;
    }

    return isValid;
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);

    if (isDirtyForm) {
      setErrorMessage("");
      validatePassword(event.target.value);
    }
  };

  const handleUpdatePassword = async (event) => {
    event.preventDefault();
    setIsDirtyForm(true);

    const isPasswordValid = validatePassword(password);

    if (isPasswordValid) {
      setIsLoading(true);
      try {
        await projectAuth.confirmPasswordReset(actionCode, password);
        handleStatusUpdate();
      } catch (error) {
        toast.error(`${t("verification.failed")}: ${error.message}`, {
          autoClose: 6000,
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <AuthContainer as="form" onSubmit={handleUpdatePassword}>
      <Typography as="h2" fontSize="lg" fontWeight="bold">
        {t("verification.setPasswordTitle")}
      </Typography>

      <Tooltip size="lg">
        <TooltipTrigger asChild>
          <IconButton
            onMouseDown={(e) => e.preventDefault()}
            type="button"
            css={{ position: "absolute", right: "$4", top: "$4" }}
            size="sm"
            isRound
            aria-label="Information"
            variant="ghost"
            icon={<IoInformationCircle />}
          />
        </TooltipTrigger>
        <TooltipContent>
          <TooltipArrow />
          Password should contain at least 6 characters.
        </TooltipContent>
      </Tooltip>

      <Typography fontSize="md" css={{ color: "$slate11" }}>
        {t("verification.setPasswordDescription")}
      </Typography>

      <Grid css={{ gap: "$2" }}>
        <PasswordTextField
          placeholder={t("auth.password.placeholder")}
          value={password}
          onChange={handlePasswordChange}
        />
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      </Grid>

      <Button type="submit" colorScheme="blue" isLoading={isLoading}>
        {t("common.save")}
      </Button>
    </AuthContainer>
  );
};

NewPassword.propTypes = {
  handleStatusUpdate: PropTypes.func.isRequired,
  actionCode: PropTypes.string.isRequired,
};

export default NewPassword;
