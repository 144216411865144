import { combineReducers } from "redux";

import billingReducer from "./billing";
import loadingStateReducer from "./loading";
import localStorageReducer from "./local-storage";
import modalReducer from "./modal";
import storageReducer from "./storage";
import web3Reducer from "./web3";

const rootReducer = combineReducers({
  billing: billingReducer,
  loadingState: loadingStateReducer,
  localStorage: localStorageReducer,
  modal: modalReducer,
  storage: storageReducer,
  web3: web3Reducer,
});

export default rootReducer;
