// @ts-nocheck
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { CommandText } from "../components";
import useStyles from "./styles";

// Config only exports current env variables
// We need the prod domain here
const PRODUCTION_STORAGE_DOMAIN = "https://storage.fleek-internal.com";
export const HnsModal = ({
  open,
  domain,
  onClose,
  onSubmit,
  loading,
  error,
  pullZoneUrl,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { siteSlug } = useParams();
  const domainSections = domain.split(".");
  const isTLD = domainSections.length === 1;
  const record = isTLD ? "ALIAS" : "CNAME";
  const recordValue = pullZoneUrl;
  const host = isTLD
    ? "@"
    : domainSections.slice(0, domainSections.length - 1).join(".");

  const tld = domainSections[domainSections.length - 1];

  const useNamebaseOnClick = () => {
    // We need to doubleEncodeURI because of transformations occurring from the namebase API
    const backToFleekUrl = encodeURIComponent(
      encodeURIComponent(
        `${window.location.origin}/#/sites/${siteSlug}/settings/hns`
      )
    );
    const redirect = encodeURIComponent(
      encodeURIComponent(
        `${window.location.origin}/#/handshake-success?redirectTo=${backToFleekUrl}`
      )
    );
    const records = btoa(
      `[{"type":"${record}","host":"${host}","value":"${recordValue}","ttl":3600}]`
    );
    const setRecordUrl = `https://namebase.io/next/domain-manager/${tld}/records?records=${records}&redirect=${redirect}`;
    window.open(setRecordUrl);
  };

  return (
    <BaseModal
      open={open}
      title={t("modals.hnsConfiguration.title")}
      maxWidth={700}
      onClose={onClose}
      className={classnames(classes.modal, {
        [classes.error]: error,
      })}
    >
      {error && (
        <div className={classes.errorBox}>
          {t("modals.dnsConfiguration.error")}
        </div>
      )}
      <Typography variant="body2" className={classes.subtitle}>
        {t("modals.hnsConfiguration.option1")}{" "}
        <img
          src={`${PRODUCTION_STORAGE_DOMAIN}/fleek-team-bucket/fleek-app/hns-logo.png`}
          alt="namebase"
          className={classes.logo}
        />
        {t("modals.hnsConfiguration.useNamebase")}
      </Typography>
      <Typography variant="body2" className={classes.description}>
        {t("modals.hnsConfiguration.descriptionOption1")}
      </Typography>
      <GenericButton
        buttonVariant="primary"
        onClick={useNamebaseOnClick}
        className={classes.namebaseRedirectButton}
      >
        {t("modals.hnsConfiguration.useNamebase")}
      </GenericButton>
      <Divider className={classes.divider} />
      <Typography
        variant="body2"
        className={classnames(classes.subtitle, classes.option2)}
      >
        {t("modals.hnsConfiguration.option2")}
      </Typography>
      <Typography variant="body2" className={classes.description}>
        {t("modals.hnsConfiguration.descriptionOption2")}
      </Typography>
      <div className={classes.blackBox}>
        <CommandText title={t("modals.record")} detail={record} />
        <CommandText title={t("modals.host")} detail={host} />
        <CommandText title={t("modals.pointsTo")} detail={recordValue} />
      </div>
      <Typography variant="body2" className={classes.footerText}>
        {t("modals.hnsConfiguration.footerText")}
      </Typography>
      <div className={classes.buttonContainer}>
        <GenericButton buttonVariant="secondary" onClick={onClose}>
          {t("modals.hnsConfiguration.buttons.cancel")}
        </GenericButton>
        <GenericButton
          buttonVariant="primary"
          onClick={onSubmit}
          loading={loading}
          disabled={loading}
        >
          {t("modals.hnsConfiguration.buttons.verifyDns")}
        </GenericButton>
      </div>
    </BaseModal>
  );
};

HnsModal.defaultProps = {
  open: false,
  domain: "domain",
  loading: false,
  error: false,
};

HnsModal.propTypes = {
  domain: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pullZoneUrl: PropTypes.string.isRequired,
};
