import { CLOSE_MODAL, OPEN_MODAL } from "src/modals/actions";

const defaultState = {
  id: null,
  open: false,
  props: null,
};

const modalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...action.payload,
        open: true,
      };

    case CLOSE_MODAL:
      return defaultState;

    default:
      return state;
  }
};

export default modalReducer;
