import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";
import config from "src/config";
import { GET_ENS_DOMAIN_INFO } from "src/graphql/queries";
import { url } from "src/utils";

import { SECTION_IDS } from "../../../../views/Sites/Site/components/Settings/get-navigation-items";
import { STEPS } from "../../container/AddEnsDomainForm/constants";
import useStyles from "./styles";

const VerifyButtons = ({ state, setState, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const match = useRouteMatch();

  const isDomainValid = (domain) => {
    const trimmedDomain = domain.trim();
    // eslint-disable-next-line
    const domainRegEx =
      /^(([\w-]|[\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d])+\.)+([\w-]|[\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d])+$/;
    const isValid = domainRegEx.test(trimmedDomain);

    return isValid;
  };

  const handleVerify = async () => {
    setState({
      ...state,
      loading: true,
    });

    const trimmedDomain = state.domain.trim();

    try {
      const {
        data: { getEnsDomainInfo: ensDomainData },
      } = await newApiClient.query({
        query: GET_ENS_DOMAIN_INFO,
        variables: {
          domain: trimmedDomain,
          network: config.ethereum.network,
        },
        fetchPolicy: "network-only",
      });

      if (!ensDomainData.ownerAddress) {
        setState({
          ...state,
          domain: trimmedDomain,
          step: STEPS.BUY_A_DOMAIN,
          loading: false,
          ensDomainInfo: ensDomainData,
        });

        return;
      }

      setState({
        ...state,
        domain: trimmedDomain,
        loading: false,
        step: STEPS.ALREADY_HAS_OWNER,
        ensDomainInfo: ensDomainData,
      });
    } catch (e) {
      /* eslint-disable no-console */
      console.error(e);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  return (
    <div>
      <GenericButton
        loading={state.loading}
        disabled={!isDomainValid(state.domain)}
        onClick={handleVerify}
        buttonVariant="primary"
        className={classes.button}
      >
        <Typography variant="body2">{t("common.verify")}</Typography>
      </GenericButton>
      <Link
        to={url.buildUrl(
          null,
          `/sites/${match.params.siteSlug}/settings/${SECTION_IDS.ENS}`
        )}
        className={classes.link}
      >
        <GenericButton onClick={onClose} buttonVariant="secondary">
          <Typography variant="body2">{t("common.cancel")}</Typography>
        </GenericButton>
      </Link>
    </div>
  );
};

VerifyButtons.propTypes = {
  state: PropTypes.shape({
    domain: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  setState: PropTypes.func.isRequired,
};

export default VerifyButtons;
