import { scroller } from "react-scroll";
import { ID_OF_SCROLLABLE_ELEMENT } from "src/constants";

const scrollToId = (id) =>
  scroller.scrollTo(id, {
    duration: 1500,
    delay: 100,
    smooth: true,
    containerId: ID_OF_SCROLLABLE_ELEMENT,
    offset: -10,
  });

export default scrollToId;
