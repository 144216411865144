import gql from 'graphql-tag';

import { BUCKET_DETAIL } from '../fragments';

export default gql`
  query getBucketBySlug($slug: String!) {
    getBucketBySlug(slug: $slug) {
      ...BucketDetail
    }
  }
  ${BUCKET_DETAIL}
`;
