// @ts-nocheck
import Typography from "@material-ui/core/Typography";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PROXY_TYPES } from "src/constants";

import { CommandText } from "../components";
import useStyles from "./styles";

export const DnsCnameModal = ({
  open,
  domain,
  terminalRecord,
  closeModal,
  onSubmit,
  proxyType,
  pullZoneUrl,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const domainSections = domain.split(".");
  const subDomain =
    domainSections.length > 2
      ? domainSections.slice(0, domainSections.length - 2)
      : [];

  const pointTextDomain =
    proxyType === PROXY_TYPES.BUNNY ? pullZoneUrl : domain;
  const pointsTo =
    proxyType === PROXY_TYPES.BUNNY ? pullZoneUrl : terminalRecord;

  const handleSubmit = async () => {
    setLoading(true);
    setError(false);
    const result = await onSubmit();

    setLoading(false);
    if (result) {
      closeModal();

      return;
    }
    setError(true);
  };

  return (
    <BaseModal
      open={open}
      title={t("modals.dnsConfiguration.title")}
      maxWidth={700}
      onClose={closeModal}
      className={classnames(classes.modal, {
        [classes.error]: error,
      })}
    >
      {error && (
        <div className={classes.errorBox}>
          {t("modals.dnsConfiguration.error")}
        </div>
      )}
      <Typography variant="body2" className={classes.pointText}>
        {t("modals.dnsConfiguration.pointText", { domain: pointTextDomain })}
      </Typography>
      <Typography variant="body2" className={classes.description}>
        {t("modals.dnsConfiguration.description", { domain: pointTextDomain })}
      </Typography>
      <div className={classes.blackBox}>
        <CommandText title={t("modals.record")} detail="CNAME" />
        <CommandText title={t("modals.host")} detail={subDomain.join(".") || "@"} />
        <CommandText title={t("modals.pointsTo")} detail={pointsTo} />
      </div>
      <Typography variant="body2" className={classes.footerText}>
        {t("modals.nameServer.step2.footer", { domain })}
      </Typography>
      <Typography
        href={`https://dnschecker.org/#CNAME/${domain}`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
        component="a"
        variant="body2"
      >
        {t("modals.nameServer.verifyPropagation")}
      </Typography>
      <div className={classes.ctaContainer}>
        <Typography
          href="https://blog.fleek.co/posts/troubleshooting-dns-domains-record-update-fleek"
          target="_blank"
          rel="noopener noreferrer"
          component="a"
          variant="body2"
        >
          {t("modals.dnsIssues")}
        </Typography>
        <div className={classes.buttonContainer}>
          <GenericButton buttonVariant="secondary" onClick={closeModal}>
            {t("modals.dnsConfiguration.buttons.cancel")}
          </GenericButton>
          <GenericButton
            buttonVariant="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          >
            {t("modals.dnsConfiguration.buttons.verifyDns")}
          </GenericButton>
        </div>
      </div>
    </BaseModal>
  );
};

DnsCnameModal.defaultProps = {
  open: false,
  domain: "domain",
  terminalRecord: "",
  pullZoneUrl: "",
};

DnsCnameModal.propTypes = {
  domain: PropTypes.string,
  open: PropTypes.bool,
  terminalRecord: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  proxyType: PropTypes.string.isRequired,
  pullZoneUrl: PropTypes.string,
};
