import { LOCAL_STORAGE_KEYS } from "src/constants";

import { UPDATE_LOCAL_STORAGE } from "./actions/local-storage";

const defaultState = {
  seenNewStorageFeature: localStorage.getItem(LOCAL_STORAGE_KEYS.seenNewStorageFeature),
};

const localStorageReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_LOCAL_STORAGE:
      return action.payload

    default:
      return state;
  }
};

export default localStorageReducer;
