import gql from 'graphql-tag';

import { BUCKET_DETAIL } from '../fragments';

export default gql`
  mutation removeStorageCustomDomain($input: RemoveStorageCustomDomainInput!) {
    removeStorageCustomDomain(input: $input) {
      ...BucketDetail
    }
  }
  ${BUCKET_DETAIL}
`;
