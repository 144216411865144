import { dripStitches, Grid } from '@fleekhq/react-drip';

const { styled } = dripStitches;

export const ProfileGrid = styled(Grid, {
  position: 'absolute',
  gap: '$4',
  maxWidth: '$xs',
  width: '$xs',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '$8',
  backgroundColor: '$contrast',
  borderRadius: '$xl',
  fontFamily: 'Manrope',
  "* > svg": { width: "auto", height: "auto" },
  '& h1, h2, h3, h4, h5, h6, p': { margin: 0 },
});
