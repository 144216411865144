import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton/GenericButton";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteBillingInformationModal } from "src/modals";

import useStyles from "./styles";

const DeleteBillingDetails = ({ 
  billingInformation,
  paymentMethod,
  refetchTeamBillingInfo, 
  teamId 
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showDeleteModal, setDeleteModal] = useState(false);
  
  const onDeleteInfo = () => {
    setDeleteModal(true);
  };

  return (
    <>
      {showDeleteModal && (
        <DeleteBillingInformationModal
          open
          onClose={() => setDeleteModal(false)}
          onSuccess={refetchTeamBillingInfo}
          teamId={teamId}
        />
      )}
      <CardTitled
        mainContent={t("billingDetails.deleteBillingDetails.title")}
        classes={{ content: classes.root }}
      >
        <Typography variant="body2">
          {t("billingDetails.deleteBillingDetails.subtitle")}
        </Typography>
        <GenericButton
          overrideClass={{
            button: classes.button,
          }}
          buttonVariant="important"
          onClick={onDeleteInfo}
          disabled={isEmpty(billingInformation) && isEmpty(paymentMethod)}
        >
          <Typography variant="body2">
            {t("billingDetails.deleteBillingDetails.DeleteBilling")}
          </Typography>
        </GenericButton>
      </CardTitled>
    </>
  );
};

DeleteBillingDetails.propTypes = {
  billingInformation: PropTypes.object,
  paymentMethod: PropTypes.object,
  refetchTeamBillingInfo: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
};

export default DeleteBillingDetails;
