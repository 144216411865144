import * as Sentry from "@sentry/react";
import { onError } from "apollo-link-error";
import {
  GQLError,
  GQLNetworkError,
  GQLUnauthorizedError,
} from "src/components/error";
import { url } from "src/utils";

const NO_ACCESS_ERROR_TYPE = "Unauthorized";
const AUTHENTICATION_ERRORS = ["Token has expired.", "Unauthorized"];

/* eslint-disable no-console */
export default onError((testErr) => {
  const { operation, networkError, graphQLErrors } = testErr;

  if (networkError) {
    const message = `[Network error - ${networkError.statusCode}] Operation: ${operation.operationName}, Message: ${networkError.message} - `;

    Sentry.captureException(
      new GQLNetworkError(
        networkError.message,
        operation.operationName,
        operation.variables,
        "NetworkError",
        networkError.statusCode
      )
    );
    console.error(message);
  }

  if (graphQLErrors) {
    let isUnauthorized = false;
    let isTokenExpired = false;
    let errorParams = [];

    graphQLErrors.forEach((gqlError) => {
      const { path, message, errorType, locations } = gqlError;

      const errMessage = `[GraphQL error]: Operation: ${
        operation.operationName
      }, Message: ${message}, Location: ${JSON.stringify(
        locations
      )}, Path: ${path}`;

      console.error(errMessage);

      isUnauthorized = errorType === NO_ACCESS_ERROR_TYPE;
      isTokenExpired = AUTHENTICATION_ERRORS.includes(message);

      errorParams = [
        message,
        operation.operationName,
        operation.variables,
        errorType,
        200,
      ];
    });

    if (isUnauthorized) {
      Sentry.captureException(new GQLUnauthorizedError(...errorParams), {
        tags: { scope: "sites", method: "gql" },
      });
      window.location.hash = url.buildUrl(null, "/error/404");
      return;
    }

    if (isTokenExpired) {
      window.location.replace("/#/auth/sign-in");
      return;
    }

    Sentry.captureException(new GQLError(...errorParams), {
      tags: { scope: "sites", method: "gql" },
    });
  }
});
