import { useQuery } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import IconFA from "@terminal-packages/ui/core/IconFA";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";
import { newApiClient } from "src/clients";
import { AddHnsDomainModal } from "src/modals";
import { GET_SITE_BY_SLUG } from "src/views/Sites/graphql/queries";
import { SITE_BY_SLUG_SUBSCRIPTION } from "src/views/Sites/graphql/subscriptions";

import useStyles from "./styles";

const HNSFooter = ({ domainsLength }) => {
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();
  const match = useRouteMatch();

  const { t } = useTranslation();

  const siteQuery = useQuery(GET_SITE_BY_SLUG, {
    client: newApiClient,
    variables: {
      slug: match.params.siteSlug,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data } = siteQuery;

  const hasFinishedFirstDeploy = !!get(data, "getSiteBySlug.domains", [])?.length;

  useEffect(() => {
    if (hasFinishedFirstDeploy) {
      return;
    }

    const unsubscribe = siteQuery.subscribeToMore({
      document: SITE_BY_SLUG_SUBSCRIPTION,
      variables: {
        slug: match.params.siteSlug,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        return {
          getSiteBySlug: {
            ...prev.getSiteBySlug,
            ...subscriptionData.data.siteSubscription,
          },
        };
      }
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    }
  }, [match.params.siteSlug, siteQuery, hasFinishedFirstDeploy]);
  
  const openAddHnsDomainModal = () => setOpenModal(true);
  const closeAddHnsDomainModal = () => setOpenModal(false);

  const linkDestination = "https://docs.fleek.co/domain-management/hns-domains";
  const linkText = t("sites.tabs.settings.hns.learnAboutHNSDomains");
  const showLink = domainsLength <= 1;

  return (
    <div className={classes.footer}>
      {showLink && (
        <div>
          <a
            href={linkDestination}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="body2" className={classes.docs}>
              {linkText}
            </Typography>
            <IconFA
              className={classes.arrowIcon}
              icon={["fal", "long-arrow-right"]}
              size="inherit"
            />
          </a>
        </div>
      )}
      <div className={classes.button}>
        <GenericButton
          onClick={openAddHnsDomainModal}
          buttonVariant="primary"
          disabled={!hasFinishedFirstDeploy}
        >
          {t("sites.tabs.settings.hns.buttonText")}
        </GenericButton>
        {!hasFinishedFirstDeploy && (
            <Typography variant="caption" color="error">
              {t("sites.tabs.settings.hns.noFirstDeploy")}
            </Typography>
          )}
      </div>
      <AddHnsDomainModal open={openModal} closeModal={closeAddHnsDomainModal} />
    </div>
  );
};

HNSFooter.propTypes = {
  domainsLength: PropTypes.number.isRequired,
};

export default HNSFooter;
