import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { EnhancedNavigationList } from "src/components";
import { url } from "src/utils";
import { scrollToId } from "src/utils";
import getSourceType from "src/utils/get-source-type";

import {
  ContinuousDeployment,
  DomainManagement,
  General,
} from "./components/Sections";
import getNavigationItems, {
  MAIN_SECTIONS,
  SECTION_IDS,
  SUB_SECTIONS,
} from "./get-navigation-items";
import useStyles from "./styles";

const Settings = (props) => {
  const { siteBySlug } = props;
  const platform = get(siteBySlug, "data.getSiteBySlug.platform");
  const classes = useStyles(props);
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const source = get(
    siteBySlug,
    "data.getSiteBySlug.deploySettings.source",
    {}
  );
  const sourceType = getSourceType(source);

  const [activeSectionId, setActiveSectionId] = useState(match.params.section);
  const items = getNavigationItems(t, activeSectionId, platform, sourceType);

  useEffect(() => {
    window.analytics.page("Site - Settings", {
      path: location.pathname,
      search: location.search,
    });

    const isSubSection = SUB_SECTIONS.includes(match.params.section);
    const isMainSection = MAIN_SECTIONS.includes(match.params.section);
    if (isSubSection) {
      scrollToId(match.params.section);
    }
    if (!isSubSection && !isMainSection) {
      history.push(
        `/sites/${match.params.siteSlug}/settings/${SECTION_IDS.GENERAL}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItem = (node, { id, type }) => (
    <Link
      to={url.buildUrl(null, `/sites/${match.params.siteSlug}/settings/${id}`)}
      className={classes.navigationLink}
      onClick={() => {
        setActiveSectionId(id);
        if (type === "subsection") {
          scrollToId(id);
        }
      }}
    >
      {node}
    </Link>
  );

  const getSettings = () => {
    switch (match.params.section) {
      case SECTION_IDS.GENERAL:
      case SECTION_IDS.SITE_DETAILS:
      case SECTION_IDS.DANGER_ZONE:
      case SECTION_IDS.OTHER_SETTINGS:
        return <General siteBySlug={siteBySlug} />;
      case SECTION_IDS.BUILD_AND_DEPLOY:
      case SECTION_IDS.CONTINUOUS_DEPLOYMENT:
      case SECTION_IDS.ADVANCED_BUILD_SETTINGS:
      case SECTION_IDS.SITE_MANAGEMENT:
        return (
          <ContinuousDeployment
            siteBySlug={siteBySlug}
            setActiveSectionId={setActiveSectionId}
          />
        );
      case SECTION_IDS.DOMAIN_MANAGEMENT:
      case SECTION_IDS.CUSTOM_DOMAINS:
      case SECTION_IDS.ENS:
      case SECTION_IDS.HNS:
      case SECTION_IDS.TTL:
      default:
        return (
          <DomainManagement
            siteBySlug={siteBySlug}
            setActiveSectionId={setActiveSectionId}
          />
        );
    }
  };

  return (
    <section className={classes.root}>
      <EnhancedNavigationList items={items} renderItem={renderItem} />
      <div className={classes.content}>{getSettings()}</div>
    </section>
  );
};

Settings.defaultProps = {
  siteBySlug: {
    loading: true,
    data: null,
    error: null,
  },
};

Settings.propTypes = {
  siteBySlug: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.object,
    error: PropTypes.object,
  }),
};

export default Settings;
