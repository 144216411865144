import { INJECTED_PROVIDER_NAMES, WEB3_PROVIDER_NAMES } from "./constants";

export const getWeb3ErrorMessage = (error) => {
  switch (error?.code) {
    case 4001:
      return "User declined the transaction";
    default:
      return error.message || "Unknown error";
  }
};

export const getHCaptchaErrorMessage = (code) => {
  switch (code) {
    case "missing-input-response":
    case "invalid-input-response":
      return "Error while authenticating. Try again, or contact support.";
    case "missing-input-secret":
    case "invalid-input-secret":
    case "no-error-code":
    default:
      return "A server error occurred, sorry for the inconvenience - please try again later";
  }
};

export const getFirebaseSignInWithPopupErrorMessage = (error) => {
  switch (error.code) {
    case "auth/user-cancelled":
      return "User cancelled to give permission.";
    case "auth/account-exists-with-different-credential":
      return "This GitHub email is already in use on an existing Fleek account. The email provided is invalid. Review it and try again.";
    case "auth/auth-domain-config-required":
      return "Authentication failed. Try again, or contact support.";
    case "auth/cancelled-popup-request":
      return "A new approval pop-up has been triggered and this one has expired.";
    case "auth/popup-blocked":
      return "The authentication pop-up was blocked by your browser. Please review your settings to continue.";
    case "auth/popup-closed-by-user":
      return "The authentication pop-up was closed by the user before completing the sign-in process.";
    case "auth/operation-not-allowed":
    case "auth/operation-not-supported-in-this-environment":
    case "auth/unauthorized-domain":
      return "Authentication failed. Try again, or contact support.";
    default:
      return "A server error occurred, sorry for the inconvenience - please try again later.";
  }
};

export const getFirebaseSignInWithCustomTokenErrorMessage = (error) => {
  switch (error.code) {
    case "auth/custom-token-mismatch":
    case "auth/user-disabled":
      return "Authentication failed. Try again, or contact support.";
    default:
      return "A server error occurred - please try again later, we’re sorry for the inconvenience.";
  }
};

export const getFirebaseSignInWithEmailAndPasswordErrorMessage = (error) => {
  switch (error.code) {
    case "auth/invalid-email":
      return "The email or password provided is invalid. Review it and try again.";
    case "auth/user-disabled":
      return "Authentication failed. Try again, or contact support.";
    case "auth/user-not-found":
      return "The email entered is not valid or does not represent an existing account. Try again.";
    case "auth/wrong-password":
      return "The email or password provided is invalid. Review it and try again.";
    default:
      return "A server error occurred - please try again later, we’re sorry for the inconvenience.";
  }
};

export const getFirebaseCreateUserWithEmailAndPasswordErrorMessage = (
  error
) => {
  switch (error.code) {
    case "auth/email-already-in-use":
      return "The email provided is already in use by an existing user.";
    case "auth/invalid-email":
      return "The email provided is invalid. Review it and try again.";
    case "auth/operation-not-allowed":
      return "Authentication failed. Try again, or contact support.";
    case "auth/weak-password":
      return "Password should contain at least 6 characters.";
    default:
      return "A server error occurred - please try again later, we’re sorry for the inconvenience.";
  }
};

export const getFirebaseSendEmailVerificationErrorMessage = (error) => {
  switch (error.code) {
    default:
      return "A server error occurred - please try again later, we’re sorry for the inconvenience.";
  }
};

export const getFirebaseSendPasswordResetEmailErrorMessage = (error) => {
  switch (error.code) {
    case "auth/invalid-email":
    case "auth/user-not-found":
      return "The email entered is not valid or does not represent an existing account. Try again.";
    default:
      return "A server error occurred - please try again later, we’re sorry for the inconvenience.";
  }
};

export const getWeb3ProviderType = (providerName) => {
  switch (providerName) {
    case INJECTED_PROVIDER_NAMES.MetaMask:
    case INJECTED_PROVIDER_NAMES.Frame:
      return WEB3_PROVIDER_NAMES.Extension;
    default:
      return providerName;
  }
};

export const getWeb3ProviderName = ({ type, name }) => {
  switch (type) {
    case WEB3_PROVIDER_NAMES.Extension:
      return name ? name : undefined;
    default:
      return type;
  }
};
