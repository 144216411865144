import { Box } from "@fleekhq/react-drip";
import { ToastContainer } from "@terminal-packages/ui/core/Toast";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AuthHeader, LoadingScreen } from "src/components";

import { projectAuth } from "../../firebase";
import { ContentWrapper } from "../Authentication/styled";
import {
  NewPassword,
  VerificationFailed,
  VerificationSuccess,
} from "./components";

const VerificationCallback = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [state, setState] = useState({
    actionType: null,
    actionCode: null,
    verificationStatus: "validating",
    validating: true,
  });

  useEffect(() => {
    const { mode, oobCode } = qs.parse(location.search);

    const validateActionCode = async () => {
      try {
        const result = await projectAuth.checkActionCode(oobCode);

        if (result) {
          if (mode === "resetPassword") {
            setState({
              ...state,
              actionType: mode,
              actionCode: oobCode,
              verificationStatus: "setPassword",
              validating: false,
            });
          } else {
            await projectAuth.applyActionCode(oobCode);
            setState({
              ...state,
              actionType: mode,
              verificationStatus: "success",
              validating: false,
            });
          }
        }
      } catch (error) {
        setState({
          ...state,
          actionType: mode,
          actionCode: oobCode,
          verificationStatus: "failed",
          validating: false,
        });
      }
    };

    validateActionCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusUpdate = () => {
    setState({
      ...state,
      actionType: "resetPassword",
      verificationStatus: "success",
      validating: false,
    });
  };

  return (
    <Box css={{ backgroundColor: "$slate1", fontFamily: "Manrope" }}>
      <Helmet>
        <title>{t("verification.verificationTitle")}</title>
      </Helmet>
      {state.validating ? (
        <LoadingScreen />
      ) : (
        <>
          <AuthHeader />
          <ContentWrapper>
            {state.verificationStatus === "setPassword" && (
              <NewPassword
                handleStatusUpdate={handleStatusUpdate}
                actionCode={state.actionCode}
              />
            )}
            {state.verificationStatus === "verifyEmail" && (
              <div>Your email was verified</div>
            )}
            {state.verificationStatus === "success" && (
              <VerificationSuccess actionType={state.actionType} />
            )}
            {state.verificationStatus === "failed" && <VerificationFailed />}
          </ContentWrapper>
        </>
      )}
      <ToastContainer />
    </Box>
  );
};

export default VerificationCallback;
