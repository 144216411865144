import { dripStitches, Grid } from '@fleekhq/react-drip';

const { styled } = dripStitches;

export const AuthContainer = styled(Grid, {
  gap: '$4',
  flexDirection: 'column',
  width: '$sm',
  boxShadow: '$lg',
  padding: '$6',
  borderRadius: '$xl',
  backgroundColor: '$contrast',
  position: 'relative',
});

export const ContentWrapper = styled('div', {
  pointerEvents: 'none',
  position: 'absolute',
  top: '80px',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: 'calc(100vh - 160px)',
  '& > *': {
    pointerEvents: 'auto',
  },
  '& svg': {
    width: 'auto',
    height: 'auto',
  },
  '& h1, h2, h3, h4, h5, h6, p': {
    margin: 0,
  },
});
