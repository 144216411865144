// @ts-nocheck
import ButtonBase from "@material-ui/core/ButtonBase";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import IconFA from "@terminal-packages/ui/core/IconFA";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openModal, UPLOAD_ENV_FILE } from "src/modals";

import EnvRow from "./components/EnvRow";
import useStyles from "./styles";

let nextEnvRowId = 0;
const getNextEnvRowId = () => {
  nextEnvRowId += 1;
  return nextEnvRowId;
};

const containsOnlyAllowedChars = /^[a-zA-Z0-9_]*$/;
const startsWithLetter = /^[a-zA-Z]/;

const getErrorMessage = (variable, t, allVariables) => {
  const translationPrefix = "sites.start.buildOptions.envVars.errors.";
  if (!variable.name && !variable.value) {
    return null;
  }
  if (!variable.name && variable.value) {
    return t(`${translationPrefix}emptyName`);
  }
  if (!containsOnlyAllowedChars.test(variable.name)) {
    return t(`${translationPrefix}onlyAlphaNumericAndUnderscore`);
  }
  if (!startsWithLetter.test(variable.name)) {
    return t(`${translationPrefix}startWithLetter`);
  }

  const sameNameVars = allVariables.filter(v => v.name === variable.name).length;
  if (sameNameVars > 1) {
    return t(`${translationPrefix}duplicated`);
  }
  return null;
};

export const EnvironmentVariables = ({ variables, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const variablesWithId = useMemo(() => {
    if (!variables.length) {
      return [{ id: 0 }]
    }

    return variables.map(v => {
      if (!v.id) {
        return { ...v, id: getNextEnvRowId() }
      }
      return v;
    })
  }, [variables]);

  const validateVariables = (variableList) => {
    return variableList.map((variable) => {
      return {
        ...variable,
        errorMessage: getErrorMessage(variable, t, variableList),
      };
    });
  }

  const onChangeInput = ({ id, fieldKey, fieldValue, index }) => {
    let newEnvVarsList = [...variablesWithId];
    newEnvVarsList[index] = {
      ...newEnvVarsList[index],
      [fieldKey]: fieldValue,
    };

    const validatedList = validateVariables(newEnvVarsList)

    onChange(validatedList);
  };

  const onNewVariable = () => {
    onChange([...variablesWithId, { id: getNextEnvRowId() }]);
  };

  const onSelectUpload = () => {
    dispatch(openModal(UPLOAD_ENV_FILE , { onUpload }));
  }

  const onUpload = (uplodedVars) => {
    let editedVars = [...variables];
    const newVars = [];
    uplodedVars.forEach(variable => {
      // If there's only an empty input, replace it with the first variable
      if (editedVars.length === 1 && !editedVars[0].name) {
        editedVars[0] = { id: getNextEnvRowId(), ...variable };
      } else {
        newVars.push({ ...variable });
      }
    })

    const mergedVars = [...editedVars, ...newVars];
    const updatedVars = mergedVars.map((variable) => {
      return {
        ...variable,
        errorMessage: getErrorMessage(variable, t, mergedVars),
      };
    });

    onChange(updatedVars);
  }

  const onDeleteVariable = (env) => {
    // Avoid erasing the empty field
    if (!env.name && !env.value) {
      return;
    }

    const newVars = variablesWithId.filter(({ id }) => id !== env.id);

    // Always show an empty row
    if (newVars.length === 0) {
      newVars.push({ id: 0 })
    }

    const validatedList = validateVariables(newVars)
    onChange(validatedList);
  };

  return (
    <div>
      {variablesWithId.map((env, index) => (
        <div
          className={classes.inputContainer}
          key={env.id}
        >
          <EnvRow classes={classes} env={env} onChange={onChangeInput} index={index} />
          <ButtonBase
            onClick={() => onDeleteVariable(env)}
            className={classes.iconContainer}
          >
            <IconFA icon={["fal", "times-circle"]} />
          </ButtonBase>
          <p className={classes.errorMessage}>{env.errorMessage}</p>
        </div>
      ))}
      <GenericButton onClick={onNewVariable} buttonVariant="secondary" className={classes.newVarButton}>
        {t("sites.start.buildOptions.envVars.newVar")}
      </GenericButton>
      <GenericButton type="button" onClick={onSelectUpload} buttonVariant="secondary">
        {t("sites.start.buildOptions.envVars.uploadFile")}
      </GenericButton>
    </div>
  );
};

EnvironmentVariables.defaultProps = {
  variables: [],
  onChange: () => {},
};

EnvironmentVariables.propTypes = {
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};
