import { DEPLOY_STATUS } from "src/constants";

const TIMEOUT_LIMIT_MINUTES = 20;

const getDeployStatus = ({ status, startedAt }) => {
  const currentTime = new Date().getTime();
  const startedAtTime = new Date(startedAt).getTime();
  const timeElapsed = currentTime - startedAtTime;
  const minutesElapsed = timeElapsed / (1000 * 60);

  if (
    status === DEPLOY_STATUS.IN_PROGRESS &&
    minutesElapsed > TIMEOUT_LIMIT_MINUTES
  ) {
    return DEPLOY_STATUS.TIMEOUT;
  }

  return status;
};

export default getDeployStatus;
