import { Button, Typography } from "@fleekhq/react-drip";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AuthContainer } from "src/views/Authentication/styled";

const VerificationSuccess = ({ actionType }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const redirectToApp = useCallback(() => {
    history.push("/");
    window.location.reload();
  }, [history]);

  useEffect(() => {
    const timer = setTimeout(redirectToApp, 5000);

    return function timeoutCleanup() {
      clearTimeout(timer);
    };
  }, [redirectToApp]);

  return (
    <AuthContainer>
      {actionType === "revertSecondFactorAddition" ? (
        <Typography as="h2" fontSize="lg" fontWeight="bold">
          {t("verification.twoFaDisabledTitle")}
        </Typography>
      ) : (
        <Typography as="h2" fontSize="lg" fontWeight="bold">
          {t("verification.congrats")}
        </Typography>
      )}
      <Typography css={{ color: "$slate11" }}>
        {actionType === "revertSecondFactorAddition" &&
          t("verification.twoFaDisabledText")}
        {actionType === "resetPassword" &&
          t("verification.passwordUpdatedText")}
        {actionType === "verifyEmail" && t("verification.emailVerifiedText")}
      </Typography>

      <Typography fontSize="sm" css={{ color: "$slate11" }}>
        You will be redirected to the{" "}
        {actionType === "resetPassword" ? "sign in page" : "application"} in 5
        seconds.
      </Typography>
      <Button colorScheme="blue" onClick={redirectToApp}>
        {t("verification.done")}
      </Button>
    </AuthContainer>
  );
};

VerificationSuccess.propTypes = {
  actionType: PropTypes.string.isRequired,
};

export default VerificationSuccess;
