import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { ObjectDetail, ObjectDetailRow } from "src/components";

const FileDetails = ({ loading, details }) => {
  const { t } = useTranslation();

  const detailsEntries = Object.entries(details);

  return (
    <ObjectDetail>
      {detailsEntries.map(([key, value]) => (
        <ObjectDetailRow
          key={key}
          value={value}
          loading={loading}
          field={`${t(`storage.details.${key}`)}:`}
        />
      ))}
    </ObjectDetail>
  );
};

FileDetails.propTypes = {
  loading: PropTypes.bool.isRequired,
  details: PropTypes.shape({
    name: PropTypes.node,
    owner: PropTypes.node,
    created: PropTypes.node,
    size: PropTypes.node,
    hash: PropTypes.node,
    objectUrl: PropTypes.node,
    filecoinID: PropTypes.node,
  }).isRequired,
};

export default FileDetails;
