import gql from 'graphql-tag';

import { SCOPED_API_KEY } from '../fragments/scoped-api-key';

export default gql`
  mutation generateAppApiKey($input: GenerateAppApiKeyInput!) {
    generateAppApiKey(input: $input) {
      ...ScopedApiKeyInfo
    }
  }
  ${SCOPED_API_KEY}
`;
