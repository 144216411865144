// @ts-nocheck
import {
  Alert,
  AuthLayoutProvider,
  AuthLayoutProvidersStep,
  Box,
} from "@fleekhq/react-drip";
import { Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { resetState } from "src/reducers/actions/web3-state";
import { useAuth } from "src/store/AuthContext";
import { CONNECTION_LABELS } from "src/views/Authentication/constants";
import useAuthentication from "src/views/Authentication/hooks/useAuthentication";

export const WalletLinkModal = ({ open, closeModal }) => {
  const dispatch = useDispatch();
  const { walletConnect } = useAuth();
  const {
    alertData,
    providersExtraState,
    authLayoutProps: { methods },
  } = useAuthentication({ web3AuthType: "link" });

  const ethereumMethod = useMemo(
    () => methods.find((method) => method.name === "Ethereum"),
    [methods]
  );

  const handleBackdropClick = async () => {
    closeModal();
    dispatch(resetState());
    await walletConnect.disconnect();
  };

  useEffect(() => {
    const isConnected = Object.values(providersExtraState).some(
      (provider) =>
        provider?.connectionLabel === CONNECTION_LABELS.Extension.done
    );
    
    if (isConnected) {
      closeModal();
    }
  }, [closeModal, providersExtraState]);

  return (
    <Modal open={open} onClose={handleBackdropClick}>
      <AuthLayoutProvider
        value={{
          methods: [ethereumMethod],
          providers: ethereumMethod.providers,
          defaultHeading: "Link Wallet",
          selectedMethodName: "Ethereum",
        }}
      >
        <Box
          css={{
            "& h1, h2, h3, h4, h5, h6, p": { margin: 0 },
            "& svg": { width: "auto", height: "auto" },
            fontFamily: "Manrope",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <AuthLayoutProvidersStep />
          {alertData.children && (
            <Alert
              {...alertData}
              css={{
                bottom: "0",
                transform: "translateY(120%)",
                position: "absolute",
              }}
            />
          )}
        </Box>
      </AuthLayoutProvider>
    </Modal>
  );
};

WalletLinkModal.defaultProps = {
  open: false,
  closeModal: () => {},
};

WalletLinkModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};
