import Typography from "@material-ui/core/Typography";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { DOMAIN_TYPE } from "src/constants";

import DomainList from "./components/DomainList";
import Footer from "./components/Footer";
import useStyles from "./styles";

const HnsDomains = ({ siteId, domains, loading, checkHNSOnClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const filteredDomains = domains.filter(
    (domain) => domain.type === DOMAIN_TYPE.HNS
  );

  return (
    <CardTitled
      mainContent={t("sites.tabs.settings.hns.title")}
      classes={{
        content: classes.cardContent,
      }}
    >
      <Typography className={classes.paragraph}>
        {t("sites.tabs.settings.hns.description")}
      </Typography>
      <DomainList
        siteId={siteId}
        domains={filteredDomains}
        loading={loading}
        checkHNSOnClick={checkHNSOnClick}
      />
      <Footer domainsLength={filteredDomains.length} />
    </CardTitled>
  );
};

HnsDomains.defaultProps = {
  siteId: "",
  domains: [],
  checkHNSOnClick: () => {},
};

HnsDomains.propTypes = {
  siteId: PropTypes.string,
  checkHNSOnClick: PropTypes.func,
  domains: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
};

export default HnsDomains;
