import { useQuery } from "@apollo/react-hooks";
import CID from "cids";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { newApiClient } from "src/clients";
import { GET_DEAL_STATUS } from "src/graphql/queries";

import SiteDetails from "./components/Details";
import GettingStarted from "./components/GettingStarted";
import presenter from "./presenter";

const Overview = ({ siteBySlug }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const ipfsHash = get(
    siteBySlug,
    "data.getSiteBySlug.publishedDeploy.ipfsHash"
  );

  const { data: dealData } = useQuery(GET_DEAL_STATUS, {
    client: newApiClient,
    fetchPolicy: "cache-and-network",
    skip: !ipfsHash,
    variables: {
      hash: ipfsHash ? new CID(ipfsHash).toV1().toString() : ipfsHash,
    },
  });

  const details = presenter.getSiteDetails({ t, siteBySlug, dealData });

  React.useEffect(() => {
    window.analytics.page("Site - Overview", {
      path: location.pathname,
      search: location.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="test-sites-overview">
      <GettingStarted siteBySlug={siteBySlug} />
      <SiteDetails
        details={details}
        loading={siteBySlug.loading && !siteBySlug.data}
      />
    </div>
  );
};

Overview.propTypes = {
  siteBySlug: PropTypes.shape({
    data: PropTypes.object,
    error: PropTypes.object,
    loading: PropTypes.bool,
  }).isRequired,
};

export default Overview;
