import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  thinnerDescription: {
    paddingRight: 105,
  },
  description: {
    display: 'none',
  },
  backdrop: {
    zIndex: 7,

    '& > div > img': {
      display: 'none',
    },

    '& > div > div': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridAutoRows: 'auto',
    },

    '& > div > div > *': {
      gridColumn: '1 / -1',
    },


    '& > div > div > *:nth-last-child(2)': {
      gridRow: '3',
      gridColumn: '2',
      margin: '10px 0 0 auto',
      height: '100%',
    },

    '& > div > div > *:last-child': {
      marginTop: '20px',
      gridRow: '3',
      gridColumn: '1',
    },
  },
}));
