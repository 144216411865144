import { useMutation } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { toast } from "@terminal-packages/ui";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { newApiClient } from "src/clients";
import { Tag } from "src/components";
import {
  DOMAIN_STATUS,
  GA_EVENTS_CATEGORIES,
  PROXY_TYPES,
} from "src/constants";
import { DNS_CNAME_MODAL, openModal } from "src/modals";

import { GET_BUCKET_BY_SLUG } from "../../graphql";
import { VERIFY_STORAGE_DNS } from "../../graphql/mutations";
import Domain from "./components/Domain";
import DomainsFooter from "./components/DomainFooter";
import useStyles from "./styles";

export type BucketType = {
  hash: string;
  slug: string;
  createdAt: string;
  pullZoneUrl?: string;
  domain?: string;
  domainStatus?: keyof typeof DOMAIN_STATUS;
};

export type CustomDomainProps = {
  isLoading?: boolean;
  bucket?: BucketType | null;
};

export const CustomDomain = ({ bucket, isLoading }: CustomDomainProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {  description } = useMemo(() => {
    const domain = bucket?.domain;
    const isDomainVerified = bucket?.domainStatus === "PROPAGATED";

    const description = t(
      !domain
        ? "storage.domain.descriptionAdd"
        : !isDomainVerified
        ? "storage.domain.descriptionVerify"
        : "storage.domain.descriptionAdded"
    ) as string;

    return { domain, isDomainVerified, description };
  }, [bucket, t]);

  const [verifyStorageDns] = useMutation(VERIFY_STORAGE_DNS, {
    client: newApiClient as any,
    update: (cache, { data: { verifyStorageDns } }) => {
      const data = cache.readQuery({
        query: GET_BUCKET_BY_SLUG,
        variables: {
          slug: bucket?.slug,
        },
      });

      cache.writeQuery({
        query: GET_BUCKET_BY_SLUG,
        variables: {
          slug: bucket?.slug,
        },
        data: {
          ...(data as any),
          getBucketBySlug: {
            ...verifyStorageDns,
          },
        },
      });
    },
  });

  const handleVerifyStorageDns = async (bucketSlug?: string) => {
    if (!bucketSlug) {
      return false;
    }

    window.ga(
      "send",
      "event",
      GA_EVENTS_CATEGORIES.STORAGE,
      "Verify Storage DNS configuration"
    );

    const eventProperties = {
      bucketSlug,
      error: null,
    };

    try {
      await verifyStorageDns({
        variables: {
          input: {
            bucketSlug,
          },
        },
      });

      toast.success(t("addStorageCustomDomain.verifySuccess") as string, {
        autoClose: 6000,
      });

      window.analytics.track(
        "Verify Storage DNS configuration",
        eventProperties
      );
    } catch (error: any) {
      eventProperties.error = error.message;
      window.analytics.track(
        "Failed Verify Storage DNS configuration",
        eventProperties
      );
      // eslint-disable-next-line no-console
      console.error(error);

      return false;
    }

    return true;
  };

  const openDnsModal = () => {
    window.ga(
      "send",
      "event",
      GA_EVENTS_CATEGORIES.STORAGE,
      "Check Storage DNS Configuration"
    );
    window.analytics.track("Check Storage DNS Configuration", {
      domain: bucket?.domain,
    });

    dispatch(
      openModal(DNS_CNAME_MODAL, {
        onSubmit: () => handleVerifyStorageDns(bucket?.slug),
        domain: bucket?.domain,
        proxyType: PROXY_TYPES.BUNNY,
        pullZoneUrl: bucket?.pullZoneUrl,
      })
    );
  };


  return (
    // @ts-ignore
    <CardTitled
      mainContent={
        <div className={classes.mainContentBox}>
          <Typography className={classes.mainContentTitle}>
            {t("storage.domain.title") as string}
          </Typography>
        </div>
      }
      classes={{ root: classes.cardRoot }}
      secondaryContent={
        <div className={classes.secondaryWrapper}>
          <Tag>New Feature</Tag>
        </div>
      }
    >
      {isLoading ? (
        <div>
          <Skeleton width={320} height={30} />
          <Skeleton width={240} height={30} />
          <Skeleton width={120} height={60} />
        </div>
      ) : (
        <>
          <Typography className={classes.paragraph}>{description}</Typography>

          <Domain bucket={bucket} verifyDns={openDnsModal} isLoading={false} />
          {!bucket?.domain && <DomainsFooter />}
        </>
      )}
    </CardTitled>
  );
};
