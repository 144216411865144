// @ts-nocheck
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@terminal-packages/ui/core/Box";
import IconFA from "@terminal-packages/ui/core/IconFA";
import classnames from "classnames";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  DEPLOY_STATUS,
  DOMAIN_TYPE,
  GA_EVENTS_CATEGORIES,
  SOURCE_TYPES,
} from "src/constants";
import { getGatewayUrl, url } from "src/utils";
import getSourceType from "src/utils/get-source-type";

import presenter from "./presenter";
import useStyles from "./styles";

const IMG_LOGO = {
  ipfs: "https://storage.googleapis.com/terminal-assets/images/wallets/ipfs.svg",
  dfinity:
    "https://storage.googleapis.com/terminal-assets/images/hosting_services/dfinity.png",
};

export const SitesCard = ({ siteBySlug }) => {
  const { t } = useTranslation();
  const siteCard = presenter.getSiteCard({ t, siteBySlug });
  const source = get(
    siteBySlug,
    "data.getSiteBySlug.deploySettings.source",
    {}
  );
  const sourceType = getSourceType(source);

  const classes = useStyles(siteCard);

  const getContent = () => {
    if (siteBySlug.loading && !siteBySlug.data) {
      return (
        <>
          <div className={classes.siteInfo}>
            <div className={classes.header}>
              <Skeleton
                width={224}
                height={29}
                variant="rect"
                animation="wave"
                classes={{
                  root: classes.skeletonRoot,
                }}
              />
              <Skeleton
                width={224}
                height={22}
                variant="rect"
                animation="wave"
                className={classes.published}
                classes={{
                  root: classes.skeletonRoot,
                }}
              />
            </div>
            <Skeleton
              width={138}
              height={22}
              variant="rect"
              animation="wave"
              classes={{
                root: classes.skeletonRoot,
              }}
            />
            <Skeleton
              width={279}
              height={22}
              variant="rect"
              animation="wave"
              classes={{
                root: classes.skeletonRoot,
              }}
            />
            <Skeleton
              width={459}
              height={22}
              variant="rect"
              animation="wave"
              classes={{
                root: classes.skeletonRoot,
              }}
            />
          </div>
          <Skeleton
            width={234}
            height={132}
            variant="rect"
            animation="wave"
            classes={{
              root: classes.skeletonRoot,
            }}
          />
        </>
      );
    }

    const getDomainOrStatus = () => {
      if (
        siteCard.status === DEPLOY_STATUS.DEPLOYED &&
        siteCard.defaultDomain !== ""
      ) {
        return (
          <div className={classes.domainContainer}>
            <a
              href={`https://${siteCard.defaultDomain}`}
              target="_blank"
              rel="noopener noreferrer"
              className={classnames(
                classes.linkStyleReset,
                classes.coloredHover
              )}
              onClick={() => {
                let domainKey;
                let eventDescription;

                if (
                  siteCard.defaultDomaintype === DOMAIN_TYPE.DEFAULT_SUBDOMAIN
                ) {
                  domainKey = "autogeneratedDomain";
                  eventDescription = "Autogenerated subdomain click";
                } else {
                  domainKey = "domain";
                  eventDescription = "Propagated custom domain click";
                }

                window.ga(
                  "send",
                  "event",
                  GA_EVENTS_CATEGORIES.SITES,
                  eventDescription,
                  `https://${siteCard.defaultDomain}`
                );
                window.analytics.track(eventDescription, {
                  siteId: siteCard.siteId,
                  teamId: url.getAccountIdFromUrl(),
                  [domainKey]: siteCard.defaultDomain,
                });
              }}
            >
              <Typography variant="body2" className={classes.domain}>
                {siteCard.defaultDomain}
              </Typography>
            </a>
            {siteCard.platform === "dfinity" && (
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.dfinityTag}
              >
                {siteCard.definityTag}
              </Typography>
            )}
          </div>
        );
      }

      return (
        <Typography variant="body2" className={classes.domain}>
          {t(
            `sites.siteHeader.deploy.${siteCard.status}`,
            siteCard.defaultDomain
          )}
        </Typography>
      );
    };

    const getIpfsOrCanister = () => {
      const key =
        siteCard.platform === "ipfs" ? siteCard.ipfsHash : siteCard.canisterId;

      if (!key) {
        return (
          <Typography variant="body2" className={classes.ipfsPending}>
            {t(`sites.siteHeader.${siteCard.platform}.pending`)}
          </Typography>
        );
      }

      if (siteCard.platform === "ipfs") {
        return (
          <a
            href={getGatewayUrl(siteCard.ipfsHash)}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.ipfs}
          >
            <Typography variant="body2">
              {t("sites.siteHeader.ipfs.verify")}
            </Typography>
          </a>
        );
      }

      return (
        <Typography variant="body2">
          {t("sites.siteHeader.dfinity.verify", { id: siteCard.canisterId })}
        </Typography>
      );
    };

    const getPreview = () => {
      if (siteCard.preview && siteCard.preview !== "") {
        return (
          <img
            className={classes.image}
            src={siteCard.preview}
            alt="Site preview"
          />
        );
      }

      return <IconFA icon={["fal", "image"]} />;
    };

    return (
      <>
        <div className={classes.siteInfo}>
          <div className={classes.header}>
            <Typography noWrap variant="h6" color="textPrimary">
              {siteCard.title}
            </Typography>
            <Typography variant="body2" className={classes.published}>
              {siteCard.subtitle}
            </Typography>
          </div>
          {getDomainOrStatus()}
          {sourceType === SOURCE_TYPES.GITHUB && (
            <div>
              <IconFA icon={["fab", "github"]} className={classes.repoIcon} />
              <a
                href={siteCard.repositoryUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.repo}
              >
                <Typography variant="body2">{siteCard.repository}</Typography>
              </a>
            </div>
          )}
          {sourceType === SOURCE_TYPES.LOCAL && (
            <div className={classes.repoContainer}>
              <IconFA icon={["fal", "hdd"]} className={classes.repoIcon} />
              <Typography variant="body2">{siteCard.localDeploy}</Typography>
            </div>
          )}
          <div className={classes.ipfsContainer}>
            <img
              src={IMG_LOGO[siteCard.platform]}
              className={classes.platformIcon}
              alt={`${siteCard.platform} Logo`}
            />
            {getIpfsOrCanister()}
          </div>
        </div>
        <div className={classes.preview}>{getPreview()}</div>
      </>
    );
  };

  return (
    <Box>
      <div className={classes.siteContent}>{getContent()}</div>
    </Box>
  );
};

SitesCard.defaultProps = {
  siteBySlug: {
    data: null,
    error: null,
    loading: false,
  },
};

SitesCard.propTypes = {
  siteBySlug: PropTypes.shape({
    data: PropTypes.object,
    error: PropTypes.object,
    loading: PropTypes.bool,
  }),
};
