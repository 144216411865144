import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton/GenericButton";
import IconFA from "@terminal-packages/ui/core/IconFA";
import React from "react";

import { MenuDropdown } from "../menu-dropdown";
import useStyles from "./styles";

export type ActionsButtonProps = {
  btnName: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

export const ActionsButton = ({
  btnName,
  disabled = false,
  children = null,
}: ActionsButtonProps) => {
  const classes = useStyles();

  return (
    <MenuDropdown
      offsetTop={7}
      className={classes.list}
      menuId="actions-menu-dropdown"
      trigger={
        <GenericButton
          disableRipple
          disableFocusRipple
          disabled={disabled}
          buttonVariant="secondary"
          overrideClass={{
            button: `${disabled ? classes.disabledButton : classes.button}`,
          }}
          css={undefined}
        >
          <div className={classes.buttonContent}>
            <span>{btnName}</span>
            <IconFA icon={["fal", "angle-down"]} />
          </div>
        </GenericButton>
      }
    >
      {children}
    </MenuDropdown>
  );
};
