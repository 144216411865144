// @ts-nocheck
import {
  Avatar,
  Button,
  CloseButton,
  Grid,
  Typography,
} from "@fleekhq/react-drip";
import { Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IoExit, IoSettings } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearS3Client, newApiClient } from "src/clients";
import { LOGIN_PROVIDER_NAMES } from "src/constants";
import { projectAuth } from "src/firebase";
import { resetState } from "src/reducers/actions/web3-state";
import { useAuth } from "src/store/AuthContext";
import { url } from "src/utils";

import { ProfileGrid } from "./styled";

export const ProfileModal = ({ open, closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    walletConnect,
    displayName,
    currentUser = {},
    isWeb3Provider,
  } = useAuth();
  const { individual, providerData } = currentUser || {};
  const history = useHistory();

  function handleGoToProfile() {
    history.push(url.buildUrl(null, "/settings/general/profile"));
    closeModal();
  }

  async function handleSignOut() {
    await newApiClient.resetStore();
    clearS3Client();
    
    try {
      await projectAuth.signOut();
      await walletConnect.disconnect();
      dispatch(resetState());
    } catch (error) {
      console.error(`Sign out failed: ${error}`);
    }
    closeModal();
  }

  const { title, subtitle, buttonLabel } = useMemo(() => {
    return {
      title: displayName,
      subtitle:
        providerData?.reduce((accumulator, currentProvider) => {
          const providerName = LOGIN_PROVIDER_NAMES[currentProvider.providerId];

          const text =
            providerName === "Email"
              ? `${currentProvider.email}`
              : providerName || "Web3 Provider";

          if (accumulator.length === 0) {
            return text;
          }

          return `${accumulator}, ${text}`;
        }, "") || "Web3 Provider",
      buttonLabel: isWeb3Provider
        ? t("layout.userMenu.disconnect")
        : t("layout.userMenu.signOut"),
    };
  }, [displayName, providerData, isWeb3Provider, t]);

  return (
    <Modal open={open} onClose={closeModal}>
      <ProfileGrid>
        <CloseButton
          size="sm"
          css={{ position: "absolute", right: "$4", top: "$4" }}
          onClick={closeModal}
        />
        <Grid css={{ justifyItems: "center", gap: "$4" }}>
          <Avatar
            src={individual?.profilePicUrl}
            fallback={title}
            size="2xl"
            isRound
          />
          <Grid css={{ justifyItems: "center", gap: "$1", mb: "$2" }}>
            <Typography
              as="h3"
              fontWeight="bold"
              fontSize="xl"
              css={{ wordBreak: "break-word", textAlign: "center" }}
            >
              {title}
            </Typography>
            <Typography as="h6" fontSize="md" css={{ color: "$slate11" }}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          css={{
            gridTemplateColumns: "1fr 1fr",
            gridAutoFlow: "column",
            gap: "$4",
          }}
        >
          <Button
            onClick={handleGoToProfile}
            variant="outline"
            topIcon={<IoSettings size={18} />}
          >
            {t("layout.userMenu.settings")}
          </Button>
          <Button
            onClick={handleSignOut}
            variant="outline"
            topIcon={<IoExit size={18} />}
          >
            {buttonLabel}
          </Button>
        </Grid>
      </ProfileGrid>
    </Modal>
  );
};

ProfileModal.defaultProps = {
  open: false,
  closeModal: () => {},
};

ProfileModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};
