import ListItem from "@terminal-packages/ui/core/ListItem";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { DOMAIN_STATUS, DOMAIN_TYPE, PROXY_TYPES } from "src/constants";

import DomainInfo from "../DomainInfo";
import DomainOptions from "../DomainOptions";
import DomainStatus from "../DomainStatus";
import useStyles from "./styles";

const Domain = ({
  siteId,
  domain,
  checkDNSOnClick,
  defaultDomain,
  platform,
}) => {
  const classes = useStyles();
  const isTerminalDNS =
    /\.tmnl\.co$/.test(domain.domain) ||
    /\.on\.fleek\.co/.test(domain.domain) ||
    !!domain.stripeSessionId;

  const domainName = get(domain, "domain", "");
  const isSubDomain = domainName.split(".").length > 2;
  const dnsLinkVerified = domain.dnsLinkStatus === DOMAIN_STATUS.PROPAGATED;
  const isDomainPropagated = domain.status === DOMAIN_STATUS.PROPAGATED;
  const dnsShouldUpgrade =
    domain.proxyType !== PROXY_TYPES.BUNNY &&
    domain.proxyType !== PROXY_TYPES.CLOUDFLARE &&
    domain.type !== DOMAIN_TYPE.DEFAULT_SUBDOMAIN;
  const isPurchasedDomain = get(domain, "boughtWithTerminal", false);
  const hideRemoveButton =
    /\.on(-(dev|stg))?\.fleek\.co/.test(domainName) ||
    /\.ic\.fleek\.co/.test(domainName);

  return (
    <ListItem className={classes.domain}>
      <DomainInfo
        siteId={siteId}
        type={domain.type}
        domain={domain.domain}
        status={domain.status}
        isTerminalDNS={isTerminalDNS}
      />
      <DomainStatus
        dnsShouldUpgrade={dnsShouldUpgrade}
        dnsLinkVerified={dnsLinkVerified}
        domain={domain.domain}
        status={domain.status}
        isTerminalDNS={isTerminalDNS}
        checkDNSOnClick={() => {
          checkDNSOnClick(domain);
        }}
      />
      <DomainOptions
        siteId={siteId}
        domain={domain.domain}
        domainId={domain.domainId}
        dnsLinkVerified={dnsLinkVerified}
        isTerminalDNS={isTerminalDNS}
        defaultDomain={defaultDomain}
        isSubDomain={isSubDomain}
        dnsShouldUpgrade={dnsShouldUpgrade}
        checkDNSOnClick={() => {
          checkDNSOnClick(domain);
        }}
        platform={platform}
        blockDomainRemove={hideRemoveButton}
        isPurchasedDomain={isPurchasedDomain}
        isDomainPropagated={isDomainPropagated}
      />
    </ListItem>
  );
};

Domain.defaultProps = {
  defaultDomain: null,
};

Domain.propTypes = {
  siteId: PropTypes.string.isRequired,
  checkDNSOnClick: PropTypes.func.isRequired,
  defaultDomain: PropTypes.string,
  domain: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string,
    domain: PropTypes.string,
    domainId: PropTypes.string,
    stripeSessionId: PropTypes.string,
    dnsLinkStatus: PropTypes.string,
    proxyType: PropTypes.string,
    cloudflareStatus: PropTypes.string,
    boughtWithTerminal: PropTypes.bool,
  }).isRequired,
  platform: PropTypes.string.isRequired,
};

export default Domain;
