import Typography from '@material-ui/core/Typography';
import InputWithError from '@terminal-packages/ui/core/InputWithError';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

export type AddStorageCustomDomainFormBodyProps = {
  error?: string | null;
  domainName: string;
  isInputDisabled?: boolean;
  onDomainNameChange: (value: string) => void;
};

const AddStorageCustomDomainFormBody = ({
  error= '',
  domainName,
  isInputDisabled = false,
  onDomainNameChange,
}: AddStorageCustomDomainFormBodyProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onChangeDomainNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onDomainNameChange(e.target.value);
  };

  return (
    <div className={classes.wrapper}>
      <Typography variant="body2" color="textSecondary">
        {t('addCustomDomain.description') as string}
      </Typography>
      <div className={classes.inputWrapper}>
        <InputWithError
          error={!!error}
          value={domainName}
          errorMessage={error || undefined}
          disabled={isInputDisabled}
          // @ts-ignore
          onChange={onChangeDomainNameInput}
          label={t('addCustomDomain.inputLabel')}
        />
      </div>
    </div>
  );
};

export default AddStorageCustomDomainFormBody;
