import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import DropdownWithPhoto from "@terminal-packages/ui/core/DropdownWithPhoto";
import IconFA from "@terminal-packages/ui/core/IconFA";
import InputWithError from "@terminal-packages/ui/core/InputWithError";
import Spinner from "@terminal-packages/ui/core/Spinner";
import { GenericTooltip } from "@terminal-packages/ui/core/Tooltips";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { GATSBY, OTHER } from "../../constants";
import useDefaultBuildSettings from "./hooks/use-default-build-settings";
import useStyles from "./styles";
import getFrameworkOptions from "./utils/get-framework-options";
import getSettingsByFramework from "./utils/get-settings-by-framework";

export type BuildSettingsProps = {
  isFieldsDisabled: boolean;
  buildCommand: string;
  publishDirectory: string;
  baseDirectory: string;
  onChangeState: (...args: any[]) => void;
  repositoryName: string;
  githubAccountName: string;
  branch: string;
  dockerImage: string;
  framework: {
    id: string;
  } | null;
};

const BuildSettings = ({
  isFieldsDisabled,
  buildCommand,
  publishDirectory,
  baseDirectory,
  onChangeState,
  repositoryName = "",
  githubAccountName = "",
  branch = "",
  dockerImage = "",
  framework = null,
}: BuildSettingsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const options = {
    owner: githubAccountName,
    repo: repositoryName,
    path: "",
    ref: branch,
  };

  const buildDefaultSettings = useDefaultBuildSettings(options);
  const defaultFramework = get(buildDefaultSettings, "framework");
  const defaultValues = get(buildDefaultSettings, "defaultValues");
  const isDefaultValuesLoading = buildDefaultSettings === null;

  useEffect(() => {
    if (buildDefaultSettings) {
      const frameworkValues =
        // @ts-ignore
        getSettingsByFramework(defaultValues)[defaultFramework];

      onChangeState({
        ...frameworkValues,
        framework: getFrameworkOptions(t).find(
          (frameworkOption) => frameworkOption.id === defaultFramework
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultValuesLoading]);

  const getOtherFrameworkOption = () =>
    getFrameworkOptions(t).find(
      (frameworkOption) => frameworkOption.id === OTHER
    );

  const getInfoButton = (text = "", helperText = null) => {
    const icon = (
      <ButtonBase className={classes.buttonTooltip}>
        <IconFA
          icon={["fal", "info-circle"]}
          size="inherit"
          className={classes.icon}
        />
      </ButtonBase>
    );
    return (
      <>
        {isDefaultValuesLoading && (
          <Spinner
            positioning="inline"
            fontSize="small"
            className={classes.spinner}
          />
        )}
        <GenericTooltip
          text={text}
          overrideClass={{ tooltip: classes.tooltip }}
          placement="right"
        >
          {icon}
        </GenericTooltip>
        {helperText && (
          <div className={classes.helperTextWrapper}>
            <IconFA
              icon={["fal", "exclamation-triangle"]}
              size="inherit"
              color="inherit"
              className={classes.helperIcon}
            />
            <Typography className={classes.helperText} variant="caption">
              {helperText}
            </Typography>
          </div>
        )}
      </>
    );
  };

  const listOfFrameworks = getFrameworkOptions(t);

  const selectedFramework =
    framework &&
    listOfFrameworks.find(
      (currentFramework) => currentFramework.id === framework?.id
    );

  return (
    <div className={classes.inputContainer}>
      <div className={classes.iconContainer}>
        <DropdownWithPhoto
          value={selectedFramework || {}}
          onSelect={(selection: any) => {
            const frameworkValues =
              // @ts-ignore
              getSettingsByFramework(defaultValues)[selection.id];
            onChangeState({
              ...frameworkValues,
              framework: selection,
            });
          }}
          label={t("sites.start.buildOptions.framework")}
          objects={listOfFrameworks}
        />
        {getInfoButton(t("sites.start.buildOptions.dockerImageTooltip"))}
      </div>
      <div className={classes.iconContainer}>
        <InputWithError
          // @ts-ignore
          label={t("siteSettings.deployment.dockerFile.label")}
          value={dockerImage}
          onChange={(event: any) => {
            onChangeState({
              dockerImage: event.target.value,
              framework: getOtherFrameworkOption(),
            });
          }}
        />
        {getInfoButton(t("sites.start.buildOptions.dockerImageTooltip"))}
      </div>
      <div className={classes.iconContainer}>
        <InputWithError
          // @ts-ignore
          label={t("sites.start.buildOptions.buildCommand")}
          value={buildCommand}
          onChange={(event: any) => {
            onChangeState({
              buildCommand: event.target.value,
              framework: getOtherFrameworkOption(),
            });
          }}
          className={classes.textInput}
          disabled={isFieldsDisabled || isDefaultValuesLoading}
        />
        {getInfoButton(
          t("sites.start.buildOptions.buildCommandTooltip"),
          (buildDefaultSettings &&
            buildDefaultSettings.framework === GATSBY &&
            t("sites.start.buildOptions.gatsbyPlugin")) ||
            undefined
        )}
      </div>
      <div className={classes.iconContainer}>
        <InputWithError
          // @ts-ignore
          label={t("sites.start.buildOptions.publishDirectory")}
          value={publishDirectory}
          onChange={(event: any) => {
            onChangeState({
              publishDirectory: event.target.value,
              framework: getOtherFrameworkOption(),
            });
          }}
          className={classes.textInput}
          disabled={isFieldsDisabled || isDefaultValuesLoading}
        />
        {getInfoButton(t("sites.start.buildOptions.publishDirectoryTooltip"))}
      </div>
      <div className={classes.iconContainer}>
        <InputWithError
          // @ts-ignore
          label={t("sites.start.buildOptions.baseDirectory")}
          value={baseDirectory}
          onChange={(event: any) => {
            onChangeState({
              baseDirectory: event.target.value,
              framework: getOtherFrameworkOption(),
            });
          }}
          className={classes.textInput}
          disabled={isFieldsDisabled || isDefaultValuesLoading}
        />
        {getInfoButton(t("sites.start.buildOptions.baseDirectoryTooltip"))}
      </div>
    </div>
  );
};

BuildSettings.defaultProps = {};

BuildSettings.propTypes = {
  isFieldsDisabled: PropTypes.bool.isRequired,
  buildCommand: PropTypes.string.isRequired,
  publishDirectory: PropTypes.string.isRequired,
  baseDirectory: PropTypes.string.isRequired,
  onChangeState: PropTypes.func.isRequired,
  repositoryName: PropTypes.string,
  githubAccountName: PropTypes.string,
  branch: PropTypes.string,
  dockerImage: PropTypes.string,
  framework: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default BuildSettings;
