import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DELETE_PROFILE_MODAL, openModal } from "src/modals/actions";
import { useAuth } from "src/store/AuthContext";

import useStyles from "./styles";

const DeleteProfile = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading } = useAuth();

  const deleteProfileOnClick = () =>
    dispatch(openModal(DELETE_PROFILE_MODAL, {}));

  return (
    <>
      <Typography variant="body1">
        <Box component="span" fontWeight={500}>
          {t("accountSettings.dangerZone.title")}
        </Box>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("accountSettings.dangerZone.description")}
      </Typography>
      <br />
      <CardTitled
        mainContent={t("accountSettings.dangerZone.card.title")}
        classes={{
          content: classes.sectionContent,
        }}
      >
        <Typography variant="body2" color="textPrimary">
          {t(`accountSettings.dangerZone.card.description`)}
        </Typography>
        <div className={classes.button}>
          <GenericButton
            disabled={loading}
            loading={loading}
            buttonVariant="important"
            onClick={deleteProfileOnClick}
          >
            {t("accountSettings.dangerZone.card.deleteProfileButton")}
          </GenericButton>
        </div>
      </CardTitled>
    </>
  );
};

export default DeleteProfile;
