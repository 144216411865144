import { Avatar } from "@fleekhq/react-drip";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { IconFormat } from "@terminal-packages/ui";
import has from "lodash/has";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { projectAuth } from "src/firebase";
import { openModal, PROFILE_MODAL } from "src/modals/actions";

import auth from "../../../../auth";
import { useAuth } from "../../../../store/AuthContext";
import getConfig from "./get-config";
import useStyles from "./styles";

const UserMenu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const btnRef = useRef(null);

  const { currentUser, displayName } = useAuth();
  const { user } = getConfig(currentUser, t);

  function handleOpenProfileModal() {
    dispatch(openModal(PROFILE_MODAL));
  }

  useEffect(() => {
    // if user is not authenticated, listen to onUserAuth and avoid query skip
    if (!projectAuth.currentUser) {
      const removeListener = auth.onListen(
        auth.events.onUserAuth,
        (isAuthenticated) => {
          if (isAuthenticated) {
            // remove listener, user is already authenticated
            removeListener();
          }
        }
      );
    }
  }, []);

  if (
    !projectAuth.currentUser ||
    !(has(user, "id") && has(user, "email") && has(user, "username"))
  ) {
    return null;
  }

  return (
    <div className={classes.root}>
      <ButtonBase
        disableRipple
        className={classes.avatarButton}
        onClick={handleOpenProfileModal}
        ref={btnRef}
      >
        <Avatar
          alt={user.username}
          src={user.profilePicUrl}
          fallback={displayName}
          isRound
          size="xs"
        />
        <Typography variant="caption" className={classes.username}>
          {displayName}
        </Typography>
        <IconFormat
          size={8}
          className={classes.angleIcon}
          icon={["fal", "angle-right"]}
        />
      </ButtonBase>
    </div>
  );
};

export default UserMenu;
