import gql from 'graphql-tag';

export default gql`
  mutation editTeamName($input: EditTeamNameInput!) {
    editTeamName(input: $input) {
      id,
      name
    }
  }
`;
