import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    paddingTop: 9,
  },
  wrapper: {
    marginTop: 6
  },
  confirmButton: {
    width: 145,
    marginRight: 10,
  },
  title: {
    marginBottom: 2,
    fontWeight: 500,
  },
  link: {
    display: 'inline-flex',
    margin: '10px 0 23px',
    textDecoration: 'none',
  },
  inputWrapper: {
    width: 330,
    marginTop: 20,
  },
}));
