import gql from 'graphql-tag';

import { DEPLOY_DETAIL } from '../fragments';

export default gql`
  mutation cancelDeploy($deployId: ID!, $siteId: ID!) {
    cancelDeploy(deployId: $deployId, siteId: $siteId) {
      ...DeployDetail
    }
  }
  ${DEPLOY_DETAIL}
`;
