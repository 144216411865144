export const ALERT_VARIANTS = {
  Primary: "primary",
  Success: "success",
  Error: "danger",
  Info: "warning",
};

export const GIT_PROVIDER_NAMES = {
  GitHub: "GitHub",
  GitLab: "GitLab",
  Radicle: "Radicle",
  BitBucket: "BitBucket",
};

export const INJECTED_PROVIDER_NAMES = {
  MetaMask: "MetaMask",
  Frame: "Frame",
};

export const WEB3_PROVIDER_NAMES = {
  Extension: "Extension",
  WalletConnect: "WalletConnect",
  Gnosis: "Gnosis Multi-Sig",
  Rainbow: "Rainbow",
};

export const CONNECTION_LABELS = {
  [WEB3_PROVIDER_NAMES.Extension]: {
    connectionAwaiting: "Approve the connection",
    approvalAwaiting: "Sign the transaction in wallet",
    connecting: "Establishing connection",
    reconnect: "Retry the connection",
    done: "Successfuly connected",
  },
  [WEB3_PROVIDER_NAMES.WalletConnect]: {
    connectionAwaiting: "Scan QR code in Mobile Wallet",
    approvalAwaiting: "Sign the transaction in Mobile Wallet",
    connecting: "Establishing connection",
    reconnect: "Retry the connection",
    done: "Successfuly connected",
  },
  [WEB3_PROVIDER_NAMES.Gnosis]: {
    connectionAwaiting: "Scan QR code in Gnosis",
    approvalAwaiting: "Sign the transaction in Gnosis",
    connecting: "Establishing connection",
    reconnect: "Retry the connection",
    done: "Successfuly connected",
  },
  [WEB3_PROVIDER_NAMES.Rainbow]: {
    connectionAwaiting: "Scan QR code in Rainbow",
    approvalAwaiting: "Sign the transaction in Rainbow",
    connecting: "Establishing connection",
    reconnect: "Retry the connection",
    done: "Successfuly connected",
  },
  [GIT_PROVIDER_NAMES.GitHub]: {
    connectionAwaiting: "Authorize Fleek Access",
    connecting: "Establishing connection",
    reconnect: "Retry the connection",
    done: "Successfuly connected",
  },
};
