import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  icon: {
    display: 'flex',
  },
  buttonText: {
    marginLeft: 10,
  },
  button: {
    minWidth: 240,
    height: 36,
    marginTop: 3,
  },
  value: {
    fontWeight: 500,
  },
});
