import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  modal: {
    '& > div': {
      outline: 'none',
    },
  },
  description: {
    marginTop: 20,
    marginBottom: 20,
    color: theme.palette.palette.black,
    lineHeight: 1.29,
  },
}));
