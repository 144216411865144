import { gql } from "graphql-tag";

export const BUCKET_DETAIL = gql`
  fragment BucketDetail on Bucket {
    hash
    pullZoneUrl
    domain
    domainStatus
  }
`;
