
import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    hiddenValueBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '20px',
    },
    hiddenText: {
        fontWeight: 'bold',
    }
}));
