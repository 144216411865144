// @ts-nocheck
import "firebase/auth";
import "react-phone-input-2/lib/style.css";

import Typography from "@material-ui/core/Typography";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import { toast } from "@terminal-packages/ui/core/Toast";
import firebase from "firebase/app";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { phoneAuthProvider } from "src/firebase";
import {
  openModal,
  TWO_FACTOR_AUTH_VERIFY_CODE_MODAL,
} from "src/modals/actions";
import { useAuth } from "src/store/AuthContext";

import useStyles from "./styles";

// global 'window' variable
const window = {
  recaptchaVerifier: undefined,
};

export const EnterNumber = ({ open, closeModal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const [state, setState] = useState({
    phoneNumber: "",
    loading: false,
  });

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "2fa-captcha",
      {
        size: "invisible",
        callback: () => {
          // reCAPTCHA solved
        },
      }
    );
  }, []);

  const handleNumberChange = (value) => {
    setState({
      ...state,
      phoneNumber: value,
    });
  };

  const handleSendCode = async (event) => {
    event.preventDefault();
    setState({
      ...state,
      loading: true,
    });
    try {
      const session = await currentUser.multiFactor.getSession();
      const phoneOpts = {
        phoneNumber: `+${state.phoneNumber}`,
        session,
      };
      window.verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneOpts,
        window.recaptchaVerifier
      );
      dispatch(
        openModal(TWO_FACTOR_AUTH_VERIFY_CODE_MODAL, {
          phoneNumber: `+${state.phoneNumber}`,
          verificationId: window.verificationId,
        })
      );
    } catch (error) {
      toast.error(`${t("modals.getTwoFa.failed")}: ${error.message}`, {
        autoClose: 6000,
      });
      setState({
        ...state,
        loading: false,
      });
    }
  };

  return (
    <>
      <BaseModal
        open={open}
        title={t("modals.getTwoFa.number.title")}
        maxWidth={500}
        onClose={closeModal}
        className={classes.modal}
      >
        <form id="sendCodeForm" onSubmit={handleSendCode}>
          <Typography variant="body2" className={classes.description}>
            {t("modals.getTwoFa.number.description")}
          </Typography>
          <div className={classes.inputContainer}>
            <PhoneInput
              country="us"
              value={state.phoneNumber}
              onChange={handleNumberChange}
              autoFormat={false}
              countryCodeEditable={false}
            />
          </div>
          <div className={classes.buttonContainer}>
            <GenericButton buttonVariant="secondary" onClick={closeModal}>
              {t("modals.getTwoFa.cancel")}
            </GenericButton>
            <GenericButton
              buttonVariant="primary"
              disabled={!state.phoneNumber || state.loading}
              loading={state.loading}
              type="submit"
              form="sendCodeForm"
            >
              {t("modals.getTwoFa.number.sendCode")}
            </GenericButton>
          </div>
        </form>
      </BaseModal>
      <div id="2fa-captcha" className={classes.twoFaCaptcha} />
    </>
  );
};

EnterNumber.defaultProps = {
  open: false,
};

EnterNumber.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};
