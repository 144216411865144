import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  iconButtonRoot: {
    width: 5,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  paperRoot: {
    borderRadius: 4,
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: theme.palette.palette.whiteThree,
  },
  listMenu: {
    borderRadius: 4,
    padding: '8px 11px',
  },
  popper: {
    zIndex: 1,
    minWidth: 147,
    top: ({ offsetTop }: any) => (offsetTop ? `${offsetTop}px !important` : 0),
  },
}));
