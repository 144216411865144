import { useQuery } from "@apollo/react-hooks";
import { toast } from "@terminal-packages/ui/core/Toast";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { newApiClient } from "src/clients";
import { GET_TEAM_API_KEYS } from "src/graphql/queries";
import { APP_API_DETAILS_MODAL, openModal } from "src/modals/actions";
import { url } from "src/utils";

import ApiSection from "../ApiSection";

const Api = () => {
  const dispatch = useDispatch();
  const teamId = url.getAccountIdFromUrl();
  const { data, loading } = useQuery(GET_TEAM_API_KEYS, {
    client: newApiClient,
    variables: {
      teamId,
    },
  });

  const apiKeys = get(data, "getTeamApiKeys.apiKeys", []) || [];

  const generateApiOnClick = () =>
    dispatch(openModal(APP_API_DETAILS_MODAL, {}));

  const i18nKey = "hostingApi";

  const handleCopy =
    ({ t, apiKey }) =>
    (e) => {
      e.preventDefault();

      navigator.clipboard.writeText(apiKey);
      toast.success(t(`accountSettings.${i18nKey}.card.copy`));
    };

  return (
    <ApiSection
      generateApiOnClick={generateApiOnClick}
      handleCopy={handleCopy}
      apiKeys={apiKeys}
      loading={loading}
      i18nKey={i18nKey}
    />
  );
};

export default Api;
