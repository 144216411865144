import gql from "graphql-tag";

import { SITE_DETAIL } from "../../views/Sites/graphql/fragments";

export default gql`
  mutation verifyEnsIpns($input: VerifyEnsIpnsInput!) {
    verifyEnsIpns(input: $input) {
      ...SiteDetail
    }
  }
  ${SITE_DETAIL}
`;
