import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "src/constants";
import { setLocalStorageState } from "src/reducers/actions/local-storage";
import { url } from "src/utils";

import Bucket from "./components/Bucket";
import File from "./components/File";
import Folder from "./components/Folder";

const Storage = () => {
  const location = useLocation();
  const { path } = useRouteMatch();
  const { seenNewStorageFeature } = useSelector((state) => state.localStorage);
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.analytics.page("Storage", {
      path: location.pathname,
      search: location.search,
    })

    if (!seenNewStorageFeature) {
      dispatch(setLocalStorageState({ seenNewStorageFeature: true }))
      localStorage.setItem(LOCAL_STORAGE_KEYS.seenNewStorageFeature, true);
    }   
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={({ match }) => (
          // redirect to default bucket
          <Redirect
            to={url.buildUrl(
              null,
              `${match.url}/${match.params.teamId}-bucket`
            )}
          />
        )}
      />
      <Route exact path={`${path}/:bucketName`}>
        <Bucket />
      </Route>
      <Route path={`${path}/:bucketName/folder/*`}>
        <Folder />
      </Route>
      <Route path={`${path}/:bucketName/object/*`}>
        <File />
      </Route>
    </Switch>
  );
};

export default Storage;
