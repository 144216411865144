import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  content: {
    padding: '3px 0 16px 0',
  },
  item: {
    height: 46,
    padding: '0 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    padding: '0 20px',
  },
  setupButton: {
    minHeight: 36,
  },
}));
