// @ts-nocheck
import makeBlockie from "ethereum-blockies-base64";
import { ethers, utils } from "ethers";
import { useEffect, useState } from "react";

export const useGetEns = ({ address, provider }) => {
  const [ensName, setEnsName] = useState(undefined);
  const [ensAvatar, setEnsAvatar] = useState(undefined);

  const getName = async (address) => {
    try {
      const name = await provider.lookupAddress(
        ethers.utils.getAddress(address)
      );

      setEnsName(name ? name : undefined);
    } catch (error) {
      console.error("ENS name lookup error", error);
    }
  };

  const getAvatar = async (name) => {
    try {
      const labelHash = utils.keccak256(
        utils.toUtf8Bytes(name?.replace(".eth", ""))
      );

      const result = await (
        await fetch(
          `https://metadata.ens.domains/mainnet/0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/${labelHash}/`
        )
      ).json();

      setEnsAvatar(
        result && result.background_image
          ? result.background_image
          : makeBlockie(address)
      );
    } catch (error) {
      console.error("ENS avatar lookup error", error);
    }
  };

  useEffect(() => {
    if (address) {
      setEnsAvatar(makeBlockie(address));
      getName(address);
    } else {
      setEnsName(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (ensName) {
      getAvatar(ensName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ensName]);

  return { name: ensName, avatar: ensAvatar };
};
