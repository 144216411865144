import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import IconFA from "@terminal-packages/ui/core/IconFA";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AddCustomDomainModal } from "src/modals";

import useStyles from "./styles";

const LINK_DESTINATINATION_REDIRECT =
  "https://docs.fleek.co/hosting/site-deployment/#monitoring-a-deployment";
const LINK_DESTINATINATION_DOMAINS =
  "https://docs.fleek.co/domain-management/custom-dns-domains";

const CustomDomainsFooter = ({
  siteId,
  domainsLength,
  hasWaitingDomain,
  siteBySlug,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  const [openModal, setOpenModal] = useState(false);

  const showRedirectLink = domainsLength > 1 && hasWaitingDomain;
  const showLink = domainsLength <= 1 || showRedirectLink;
  const publishedDeploy = get(siteBySlug, "data.getSiteBySlug.publishedDeploy");

  const disabledBtn = !publishedDeploy;

  const linkText = showRedirectLink
    ? t("sites.tabs.settings.customDomains.learnAboutRedirect")
    : t("sites.tabs.settings.customDomains.learnAboutCustomDomains");

  const linkDestination = showRedirectLink
    ? LINK_DESTINATINATION_REDIRECT
    : LINK_DESTINATINATION_DOMAINS;

  const openCustomDomainModal = () => setOpenModal(true);
  const closeCustomDomainModal = () => setOpenModal(false);

  return (
    <div className={classes.footer}>
      {showLink && (
        <div>
          <a
            href={linkDestination}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="body2" className={classes.docs}>
              {linkText}
            </Typography>
            <IconFA
              className={classes.arrowIcon}
              icon={["fal", "long-arrow-right"]}
              size="inherit"
            />
          </a>
        </div>
      )}
      <div className={classes.button}>
          <GenericButton
            buttonVariant="primary"
            disabled={disabledBtn}
            className={classes.button}
            onClick={() => openCustomDomainModal()}>
            {t("sites.tabs.settings.customDomains.buttonText")}
          </GenericButton>
          <AddCustomDomainModal open={openModal} closeModal={closeCustomDomainModal} siteId={siteId} />
      </div>
    </div>
  );
};

CustomDomainsFooter.defaultProps = {
  siteBySlug: {
    loading: true,
    data: null,
    error: null,
  },
};

CustomDomainsFooter.propTypes = {
  siteId: PropTypes.string.isRequired,
  domainsLength: PropTypes.number.isRequired,
  hasWaitingDomain: PropTypes.bool.isRequired,
  siteBySlug: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.object,
    error: PropTypes.object,
  }),
};

export default CustomDomainsFooter;
