import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import LabelInput from "@terminal-packages/ui/core/LabelInput";
import classnames from "classnames";
import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { linkEmailToWallet } from "src/views/AuthCallback/utils";

import useStyles from "./styles";

const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegex.test(email);
};

const SetEmailModal = ({
  i18n,
  className,
  open,
  closeModal,
  setBillingEmail,
  walletAddress
}) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError(get(i18n, "error.email", ""));
      return;
    }

    try {
      setLoading(true);
      await linkEmailToWallet(walletAddress, email);
      setBillingEmail(email);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const setValue = (value, setFn) => {
    if (error.length > 0) setError([]);
    setFn(value);
  };

  return (
    <BaseModal
      open={open}
      maxWidth={460}
      onClose={closeModal}
      title={i18n.title}
      disableAutoFocus
      disableEnforceFocus
    >
      <form onSubmit={onSubmit} className={classnames(classes.form, className)}>
        {!isEmpty(error) && (
          <Alert severity="error" className={classes.alert}>
            <AlertTitle>{get(i18n, "error.title", "")}</AlertTitle>
            <span className={classes.error}>{error}</span>
          </Alert>
        )}
        <Typography variant="body2" className={classes.description}>
          {get(i18n, "inputs.email.description", "")}
        </Typography>
        <LabelInput
          labelProps={{ className: classes.input }}
          value={email}
          label={get(i18n, "inputs.email.label", "")}
          placeholder={get(i18n, "inputs.email.placeholder", "")}
          onChange={(e) => setValue(e.target.value, setEmail)}
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={classes.button}
          disabled={loading}
        >
          {get(i18n, "inputs.email.buttonText", "")}
        </Button>
      </form>
    </BaseModal>
  );
};

SetEmailModal.defaultProps = {
  open: false,
  className: null,
  userId: "",
  walletAddress: "",
  i18n: {},
};

SetEmailModal.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  userId: PropTypes.string,
  walletAddress: PropTypes.string,
  i18n: PropTypes.shape({
    inputs: PropTypes.shape({
      email: PropTypes.shape({
        label: PropTypes.string,
        placeholder: PropTypes.string,
      }),
    }),
    error: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
};

export default SetEmailModal;
