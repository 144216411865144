import Typography from "@material-ui/core/Typography";
import ArrowLink from "@terminal-packages/ui/core/ArrowLink";
import InputWithError from "@terminal-packages/ui/core/InputWithError";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SelectBranch, StepBase } from "src/components";
import { GA_EVENTS_CATEGORIES } from "src/constants";
import { url } from "src/utils";
import getAccountIdFromUrl from "src/utils/get-account-id-from-url";

import BuildSettings from "./components/BuildSettings";
import SubmitSection from "./components/SubmitSection";
import AdvancedSection from "./containers/AdvancedSection";
import useStyles from "./styles";

type StateOptions = {
  branchName?: string;
  buildCommand: string;
  publishDirectory: string;
  baseDirectory: string;
  dockerImage: string;
  envVars: any[];
  isEnvVarsError: boolean;
  framework: {
    id: string;
  } | null;
  dfinityUseProxy: boolean;
};

const initialState: StateOptions = {
  branchName: undefined,
  buildCommand: "",
  publishDirectory: "",
  baseDirectory: "",
  dockerImage: "",
  envVars: [],
  isEnvVarsError: false,
  framework: null,
  dfinityUseProxy: true,
};

type ParamNames = {
  repositoryName: string;
  accountName: string;
  installationId: string;
  hostingServiceId: string;
};

type GetDeployMutationInputOptions = Pick<
  ParamNames,
  "installationId" | "hostingServiceId"
> & {
  state: StateOptions;
  repositoryUrl: string;
};

const getDeployMutationInput = ({
  state,
  repositoryUrl,
  installationId,
  hostingServiceId,
}: GetDeployMutationInputOptions) => {
  const environmentVariables = state.envVars
    .filter(({ name }) => !!name)
    .map(({ name, value }) => ({ name, value }));

  const dockerImage = state.dockerImage ? state.dockerImage.trim() : undefined;

  return {
    teamId: getAccountIdFromUrl(),
    platform: hostingServiceId,
    githubSource: {
      installationId,
      url: repositoryUrl,
      branch: state.branchName,
    },
    buildSettings: {
      buildCommand: state.buildCommand || undefined,
      publishDirectoryPath: state.publishDirectory || undefined,
      baseDirectoryPath: state.baseDirectory || undefined,
      dockerImage: dockerImage === "" ? undefined : dockerImage,
      environmentVariables: environmentVariables.length
        ? environmentVariables
        : undefined,
    },
    ...(hostingServiceId === "dfinity" && {
      dfinityUseProxy: state.dfinityUseProxy,
    }),
  };
};

type BuildOptionsProps = {
  onClickDeploy: Function;
  isDeployLoading: boolean;
};

const BuildOptions = ({
  onClickDeploy,
  isDeployLoading,
}: BuildOptionsProps) => {
  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const { t } = useTranslation();
  const { repositoryName, accountName, installationId, hostingServiceId } =
    useParams<ParamNames>();

  const fullrepositoryName = `${accountName}/${repositoryName}`;
  const deployMutationInput = getDeployMutationInput({
    state,
    repositoryUrl: `https://api.github.com/repos/${fullrepositoryName}`,
    installationId,
    hostingServiceId,
  });

  const handleClickOnDeploy = async () => {
    window.ga(
      "send",
      "event",
      GA_EVENTS_CATEGORIES.SITES,
      "User triggered deploy",
      `${deployMutationInput.githubSource.branch}, ${
        state.framework && state.framework.id
      }, ${deployMutationInput.buildSettings.dockerImage}, ${
        deployMutationInput.buildSettings.buildCommand
      }, ${deployMutationInput.buildSettings.publishDirectoryPath}`
    );

    try {
      console.log(deployMutationInput);
      const { data } = await onClickDeploy({
        variables: {
          input: deployMutationInput,
        },
      });

      const siteId = get(data, "addSite.id");

      window.analytics.track("Add Site Deployment", {
        siteId,
        teamId: url.getAccountIdFromUrl(),
        dockerImage: deployMutationInput.buildSettings.dockerImage,
        buildCommand: deployMutationInput.buildSettings.buildCommand,
        framework: state.framework && state.framework.id,
        repositoryBranch: deployMutationInput.githubSource.branch,
        publishDirectoryPath:
          deployMutationInput.buildSettings.publishDirectoryPath,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Error during addSite mutation:", err);
    }
  };

  const isSubmitButtonDisabled = !state.branchName || state.isEnvVarsError;

  return (
    <StepBase
      title={t("sites.start.buildOptions.cardTitle", {
        repository: `${accountName}/${repositoryName}`,
      })}
      subtitle={t("sites.start.buildOptions.cardSubtitle")}
    >
      <>
        <div className={classes.inputContainer}>
          <InputWithError
            // @ts-ignore
            label={t("sites.start.buildOptions.repository")}
            value={fullrepositoryName}
            disabled
            className={classes.textInput}
          />
          <SelectBranch
            repositoryName={repositoryName}
            defaultBranchName="master"
            githubAccountName={accountName}
            branch={state.branchName}
            setBranch={(branchName) =>
              setState({
                ...state,
                branchName,
              })
            }
          />
        </div>
        <div className={classes.secondaryTitleComponent}>
          <Typography variant="body1" className={classes.title}>
            {t("sites.start.buildOptions.title") as string}
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            {t("sites.start.buildOptions.subtitle") as string}
          </Typography>
          <a
            href="https://docs.fleek.co/hosting/site-deployment/#build-parameters"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.docs}
          >
            <ArrowLink>{t("sites.start.buildOptions.docs")}</ArrowLink>
          </a>
        </div>
        <BuildSettings
          repositoryName={repositoryName}
          githubAccountName={accountName}
          branch={state.branchName || "master"}
          buildCommand={state.buildCommand}
          publishDirectory={state.publishDirectory}
          baseDirectory={state.baseDirectory}
          dockerImage={state.dockerImage}
          framework={state.framework}
          onChangeState={(newState) =>
            setState({
              ...state,
              ...newState,
            })
          }
          isFieldsDisabled={isDeployLoading}
        />
        <div className={classes.buttonContainer}>
          <AdvancedSection state={state} setState={setState} />
        </div>
        <div className={classes.buttonContainer}>
          <SubmitSection
            isDeployLoading={isDeployLoading}
            onDeploy={handleClickOnDeploy}
            disabled={isSubmitButtonDisabled}
          />
        </div>
      </>
    </StepBase>
  );
};

BuildOptions.propTypes = {
  isDeployLoading: PropTypes.bool.isRequired,
  onClickDeploy: PropTypes.func.isRequired,
};

export default BuildOptions;
