// TODO: Update props
import get from "lodash/get";
import { useCallback, useEffect, useReducer } from "react";
import { useOctokit } from "src/hooks";

import {
  CREATE_REACT_APP,
  GATSBY,
  GRIDSOME,
  HUGO,
  NEXT_JS,
  NUXT_JS,
  SAPPER,
  SVELTE,
  WASM_PACK,
} from "../../../../constants";
import getBuildSettings from "./utils/get-build-settings";
import getDataFromToml from "./utils/get-data-from-toml";

type FetchRepoContentOptions = {
  path: string;
};

type GetFileOptions = {
  fetchRepoContent: (options?: FetchRepoContentOptions) => Promise<any>;
  setState: any;
};

const getToml = async ({ fetchRepoContent, setState }: GetFileOptions) => {
  try {
    const netlifyToml = await fetchRepoContent({
      path: "netlify.toml",
    }).then((result) => {
      const stringifiedContent = Buffer.from(
        result.data.content,
        "base64"
      ).toString();
      return stringifiedContent;
    });
    setState({
      toml: getDataFromToml(netlifyToml),
    });
  } catch (error) {
    setState({
      toml: {},
    });
  }
};

const getPackageJson = async ({
  fetchRepoContent,
  setState,
}: GetFileOptions) => {
  try {
    const packageJsonContent = await fetchRepoContent({
      path: "package.json",
    }).then((result) => {
      const stringifiedContent = Buffer.from(
        result.data.content,
        "base64"
      ).toString();
      const json = JSON.parse(stringifiedContent);
      return json;
    });

    let framework = null;

    if (get(packageJsonContent, "dependencies.gatsby")) {
      framework = GATSBY;
    } else if (get(packageJsonContent, "dependencies.react-scripts")) {
      framework = CREATE_REACT_APP;
    } else if (get(packageJsonContent, "dependencies.next")) {
      framework = NEXT_JS;
    } else if (get(packageJsonContent, "dependencies.nuxt")) {
      framework = NUXT_JS;
    } else if (get(packageJsonContent, "dependencies.gridsome")) {
      framework = GRIDSOME;
    } else if (
      get(packageJsonContent, "devDependencies.sapper") ||
      get(packageJsonContent, "dependencies.sapper")
    ) {
      framework = SAPPER;
    } else if (
      get(packageJsonContent, "devDependencies.svelte") ||
      get(packageJsonContent, "dependencies.svelte")
    ) {
      framework = SVELTE;
    } else if (
      get(packageJsonContent, "devDependencies.@wasm-tool/wasm-pack-plugin") ||
      get(packageJsonContent, "dependencies.@wasm-tool/wasm-pack-plugin")
    ) {
      framework = WASM_PACK;
    }

    setState({
      packageJson: {
        hasBuildCommand: !!get(packageJsonContent, "scripts.build"),
        framework,
      },
    });
  } catch (error) {
    setState({
      packageJson: {},
    });
  }
};

const getFleekJson = async ({ fetchRepoContent, setState }: GetFileOptions) => {
  try {
    const fleekJsonContent = await fetchRepoContent({
      path: ".fleek.json",
    }).then((result) => {
      const stringifiedContent = Buffer.from(
        result.data.content,
        "base64"
      ).toString();
      const json = JSON.parse(stringifiedContent);
      return json;
    });

    const envVarsObject = get(fleekJsonContent, "build.environment", {});
    const envVars = Object.entries(envVarsObject).map(([name, value]) => ({
      name,
      value,
    }));

    setState({
      fleekJson: {
        dockerImage: get(fleekJsonContent, "build.image", ""),
        publishDirectory: get(fleekJsonContent, "build.publicDir", ""),
        buildCommand: get(fleekJsonContent, "build.command", ""),
        envVars,
        detected: true,
      },
    });
  } catch (error) {
    setState({
      fleekJson: {
        detected: false,
      },
    });
  }
};

const getRootDirectory = async ({
  fetchRepoContent,
  setState,
}: GetFileOptions) => {
  try {
    const { data: rootDirectory } = await fetchRepoContent();
    const hasYarnLock = !!rootDirectory.find(
      ({ name }: any) => name === "yarn.lock"
    );
    const hasPackageLock = !!rootDirectory.find(
      ({ name }: any) => name === "package-lock.json"
    );
    const hasWpContent = !!rootDirectory.find(
      ({ name }: any) => name === "wp-content"
    );
    const hasGemfile = !!rootDirectory.find(
      ({ name }: any) => name === "Gemfile"
    );
    const hasMkDocsYml = !!rootDirectory.find(
      ({ name }: any) => name === "mkdocs.yml"
    );
    setState({
      rootDirectory: {
        hasYarnLock,
        hasPackageLock,
        hasWpContent,
        hasGemfile,
        hasMkDocsYml,
      },
    });
  } catch (error) {
    setState({
      rootDirectory: {},
    });
  }
};

const getHugoFiles = async ({ fetchRepoContent, setState }: GetFileOptions) => {
  let framework = null;
  try {
    await fetchRepoContent({
      path: "archetypes/default.md",
    });
    framework = HUGO;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("No hugo configuration found");
  }

  setState({
    hugoFiles: {
      framework,
    },
  });
};

type UseDefaultBuildSettingsOptions = {
  owner: string;
  repo: string;
  path: string;
  ref: any;
};

const useDefaultBuildSettings = (options: UseDefaultBuildSettingsOptions) => {
  const { repos } = useOctokit();
  // TODO: Contents / content pay attention
  const fetchRepoContent = useCallback(
    (overrideOptions = {}) =>
      repos.getContent({
        ...options,
        ...overrideOptions,
      }),
    [options, repos]
  );
  const [state, setState] = useReducer(
    // @ts-ignore
    (prevState, newState) => ({ ...prevState, ...newState }),
    {}
  );

  const { rootDirectory, packageJson, toml, hugoFiles, fleekJson } = state;
  const isFullState =
    rootDirectory && packageJson && toml && hugoFiles && fleekJson;

  useEffect(() => {
    if (options.ref && !isFullState) {
      getRootDirectory({ fetchRepoContent, setState });
      getPackageJson({ fetchRepoContent, setState });
      getToml({ fetchRepoContent, setState });
      getHugoFiles({ fetchRepoContent, setState });
      getFleekJson({ fetchRepoContent, setState });
    }
  }, [fetchRepoContent, isFullState, options.ref]);

  if (!isFullState) {
    return null;
  }
  return getBuildSettings(state);
};

export default useDefaultBuildSettings;
