import { MappedRepository } from "./use-github";

export type TransformAccountToSelectorFormatOptions = {
  repository?: MappedRepository;
  isSelected: boolean;
};

export const transformAccountToSelectorFormat = ({
  repository,
  isSelected,
}: TransformAccountToSelectorFormatOptions) => ({
  id: repository?.account.name,
  name: repository?.account.name,
  image: repository?.account.avatar,
  selected: isSelected,
});
