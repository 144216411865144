import ListItem from '@terminal-packages/ui/core/ListItem';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import DomainInfo from '../DomainInfo';
import DomainOptions from '../DomainOptions';
import DomainStatus from '../DomainStatus';
import useStyles from './styles';

const Domain = ({
  siteId,
  domain,
  checkHNSOnClick,
}) => {
  const classes = useStyles();

  const domainName = get(domain, 'domain', '');
  const isSubDomain = domainName.split('.').length > 2;

  return (
    <ListItem className={classes.domain}>
      <DomainInfo
        domain={domain.domain}
        status={domain.status}
      />
      <DomainStatus
        domain={domain.domain}
        status={domain.status}
        checkHNSOnClick={() => { checkHNSOnClick(domain); }}
      />
      <DomainOptions
        siteId={siteId}
        domain={domain.domain}
        domainId={domain.domainId}
        isSubDomain={isSubDomain}
      />
    </ListItem>
  );
};

Domain.propTypes = {
  siteId: PropTypes.string.isRequired,
  checkHNSOnClick: PropTypes.func.isRequired,
  domain: PropTypes.shape({
    status: PropTypes.string,
    domain: PropTypes.string,
    domainId: PropTypes.string,
  }).isRequired,
};

export default Domain;
