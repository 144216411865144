// @ts-nocheck
import IconFA from "@terminal-packages/ui/core/IconFA";
import PropTypes from "prop-types";
import { Info } from "src/components/info";

import useStyles from "./styles";

export const EnhancedText = (props) => {
  const { loading, children, tooltipText } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {loading && (
        <IconFA
          icon={["fad", "spinner-third"]}
          classes={{
            fontAwesomeOverrides: classes.loadingIcon,
            iconContainer: classes.loadingIcon,
          }}
        />
      )}
      {children}
      {tooltipText && <Info text={tooltipText} className={classes.info} />}
    </div>
  );
};

EnhancedText.defaultProps = {
  children: null,
  loading: false,
  tooltipText: null,
};

EnhancedText.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  tooltipText: PropTypes.arrayOf(PropTypes.string),
};
