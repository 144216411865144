// @ts-nocheck
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import PropTypes from "prop-types";
import AddHNSDomainForm from "src/components/add-hns-domain-form";

export const AddHnsDomainModal = ({ open, closeModal }) => (
  <BaseModal open={open} maxWidth={460} maxHeigth={400} onClose={closeModal}>
    <AddHNSDomainForm closeModal={closeModal} />
  </BaseModal>
);

AddHnsDomainModal.defaultProps = {
  open: false,
};

AddHnsDomainModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};
