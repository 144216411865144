import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 106,
    marginTop: 37,
    marginBottom: 32,
  },
  box: {
    padding: '29px 51px 32px 51px',
    width: 482,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkIcon: {
    fontSize: 35,
    color: '#00C9A7',
  },
  title: {
    fontSize: 21,
    marginBottom: 18,
  },
  description: {
    fontSize: 15,
    marginBottom: 22,
  },
}));
