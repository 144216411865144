import contentHash from "@ensdomains/content-hash";
import Torus from "@toruslabs/torus-embed";
import config from "src/config";
import { createWalletConnectProvider } from "src/integrations/walletConnect/createWalletConnectProvider";
import Web3 from "web3";
import Web3Modal from "web3modal";

import {
  handleFailedToConnect,
  handleFailedToSign,
  handleWrongNetId,
} from "./handle-msg-errors";
import handleSuccessfulVerification from "./handle-successful-verification";
import setContentHash from "./set-content-hash";

const handleSetContentHash = async (params) => {
  const { state, setState, ensDomain, ipns } = params;

  setState({
    ...state,
    error: null,
    loading: true,
  });

  const { domain } = ensDomain;

  let netId;
  let web3;

  try {
    const providerOptions = {
      walletconnect: {
        package: await WalletConnectProvider(setState),
        options: {
          infuraId: config.ethereum.infuraId,
        },
      },
      torus: {
        package: Torus,
      },
    };

    const web3Modal = new Web3Modal({
      cacheProvider: false,
      providerOptions,
    });

    web3Modal.providerController.cachedProvider = null;

    const provider = await web3Modal.connect();
    web3 = new Web3(provider);

    netId = await web3.eth.net.getId();
  } catch (e) {
    handleFailedToConnect(params);
    return;
  }

  const accounts = await web3.eth.getAccounts();

  // if (accounts[0] !== ownerAddress) {
  //   handleNotOwnerOfDomain(params);
  //   return;
  // }

  if (netId !== config.ethereum.netId) {
    handleWrongNetId(params);
    return;
  }
  let isIpnsAlreadySet = false;

  try {
    const { decoded: retrievedIpns } = await web3.eth.ens.getContenthash(
      domain,
    );
    const decodedRetrievedIpns = contentHash.decode(retrievedIpns);
    if (decodedRetrievedIpns === ipns) {
      isIpnsAlreadySet = true;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  if (isIpnsAlreadySet) {
    try {
      const signature = await web3.eth.personal.sign(
        `I registered site ${ensDomain.domain}`,
        accounts[0],
      );
      handleSuccessfulVerification({
        ...params,
        domain,
        state,
        setState,
        signature,
      });
    } catch (e) {
      handleFailedToSign(params);
    }
    return;
  }

  setContentHash({
    ...params,
    domain,
    state,
    setState,
    web3,
    account: accounts[0],
  });
};

const WalletConnectProvider = async (setState) => {
  const provider = await createWalletConnectProvider({
    clearSession: true,
    showQrModal: true,
  });

  provider.on("connect", () => {
    setState((state) => ({
      ...state,
      error: {
        type: "warning",
        message: "Confirm the transaction in your wallet",
      },
    }));
  });

  return function WalletConnectProvider() {
    return provider;
  };
};

export default handleSetContentHash;
