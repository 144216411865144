import { useQuery } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import StripedList from "@terminal-packages/ui/core/StripedList";
import CID from "cids";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { newApiClient } from "src/clients";
import { GET_DEAL_STATUS } from "src/graphql/queries";
import { ChangeSiteNameModal } from "src/modals";

import presenter from "../../../../Overview/presenter";
import useStyles from "./styles";

const SiteDetails = ({ i18n, siteBySlug }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showChangeNameModal, setChangeNameModal] = React.useState(false);
  const ipfsHash = get(
    siteBySlug,
    "data.getSiteBySlug.publishedDeploy.ipfsHash"
  );

  const { data: dealData } = useQuery(GET_DEAL_STATUS, {
    client: newApiClient,
    fetchPolicy: "cache-and-network",
    skip: !ipfsHash,
    variables: {
      hash: ipfsHash ? new CID(ipfsHash).toV1().toString() : ipfsHash,
    },
  });

  const details = presenter.getSiteDetails({ t, siteBySlug, dealData });

  return (
    <>
      {showChangeNameModal && (
        <ChangeSiteNameModal
          open
          siteBySlug={siteBySlug}
          onClose={() => setChangeNameModal(false)}
        />
      )}
      <CardTitled
        mainContent={i18n.title}
        classes={{
          content: classes.sectionContent,
        }}
      >
        <StripedList>
          {Object.entries(details).map(([key, value]) => (
            <div key={key} className={classes.textContainer}>
              <Typography variant="body2">
                {`${t(`sites.overview.${key}`)}:`}
              </Typography>
              <Typography
                noWrap
                variant="body2"
                className={classes.secondColumn}
              >
                {value}
              </Typography>
            </div>
          ))}
        </StripedList>
        <div className={classes.buttonContainer}>
          <GenericButton
            buttonVariant="secondary"
            onClick={() => setChangeNameModal(true)}
          >
            {t("siteSettings.general.siteDetails.changeName")}
          </GenericButton>
        </div>
      </CardTitled>
    </>
  );
};

SiteDetails.propTypes = {
  i18n: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  siteBySlug: PropTypes.shape({}).isRequired,
};

export default SiteDetails;
