import { ApolloLink } from "apollo-link";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { projectAuth } from "src/firebase";

import config from "../../../config";
import httpErrorHandler from "./error-handler";
import httpLink from "./http";

const awsAuth = {
  url: config.newApi.baseURL,
  region: "us-west-2",
  auth: {
    type: "OPENID_CONNECT",
    jwtToken: async () => {
      const idToken = await projectAuth.currentUser?.getIdToken();

      return idToken ?? null;
    },
  },
};

export default ApolloLink.from([
  httpErrorHandler,
  createAuthLink(awsAuth),
  createSubscriptionHandshakeLink(awsAuth, httpLink),
]);
