import get from "lodash/get";
import { getDeployStatus, getSiteName, url } from "src/utils";

const getSite = (site) => {
  const slug = get(site, "slug");
  const siteName = getSiteName(site);
  const siteOwner = get(site, "team.name", "-");
  const ipfsHash = get(site, "latestDeploy.ipfsHash");
  const imageUrl = get(site, "latestDeploy.previewImage");
  const githubUrl = get(site, "latestDeploy.repository.url");
  const lastPublishedTimestamp = get(site, "latestDeploy.completedAt", "");
  const startedAt =
    get(site, "publishedDeploy.startedAt", null) ||
    get(site, "latestDeploy.startedAt", "");
  const statusField =
    get(site, "publishedDeploy.status", null) ||
    get(site, "latestDeploy.status", "");
  const status = getDeployStatus({
    status: statusField,
    startedAt,
  });
  const ipfsUrl = ipfsHash ? `https://ipfs.io/ipfs/${ipfsHash}` : null;
  const cardOnClickDestination = url.buildUrl(null, `/sites/${slug}/overview`);
  const platform = get(site, "platform", "ipfs");
  const source = get(site, "deploySettings.source", {});

  return {
    ipfsUrl,
    siteName,
    imageUrl,
    githubUrl,
    siteOwner,
    cardOnClickDestination,
    lastPublishedTimestamp,
    status,
    platform,
    source,
  };
};

const presenter = {
  getSite,
};

export default presenter;
