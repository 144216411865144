type Variable = { name: string; value: string };

export enum AllowedMimeTypes {
  JSON = 'application/json',
  ENV = "",
  TEXT = 'text/plain'
}

export const ALLOWED_MIME_TYPES = Object.values(AllowedMimeTypes);

const singleQuote = "'";
const doubleQuote = '"';
const removeQuotesFromValue = (value: string) => {
  const firstChar = value[0];
  const lastChar = value[value.length - 1];
  if ((firstChar === singleQuote && lastChar === singleQuote) || (firstChar === doubleQuote && lastChar === doubleQuote)) {
    return value.substring(1, value.length - 1);
  }
  return value;
}

const parseJSONFile = (fileContents: string) => {
  const object = JSON.parse(fileContents);
  return Object.entries(object)
    .filter(([_, value]) => typeof value === 'string')
    .map(([key, value]) => {
      return { name: key, value: removeQuotesFromValue(value as string) }
    })
}

const parseTextContents = (fileContents: string) => {
  const lines = fileContents.split(/\r?\n/);

  return lines.reduce((result, line) => {
    const splittedLine = line.split('=');
    if (splittedLine.length < 2) {
      return result;
    }

    const key = splittedLine[0];
    let value = splittedLine[1];

    result.push({ name: key, value: removeQuotesFromValue(value) });
    return result;
  }, [] as Variable[]);
}

export const getEnvVariables = (file: File, onParse: (variables: Variable[]) => void) => {
  var reader = new FileReader();
  reader.onload = function(e) {
    var contents = e.target?.result as string;
    let variables: Variable[] = [];

    if (file.type === AllowedMimeTypes.JSON) {
      variables = parseJSONFile(contents);
    } else {
      variables = parseTextContents(contents)
    }

    onParse(variables);
  };
  reader.readAsText(file);
}