// @ts-nocheck
import { Grid, Link } from "@fleekhq/react-drip";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useTeam } from "src/store/AuthContext";

import {
  BannerContainer,
  BannerText,
  Header,
  LogoImg,
} from "./auth-header.styled";

export const AuthHeader = forwardRef<any, any>(function AuthHeader(props, ref) {
  const { t } = useTranslation();
  const team = useTeam();

  let bannerComponent = (
    <BannerText>
      ⚡ The Fleek.xyz platform is now live. Go to&nbsp;
      <Link
        isExternal
        href="https://app.fleek.xyz"
        css={{
          color: "white",
          textDecoration: "underline",
          fontWeight: "600",
        }}
      >
        app.fleek.xyz
      </Link>
      &nbsp; and start experiencing the new era of Fleek ⚡
    </BannerText>
  );

  if (
    team?.scheduledMigrationRequest &&
    team?.scheduledMigrationRequest.triggerAfter > new Date()
  ) {
    bannerComponent = (
      <BannerText>
        {`⚡ Migration of your team is scheduled to ${team?.scheduledMigrationRequest.triggerAfter.toLocaleString()}`}
      </BannerText>
    );
  } else if (team?.scheduledMigrationRequest) {
    bannerComponent = (
      <BannerText>
        {`⚡ Migration of your team ${
          team?.scheduledMigrationRequest.status
        } in ${team?.scheduledMigrationRequest.triggerAfter.toLocaleString()}`}
      </BannerText>
    );
  }

  return (
    <>
      <BannerContainer>{bannerComponent}</BannerContainer>
      <Header ref={ref} {...props}>
        <LogoImg
          src="https://storage.googleapis.com/terminal-assets/images/fleek/fleek-logo.png"
          alt="fleek-logo"
        />

        <Grid css={{ gridAutoFlow: "column", gap: "$8", color: "$slate11" }}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://blog.fleek.co/"
          >
            {t("auth.header.blog") as string}
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/fleek"
          >
            {t("auth.header.community") as string}
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.fleek.co/"
          >
            {t("auth.header.docs") as string}
          </Link>
        </Grid>
      </Header>
    </>
  );
});
