import { useQuery } from "@apollo/react-hooks";
import Box from "@terminal-packages/ui/core/Box";
import get from "lodash/get";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";
import { FileCard } from "src/components";
import { DOMAIN_STATUS } from "src/constants";
import { getStorageObjectPath } from "src/utils";

import { fetchObjects } from "../../actions";
import { GET_BUCKET_BY_SLUG, GET_STORAGE_OBJECT } from "../../graphql";
import objectsSelector from "../../utils/objects-selector";
import ButtonsGroup from "../ButtonsGroup";
import { CustomDomain } from "../CustomDomain";
import Search from "../Search";
import Table from "../Table";
import useStyles from "./styles";

const Folder = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { params } = useRouteMatch();
  const bucketSlug = params.bucketName;
  const pathnameArr = location.pathname.split("/");
  const folderId = pathnameArr.pop();

  const prefix = get(params, "0");

  const { folder, isFolderObjectsLoading } = useSelector((state) => {
    const rows = objectsSelector(state, bucketSlug, prefix, "/");

    const fol = state.storage.objects.find(
      (obj) => obj.fullKey === `${bucketSlug}/${prefix}/`
    );

    return {
      folder: fol,
      isFolderObjectsLoading: !rows.length && state.storage.loading,
    };
  });

  useEffect(() => {
    dispatch(
      fetchObjects({
        Prefix: `${prefix}/`,
        Delimiter: "/",
        Bucket: bucketSlug,
      })
    );
  }, [prefix, bucketSlug, dispatch]);

  const { data, loading } = useQuery(GET_STORAGE_OBJECT, {
    client: newApiClient,
    variables: {
      bucket: bucketSlug,
      key: `${prefix}/`,
    },
  });

  const getBucketBySlugResult = useQuery(GET_BUCKET_BY_SLUG, {
    client: newApiClient,
    fetchPolicy: "cache-and-network",
    variables: {
      slug: bucketSlug,
    },
  });


  const apiBucket = getBucketBySlugResult?.data?.getBucketBySlug;

  const validCustomDomain = apiBucket?.domainStatus === DOMAIN_STATUS.PROPAGATED && apiBucket?.domain
  const objectUrl = getStorageObjectPath(
    bucketSlug, 
    prefix, 
    validCustomDomain
  );

  // TODO: lastModification, ipfsHash and url after BE integration
  return (
    <>
      <FileCard
        loadingHash={loading}
        ipfsHash={get(data, "getStorageObject.hash", "") || ""}
        fileName={folderId}
        url={objectUrl}
        icon="folder"
        status="success"
        hasCustomDomain={!!validCustomDomain}
        lastModification={
          folder
            ? t("storage.lastModified", {
                date: moment(folder.lastModification).calendar(),
              })
            : ""
        }
      />
      <br />
      <CustomDomain
        isLoading={getBucketBySlugResult.loading}
        bucket={{
          ...(getBucketBySlugResult.data?.getBucketBySlug || {}),
          slug: bucketSlug,
        }}
      />
      <br />
      <Box padding="20px 0">
        <div className={classes.actionsHeader}>
          <Search />
          <ButtonsGroup
            delimiter="/"
            prefix={prefix}
            bucket={bucketSlug}
            disabled={isFolderObjectsLoading}
          />
        </div>

        <Table delimiter="/" prefix={prefix} bucket={bucketSlug} />
      </Box>
    </>
  );
};

export default Folder;
