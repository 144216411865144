import { dripStitches } from "@fleekhq/react-drip";

const { styled } = dripStitches;

export const Header = styled("header", {
  position: "absolute",
  left: "0",
  right: "0",
  top: "45px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "25px",
  maxWidth: "1024px",
  margin: "0 auto",
});

export const LogoImg = styled("img", {
  width: "75px",
  height: "33px",
});

export const BannerContainer = styled("div", {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: '$blue10',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '3px 10px',
  zIndex: 10,
  height: '45px',
});

export const BannerText = styled("p", {
  color: 'white',
  margin: 0,
  fontSize: 14,
  fontWeight: 600
});
