// @ts-nocheck
import "firebase/auth";

import Typography from "@material-ui/core/Typography";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import InputSlim from "@terminal-packages/ui/core/InputSlim";
import { toast } from "@terminal-packages/ui/core/Toast";
import firebase from "firebase/app";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { phoneAuthProvider } from "src/firebase";
import { useAuth } from "src/store/AuthContext";

import useStyles from "./styles";
import mask from "./utils";

export const VerifyCode = ({
  open,
  closeModal,
  phoneNumber,
  verificationId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const [state, setState] = useState({
    code: "",
    loading: false,
  });
  const [verificationDetails, setVerificationDetails] =
    useState(verificationId);
  const [captchaVerifier, setCaptchaVerifier] = useState(null);

  useEffect(() => {
    const newRecaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "2fa-captcha",
      {
        size: "invisible",
        callback: () => {
          // reCAPTCHA solved
        },
      }
    );
    setCaptchaVerifier(newRecaptchaVerifier);
  }, []);

  const handleCodeChange = (value) => {
    setState({
      ...state,
      code: value,
    });
  };

  const handleResendCode = async (event) => {
    event.preventDefault();
    try {
      const session = await currentUser.multiFactor.getSession();
      const phoneOpts = {
        phoneNumber,
        session,
      };
      const newVerificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneOpts,
        captchaVerifier
      );
      setVerificationDetails(newVerificationId);
    } catch (error) {
      toast.error(`${t("modals.getTwoFa.failed")}: ${error.message}`, {
        autoClose: 6000,
      });
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const handleVerifyCode = async (event) => {
    event.preventDefault();
    setState({
      ...state,
      loading: true,
    });
    try {
      const cred = await firebase.auth.PhoneAuthProvider.credential(
        verificationDetails,
        state.code
      );
      const multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      await currentUser.multiFactor.enroll(
        multiFactorAssertion,
        "phone number"
      );
      window.location.reload();
      closeModal();
    } catch (error) {
      toast.error(`${t("modals.getTwoFa.failed")}: ${error.message}`, {
        autoClose: 6000,
      });
      setState({
        ...state,
        loading: false,
      });
    }
  };

  return (
    <>
      <BaseModal
        open={open}
        title={t("modals.getTwoFa.code.title")}
        maxWidth={500}
        onClose={closeModal}
        className={classes.modal}
      >
        <form id="verifyCodeForm" onSubmit={handleVerifyCode}>
          <Typography variant="body2" className={classes.description}>
            {t("modals.getTwoFa.code.description")} {mask(phoneNumber, 4)}
          </Typography>
          <div className={classes.inputContainer}>
            <InputSlim
              label={t("modals.getTwoFa.code.enterCode")}
              value={state.code}
              onChange={handleCodeChange}
            />
          </div>
          <Typography
            variant="body2"
            className={classes.codeNotSent}
            onClick={handleResendCode}
          >
            {t("modals.getTwoFa.code.codeNotSent")}
          </Typography>
          <div className={classes.buttonContainer}>
            <GenericButton buttonVariant="secondary" onClick={closeModal}>
              {t("modals.getTwoFa.cancel")}
            </GenericButton>
            <GenericButton
              buttonVariant="primary"
              disabled={!state.code || state.loading}
              loading={state.loading}
              type="submit"
              form="verifyCodeForm"
            >
              {t("modals.getTwoFa.confirm")}
            </GenericButton>
          </div>
        </form>
      </BaseModal>
      <div id="2fa-captcha" className={classes.twoFaCaptcha} />
    </>
  );
};

VerifyCode.defaultProps = {
  open: false,
};

VerifyCode.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  verificationId: PropTypes.string.isRequired,
};
