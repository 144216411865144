import get from "lodash/get";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { newApiClient } from "src/clients";
import { LoadingScreen } from "src/components";
import { GET_MEMBERSHIPS } from "src/graphql/queries";
import { getOlderItem, url } from "src/utils";

import { projectAuth } from "../../firebase";

const Root = () => {
  const [state, setState] = useState({
    teamId: url.getAccountIdFromUrl(),
    loading: !url.getAccountIdFromUrl(),
    error: false,
  });

  /* eslint-disable consistent-return */
  useEffect(() => {
    const getTeamId = async () => {
      try {
        const { data } = await newApiClient.query({
          query: GET_MEMBERSHIPS,
          skip: !projectAuth.currentUser,
          fetchPolicy: "network-only",
        });

        const memberships = get(data, "getMemberships.memberships", []) || [];
        const [firstTeam] = [...memberships].sort(getOlderItem);
        const teamId = get(firstTeam, "teamId", null) || null;

        setState({
          ...state,
          ...(teamId && { teamId }),
          loading: !teamId,
          error: !teamId,
        });
      } catch (error) {
        /* eslint-disable no-console */
        console.error(error);
      }
    };

    const polling = setInterval(() => {
      if (state.loading && !state.teamId) {
        getTeamId();
      }
    }, 500);

    return () => clearInterval(polling);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.loading) {
    return <LoadingScreen />;
  }

  return state.error ? (
    <Redirect to="/error/500" />
  ) : (
    <Redirect
      to={url.buildUrl(
        { accountId: state.teamId },
        `/teams/${state.teamId}/sites`
      )}
    />
  );
};

export default Root;
