import { useQuery } from "@apollo/react-hooks";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { ButtonWithIcon } from "@terminal-packages/ui/core/Buttons";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import List from "@terminal-packages/ui/core/List";
import ListItem from "@terminal-packages/ui/core/ListItem";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { newApiClient } from "src/clients";
import { GET_TEAM_BY_ID } from "src/graphql/queries";
import { EDIT_TEAM_INFO_MODAL, openModal } from "src/modals/actions";
import { url } from "src/utils";

import useStyles from "./styles";

const TeamDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const teamId = url.getAccountIdFromUrl();

  const { data, loading } = useQuery(GET_TEAM_BY_ID, {
    client: newApiClient,
    variables: {
      id: teamId,
    },
  });

  const teamName = get(data, "getTeamById.name") || "";

  const teamDetailsEntries = Object.entries({
    teamName,
    id: teamId,
    currentMembers: get(data, "getTeamById.membersCount") || "0",
    pendingInvites: get(data, "getTeamById.pendingInviteCount") || "0",
  });

  const editInfoOnClick = () =>
    dispatch(
      openModal(EDIT_TEAM_INFO_MODAL, {
        teamId,
        currentTeamName: teamName,
      })
    );

  const EditButton = (
    <ButtonWithIcon
      icon={<FontAwesomeIcon icon={faPencil} size="lg" />}
      classes={{
        iconAndTextContainer: classes.editIconAndTextContainer,
        iconContainer: classes.editIcon,
      }}
      onClick={editInfoOnClick}
      disabled={loading}
    >
      {t("teamSettings.teamDetails.edit")}
    </ButtonWithIcon>
  );

  return (
    <section className={classes.root}>
      <Typography variant="body1">
        <Box component="span" fontWeight={500}>
          {t("teamSettings.teamDetails.title")}
        </Box>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("teamSettings.teamDetails.description")}
      </Typography>
      <br />
      <CardTitled
        mainContent={t("teamSettings.teamDetails.card.title")}
        secondaryContent={EditButton}
        classes={{
          content: classes.content,
        }}
      >
        <List striped>
          {teamDetailsEntries.map(([key, value]) => (
            <ListItem key={key} className={classes.item}>
              <Typography
                variant="body2"
                color="textPrimary"
                className={classes.key}
              >
                {`${t(`teamSettings.teamDetails.card.${key}`)}:`}
              </Typography>
              {loading ? (
                <Skeleton
                  width={240}
                  height={20}
                  variant="rect"
                  animation="wave"
                  classes={{
                    root: classes.skeletonRoot,
                  }}
                />
              ) : (
                <Typography noWrap variant="body2">
                  <Box component="span" fontWeight={500}>
                    {value}
                  </Box>
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
      </CardTitled>
    </section>
  );
};

export default TeamDetails;
