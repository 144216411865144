import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EnvironmentVariables } from "src/components";

import useStyles from "./styles";

export type AdvancedSectionProps = {
  state: any;
  setState: any;
};

const AdvancedSection = ({ state, setState }: AdvancedSectionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isShownAdvanced, setIsShownAdvanced] = useState(false);
  const isAnyEnvVar = state.envVars.length > 0;

  useEffect(() => {
    if (isAnyEnvVar) {
      setIsShownAdvanced(true);
    }
  }, [isAnyEnvVar]);

  const onClickShowAdvanced = () => {
    setIsShownAdvanced(true);
  };

  const onChangeEnvVars = (envVars: any) => {
    setState({
      ...state,
      envVars,
      isEnvVarsError: envVars.some(({ errorMessage }: any) => errorMessage),
    });
  };

  if (isShownAdvanced) {
    return (
      <div>
        <div>
          <Typography variant="body1" className={classes.title}>
            {t("sites.start.buildOptions.envVars.title") as string}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t("sites.start.buildOptions.envVars.subtitle") as string}
          </Typography>
          <div className={classes.settingWrapper}>
            <EnvironmentVariables
              variables={state.envVars}
              onChange={onChangeEnvVars}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <GenericButton
      buttonVariant="secondary"
      onClick={onClickShowAdvanced}
      css={undefined}
    >
      {t("sites.start.buildOptions.showAdvanced") as string}
    </GenericButton>
  );
};

AdvancedSection.propTypes = {
  state: PropTypes.shape({
    envVars: PropTypes.array,
    dockerImage: PropTypes.string,
    dfinityUseProxy: PropTypes.bool,
  }).isRequired,
  setState: PropTypes.func.isRequired,
};

export default AdvancedSection;
