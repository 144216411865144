import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import ListItem from "@terminal-packages/ui/core/ListItem";

import useStyles from "./styles";

export type OptionProps = {
  name: string;
  warning?: boolean;
  onClick: () => void;
};

export const Option = ({ name, warning = false, onClick }: OptionProps) => {
  const classes = useStyles();
  const color = warning ? "error" : "primary";

  return (
    <ListItem className={classes.option}>
      <ButtonBase type="button" onClick={onClick}>
        <Typography color={color} variant="caption">
          {name}
        </Typography>
      </ButtonBase>
    </ListItem>
  );
};
