const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

const formatBytes = (bytes, decimals = 2, isBase10 = false) => {
  const base = isBase10 ? 1000 : 1024;

  if (bytes === 0) return '0 Bytes';

  const i = Math.floor(Math.log(bytes) / Math.log(base));
  const value = parseFloat(bytes / base ** i).toFixed(decimals);

  return `${value} ${units[i]}`;
};

export default formatBytes;
