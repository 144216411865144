// @ts-nocheck
import { useMutation } from "@apollo/react-hooks";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import InputSlim from "@terminal-packages/ui/core/InputSlim";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { newApiClient } from "src/clients";
import { EDIT_TEAM } from "src/graphql/mutations";
import { GET_MEMBERSHIPS } from "src/graphql/queries";

import useStyles from "./styles";

const TRANSITION_TIMEOUT = 300;

export const EditTeamInfo = ({ open, closeModal, teamId, currentTeamName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [teamName, setTeamName] = useState(currentTeamName);

  const validName = teamName !== "" && teamName !== currentTeamName;

  const [editTeamInfo] = useMutation(EDIT_TEAM, {
    client: newApiClient,
    update(cache, { data }) {
      const newTeamName = data.editTeamName.name;
      const existingMemberships = cache.readQuery({
        query: GET_MEMBERSHIPS,
      });

      if (newTeamName && existingMemberships) {
        const newExistingMemberships = {
          ...existingMemberships,
          getMemberships: {
            ...existingMemberships.getMemberships,
            memberships: existingMemberships.getMemberships.memberships.map(
              (site) => {
                if (site.teamId === teamId) {
                  return {
                    ...site,
                    teamName: newTeamName,
                  };
                }

                return site;
              }
            ),
          },
        };

        cache.writeQuery({
          query: GET_MEMBERSHIPS,
          data: newExistingMemberships,
        });
      }
    },
  });

  const displayError = () => {
    setError(true);

    setTimeout(() => {
      setError(false);
    }, 5500);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const { errors } = await editTeamInfo({
        errorPolicy: "all",
        variables: {
          input: {
            teamId,
            name: teamName,
          },
        },
      });

      if (!errors) {
        closeModal();
        return;
      }

      displayError();
      setLoading(false);
    } catch (e) {
      setLoading(false);

      displayError();
    }
  };

  return (
    <BaseModal
      open={open}
      title={t("modals.editTeamInfo.title")}
      maxWidth={500}
      onClose={closeModal}
      className={classes.modal}
    >
      <Typography variant="body2" className={classes.description}>
        {t("modals.editTeamInfo.description")}
      </Typography>
      <div className={classes.inputContainer}>
        <InputSlim
          label={t("modals.editTeamInfo.name")}
          value={teamName}
          onChange={setTeamName}
        />
      </div>
      {error && (
        <Grow in={error} timeout={TRANSITION_TIMEOUT}>
          <div variant="body" className={classes.errorBox}>
            {t("modals.editTeamInfo.error")}
          </div>
        </Grow>
      )}
      <div className={classes.buttonContainer}>
        <GenericButton
          buttonVariant="secondary"
          onClick={closeModal}
          disabled={loading}
        >
          {t("modals.editTeamInfo.cancel")}
        </GenericButton>
        <GenericButton
          buttonVariant="primary"
          onClick={handleSubmit}
          disabled={!validName || loading}
        >
          {t("modals.editTeamInfo.continue")}
        </GenericButton>
      </div>
    </BaseModal>
  );
};

EditTeamInfo.defaultProps = {
  open: false,
};

EditTeamInfo.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  currentTeamName: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
};
