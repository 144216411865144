import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  input: {
    marginBottom: 10,
  },
  button: {
    marginTop: 10,
  },
  error: {
    margin: 0,
    fontSize: 13,
  },
  alert: {
    marginBottom: 20,
  },
  description: {
    marginBottom: 20,
    color: theme.palette.palette.grey2,
  },
}));
