
export const SET_CHAIN = 'SET_CHAIN';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_IS_CONNECTED = 'SET_IS_CONNECTED';
export const SET_WEB3_PROVIDER = 'SET_WEB3_PROVIDER';
export const SET_ENS = 'SET_ENS';
export const RESET_STATE = 'RESET_STATE';

export const setChain = (state) => ({ type: SET_CHAIN, payload: state });
export const setAccounts = (state) => ({ type: SET_ACCOUNTS, payload: state });
export const setIsConnected = (state) => ({ type: SET_IS_CONNECTED, payload: state });
export const setWeb3Provider = (state) => ({ type: SET_WEB3_PROVIDER, payload: state });
export const setENS = (state) => ({ type: SET_ENS, payload: state });
export const resetState = () => ({ type: RESET_STATE });
