import {
  CREATE_REACT_APP,
  GATSBY,
  GRIDSOME,
  HUGO,
  JEKYLL,
  MKDOCS,
  NEXT_JS,
  NUXT_JS,
  OTHER,
  SAPPER,
  SVELTE,
  WASM_PACK,
  WORDPRESS,
} from "../../../constants";

// TODO: Update TS types
const getSettingsByFramework = (defaults: any) => ({
  [OTHER]: {
    framework: OTHER,
    ...defaults,
  },
  [GATSBY]: {
    framework: GATSBY,
    buildCommand: defaults.buildCommand || "yarn && yarn build",
    publishDirectory: "public",
    dockerImage: "fleek/gatsby:node-16",
  },
  [HUGO]: {
    framework: HUGO,
    buildCommand: "hugo",
    publishDirectory: "public",
    dockerImage: "fleek/hugo-v0.85",
  },
  [NEXT_JS]: {
    framework: NEXT_JS,
    buildCommand: "npm install && npm run build && npm run export",
    publishDirectory: "out",
    dockerImage: "fleek/next-js:node-16",
  },
  [CREATE_REACT_APP]: {
    framework: CREATE_REACT_APP,
    buildCommand: defaults.buildCommand || "yarn && yarn build",
    publishDirectory: "build",
    dockerImage: "fleek/create-react-app:node-16",
  },
  [JEKYLL]: {
    framework: JEKYLL,
    buildCommand: "jekyll build",
    publishDirectory: "_site",
    dockerImage: "fleek/jekyll",
  },
  [NUXT_JS]: {
    framework: NUXT_JS,
    buildCommand: `${defaults.installCommand} && ${defaults.runScriptCommand} build && ${defaults.runScriptCommand} export`,
    publishDirectory: "dist",
    dockerImage: "fleek/nuxtjs:node-16",
  },
  [WORDPRESS]: {
    framework: WORDPRESS,
    buildCommand: "",
    publishDirectory: "",
    dockerImage: "",
  },
  [GRIDSOME]: {
    framework: GRIDSOME,
    buildCommand: defaults.buildCommand || "yarn && yarn build",
    publishDirectory: "dist",
    dockerImage: "fleek/gridsome:node-16",
  },
  [SVELTE]: {
    framework: SVELTE,
    buildCommand: defaults.buildCommand || "yarn && yarn build",
    publishDirectory: "public",
    dockerImage: "fleek/svelte:node-16",
  },
  [MKDOCS]: {
    framework: MKDOCS,
    buildCommand: defaults.buildCommand || "mkdocs build",
    publishDirectory: "site",
    dockerImage: "fleek/mkdocs",
  },
  [SAPPER]: {
    framework: SAPPER,
    buildCommand: `${defaults.installCommand} && ${defaults.runScriptCommand} export`,
    publishDirectory: "__sapper__/export",
    dockerImage: "fleek/svelte:node-16",
  },
  [WASM_PACK]: {
    framework: WASM_PACK,
    buildCommand: defaults.buildCommand || "yarn && yarn build",
    publishDirectory: "dist",
    dockerImage: "fleek/wasm-pack",
  },
});

export default getSettingsByFramework;
