import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { VirtualizedFilesTable } from "src/components";
import { useAccountId } from "src/hooks";
import { url } from "src/utils";

import { editObject, editObjects, unselectAllObjects } from "../../actions";
import objectsSelector from "../../utils/objects-selector";
import useStyles from "./styles";

const ROW_HEIGHT = 35;
const LOADING_ROWS = 5;
const HEADER_HEIGHT = 35;
const PADDING_BOTTOM = 40;
const TABLE_OFFSET_TOP = 320;

const getTableContainerStyles = (rowsLength = 0, loading) => {
  if (loading) return { minHeight: ROW_HEIGHT * LOADING_ROWS + HEADER_HEIGHT };
  if (rowsLength <= 0) return { minHeight: HEADER_HEIGHT };

  const maxHeight = window.innerHeight - TABLE_OFFSET_TOP - PADDING_BOTTOM;

  return {
    height: ROW_HEIGHT * rowsLength + HEADER_HEIGHT,
    maxHeight: maxHeight < ROW_HEIGHT * 3 ? 200 : maxHeight,
  };
};

const Table = (props) => {
  const { bucket, prefix, delimiter } = props;

  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountId = useAccountId();
  const loading = useSelector((state) => state.storage.loading);

  const rows = useSelector((state) =>
    objectsSelector(state, bucket, prefix, delimiter)
  );

  const isCacheRows = rows.length > 0;
  const tableLoading = loading && !isCacheRows;

  const [containerStyles, setContainerStyles] = useState(
    getTableContainerStyles(rows.length, tableLoading)
  );

  useEffect(() => {
    const resizeHandler = () =>
      setContainerStyles(getTableContainerStyles(rows.length, tableLoading));

    resizeHandler();

    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, [rows.length, tableLoading]);

  const allItemsSelected =
    rows.length < 1 ? false : rows.every((_row) => _row.selected);

  const head = [
    {
      label: "",
      dataKey: "select",
      width: 68,
    },
    {
      label: t("storage.table.columns.name"),
      dataKey: "name",
      width: 120,
      flexGrow: 2,
    },
    {
      label: t("storage.table.columns.lastModified"),
      dataKey: "lastModified",
      width: 120,
      flexGrow: 1,
    },
    {
      label: t("storage.table.columns.size"),
      dataKey: "bytesSize",
      width: 120,
      flexGrow: 1,
    },
  ];

  /* eslint-disable no-console */
  const onSingleClickRow = (row) => {
    dispatch(
      editObject({
        ...row,
        selected: !row.selected,
      })
    );
  };

  const onDoubleClickRow = (row) => {
    const pathKey = row.type === "folder" ? "folder" : "object";
    const objectPath =
      row.type === "folder" ? row.key.replace(/\/$/, "") : row.key;

    const redirectUrl = url.buildUrl(
      null,
      `/teams/${accountId}/storage/${bucket}/${pathKey}/${objectPath}`
    );

    dispatch(unselectAllObjects());

    history.push(redirectUrl);
  };

  const onSelectAllClick = () => {
    if (rows.length < 1) return;

    if (allItemsSelected) {
      const newObjects = rows.map((_row) => ({
        ..._row,
        selected: false,
      }));

      dispatch(editObjects(newObjects));
    } else {
      const newObjects = rows.map((_row) => ({
        ..._row,
        selected: true,
      }));

      dispatch(editObjects(newObjects));
    }
  };

  return (
    <div className={classes.root}>
      <div style={{ width: "100%", ...containerStyles }} id="table-container">
        <VirtualizedFilesTable
          head={head}
          rows={rows}
          loading={tableLoading}
          loadingRows={LOADING_ROWS}
          onSingleClickRow={onSingleClickRow}
          onDoubleClickRow={onDoubleClickRow}
          onSelectAllClick={onSelectAllClick}
          allItemsSelected={!loading && allItemsSelected}
        />
      </div>
      {!loading && (!rows || rows.length === 0) && (
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.text}
        >
          {t("storage.table.emptyMessage")}
        </Typography>
      )}
    </div>
  );
};

Table.defaultProps = {
  bucket: "",
  prefix: null,
  delimiter: "/",
};

Table.propTypes = {
  prefix: PropTypes.string,
  bucket: PropTypes.string,
  delimiter: PropTypes.string,
};

export default Table;
