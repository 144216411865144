import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";

import { GET_SITE_BY_SLUG } from "../../../../views/Sites/graphql/queries";
import AddEnsButtons from "../../components/AddEnsButtons";
import AddEnsDescription from "../../components/AddEnsDescription";
import AlreadyHasOwner from "../../components/AlreadyHasOwner";
import BuyDomain from "../../components/BuyDomain";
import EnsDomainField from "../../components/EnsDomainField";
import VerifyButtons from "../../components/VerifyButtons";
import { STEPS } from "./constants";

const AddEnsDomainForm = ({ onModalClose }) => {
  const [state, setState] = useState({
    domain: "",
    loading: false,
    step: STEPS.VERIFY,
    ensDomainInfo: null,
  });

  const match = useRouteMatch();
  

  const { data } = useQuery(GET_SITE_BY_SLUG, {
    client: newApiClient,
    variables: {
      slug: match.params.siteSlug,
    },
    fetchPolicy: "cache-and-network",
  });

  const siteId = get(data, "getSiteBySlug.id", "");


  const formByStep = {
    [STEPS.VERIFY]: (
      <>
        <EnsDomainField state={state} setState={setState} />
        <VerifyButtons state={state} setState={setState} onClose={onModalClose} />
      </>
    ),
    [STEPS.ALREADY_HAS_OWNER]: (
      <>
        <EnsDomainField state={state} setState={setState} />
        <AlreadyHasOwner state={state} />
        <AddEnsButtons state={state} setState={setState} siteId={siteId} />
      </>
    ),
    [STEPS.BUY_A_DOMAIN]: (
      <>
        <EnsDomainField state={state} setState={setState} />
        <BuyDomain state={state} setState={setState} />
        <AddEnsButtons state={state} setState={setState} siteId={siteId} />
      </>
    ),
  };

  return (
    <>
      <AddEnsDescription />
      {formByStep[state.step] || null}
    </>
  );
};

export default AddEnsDomainForm;
