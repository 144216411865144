/* eslint-disable */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGithubToken } from "src/hooks";
import Typography from "@material-ui/core/Typography";
import IconFA from "@terminal-packages/ui/core/IconFA";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";

import { PickRepository } from "src/components";
import useStyles from "./styles";

const ConnectToGithub = ({ onChange, initialValue }) => {
  const [step, setStep] = useState("CONNECT_TO_GITHUB");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const [, githubToken] = useGithubToken();
  const [selectedRepoUrl, setSelectedRepoUrl] = useState(initialValue);

  useEffect(() => {
    if (githubToken && loading) {
      setStep("SELECT_REPO");
    }
  }, [githubToken]);

  const handleOnGithubClick = () => {
    if (githubToken) {
      setStep("SELECT_REPO");
      return;
    }
    setLoading(true);
  };

  const onSuccess = ({ installationId, accountName, repositoryName }) => {
    const newUrl = `https://api.github.com/repos/${accountName}/${repositoryName}`;

    setSelectedRepoUrl(newUrl);
    onChange(`https://api.github.com/repos/${accountName}/${repositoryName}`, {
      accountName,
      repositoryName,
      installationId,
      url: newUrl,
    });
    setStep("CONNECT_TO_GITHUB");
  };

  if (step === "CONNECT_TO_GITHUB") {
    return (
      <div>
        <Typography variant="body2" className={classes.value}>
          {selectedRepoUrl}
        </Typography>
        <GenericButton
          buttonVariant="branded"
          onClick={handleOnGithubClick}
          loading={loading}
          overrideClass={{ button: classes.button }}
        >
          <IconFA
            className={classes.icon}
            icon={["fab", "github"]}
            size="small"
          />
          <Typography className={classes.buttonText} variant="button">
            {t("siteSettings.deployment.buildSettings.changeRepository")}
          </Typography>
        </GenericButton>
      </div>
    );
  }
  return (
    <PickRepository
      onError={() => setStep("CONNECT_TO_GITHUB")}
      onSuccess={onSuccess}
    />
  );
};

ConnectToGithub.propTypes = {
  initialValue: PropTypes.string.isRequired,
};

export default ConnectToGithub;
