import config from "src/config";

/**
 * Builds URL with path for storage object.
 * IE: https://storage-dev.fleek.zone/team-foo-bucket/foo.txt
 * @param {string} bucket bucket name
 * @param {string} restOfPath path to the object
 * @return {string} full object path
 */
 
const getStorageObjectPath = (bucketName, restOfPath = "", domain) => {
    // domain url lacks ending `/`
    let baseUrl = domain ? `${domain}/` : config.s3Client.baseURL;
    return `${baseUrl}${bucketName}/${restOfPath}`;
}

export default getStorageObjectPath;
