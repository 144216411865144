import { toast } from "@terminal-packages/ui";
import ComplexOverlayBox from "@terminal-packages/ui/core/ComplexOverlayBox"
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import manOnBrowserBackgroundSvg from "src/components/no-data-overlay/images/man-on-browser-background.svg";
import { useAccountId } from "src/hooks";
import { useAuth } from "src/store/AuthContext";
import { useTeam } from "src/store/AuthContext";

import useStyles from './styles';

export const MigrationGuard = () => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const accountId = useAccountId();
  const { migrationStatus, scheduledMigration, teamId } = useTeam(accountId);

  const [open, setOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState(null);
  const [localStorageKey, setLocalStorageKey] = useState();
  const migrationRef = useRef();

  useEffect(() => {
    if (!currentUser?.id || !teamId) return;

    const migrationRefKey = `migration:${teamId}:${currentUser.id}`
    if (migrationRef.current !== migrationRefKey) {
      setModalConfig(null);
      setOpen(false);
      migrationRef.current = migrationRefKey;
    }

    const scheduledLocalStorageKey = `SCHEDULED:${currentUser?.id}:${scheduledMigration?.teamId}`;
    const hasShownModal = localStorage.getItem(scheduledLocalStorageKey) ?? false;

    const modalConfigs = getModalConfigs(t, scheduledMigration);
    let selectedModalType = null;

    if (migrationStatus === 'COMPLETED') {
      selectedModalType = MODAL_TYPES.COMPLETE;
    } else if (migrationStatus === 'IN_PROGRESS') {
      selectedModalType = MODAL_TYPES.IN_PROGRESS;
    } else if (migrationStatus === 'MIGRATION_FAILED') {
      selectedModalType = MODAL_TYPES.FAILED;
    } else if (
      scheduledMigration &&
      scheduledMigration?.triggerAfter &&
      !hasShownModal
    ) {
      setLocalStorageKey(scheduledLocalStorageKey);
      selectedModalType = MODAL_TYPES.SCHEDULED;
    }

    if (selectedModalType) {
      const config = modalConfigs[selectedModalType];
      setModalConfig(config);
      setOpen(true);
    }
  }, [currentUser?.id, migrationStatus, scheduledMigration, t, teamId]);


  const onClickButton = () => {
    if (modalConfig?.onClickButton) {
      modalConfig.onClickButton({ localStorageKey, setOpen });
    }
  }

  if (!modalConfig || !open) return null

  return (
    <ComplexOverlayBox
      key={modalConfig.modalCopy.title}
      i18n={modalConfig.modalCopy}
      onClickButton={onClickButton}
      ImageComponent={ImageComponent}
      link={modalConfig.link}
      overrideClasses={{
        backdrop: classes.backdrop,
        description: classes.description,
      }}
      isShownOverlay
    >
      {null}
    </ComplexOverlayBox>
  )
};

const ImageComponent = (props) => {
  return (
    <img src={manOnBrowserBackgroundSvg} alt="man on browser" {...props} />
  );
};

const MODAL_TYPES = {
  COMPLETE: 'COMPLETE',
  SCHEDULED: 'SCHEDULED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
}

const getModalConfigs = (t, scheduledMigration) => ({
  [MODAL_TYPES.COMPLETE]: {
    modalCopy: {
      title: t('migration.modal.title'),
      subtitle: t('migration.modal.subtitle', { migrationDate: '' }),
      buttonText: t('migration.modal.buttonText'),
      description: '',
      linkText: t('migration.modal.goToDocs'),
    },
    onClickButton: () => {
      window.location.replace('https://app.fleek.xyz/')
    },
    link: 'https://fleek.xyz/docs/fleek-dot-co/',
  },
  [MODAL_TYPES.SCHEDULED]: {
    modalCopy: {
      title: t('migration.scheduledModal.title'),
      subtitle: t('migration.scheduledModal.subtitle', {
        migrationDate: new Date(scheduledMigration?.triggerAfter || '').toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      }),
      description: t('migration.scheduledModal.description'),
      buttonText: t('migration.scheduledModal.buttonText'),
      linkText: t('migration.scheduledModal.learnMore'),
    },
    onClickButton: ({ localStorageKey, setOpen }) => {
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, true);
      }
      toast.success(t('migration.scheduledModal.subtitle'));

      setOpen(false);
    },
    link: 'https://fleek.xyz/docs/fleek-dot-co/',
  },
  [MODAL_TYPES.FAILED]: {
    modalCopy: {
      title: t('migration.failed.title'),
      subtitle: t('migration.failed.subtitle'),
      description: t('migration.failed.description'),
      buttonText: t('migration.failed.buttonText'),
      linkText: t('migration.failed.learnMore'),
    },
    onClickButton: () => {
      window.open('https://fleek.xyz/requests/new/', undefined)
    },
    link: 'https://fleek.xyz/docs/fleek-dot-co/',
  },
  [MODAL_TYPES.IN_PROGRESS]: {
    modalCopy: {
      title: t('migration.inProgressModal.title'),
      subtitle: t('migration.inProgressModal.subtitle'),
      description: t('migration.inProgressModal.description'),
      buttonText: t('migration.inProgressModal.buttonText'),
      linkText: t('migration.inProgressModal.learnMore'),
    },
    onClickButton: () => {
      window.location.reload();
    },
    link: 'https://fleek.xyz/docs/fleek-dot-co/',
  }
});
