import gql from 'graphql-tag';

export const TEAM_INFO = gql`
  fragment TeamInfo on Team {
    id
    name
    creatorId
    seatCount
    membersCount
    stripeCustomerId
    pendingInviteCount
    status
  }
`;
