import { Octokit } from "@octokit/rest";

import { useGithubToken } from "../use-github-token";

export const useOctokit = (
  options?: ConstructorParameters<typeof Octokit>[0]
) => {
  const {
    auth,
    baseUrl = "https://api.github.com",
    ...otherOptions
  } = options || {};

  const [, githubToken] = useGithubToken();

  return new Octokit({ auth: auth || githubToken, baseUrl, ...otherOptions });
};
