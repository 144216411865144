import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  modal: {
    '& > div': {
      outline: 'none',
    },
  },
  errorBox: {
    width: 500,
    padding: '9px 15px',
    boxSizing: 'border-box',
    height: 68,
    backgroundColor: theme.palette.palette.errorRed,
    borderRadius: 4,
    position: 'absolute',
    top: '-70px',
    left: 0,
    color: 'white',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    marginTop: 10,
    marginBottom: 20,
    lineHeight: 1.29,
  },
  buttonContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 15,
    },
  },
  subtitle: {
    fontWeight: 500,
    margin: '20px 0',
    fontSize: 16,
  },
}));
