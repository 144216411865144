import axios from 'axios';
import { useEffect, useState } from 'react';

export const POPULAR_WEB3_PROVIDERS_NAME = ['Gnosis Safe Multisig', 'Rainbow'];
export const POPULAR_WEB3_PROVIDERS_SHORTNAME = ['Gnosis Safe', 'Rainbow'];

export function formatRegistryEntry(entry, platform = 'mobile') {
  return {
    name: entry.name || '',
    shortName: entry.metadata.shortName || '',
    color: entry.metadata.colors.primary || '',
    logo: entry.image_url.sm ?? '',
    universalLink: entry[platform].universal || '',
    deepLink: entry[platform].native || '',
  };
}

export function formatRegistry(registry, platform = 'mobile') {
  return Object.values(registry)
    .filter(
      (entry) => (!!entry[platform].universal || !!entry[platform].native)
          && (POPULAR_WEB3_PROVIDERS_NAME.includes(entry.name)
            || POPULAR_WEB3_PROVIDERS_SHORTNAME.includes(entry.shortName)),
    )
    .map((entry) => formatRegistryEntry(entry, platform));
}

const registryUrl = 'https://registry.walletconnect.com';

export const useWalletConnectRegistry = () => {
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    async function fetchProviders() {
      try {
        const { data } = await axios.get(`${registryUrl}/api/v2/wallets`);

        const formattedRegistry = formatRegistry(data.listings);

        setProviders(formattedRegistry);
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchProviders();
  }, []);

  return { isLoading, errorMessage, providers };
};
