// @ts-nocheck
import { useMutation } from "@apollo/react-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";
import { EDIT_DEPLOY_SETTINGS } from "src/views/Sites/graphql/mutations";
import { GET_SITE_BY_SLUG } from "src/views/Sites/graphql/queries";

import useStyles from "./styles";

export const AutoPublishButton = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { autoPublishing, siteId } = props;

  const match = useRouteMatch();

  // GraphQl mutation
  const [editDeploySettingsMut, { loading: editDeploySettingsMutLoading }] =
    useMutation(EDIT_DEPLOY_SETTINGS, {
      client: newApiClient,
    });

  // GraphQl cache handler
  const onUpdateCache =
    () =>
    (cache, { data }) => {
      // Read current state
      const currentState = cache.readQuery({
        query: GET_SITE_BY_SLUG,
        variables: {
          slug: match.params.siteSlug,
        },
      });

      // Update next state to persist latest
      cache.writeQuery({
        query: GET_SITE_BY_SLUG,
        variables: {
          slug: match.params.siteSlug,
        },
        data: {
          ...currentState,
          getSiteBySlug: {
            ...currentState.getSiteBySlug,
            deploySettings: {
              ...currentState.getSiteBySlug.deploySettings,
              autoPublishing:
                data.editDeploySettings.deploySettings.autoPublishing,
            },
          },
        },
      });
    };

  // GraphQl call handler
  const editDeploySettingsHandler = useCallback(async () => {
    if (editDeploySettingsMutLoading) {
      return;
    }

    try {
      await editDeploySettingsMut({
        variables: {
          input: {
            siteId,
            autoPublishing: !autoPublishing,
          },
        },
        update: onUpdateCache(),
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, autoPublishing, editDeploySettingsMutLoading]);

  return (
    <GenericButton
      buttonVariant="secondary"
      overrideClass={{ button: classes.root }}
      onClick={editDeploySettingsHandler}
    >
      {(editDeploySettingsMutLoading && (
        <CircularProgress size="20px" className={classes.spinner} />
      )) ||
        t(`autopublishing.cta.${autoPublishing ? "stop" : "start"}`)}
    </GenericButton>
  );
};

AutoPublishButton.propTypes = {
  autoPublishing: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
};
