import gql from "graphql-tag";

import { SITE_DETAIL } from "../../views/Sites/graphql/fragments";

export default gql`
  mutation verifyEnsConfiguration($input: VerifyEnsConfigurationInput!) {
    verifyEnsConfiguration(input: $input) {
      ...SiteDetail
    }
  }
  ${SITE_DETAIL}
`;
