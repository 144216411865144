import useStyles from "./styles";

const DiscordSupportAction = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.gg/fleek"
      >
        <img alt="fleek-loading" src="/discord.svg" className={classes.logo} />
      </a>
    </div>
  );
};

export default DiscordSupportAction;
