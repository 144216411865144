import { useMutation } from "@apollo/react-hooks";
import get from "lodash/get";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";
import { newApiClient } from "src/clients";
import { GA_EVENTS_CATEGORIES } from "src/constants";
import { useAccountId } from "src/hooks";
import { GET_BUCKET_BY_SLUG } from "src/views/Teams/Storage/graphql";
import { ADD_STORAGE_CUSTOM_DOMAIN } from "src/views/Teams/Storage/graphql/mutations";

import AddCustomDomainAction from "./add-action";
import AddStorageCustomDomainFormBody from "./domain-name";

export type AddStorageCustomDomainFormProps = {
  closeModal: () => void;
};

export const AddStorageCustomDomainForm = ({
  closeModal,
}: AddStorageCustomDomainFormProps) => {
  const { t } = useTranslation();
  const teamId = useAccountId();
  const { params } = useRouteMatch<{ bucketName?: string }>();
  const bucketSlug = params.bucketName;

  const [addStorageCustomDomainMutation] = useMutation(
    ADD_STORAGE_CUSTOM_DOMAIN,
    {
      client: newApiClient as any,
      update: (cache, { data: { addStorageCustomDomain } }) => {
        const data: any = cache.readQuery({
          query: GET_BUCKET_BY_SLUG,
          variables: {
            slug: bucketSlug,
          },
        });

        const newData = {
          ...data,
          getBucketBySlug: {
            ...data.getBucketBySlug,
            ...addStorageCustomDomain,
          },
        };

        cache.writeQuery({
          query: GET_BUCKET_BY_SLUG,
          variables: {
            slug: bucketSlug,
          },
          data: newData,
        });
      },
    }
  );

  const [state, setState] = useState({
    error: null,
    isLoading: false,
    domainName: "",
  });

  const handleAddStorageCustomDomain = async () => {
    setState({
      ...state,
      isLoading: true,
    });

    const domain = state.domainName;

    window.ga(
      "send",
      "event",
      GA_EVENTS_CATEGORIES.STORAGE,
      "Add custom storage domain",
      domain
    );
    window.analytics.track("Add custom storage domain", {
      teamId,
      domain,
      bucketSlug,
    });

    try {
      const { data } = await addStorageCustomDomainMutation({
        variables: {
          input: {
            domainName: state.domainName,
            bucketSlug: bucketSlug,
          },
        },
      });

      const success = get(data, "addStorageCustomDomain");

      if (success) {
        closeModal();
      }

      setState({
        ...state,
        isLoading: false,
      });
    } catch (error: any) {
      console.error("Error adding storage custom domain: ", error.message);

      setState({
        ...state,
        isLoading: false,
        error: t("addCustomDomain.errors.add"),
      });
    }
  };

  const onDomainNameChange = (value: string) => {
    const newState = {
      ...state,
      domainName: value.replace(/\s/, "").toLowerCase(),
    };

    if (state.error) {
      newState.error = null;
    }

    setState(newState);
  };

  const hostnameRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
  const isFormValid =
    state.domainName.length > 0 && hostnameRegex.test(state.domainName);

  return (
    <div id="test-add-storage-custom-domain">
      <AddStorageCustomDomainFormBody
        error={state.error}
        domainName={state.domainName}
        onDomainNameChange={onDomainNameChange}
      />
      <AddCustomDomainAction
        isLoading={state.isLoading}
        onAdd={handleAddStorageCustomDomain}
        onCancel={closeModal}
        isFormValid={isFormValid}
      />
    </div>
  );
};
