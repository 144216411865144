import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setAccounts,
  setChain,
  setIsConnected,
  setWeb3Provider,
} from "src/reducers/actions/web3-state";

import { getChainName } from "../ethereum/utils";

export const useWalletExtension = (options) => {
  const dispatch = useDispatch();

  const { onAccountsChanged, onChainChanged } = options || {};

  const isMounted = useRef(true);
  const isConnecteCalled = useRef(false);

  const [provider] = useState(window.ethereum);

  const getAccounts = async (
    { requestPermission } = { requestPermission: false }
  ) => {
    if (!provider) {
      throw new Error("Extension is not available.");
    }
    const accounts = await provider.request({
      method: requestPermission ? "eth_requestAccounts" : "eth_accounts",
      params: [],
    });

    if (accounts?.length) {
      dispatch(setAccounts(accounts));
      if (requestPermission) {
        dispatch(setIsConnected(true));
      }
    }

    return accounts;
  };

  const getChain = async () => {
    if (!provider) {
      throw new Error("Extension is not available.");
    }

    const chainId = await provider.request({
      method: "net_version",
      params: [],
    });
    const chainName = getChainName(chainId);
    const chainData = { id: chainId, name: chainName };

    dispatch(setChain(chainData));

    return chainData;
  };

  const _onAccountsChanged = useCallback(
    (accounts) => {
      if (!accounts?.length) dispatch(setIsConnected(false));
      dispatch(setAccounts(accounts));

      if (onAccountsChanged) {
        onAccountsChanged(accounts);
      }
    },
    [dispatch, onAccountsChanged]
  );

  useEffect(() => {
    if (onAccountsChanged) {
      window.ethereum?.on("accountsChanged", _onAccountsChanged);
    }

    return function cleanup() {
      window.ethereum?.removeListener("accountsChanged", _onAccountsChanged);
    };
  }, [onAccountsChanged, _onAccountsChanged]);

  const initialize = async (
    Web3Interface,
    settings = {},
    connectionSettings = { requestPermission: true }
  ) => {
    if (!provider) throw Error("Extension is not available.");
    if (!Web3Interface) {
      throw Error(
        "Web3 Provider is required. You can use either ethers.js or web3.js."
      );
    }
    if (!isMounted.current) throw Error("Component is not mounted.");
    isConnecteCalled.current = true;

    const web3Provider = new Web3Interface(
      ...(Object.keys(settings).length ? [provider, settings] : [provider])
    );

    dispatch(setWeb3Provider(web3Provider));

    await getAccounts(connectionSettings);
    await getChain();

    window.ethereum?.on("chainChanged", (chainId) => {
      if (onChainChanged) {
        onChainChanged(chainId);
      }

      const parsedChainId = parseInt(chainId, 16).toString();
      const chainInfo = {
        id: parsedChainId,
        name: getChainName(parsedChainId),
      };
      dispatch(setChain(chainInfo));
    });

    isConnecteCalled.current = false;

    return web3Provider;
  };

  const metadata = useMemo(() => {
    const frameMetadata = {
      src: "/images/frame.png",
      subSrc: "/images/frame.png",
      name: "Frame",
      description: "Make sure you’re using correct Frame account!",
    };

    const metaMaskMetadata = {
      src: "/images/meta-mask.png",
      subSrc: "/images/meta-mask-sm.png",
      name: "MetaMask",
      description: "Make sure you’re using correct Metamask account!",
    };

    if (provider?.isFrame) {
      return frameMetadata;
    }

    return metaMaskMetadata;
  }, [provider]);

  return {
    metadata,
    initialize,
    getAccounts,
    getChain,
    isAvailable: Boolean(provider),
  };
};
