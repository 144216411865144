import en from "./en/translation.json";

const locales = {
  resources: {
    en: { translation: en },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
};

export default locales;
