import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import DropdownWithPhoto from "@terminal-packages/ui/core/DropdownWithPhoto";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { Info, StepBase } from "src/components";
import { useAddNewSiteContext } from "src/views/Start/container/add-new-site";

import { HOSTING_SERVICES } from "./constants";
import useStyles from "./styles";

const DeployLocation = () => {
  const { goToNextStep } = useAddNewSiteContext();

  const classes = useStyles();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const [hostingService, setHostingService] = useState(HOSTING_SERVICES[0]);

  return (
    <StepBase
      title={t("sites.start.deployLocation.title")}
      subtitle={
        <>
          {t("sites.start.deployLocation.subtitle")}&nbsp;
          <Link
            target="_blank"
            href="http://docs.fleek.co/"
            className={classes.link}
          >
            {t("sites.start.deployLocation.clickHere") as string}
          </Link>
          .
        </>
      }
    >
      <Box
        marginTop="25px"
        marginBottom="5px"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box maxWidth={334} width="100%">
          <DropdownWithPhoto
            value={hostingService}
            objects={HOSTING_SERVICES}
            label={t("sites.start.deployLocation.hostingServices")}
            onSelect={(selection: any) => {
              setHostingService(selection);
            }}
          />
        </Box>
        <Box ml="12px">
          <Info text={t("sites.start.deployLocation.info")} />
        </Box>
      </Box>
      <GenericButton
        textVariant="button"
        buttonVariant="primary"
        overrideClass={{
          button: classes.button,
        }}
        onClick={() =>
          goToNextStep({
            installationId: (match.params as any).installationId,
            accountName: (match.params as any).accountName,
            repositoryName: (match.params as any).repositoryName,
            hostingServiceId: hostingService.id,
          })
        }
        css={undefined}
      >
        {t("common.continue") as string}
      </GenericButton>
    </StepBase>
  );
};

export default DeployLocation;
