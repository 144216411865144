import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  button: {
    marginTop: 20,
    height: 34,
    width: 108,
    '& span': {
      color: theme.palette.palette.white,
    },
  },
  link: {
    color: '#006EFF',
  },
  infoIcon: {
    display: 'flex',
    fontSize: 14,
    color: '#666666',
  },
  experimentalLabel: {
    border: `1px solid ${theme.palette.palette.fleekPink}`,
    borderRadius: 3,
    padding: '0 3px',
    color: theme.palette.palette.fleekPink,
  },
}));
