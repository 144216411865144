import { ButtonBase, Tooltip } from "@material-ui/core";
import { IconFA } from "@terminal-packages/ui";
import classnames from "classnames";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tag } from "src/components/tag";
import { setLocalStorageState } from "src/reducers/actions/local-storage";
import { useTeam } from "src/store/AuthContext";

import useStyles from "./styles";

const LinkTooltip = ({ tooltipContent }) => {
  const classes = useStyles();

  const TooltipIcon = (
    <ButtonBase
      className={classnames(
        classes.buttonTooltip,
        classes.storageDisabledTooltip
      )}
    >
      <IconFA
        icon={["fal", "info-circle"]}
        size="inherit"
        className={classes.icon}
      />
    </ButtonBase>
  );

  return (
    <Tooltip
      title={tooltipContent}
      placement="right"
      arrow={false}
      classes={{
        tooltip: classes.tooltip,
      }}
      interactive
    >
      {TooltipIcon}
    </Tooltip>
  );
};

const TooltipLink = ({ link, title }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    {title}
  </a>
);

const NewUserStorageLink = ({ to, className, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { canUseStorage } = useTeam();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.localStorage);

  useEffect(() => {
    // Use local storage to check if user has seen the new feature
    const seenNewStorageFeature = localStorage.getItem("seen_new_storage_feature");

    if (seenNewStorageFeature) {
      dispatch(
        setLocalStorageState({
          seenNewStorageFeature: true,
        })
      );
    }
  }, [dispatch]);

  return (
    <div
      className={classnames(classes.container, {
        [classes.disabled]: canUseStorage,
      })}
    >
      {canUseStorage ? (
        <p className={classnames(className, classes.disabled)}>{children}</p>
      ) : (
        <Link to={to} className={classnames(className, classes.link)}>
          {children}
          {!state.seenNewStorageFeature && (
            <Tag style={{ marginLeft: "auto", marginRight: "20px" }} size="sm">
              New
            </Tag>
          )}
        </Link>
      )}
      {canUseStorage && (
        <LinkTooltip
          tooltipContent={
            <p className={classes.tooltipContent}>
              {t("layout.sidebar.storageDisabled")}{" "}
              <TooltipLink link="https://www.fleek.xyz" title="fleek.xyz" />
              {", "}
              <TooltipLink
                link="https://twitter.com/fleekxyz"
                title="@fleekxyz"
              />
              {", "}
              {t("layout.sidebar.orOur")}{" "}
              <TooltipLink link="https://discord.gg/fleek" title="Discord" />
            </p>
          }
        />
      )}
    </div>
  );
};

export default NewUserStorageLink;
