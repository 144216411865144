// @ts-nocheck
import NavigationList from "@terminal-packages/ui/core/NavigationList";

import useStyles from "./styles";

export const EnhancedNavigationList = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <NavigationList {...props} />
    </div>
  );
};
