import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  sectionContent: {
    display: "flex",
    flexDirection: "column",
    padding: "25px 30px",
  },
  button: {
    maxWidth: 192,
    marginTop: 15,
  },
}));
