import BaseModal from "@terminal-packages/ui/core/BaseModal";
import { useTranslation } from "react-i18next";

import { AddStorageCustomDomainForm } from "./components/Form";

export type AddStorageCustomDomainModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export const AddStorageCustomDomainModal = ({
  isOpen = false,
  closeModal,
}: AddStorageCustomDomainModalProps) => {
  const { t } = useTranslation();

  return (
    <BaseModal title={t('addStorageCustomDomain.title') as string} open={isOpen} maxWidth={450} onClose={closeModal}>
      <AddStorageCustomDomainForm closeModal={closeModal} />
    </BaseModal>
  );
};
