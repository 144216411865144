import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  'root': {
    backgroundColor: "rgb(80, 135, 236)",
    color: "white",
    fontWeight: "bold",
  },
  'sm': {
    fontSize: "10px",
    borderRadius: "4px",
    paddingLeft: "3px",
    paddingRight: "3px",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  'md': {
    fontSize: "12px",
    borderRadius: "4px",
    paddingLeft: "6px",
    paddingRight: "6px",
    paddingTop: "4px",
    paddingBottom: "4px",
  }
}));
