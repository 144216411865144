// @ts-nocheck
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import PropTypes from "prop-types";
import AddCustomDomainForm from "src/views/Sites/AddCustomDomain/container/AddCustomDomainForm";


export const AddCustomDomainModal = ({ open, closeModal, siteId }) => {
  return (
    <BaseModal
      open={open}
      maxWidth={450}
      onClose={closeModal}
    >
      <AddCustomDomainForm siteId={siteId} closeModal={closeModal} />
    </BaseModal>
  );
};

AddCustomDomainModal.defaultProps = {
  open: false,
};

AddCustomDomainModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
};
