import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import React from "react";
import { useTranslation } from "react-i18next";

import useStyles from "./styles";

export type SubmitSectionProps = {
  isDeployLoading: boolean;
  onDeploy: () => void;
  disabled: boolean;
};

const SubmitSection = ({
  isDeployLoading,
  onDeploy,
  disabled,
}: SubmitSectionProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const overrideClass = {
    button: classes.button,
  };

  return (
    <GenericButton
      onClick={onDeploy}
      overrideClass={overrideClass}
      buttonVariant="primary"
      textVariant="button"
      disabled={disabled}
      loading={isDeployLoading}
      css={undefined}
    >
      {t("sites.start.buildOptions.deploySiteMsg") as string}
    </GenericButton>
  );
};

export default SubmitSection;
