import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import React from "react";
import { useTranslation } from "react-i18next";

import useStyles from "./styles";

export type AddCustomDomainActionProps = {
  isLoading: boolean;
  onAdd: () => void;
  onCancel: () => void;
  isFormValid: boolean;
};

const AddCustomDomainAction = ({
  isLoading,
  onAdd,
  onCancel,
  isFormValid,
}: AddCustomDomainActionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {/* @ts-ignore */}
      <GenericButton
        loading={isLoading}
        buttonVariant="primary"
        onClick={onAdd}
        disabled={!isFormValid}
        overrideClass={{ button: classes.confirmButton }}
      >
        {t("addStorageCustomDomain.add") as string}
      </GenericButton>
      {/* @ts-ignore */}
      <GenericButton
        disabled={isLoading}
        onClick={onCancel}
        buttonVariant="secondary"
      >
        {t("common.cancel") as string}
      </GenericButton>
    </div>
  );
};

export default AddCustomDomainAction;
