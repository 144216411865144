import { useDispatch, useSelector } from "react-redux";

import {
  API_DETAILS_MODAL,
  APP_API_DETAILS_MODAL,
  closeModal,
  CONFIRM_DNS_UPGRADE_MODAL,
  CREATE_FOLDER_MODAL,
  DELETE_PROFILE_MODAL,
  DELETE_STORAGE_ITEMS_MODAL,
  DNS_A_RECORD_MODAL,
  DNS_CNAME_MODAL,
  DNS_LINK_MODAL,
  EDIT_TEAM_INFO_MODAL,
  EDIT_USER_INFO_MODAL,
  HNS_MODAL,
  PAYMENT_METHOD_MODAL,
  PROFILE_MODAL,
  TWO_FACTOR_AUTH_NUMBER_MODAL,
  TWO_FACTOR_AUTH_VERIFY_CODE_MODAL,
  TWO_FACTOR_AUTH_VERIFY_EMAIL_MODAL,
  UPLOAD_ENV_FILE,
  UPLOAD_ITEMS_MODAL,
  WALLET_LINK_MODAL,
} from "./actions";
import { ApiDetailsModal } from "./api-details";
import { AppApiDetailsModal } from "./app-api-details";
import { ConfirmDnsUpgrade } from "./confirm-dns-upgrade";
import { CreateFolderModal } from "./create-folder";
import { DeleteProfileModal } from "./delete-profile";
import { DeleteStorageItemsModal } from "./delete-storage-items";
import { DnsARecordModal } from "./dns-a-record-modal";
import { DnsCnameModal } from "./dns-c-name-modal";
import { DnsLinkModal } from "./dns-link";
import { EditTeamInfo } from "./edit-team-info";
import { EditUserInfo } from "./edit-user-info";
import { HnsModal } from "./hns-modal";
import { PaymentMethodModal } from "./payment-method-modal";
import { ProfileModal } from "./profile-modal";
import { EnterNumber } from "./two-factor-auth/enter-number";
import { VerifyCode } from "./two-factor-auth/verify-code";
import { VerifyEmail } from "./two-factor-auth/verify-email";
import { UploadEnvFileModal } from "./upload-env-file-modal";
import { UploadFilesModal } from "./upload-files";
import { WalletLinkModal } from "./wallet-link-modal";

const MODALS = {
  [DNS_LINK_MODAL]: DnsLinkModal,
  [PAYMENT_METHOD_MODAL]: PaymentMethodModal,
  [DELETE_STORAGE_ITEMS_MODAL]: DeleteStorageItemsModal,
  [CREATE_FOLDER_MODAL]: CreateFolderModal,
  [UPLOAD_ITEMS_MODAL]: UploadFilesModal,
  [API_DETAILS_MODAL]: ApiDetailsModal,
  [HNS_MODAL]: HnsModal,
  [APP_API_DETAILS_MODAL]: AppApiDetailsModal,
  [EDIT_USER_INFO_MODAL]: EditUserInfo,
  [DELETE_PROFILE_MODAL]: DeleteProfileModal,
  [DNS_CNAME_MODAL]: DnsCnameModal,
  [DNS_A_RECORD_MODAL]: DnsARecordModal,
  [CONFIRM_DNS_UPGRADE_MODAL]: ConfirmDnsUpgrade,
  [EDIT_TEAM_INFO_MODAL]: EditTeamInfo,
  [TWO_FACTOR_AUTH_NUMBER_MODAL]: EnterNumber,
  [TWO_FACTOR_AUTH_VERIFY_CODE_MODAL]: VerifyCode,
  [TWO_FACTOR_AUTH_VERIFY_EMAIL_MODAL]: VerifyEmail,
  [WALLET_LINK_MODAL]: WalletLinkModal,
  [PROFILE_MODAL]: ProfileModal,
  [UPLOAD_ENV_FILE]: UploadEnvFileModal,
};

export const Modal = () => {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => (state as any).modal);

  const closeModalHandler = () => dispatch(closeModal());

  if (!modalState.open) return null;

  const ModalComponent = MODALS[modalState.id as keyof typeof MODALS];

  if (!ModalComponent) return null;

  return (
    <ModalComponent {...modalState.props} closeModal={closeModalHandler} open />
  );
};
