import gql from 'graphql-tag';

import { BUCKET_DETAIL } from '../fragments';

export default gql`
  mutation addStorageCustomDomain($input: AddStorageCustomDomainInput!) {
    addStorageCustomDomain(input: $input) {
      ...BucketDetail
    }
  }
  ${BUCKET_DETAIL}
`;
