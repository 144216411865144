import { dripStitches, Grid } from '@fleekhq/react-drip';
import { makeStyles } from '@material-ui/core/styles';

const { styled } = dripStitches;

export const UploadEnvFileContainer = styled(Grid, {
  position: 'absolute',
  gap: '$4',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '$8',
  backgroundColor: '$contrast',
  borderRadius: '$xl',
  fontFamily: 'Manrope',
  "* > svg": { width: "auto", height: "auto" },
  '& h1, h2, h3, h4, h5, h6, p': { margin: 0 },
});

export default makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    margin: '18px 18px 0',
    alignSelf: 'end'
  },
  hidden: {
    visibility: 'hidden'
  },
  uploadedFileContainer: {
    position: 'absolute',
    padding: 32,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  fileImgContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 0',
    gap: 10
  },
  fileImg: {
    alignSelf: 'center',
    height: 100,
  },
  cancelButton: {
    marginRight: 10
  },
  error: {
    color: 'red',
    fontSize: 13,
  }
}));
