import React, { forwardRef } from 'react'

import useStyles from './tag.styles';

export type TagProps = {
  children: React.ReactNode
  size?: 'sm' | 'md'
}

export const Tag = forwardRef<HTMLSpanElement, TagProps>(({size = 'md', ...props}, ref) => {
  const classes = useStyles();

  const sizeClassName = classes[size];
  
  return (
    <span ref={ref} className={`${classes.root} ${sizeClassName}`} {...props}  />
  )
})
