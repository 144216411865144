import { useMutation } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import { toast } from "@terminal-packages/ui/core/Toast";
import PropTypes from "prop-types";
import React from "react";
import { newApiClient } from "src/clients";
import { CANCEL_DEPLOY } from "src/views/Sites/graphql/mutations";

const CancelButton = ({
  disabled,
  setMutationInProgress,
  classes,
  t,
  siteId,
  deployId,
}) => {
  const [onClickCancel] = useMutation(CANCEL_DEPLOY, {
    client: newApiClient,
    variables: {
      siteId,
      deployId,
    },
  });

  const onClick = async () => {
    try {
      setMutationInProgress(true);
      await onClickCancel();
      setMutationInProgress(false);
    } catch {
      setMutationInProgress(false);
      toast.error(
        t("sites.tabs.deploys.sections.deployStatus.cancelDeployError")
      );
    }
  };

  return (
    <GenericButton
      disabled={disabled}
      onClick={onClick}
      buttonVariant="important"
      overrideClass={{ button: classes.settingsButton }}
    >
      <Typography variant="body2">
        {t("sites.tabs.deploys.sections.deployStatus.cancelDeploy")}
      </Typography>
    </GenericButton>
  );
};

CancelButton.propTypes = {
  classes: PropTypes.shape({
    settingsButton: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  setMutationInProgress: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  deployId: PropTypes.string.isRequired,
};

export default CancelButton;
