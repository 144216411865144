// @ts-nocheck
import Typography from "@material-ui/core/Typography";
import BaseModal from "@terminal-packages/ui/core/BaseModal";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/store/AuthContext";

import useStyles from "./styles";
import mask from "./utils.ts";

export const VerifyEmail = ({ open, closeModal }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  return (
    <BaseModal
      open={open}
      title={t("modals.getTwoFa.email.title")}
      maxWidth={500}
      onClose={closeModal}
      className={classes.modal}
    >
      <Typography variant="body2" className={classes.description}>
        {t("modals.getTwoFa.email.setup")}
      </Typography>
      <Typography variant="body2" className={classes.description}>
        {t("modals.getTwoFa.email.sentAnEmail")} {mask(currentUser.email)},{" "}
        {t("modals.getTwoFa.email.instructions")}
      </Typography>
    </BaseModal>
  );
};

VerifyEmail.defaultProps = {
  open: false,
};

VerifyEmail.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};
