import Typography from '@material-ui/core/Typography';
import Box from '@terminal-packages/ui/core/Box';
import GenericButton from '@terminal-packages/ui/core/Buttons/GenericButton';
import IconFA from '@terminal-packages/ui/core/IconFA';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useStyles from './styles';

const HandshakeSuccess = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const backToFleekOnClick = () => {
    const { redirectTo } = qs.parse(location.search);
    const backToFleekUrl = decodeURIComponent(redirectTo);
    window.location.href = backToFleekUrl;
    window.location.reload();
  };

  return (
    <div className={classes.container}>
      <img
        className={classes.logo}
        src="https://storage.googleapis.com/terminal-assets/images/fleek/fleek-logo.png"
        alt="fleek-logo"
      />
      <Box
        overrideClass={{
          wrapper: classes.box,
        }}
      >
        <div className={classes.checkIcon}>
          <IconFA
            fontSize="inherit"
            icon={['fas', 'check']}
          />
        </div>
        <Typography className={classes.title}>
          {t('handshakeSuccess.title')}
        </Typography>
        <Typography className={classes.description}>
          {t('handshakeSuccess.message')}
        </Typography>
        <GenericButton
          buttonVariant="primary"
          onClick={backToFleekOnClick}
          className={classes.namebaseRedirectButton}
        >
          {t('handshakeSuccess.button')}
        </GenericButton>
      </Box>
    </div>
  );
};

export default HandshakeSuccess;
