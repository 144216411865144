import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import { url } from "src/utils";

import { BucketType } from "../..";
import useStyles from "./styles";

export type DomainInfoProps = Pick<BucketType, "domain" | "domainStatus">;

const DomainInfo = ({ domain, domainStatus }: DomainInfoProps) => {
  const classes = useStyles();

  return (
    <div className={classes.domainInfo}>
      {domainStatus === "PROPAGATED" ? (
        <Typography variant="body2">
          <a
            href={`https://${domain}`}
            target="_blank"
            rel="noopener noreferrer"
            className={classnames(classes.domainText, classes.activeText)}
            onClick={() => {
              window.analytics.track(
                "Propagated added custom storage domain click",
                {
                  domain,
                  teamId: url.getAccountIdFromUrl(),
                }
              );
            }}
          >
            {domain}
          </a>
        </Typography>
      ) : (
        <Typography
          variant="body2"
          className={classnames(classes.domainText, classes.inactiveText)}
        >
          {domain}
        </Typography>
      )}
    </div>
  );
};

export default DomainInfo;
