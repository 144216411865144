import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import Chip from "@terminal-packages/ui/core/Chip";
import List from "@terminal-packages/ui/core/List";
import { toast } from "@terminal-packages/ui/core/Toast";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  openModal,
  TWO_FACTOR_AUTH_NUMBER_MODAL,
  TWO_FACTOR_AUTH_VERIFY_EMAIL_MODAL,
} from "src/modals/actions";
import { useAuth } from "src/store/AuthContext";

import { projectAuth } from "../../../../../../firebase";
import useStyles from "./styles";

const TwoFactorAuthentication = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [state, setState] = useState({
    loading: false,
  });

  const setup2Fa = async (event) => {
    event.preventDefault();
    if (currentUser.emailVerified) {
      dispatch(openModal(TWO_FACTOR_AUTH_NUMBER_MODAL));
    } else {
      setState({
        ...state,
        loading: true,
      });
      try {
        await projectAuth.currentUser.sendEmailVerification();
        dispatch(openModal(TWO_FACTOR_AUTH_VERIFY_EMAIL_MODAL));
        setState({
          ...state,
          loading: false,
        });
      } catch (error) {
        toast.error(`${t("auth.emailVerificationFailed")}: ${error.message}`, {
          autoClose: 6000,
        });
        setState({
          ...state,
          loading: false,
        });
      }
    }
  };

  const twoFaStatus = () => {
    let twoFAEnabled = false;
    if (
      currentUser &&
      currentUser.twoFactorAuth &&
      currentUser.twoFactorAuth.enabled
    ) {
      twoFAEnabled = true;
    }
    return (
      <Chip
        text={t(
          `${
            twoFAEnabled
              ? "accountSettings.twoFaAuth.card.statusEnabled"
              : "accountSettings.twoFaAuth.card.statusDisabled"
          }`
        )}
        color={twoFAEnabled ? "green" : "red"}
      />
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="body1">
        <Box component="span" fontWeight={500}>
          {t("accountSettings.twoFaAuth.title")}
        </Box>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("accountSettings.twoFaAuth.description")}
      </Typography>
      <br />
      <CardTitled
        secondaryContent={
          currentUser && currentUser.multiFactor && twoFaStatus()
        }
        mainContent={t("accountSettings.twoFaAuth.card.title")}
        classes={{
          content: classes.content,
        }}
      >
        <List striped className={classes.item}>
          {currentUser &&
          currentUser.twoFactorAuth &&
          currentUser.twoFactorAuth.enabled
            ? `${t("accountSettings.twoFaAuth.card.enabledPlaceholder")} ${
                currentUser.twoFactorAuth.phoneNumberMasked
              }`
            : t("accountSettings.twoFaAuth.card.disabledPlaceholder")}
        </List>
        <br />
        {currentUser &&
          currentUser.twoFactorAuth &&
          !currentUser.twoFactorAuth.enabled && (
            <div className={classes.buttonContainer}>
              <GenericButton
                buttonVariant="primary"
                className={classes.setupButton}
                onClick={setup2Fa}
                disabled={state.loading}
                loading={state.loading}
              >
                {t("accountSettings.twoFaAuth.card.new")}
              </GenericButton>
            </div>
          )}
      </CardTitled>
    </div>
  );
};

export default TwoFactorAuthentication;
