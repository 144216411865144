import { useTranslation } from "react-i18next";
import { PickRepository, StepBase } from "src/components";
import { useAddNewSiteContext } from "src/views/Start/container/add-new-site";

const PickRepositoryStep = () => {
  const { t } = useTranslation();
  const { goToNextStep, goToPreviousStep } = useAddNewSiteContext();

  return (
    <StepBase
      title={t("sites.start.pickRepository.title")}
      subtitle={t("sites.start.pickRepository.subtitle")}
    >
      <PickRepository onError={goToPreviousStep} onSuccess={goToNextStep} />
    </StepBase>
  );
};

export default PickRepositoryStep;
