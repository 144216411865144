import {
  RESET_STATE,
  SET_ACCOUNTS,
  SET_CHAIN,
  SET_ENS,
  SET_IS_CONNECTED,
  SET_WEB3_PROVIDER,
} from "./actions/web3-state";

export const WEB3_INITIAL_STATE = {
  accounts: [],
  chain: {},
  isConnected: false,
  web3Provider: null,
  ens: {
    name: null,
    avatar: null,
  },
};

const web3Reducer = (state = WEB3_INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_WEB3_PROVIDER:
      return {
        ...state,
        web3Provider: action.payload,
      };

    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };

    case SET_CHAIN:
      return {
        ...state,
        chain: action.payload,
      };

    case SET_IS_CONNECTED:
      return {
        ...state,
        isConnected: action.payload,
      };

    case SET_ENS:
      return {
        ...state,
        ens: action.payload,
      };

    case RESET_STATE:
      return WEB3_INITIAL_STATE;

    default:
      return state;
  }
};

export default web3Reducer;
