import ArrowLink from "@terminal-packages/ui/core/ArrowLink";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { AddEnsDomainModal } from "src/modals";

import useStyles from "./styles";

const Footer = () => {
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();

  const openAddEnsDomainModal = () => setOpenModal(true);
  const closeAddEnsDomainModal = () => setOpenModal(false);


  return (
    <>
      <a
        href="https://docs.fleek.co/domain-management/ens-domains"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.resetAnchorStyles}
      >
        <ArrowLink className={classes.link}>
          {t("sites.tabs.settings.ens.noDomain.learnMore")}
        </ArrowLink>
      </a>
      <GenericButton
        onClick={openAddEnsDomainModal}
        buttonVariant="primary"
        className={classes.button}
      >
        {t("sites.tabs.settings.ens.noDomain.addEns")}
      </GenericButton>
      <AddEnsDomainModal open={openModal} closeModal={closeAddEnsDomainModal} />
    </>
  );
};

export default Footer;
